import { Panel } from '@cancercentrum/rcc-bootstrap';
import type { Utskicksprojekt } from '../types';
import useDataSet from '../../../hooks/useDataSet';
import { NotInProject } from './NotInProject';
import { SurveyViewer } from './SurveyViewer';
import { SurveyHeader } from './SurveyHeader';
import { PatientPortal } from './PatientPortal';

export const ProjectViewer = (props: {
    utskicksprojekt: Utskicksprojekt;
    otherProjectsForSameSurvey: Utskicksprojekt[];
    sparrad: boolean;
    reload: () => void;
}) => {
    const { utskicksprojekt, reload, sparrad, otherProjectsForSameSurvey } = props;
    const isInUtskicksprojekt = !!utskicksprojekt.svarRootTableId;
    const ds = useDataSet();
    const hasPatientPortal = !!ds.Patientportal;

    return (
        <Panel className="mt-2">
            <Panel.Heading>
                <SurveyHeader utskicksprojekt={utskicksprojekt} sparrad={sparrad} />
            </Panel.Heading>
            <Panel.Body>
                {process.env.RCC_ENV !== 'external_demo' && (
                    <div>
                        {hasPatientPortal && !sparrad && (utskicksprojekt.config.canAdd || !!utskicksprojekt.config.allow1177) && (
                            <div className="mb-2">
                                <PatientPortal
                                    svarRootTableId={utskicksprojekt.svarRootTableId}
                                    deltagare={utskicksprojekt.deltagare}
                                />
                            </div>
                        )}
                        {!isInUtskicksprojekt && <NotInProject utskicksprojekt={utskicksprojekt} reload={reload} sparrad={sparrad} />}
                    </div>
                )}
                {isInUtskicksprojekt && <SurveyViewer utskicksprojekt={utskicksprojekt} otherProjectsForSameSurvey={otherProjectsForSameSurvey} />}
            </Panel.Body>
        </Panel>
    );
};
