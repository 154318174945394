import classnames from 'classnames';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { Explanation } from '@cancercentrum/rcc-react';
import type { ReactElement, ReactNode } from 'react';
import type { RegvarMetadata } from '@cancercentrum/inca';

export interface StaticQuestionProps {
    regvar: RegvarModel<RegvarMetadata>;
    value: ReactNode;
    labelClassName: string;
    wrapperClassName: string;
    className?: string;
    label?: ReactNode;
    labelHelp?: ReactNode;
}

export const StaticQuestion = (props: StaticQuestionProps): ReactElement => {
    const { regvar, value, labelClassName, wrapperClassName, className, label, labelHelp } = props;

    return (
        <div className={classnames('question', className)}>
            <div className="form-group row">
                <label className={classnames('form-label', labelClassName)}>{label || regvar.$rcc.regvarMetadata.label} {!!labelHelp && <Explanation>{labelHelp}</Explanation>}</label>
                <div className={wrapperClassName}>
                    <div className="form-control-static">{value}</div>
                </div>
            </div>
        </div>
    );
};
