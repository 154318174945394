import { DropdownList } from '@cancercentrum/rcc-react';
import type { ReactElement } from 'react';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import type { RegvarValueDomainMetadata } from '@cancercentrum/inca';
import { formatStudieElement, formatStudieText, useSelectedVDStudier } from '../studie';
import Question from '../../components/IPOQuestion';
import type { IPOQuestionProps } from '../../components/IPOQuestion';

interface LkmStudieQuestionProps extends IPOQuestionProps {
    regvar: RegvarModel<RegvarValueDomainMetadata>;
}

/**
 * Question för startdatum där maxDate är satt till dagens datum + 4 veckor (IPO-361).
 */
export const LkmStudieQuestion = (props: LkmStudieQuestionProps): ReactElement => {
    const {
        regvar,
        ...rest
    } = props;
    const studier = useSelectedVDStudier(regvar.getValue());

    return (
        <Question
            regvar={regvar}
            className="studie-question"
            options={studier}
            componentClass={DropdownList}
            formatter={formatStudieElement}
            formatterText={formatStudieText}
            {...rest}
        />
    );
};
