import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import type { ReactElement } from 'react';
import { ECOGColors } from '../../constants';

export const EcogIcon = (props: {
    value: string;
    className?: string;
}): ReactElement => {
    const { value, className } = props;
    const color = ECOGColors[value];

    return <FontAwesomeIcon icon={faCircle} className={className} style={{ color, stroke: 'black', strokeWidth: 40 }} />;
};
