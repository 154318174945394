import { memo } from 'react';
import { Label, OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CriteriaFieldset } from './CriteriaFieldset';
import { InfoFieldset } from './InfoFieldset';
import usePDL from './usePDL';

const PdlHeaderSection = () => {
    const { currentUser, samtycke, positions } = usePDL();
    const content = samtycke
        ? <Label bsStyle="success" className="pointer"><FontAwesomeIcon icon={faCheckCircle} className="me-1" />Samtycke finns</Label>
        : <Label bsStyle="danger" className="pointer"><FontAwesomeIcon icon={faTimesCircle} className="me-1" />Samtycke saknas</Label>;
    const tooltip = (
        <Popover id="PdlTooltip" className="pdl__popover mw-100" title="Patientdatalagen">
            <div className="patientdatalagen__info">
                <CriteriaFieldset currentUser={currentUser} samtycke={samtycke} />
                <InfoFieldset currentUser={currentUser} samtycke={samtycke} positions={positions} />

                <em>För mer information, klicka på fliken Datarättigheter.</em>
            </div>
        </Popover>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={tooltip}
            popperConfig={{ strategy: 'fixed' }}
            trigger="click"
            rootClose={true}
        >
            {content}
        </OverlayTrigger>
    );
};

export default memo(PdlHeaderSection);
