import { useMemo } from 'react';
import { LoadingAlert } from '@cancercentrum/rcc-react';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import { TabHeader } from './TabHeader';
import { DisplayName, ModuleName } from './constants';
import DefaultTabContent from './DefaultTabContent';
import type { SurveysPromise } from './types';

export interface EnkatTab2Props {
    surveysPromise: SurveysPromise;
    highlight?: boolean;
}

const EnkatTab2 = (props: EnkatTab2Props) => {
    const { surveysPromise, highlight = false } = props;

    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
        };
    }, []);

    const shouldHideTab = !surveysPromise.isLoading && !!surveysPromise.result && !surveysPromise.result.some(u => u.answers.length || u.config.canAdd);

    return (
        <HTab eventKey={ModuleName} header={<TabHeader surveysPromise={surveysPromise} highlight={highlight} />} hide={shouldHideTab}>
            <ModuleContext.Provider value={ctx}>
                <div className="mt-3">
                    {surveysPromise.isLoading && <LoadingAlert />}
                    {surveysPromise.result && (
                        <div data-testid={`Module_${ModuleName}`}>
                            <DefaultTabContent utskicksprojekt={surveysPromise.result} reload={surveysPromise.reload} />
                        </div>
                    )}
                </div>
            </ModuleContext.Provider>
        </HTab>
    );
};

export default EnkatTab2;
