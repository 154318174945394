export const roundNumber = (input: number, precision: number): number => {
    const v = Math.pow(10, precision);
    return Math.round(v * (input + Number.EPSILON)) / v;
};

// Formaterar nummer och ändrar punkt till komma.
export const formatNumber = (input: number, precision: number): string => {
    return roundNumber(input, precision).toFixed(precision).replace('.', ',');
};

export const tryFormatNumber = <T>(input: T, precision: number): string | T  => {
  if (typeof input === 'number') {
      return formatNumber(input, precision);
  }

  return input;
};

export const formatDecimal = (val: unknown, precision: number): string => {
    if (typeof val !== 'number' && typeof val !== 'string') {
        return '';
    }

    let res: string | number = val;

    if (typeof res === 'string') {
        const parsedNumber = Number.parseFloat(res.replace(',', '.'));

        if (!Number.isNaN(parsedNumber)) {
            res = parsedNumber;
        }
    }

    if (typeof res !== 'number') {
        return res;
    }

    return res.toFixed(precision).replace('.', ',');
};
