import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RowModelTableMetadata } from '@cancercentrum/rcc-react';
import { GridActions } from '@cancercentrum/rcc-react';
import type { OnBeforeAddCallback, OnRowAddedCallback } from './components/AddSubTableRowButton';
import { AddSubTableRowButton } from './components/AddSubTableRowButton';
import { useModuleContext } from './ModuleContext';
import type { OverviewState } from './types';

export interface AddModuleRowButtonProps<T extends RowModelTableMetadata> {
    className?: string;
    text?: string;
    disabled?: boolean;
    onBeforeAdd?: OnBeforeAddCallback<T>;
}

const AddModuleRowButton = <T extends RowModelTableMetadata>(props: AddModuleRowButtonProps<T>): ReactElement => {
    const { className, onBeforeAdd, text, disabled } = props;
    const ctx = useModuleContext();
    const { tableName, gridId } = ctx;
    if (!tableName) {
        throw new Error('AddModuleRowButton requires ModuleContext to have tableName defined.');
    }
    if (!gridId) {
        throw new Error('AddModuleRowButton requires ModuleContext to have gridId defined.');
    }

    const selector = useCallback((state: OverviewState) => state.data.present.getSubTable(tableName as any), [tableName]);
    const subTable = useSelector(selector);
    const dispatch = useDispatch();

    const onRowAdded: OnRowAddedCallback<T> = (newRow) => {
        dispatch(GridActions.setSelectedItemId(gridId, newRow.getId()));
    };

    return (
        <AddSubTableRowButton
            subTable={subTable}
            onBeforeAdd={onBeforeAdd}
            onRowAdded={onRowAdded}
            className={className}
            disabled={disabled}
        >
            {text || `Registrera ${(ctx.displayName || '').toLowerCase()}`}
        </AddSubTableRowButton>
    );
};

export default AddModuleRowButton;
