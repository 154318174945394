import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { KeyboardEvent, KeyboardEventHandler, MouseEvent, MouseEventHandler, ReactElement } from 'react';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

export const Checkbox = (props: {
    checked?: boolean;
    onChange?: (e: MouseEvent | KeyboardEvent) => void;
    className?: string;
}): ReactElement => {
    const { checked, onChange, className } = props;
    const onKeyPress: KeyboardEventHandler = e => {
        if (!onChange || e.ctrlKey || e.altKey || e.metaKey || e.shiftKey) {
            return;
        }

        if (e.key === ' ' || e.key === 'Enter') {
            onChange(e);
        }
    };

    const onClick: MouseEventHandler = e => {
        if (!onChange || e.ctrlKey || e.altKey || e.metaKey || e.shiftKey) {
            return;
        }

        onChange(e);
    };

    return (
        <FontAwesomeIcon
            icon={checked ? faCheckSquare : faSquare}
            className={classnames('fa-lg', className, { pointer: onChange })}
            onClick={onClick}
            fixedWidth={true}
            tabIndex={onChange ? 0 : undefined}
            onKeyPress={onKeyPress}
        />
    );
};
