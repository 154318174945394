import { useMemo } from 'react';
import classnames from 'classnames';
import type { ReactElement } from 'react';
import { StandardTabHeader } from '../../components/StandardTabHeader';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import { DisplayName, GridId, ModuleName, TableName } from './constants';
import { useGetKirurgiSubTable } from './useGetKirurgiSubTable';

const KirurgiTab = (props: {
    children: ReactElement;
    highlight?: boolean;
}): ReactElement => {
    const { children, highlight } = props;
    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
            tableName: TableName,
            gridId: GridId,
        };
    }, []);
    const subTable = useGetKirurgiSubTable();
    const header = (
        <StandardTabHeader
            displayName={DisplayName}
            rows={subTable.items}
            className={classnames({
                'tab-highlight': highlight,
            })}
        />
    );

    return (
        <HTab eventKey={ModuleName} header={header}>
            <ModuleContext.Provider value={ctx}>
                <div data-testid={`Module_${ModuleName}`}>
                    {children}
                </div>
            </ModuleContext.Provider>
        </HTab>
    );
};

export default KirurgiTab;
