import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dataUtils, DetachedRow, ListButtonGroup, useDataActions, useIncaForm } from '@cancercentrum/rcc-react';
import Question from '../../../components/IPOQuestion';
import { ReadOnlyContext } from '../../../components/ReadOnlyContext';
import useRowHistory from '../../../hooks/useRowHistory';
import RRHistoryContext from '../../../components/RRHistoryContext';
import type { OverviewState } from '../../../types';
import type { SurveyAnswer } from '../types';
import type { PROM_åtgärd } from '../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useIPO } from '../../../hooks/useIPO';

interface ÅtgärdspanelProps {
    svar: SurveyAnswer;
}

export const Atgardspanel = (props: ÅtgärdspanelProps) => {
    const { svar } = props;
    const ipo = useIPO();
    const inca = useIncaForm();

    const rootDataActions = useDataActions([]);

    const actionSelector = useMemo(() => {
        return (state: OverviewState) => {
            return state.data.present.getSubTableRows('PROM-åtgärd').find(x => x?.getRegvarValue('PROM_enkatsvar_fk') === svar.id);
        };
    }, [svar.id]);

    const existingActionRow = useSelector(actionSelector);
    const actionRow = useMemo(() => {
        const newRow = dataUtils.createDataRow('PROM-åtgärd', inca.form.metadata);
        return existingActionRow || dataUtils.createViewModel<PROM_åtgärd>(newRow, inca.form.metadata, 'PROM-åtgärd').setRegvarValue('PROM_enkatsvar_fk', svar.id);
    }, [existingActionRow, svar.id, inca]);

    const updateAction = useCallback((val: number | null) => {
        if (val === null) {
            if (existingActionRow) {
                rootDataActions.updateRow(row => row.removeSubTableRow(existingActionRow));
            }
        } else {
            if (existingActionRow) {
                rootDataActions.updateRow(row => row.setRegvarValue('PROM_atgard', val),
                    { path: [{ rowId: existingActionRow.getId(), tableName: existingActionRow.$rcc.tableName }] });
            } else {
                rootDataActions.updateRow(row => row.addSubTableRow(actionRow.setRegvarValue('PROM_atgard', val)));
            }
        }
    }, [actionRow, existingActionRow, rootDataActions]);

    const isOwnedByOtherPos = !!existingActionRow?.$extra?.dataRowOwnerPositionId && existingActionRow.$extra.dataRowOwnerPositionId !== ipo.currentUser.position.id;

    const rrHistory = useRowHistory(existingActionRow?.$extra.registerRecordId, true);

    return (
        <ReadOnlyContext.Provider value={isOwnedByOtherPos}>
            <RRHistoryContext.Provider value={rrHistory}>
                <DetachedRow id={`atgard_${actionRow.getId()}`} initialRow={actionRow}>
                    {({ row }) => {
                        return (
                            <Question
                                regvar={row.getRegvar('PROM_atgard')}
                                onValueChange={updateAction}
                                componentClass={ListButtonGroup}
                                vertical={true}
                            />
                        );
                    }}
                </DetachedRow>
            </RRHistoryContext.Provider>
        </ReadOnlyContext.Provider>
    );
};
