import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactElement } from 'react';
import type { CurrentUser } from '@cancercentrum/inca';
import { faList } from '@fortawesome/free-solid-svg-icons';
import StatusCheckbox from '../StatusCheckbox';

export const CriteriaFieldset = (props: {
    currentUser: CurrentUser;
    samtycke: boolean;
}): ReactElement => {
    const { currentUser, samtycke } = props;

    return (
        <fieldset>
            <legend><FontAwesomeIcon icon={faList} className="me-2" /> Kriterier</legend>
            <ul className="list-unstyled">
                <li><StatusCheckbox checked={true} className="me-1" /> Jag, {currentUser.user.firstName} {currentUser.user.lastName}, har intygat att det finns en vårdrelation till patienten.</li>
                <li><StatusCheckbox checked={samtycke} className="me-1" /> Patienten har lämnat sitt samtycke till sammanhållen journalföring.</li>
                {/* <li><StatusCheckbox checked={nodatkomst}/> Jag har begärt nödåtkomst till patientens data.</li>*/}
            </ul>
        </fieldset>
    );
};
