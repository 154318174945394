import type { ReactElement } from 'react';
import { memo, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { GridActions } from '@cancercentrum/rcc-react';
import { useAtomValue, useSetAtom } from 'jotai';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { Point } from '../../components/Chart/Point';
import type { OverviewState } from '../../types';
import { Tooltip } from '../../components/Chart/Tooltip';
import { lakemedelVdsAtom } from '../lakemedel';
import { selectedTabAtom } from '../../atoms';
import type { BiverkningsrapporteringRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import * as constants from './constants';
import { getExtStatusText, renderBiverkanTooltip } from './utils';
import type { BiverkanTooltipRenderer } from './types';
import { biverkningarExtAtom } from './useExtBiverkningLoader';
import { ctcaeAtom } from './useCtcaeLoader';

const CATEGORIES = ['Biverkningar'];

const biverkanSelector = (state: OverviewState) => state.data.present.getSubTableRows(constants.TableName).toArray();
const color = 'red';
const marker = {
    symbol: 'cross',
    radius: 7,
    lineWidth: 3,
    fillColor: color,
    lineColor: color,
    states: {
        hover: {
            fillColor: color,
            lineColor: color,
        },
    },
};

const getReasonCharacter = (row: BiverkningsrapporteringRowModel) => {
    const reason = row.getRegvarValue('Biv_orsak')?.identifier;
    const typeMap: Record<string, string> = {
        stralbehandling: 'S',
        lakemedel: 'L',
    };

    return typeMap[reason || ''] || '';
};

const BiverkanAxis = (props: {
    renderTooltip?: BiverkanTooltipRenderer;
}): ReactElement => {
    const { renderTooltip = renderBiverkanTooltip } = props;
    const lakemedelPromise = useAtomValue(lakemedelVdsAtom);
    const rows = useSelector(biverkanSelector, shallowEqual).filter(x => isValidDate(x!.getRegvarValue('Biv_datum')));
    const ctcae = useAtomValue(ctcaeAtom);
    const setActiveTab = useSetAtom(selectedTabAtom);
    const dispatch = useDispatch();
    const { result: extBiv } = useAtomValue(biverkningarExtAtom);

    const points = useMemo(() => {
        if (!lakemedelPromise.result) {
            return [];
        }

        const errands = extBiv?.errands || [];
        const registerRecords = extBiv?.registerRecords || [];

        return rows.map(row => {
            const orsak = row.getRegvarValue('Biv_orsak_annan') || row.getRegvarValue('Biv_orsak')?.text || '';
            const strDate = row.getRegvarValue('Biv_datum')!;
            const date = parseISO(strDate);
            const label = getReasonCharacter(row);
            const uuid = row.getRegvarValue('Biv_uuid');
            const errands2 = errands.filter(x => x.data.r_ipo_uuid === uuid) || [];
            const rr = registerRecords.find(x => x.data.r_ipo_uuid === uuid);
            const extStatus = getExtStatusText(errands2, rr);
            const tooltip = (
                <Tooltip>
                    <Tooltip.Header>
                        <div>
                            <div>Biverkning {strDate}</div>
                            <div className="fw-normal">{orsak}</div>
                        </div>
                    </Tooltip.Header>
                    <Tooltip.Body>
                        <div>
                            {renderTooltip({ row, ctcae, lakemedelPromise })}
                        </div>
                        {!!extStatus && (
                            <div className="mt-2">{extStatus}</div>
                        )}
                    </Tooltip.Body>
                </Tooltip>
            );

            return (
                <Point
                    key={row.getId()}
                    x={getUTCStartOfDayTime(date)}
                    y={0}
                    marker={marker}
                    renderTooltip={() => tooltip}
                    labelText={label}
                    color={color}
                    events={{
                        click() {
                            setActiveTab(constants.ModuleName);
                            dispatch(GridActions.setSelectedItemId(constants.GridId, row.getId()));
                        },
                    }}
                />
            );
        });
    }, [rows, ctcae, lakemedelPromise, dispatch, setActiveTab, renderTooltip, extBiv]);

    return (
        <CategoriesAxis categories={CATEGORIES}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};

export default memo(BiverkanAxis);
