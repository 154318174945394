import { Explanation } from '@cancercentrum/rcc-react';
import { OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import { useId } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { getDisplayName, getMessageTitle } from '../utils';
import type { Utskicksprojekt } from '../types';
import { LetterLink } from './LetterLink';
import { PatientportalMessageHistory } from './PatientportalMessageHistory';

export interface SurveyHeaderProps {
    utskicksprojekt: Utskicksprojekt;
    sparrad: boolean;
}

export const SurveyHeader = (props: SurveyHeaderProps) => {
    const { utskicksprojekt, sparrad } = props;
    const id = useId();

    return (
        <div>
            <div>
                <strong className="me-2">
                    {getDisplayName(utskicksprojekt)}
                </strong>
                {!!utskicksprojekt.ipoDescription && <Explanation>{utskicksprojekt.ipoDescription}</Explanation>}
            </div>
            <div>
                <small><strong>Enkätkod:</strong> {utskicksprojekt.survey.surveyCode}</small>
            </div>
            <div>
                <small><strong>Personlig kod:</strong> {utskicksprojekt.personalCode || 'patienten saknar personlig kod'}</small>
            </div>
            {!!utskicksprojekt.survey.version_kommentar && (
                <OverlayTrigger
                    rootClose={true}
                    overlay={(
                        <Popover id={id}>
                            <div className="text-pre-wrap">{utskicksprojekt.survey.version_kommentar}</div>
                        </Popover>
                    )}
                    trigger="click"
                    placement="right"
                >
                    <small><a href="" onClick={e => e.preventDefault()}><FontAwesomeIcon fixedWidth={true} icon={faFileLines} /> Visa information om enkätversioner</a></small>
                </OverlayTrigger>
            )}
            {utskicksprojekt.config.allowPrintLetter && !!utskicksprojekt.personalCode && !!utskicksprojekt.svarRootTableId && !sparrad && process.env.RCC_ENV !== 'external_demo' && (
                <div>
                    <small>
                        <span className="me-3">
                            <LetterLink
                                personalCode={utskicksprojekt.personalCode}
                                displayName={getMessageTitle(utskicksprojekt)}
                                svarRootTableId={utskicksprojekt.svarRootTableId}
                                deltagare={utskicksprojekt.deltagare}
                            />
                        </span>
                        <PatientportalMessageHistory svar_vd={utskicksprojekt.svarRootTableId} notifieringsmetodIdentier="pdf" />
                    </small>
                </div>
            )}
        </div>
    );
};
