import type { ListItem } from '@cancercentrum/inca';
import { GridActions } from '@cancercentrum/rcc-react';
import { useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import { useSetAtom } from 'jotai';
import { ECOGColors } from '../constants';
import { valueAndTextFormatter, valueFormatter } from '../utils/formatters';
import { EtjansterSection, IpoSection, Tooltip } from './components/Chart/Tooltip';
import { getUTCStartOfDayTime } from './utils/date';
import CategoriesAxis from './components/Chart/CategoriesAxis';
import ScatterSeries from './components/Chart/ScatterSeries';
import Point from './components/Chart/Point';
import { useIPO } from './hooks/useIPO';
import { KontaktEcogPoints } from './modules/kontakt';
import { KirurgiEcogPoints } from './modules/kirurgi';
import { StralEcogPoints } from './modules/stralbehandling';
import { LakemedelEcogPoints } from './modules/lakemedel';
import { selectedTabAtom } from './atoms';

const CATEGORIES = ['ECOG-(WHO)'];

export const EcogPoint = (props: {
    date: string;
    ecog: ListItem;
    rowId: number | string;
    displayName: string;
    moduleName: string;
    gridId?: string;
}) => {
    const { date, ecog, displayName, moduleName, gridId, rowId } = props;
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const parsedDate = getUTCStartOfDayTime(parseISO(date));
    const value = ecog.value;
    const color = ECOGColors[value] || '#000';

    const marker = {
        symbol: 'circle',
        radius: 8,
        lineWidth: 1,
        lineColor: '#000',
    };

    const dataLabels = {
        enabled: true,
        allowOverlap: true,
        x: 0,
        y: 5,
    };

    return (
        <Point
            x={parsedDate}
            y={0}
            color={color}
            labelText={value}
            marker={marker}
            dataLabels={dataLabels}
            renderTooltip={() => (
                <Tooltip>
                    <Tooltip.Header>{displayName} {date}</Tooltip.Header>
                    <Tooltip.Body>
                        <IpoSection>
                            <div>ECOG-(WHO): {valueFormatter(ecog)}</div>
                        </IpoSection>
                        <EtjansterSection>
                            <div>ECOG-(WHO): {valueAndTextFormatter(ecog)}</div>
                        </EtjansterSection>
                    </Tooltip.Body>
                </Tooltip>
            )}
            events={{
                click() {
                    setActiveTab(moduleName);

                    if (gridId) {
                        dispatch(GridActions.setSelectedItemId(gridId, rowId));
                    }
                },
            }}
        />
    );
};

/**
 * Visar ECOG-punkter från flera olika källor, baserat på vad som är specat i dataset.
 */
export const EcogAxis = (): JSX.Element => {
    const { dataset } = useIPO();
    const hasKontaktEcog = !!dataset.Kontakt?.K_besoksdatum && !!dataset.Kontakt?.K_ecog;
    const hasKirurgiEcog = !!dataset.Kirurgi?.Kir_datum && !!dataset.Kirurgi?.kir_ecog;
    const hasStralEcog = !!dataset.Stralbehandling?.Stral_startdatum && !!dataset.Stralbehandling?.Stral_ecog;
    const hasLkmEcog = !!dataset.Läkemedel?.Lkm_startdatum && !!dataset.Läkemedel?.Lkm_ecog;

    return (
        <CategoriesAxis categories={CATEGORIES} plotBands={true}>
            <ScatterSeries>
                {hasKontaktEcog && <KontaktEcogPoints />}
                {hasKirurgiEcog && <KirurgiEcogPoints />}
                {hasStralEcog && <StralEcogPoints />}
                {hasLkmEcog && <LakemedelEcogPoints />}
            </ScatterSeries>
        </CategoriesAxis>
    );
};
