import { valueAndTextFormatter } from '../../../utils/formatters';
import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        senastKontrollerad: {},
        komplettLkmAdjuvant: {},
        komplettLkmPalliativ: {},
        selectedVd: {},
    },
    Basdata: {
        Basdata_hudmelanom_vd: {},
    },
    Studie: {},
    Kontakt: {
        K_vardgivare: {},
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_sammantagenBedomning: {
            props: {
                formatter: valueAndTextFormatter,
            },
            identifiers: ['rf', 'fm', 'cr', 'pr', 'sd', 'pd', 'lr1', 'ek', 'ab', 'rd'],
        },
        K_palliativtTeam: {
            identifiers: ['nej', 'ja'],
        },
        K_sjukdomsstadium: {
            identifiers: ['i', 'ii', 'iii', 'iv'],
        },
        K_sjukdomsstadiumSpec: {
            identifiers: ['a', 'b', 'c', 'iiia', 'iiib', 'iiic', 'iiid'],
        },
        K_tstadium: {
            identifiers: ['tis', 'tx', 't0', 't1', 't1a', 't1b', 't2', 't2a', 't2b', 't3', 't3a', 't3b', 't4', 't4a', 't4b'],
        },
        K_nstadium: {
            identifiers: ['nx', 'n0', 'n1', 'n1a', 'n1b', 'n1c', 'n2', 'n2a', 'n2b', 'n2c', 'n3', 'n3a', 'n3b', 'n3c'],
        },
        K_mstadium: {
            identifiers: ['mx', 'm0', 'm1', 'm1a', 'm1b', 'm1c', 'm1d'],
        },
        K_stadiumDatum: {},
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['7', '3', '1', '2', '5'],
        },
        Bild_sammanvagdRadiologiskBedomning: {
            identifiers: ['cr', 'pr', 'sd', 'pd', 'ek', 'rf', 'rd', 'lr1', 'fm'],
        },
    },
    Labbprov: {
        Labb_LD_1: {},
        Labb_s100b: {},
    },
    Läkemedel: {
        Lkm_behandlingsintention: {
            identifiers: ['1', '2'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '3'],
        },
        Lkm_antalCyklerGivna: {
            label: 'Antal givna kurer',
        },
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
    },
    Mutationsanalys: {
        Mut_melanomtyp: {
            identifiers: ['kutant', 'uvealt', 'mucosalt', 'ej_mojligt'],
        },
        Mut_BRAFv600: {
            identifiers: ['ja', 'nej', 'ej_utf'],
        },
        Mut_NRAS: {
            identifiers: ['ja', 'nej', 'ej_utf'],
        },
        Mut_Ckit: {
            identifiers: ['ja', 'nej', 'ej_utf'],
        },
        Mut_pdl1: {
            identifiers: ['lt1', 'gte1', 'ej_utf'],
        },
        Mut_annan_behandlingspreditiv: {
            identifiers: ['nej', 'ja'],
        },
        Mut_annan_behandlingspreditiv_spec: {},
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_biverkanSpec: {},
        Bv_grad: {
            identifiers: ['1', '2', '3', '4', '5'],
        },
        Bv_imundBehGiven: {
            identifiers: ['nej', 'ja'],
        },
        Bv_behovAvSjukvard: {
            identifiers: ['nej', 'ja'],
        },
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_behtyp_steroider: {},
        Bv_behtyp_infliximab: {},
        Bv_behtyp_ovrigt: {},
    },
    Kirurgi: {
        Kir_kirurgityp: {
            identifiers: ['lymfkortelutrymning', 'isolerad_hyperterm_perfusion', 'elektrokemo', 'metastaskirurgi'],
        },
        Kir_totBortopLymfkortlar: {},
        Kir_totPosLymfkortlar: {},
        Kir_antalMetastaser: {},
        Kir_storstaTumordiameter: {},
        Kir_toxicitetEnlWieberdink: {
            identifiers: ['i', 'ii', 'iii', 'iv', 'v'],
        },
        Kir_respons: {
            identifiers: ['cr', 'pr', 'sd', 'pd'],
        },
        Kir_radikalKirurgi: {
            identifiers: ['nej', 'ja'],
        },
    },
    KirurgiLokal: {
        Kirlok_target: {
            identifiers: ['huvud-hals', 'axill', 'ljumske', 'arm', 'ben', 'hud_subcutant', 'hjarna', 'lunga', '5', 'gastrointestinalkanal', 'binjure', '4', 'in_transit_satellit', 'bukspottskortel', 'njure', 'icke_regionala_lymfkortlar', 'bal', 'ovrig'],
        },
    },
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_behandlingstyp: {
            identifiers: ['fotoner', '3', 'brachyterapi', '4', 'elektroner', 'rontgen', '2'],
        },
        Stral_indikation: {
            identifiers: ['1', '2'],
        },
        Stral_dosPerFraktionD2: {},
        Stral_slutdosD2: {},
    },
    StralTarget: {
        ST_target: {
            identifiers: [
                'binjure',
                'bukspottskortel',
                'hjarna',
                'in_transit_satellit',
                'hud_subcutant',
                '5', // lever
                'lunga',
                'pleura',
                'lymfkortlar',
                'mediastinum',
                'mjukdel_muskel',
                'njure',
                'tyroidea',
                '4', // skelett
                'ovrig',
            ],
        },
    },
    Patientportal: {},
};

export default dataset;
