import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useMemo } from 'react';
import type { OverviewState } from '../../types';
import type { StudieVDItem } from './types';
import { useVDStudier } from './useVDStudier';

const getSelectedStudieIds = createSelector([
        (state: OverviewState) => state.data.present.getSubTable('Studie'),
    ],
    (studieSubTable) => {
        return studieSubTable
            .getRows()
            .toArray()
            .map(x => x!.getRegvarValue('Stud_studie_vd') as number | null)
            .filter((x: number | null): x is number => !!x);
    },
);

/**
 * Hämtar alla VD-studier som valts i studiefliken.
 */
export const useSelectedVDStudier = (currentId: number | null | undefined): StudieVDItem[] => {
    const vdStudier = useVDStudier();
    const selectedStudieIds = useSelector(getSelectedStudieIds);

    return useMemo(() => {
        return vdStudier.filter(x => x.id === currentId || selectedStudieIds.includes(x.id));
    }, [vdStudier, selectedStudieIds, currentId]);
};
