import type { MouseEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

export interface GridRemoveIconProps {
    title?: string;
    onClick: () => void;
}

export const GridRemoveIcon = (props: GridRemoveIconProps): ReactElement => {
    const {
        title = 'Ta bort',
    } = props;
    const [hover, setHover] = useState(false);

    const onClick: MouseEventHandler = e => {
        e.stopPropagation();
        e.preventDefault();

        props.onClick();
    };
    const onMouseOver = () => {
        setHover(true);
    };
    const onMouseOut = () => {
        setHover(false);
    };

    return (
        <FontAwesomeIcon
            className={classnames('pointer', { 'text-danger': hover })}
            icon={faTrashAlt}
            title={title}
            fixedWidth={true}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            style={{ fontSize: '1rem' }}
        />
    );
};
