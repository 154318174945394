import type { ReactElement } from 'react';
import { memo } from 'react';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { isValidDate } from '../../utils/date';
import type { BilddiagnostikRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import BilddiaPoint from './Point';
import { useGetBilddiaSubTable } from './useGetBilddiaSubTable';

const CATEGORIES = ['Bilddiagnostik'];


const BilddiaAxis = (props: {
    getColor?: (row: BilddiagnostikRowModel) => string;
    renderTooltip?: (row: BilddiagnostikRowModel) => ReactElement;
    pointClass: (compProps: {
        row: BilddiagnostikRowModel;
    }) => ReactElement;
}): ReactElement => {
    const DATE_SN = 'Bild_datum';
    const { renderTooltip, getColor, pointClass: PointClass } = props;
    const subTable = useGetBilddiaSubTable();
    const rows = subTable.getRows().filter(x => isValidDate(x.getRegvarValue(DATE_SN)));
    const points = rows.map(x => {
            if (PointClass) {
                return <PointClass key={x.getId()} row={x} />;
            }

            const color = getColor ? getColor(x) : '#000';
            const tooltip = (renderTooltip || (() => <div />))(x);

            return (
                <BilddiaPoint key={x.getId()} id={x.getId().toString()} date={x.getRegvarValue(DATE_SN)!} tooltipContent={tooltip} color={color} />
            );
        },
    );

    return (
        <CategoriesAxis categories={CATEGORIES} plotBands={true}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};

export default memo(BilddiaAxis);
