import type { ReactElement } from 'react';
import Question from '../../../components/IPOQuestion';
import { EcogIcon } from '../../../components/EcogIcon';
import useLayoutSettings from '../../../hooks/useLayoutSettings';
import { EcogDropdownList } from '../../../components/EcogDropdownList';
import type { IPOQuestionProps } from '../../../components/IPOQuestion';
import type { LastEcogResult } from '../../kontakt';

interface EcogQuestionProps extends IPOQuestionProps {
    labelClassName?: string;
    wrapperClassName?: string;
    lastEcog?: LastEcogResult;
}

const EcogQuestion = (props: EcogQuestionProps): ReactElement => {
    const {
        labelClassName = 'col-sm-2',
        wrapperClassName = 'col-sm-10',
        lastEcog,
        ...rest
    } = props;
    const help = lastEcog ? <em>ECOG <EcogIcon value={lastEcog.ecog.value}/> {lastEcog.ecog.value} från {lastEcog.date} är senast registrerade värde i kontaktfliken.</em> : undefined;
    const ctxSettings = useLayoutSettings();

    return (
        <div className="ecog-question">
            <Question
                labelClassName={labelClassName}
                wrapperClassName={wrapperClassName}
                componentClass={EcogDropdownList}
                required={!ctxSettings.ecogOptional}
                help={help}
                {...rest}
            />
        </div>
    );
};

export default EcogQuestion;
