import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        diagnosdatum: {},
        morfologi: {
            identifiers: ['klarcellig', 'papillar', 'kromofob', 'bellini', 'oklassificerbar', 'otillrackligt_material', 'annan'],
        },
        papillartyp: {
            identifiers: ['typ1', 'typ2', 'saknas'],
        },
        senastKontrollerad: {},
        komplettLkmPalliativ: {},
        selectedVd: {},
    },
    Basdata: {
        Basdata_njure_vd: {},
    },
    Kontakt: {
        K_vardgivare: {},
        K_besokstyp: {
            identifiers: ['1', '2', '3'],
        },
        K_yrkesroll: {
            identifiers: ['1', '2', '3'],
        },
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_karnofsky: {
            identifiers: ['100', '90', '80', '70', '60', '50', '40', '30', '20', '10'],
        },
        K_harKliniskBedomning: {},
        K_tumorlokalisation: {},
        K_kliniskBedomning: {
            identifiers: ['m', 'cr', 'pr', 'sd', 'pd', 'ab'],
        },
        K_sammantagenBedomning: {
            identifiers: ['cr', 'pr', 'rf', 'sd', 'pd', 'ab'],
        },
        K_kontaktsjukskoterska: {
            label: 'Har tilldelad kontaktsjuksköterska?',
            identifiers: ['nej', 'ja'],
        },
        K_kontaktsjukskoterska_namn: {},
    },
    Biobank: {},
    Studie: {},
    Labbprov: {
        Labb_calcium_2: {},
        Labb_albumin_0: {},
        Labb_korrigeratCalcium: {},
        Labb_ALP_2: {},
        Labb_CRP_0: {},
        Labb_LD_1: {},
        Labb_Hb_0: {},
        Labb_LPK_2: {},
        Labb_neutrofila_1: {},
        Labb_TPK_0: {},
        Labb_Krea_0: {},
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['7', '3', '1', '4', '2'],
        },
        Bild_sammanvagdRadiologiskBedomning: {
            identifiers: ['cr', 'pr', 'rf', 'sd', 'pd', 'ab'],
        },
        Bild_tumlok_binjure: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_hjarna: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_kvarnjure: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_lever: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_lunga: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_pleura: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_lymfkortlar: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_mediastinum: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_njureloge: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_skelett: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_primartumor: {
            identifiers: ['cr', 'pr', 'sd', 'pd', 'ab'],
        },
        Bild_tumlok_ovrigt: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'ab', 'km'],
        },
        Bild_tumlok_ovrigt_spec: {},
    },
    Läkemedel: {
        Lkm_behandlingsintention: {
            identifiers: ['1', '2'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '3'],
        },
        Lkm_startdos_lista: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Lkm_hengsKriterie: {
            identifiers: ['0', '1', '2'],
        },
        Lkm_hengsBaseratPa: {},
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
    },
    Dosjustering: {
        Dj_datum: {},
        Dj_justering: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Dj_orsak: {
            identifiers: ['enligt_plan', '2', '1', '3', '5'],
        },
    },
    Kirurgi: {
        Kir_indikation: {
            identifiers: ['op_prim_efter_onk', 'metastas'],
        },
        Kir_metastaskirurgi: {
            props: {
                label: 'Typ',
            },
            identifiers: ['1', '2', '3', '4'],
        },
        Kir_metastaskirurgi_annan_spec: {},
    },
    KirurgiLokal: {
        Kirlok_target: {
            identifiers: ['binjure', 'hjarna', 'kvarnjure', '5', 'lunga', 'lymfkortlar', 'mediastinum', 'muskel', 'njureloge_lokrec', 'pleura', '4', 'ovrig'],
        },
        Kirlok_target_spec: {},
    },
    Stralbehandling: {
        Stral_slutdatum: {},
        Stral_behandlingstyp: {
            identifiers: ['1', '2'],
        },
        Stral_dosPerFraktionD2: {},
        Stral_slutdosD2: {},
    },
    StralTarget: {
        ST_target: {
            identifiers: ['binjure', 'hjarna', 'kvarnjure', '5', 'lunga', 'lymfkortlar', 'mediastinum', 'muskel', 'njureloge_lokrec', 'pleura', '4', 'ovrig'],
        },
        ST_target_spec: {},
    },
    Recidivrapportering: {
        R_sida: {
            identifiers: ['1', '2'],
        },
        R_datum: {
            props: {
                label: 'Datum för lokoregionalt recidiv',
            },
        },
        R_recidivtyp: {
            identifiers: ['1', '2'],
        },
    },
    RecidivrapporteringLokal: {
        RL_lokal: {
            identifiers: ['binjure', 'hjarna', 'kvarnjure', '5', 'lunga', 'lymfkortlar', 'mediastinum', 'muskel', 'njureloge_lokrec', 'pleura', '4', 'ovrig'],
        },
        RL_spec: {},
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    Patientportal: {},
};

export default dataset;
