import { useContext } from 'react';
import invariant from 'invariant';
import type { SeriesContext as SeriesContextType } from '../types';
import SeriesContext from './index';

export default (): SeriesContextType => {
    const seriesContext = useContext(SeriesContext);
    invariant(seriesContext, 'SeriesContext cannot be null.');

    return seriesContext;
};
