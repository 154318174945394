import { useRef } from 'react';
import { parseInline } from 'marked';
import { Button, Modal } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import type { Patient } from '@cancercentrum/inca';
import isNil from 'lodash/isNil';
import type { QuestionMetadata, SummaryField, SummaryFieldCondition, SummaryFieldRegvar, SurveyAnswerWithUtskicksprojekt } from '../types';
import { getAllFollowUpQuestions, getValueAsText } from '../utils.ts';
import { shouldShowSummaryFieldHeader } from './SummaryField.tsx';

function getMarkdownPlainText(md: string | null) {
    const html = parseInline(md || '') as string;
    const node = document.createElement('div');

    node.innerHTML = html;

    return node.textContent || '';
}

class StringBuilder {
    #lines: string[] = [];

    append(value: any) {
        if (value === null || value === undefined) {
            return;
        }

        this.#lines.push(value.toString());
    }

    getString() {
        return this.#lines.join('\n');
    }
}

export const SurveyAnswerTextModal = (props: {
    onHide?: () => void;
    patient: Patient;
    answer: SurveyAnswerWithUtskicksprojekt;
}) => {
    const { onHide, patient, answer } = props;
    const { utskicksprojekt } = answer;
    const containerRef = useRef<HTMLDivElement>(null!);

    const copyToClipboard = () => {
        const range = document.createRange();
        range.selectNodeContents(containerRef.current);

        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);

        document.execCommand('copy');
    };

    const sb = new StringBuilder();

    sb.append(`# ${patient.ssn} - ${patient.lastName}, ${patient.firstName}`);
    sb.append('');
    sb.append(`${utskicksprojekt.ipoName || utskicksprojekt.name}`);
    answer.enkatversion && sb.append(`Enkätversion ${answer.enkatversion}`);
    sb.append(`Besvarad ${answer.endDate}`);
    sb.append('');

    if (shouldShowSummaryFieldHeader(answer, utskicksprojekt.survey, utskicksprojekt.config.summaryFields)) {
        sb.append('## Global hälsa');
        utskicksprojekt.config.summaryFields.forEach(summaryField => {
            if ('shortname' in summaryField) {
                const sf = summaryField as SummaryFieldRegvar;
                const question = utskicksprojekt.survey.questions[sf.shortname];
                const an = summaryField.formatter
                    ? summaryField.formatter(answer, question, utskicksprojekt.survey).text
                    : getValueAsText(answer, question, utskicksprojekt.survey);

                if (question && !isNil(an)) {
                    sb.append(summaryField.label || getMarkdownPlainText(question.label));
                    sb.append(an || '-');
                    sb.append('');
                }
            } else if ('condition' in summaryField) {
                sb.append(`${summaryField.label}: ${summaryField.formatter(answer, utskicksprojekt.survey)}`);
            }
        });
    }

    utskicksprojekt.config.severityGrades.forEach(grade => {
        sb.append(`## ${grade.name}`);

        const {
            hiddenQuestions,
            summaryFields,
            followupQuestions = {},
        } = utskicksprojekt.config;
        const regvarSummaryFields = summaryFields.filter((x): x is SummaryFieldRegvar => 'shortname' in x);
        const conditionSummaryFields = summaryFields.filter((x: SummaryField): x is SummaryFieldCondition => 'condition' in x);
        const allFollowupQuestions = getAllFollowUpQuestions(utskicksprojekt.config);
        const questions = Object.keys(utskicksprojekt.survey.questions).filter(sn => {
            const isHidden = !!hiddenQuestions?.includes(sn);
            const isSummaryField = regvarSummaryFields.some(summ => summ.shortname === sn && !summ.showInGradeGroup);
            const isFollowupQuestion = allFollowupQuestions.includes(sn);
            return !isHidden && !isSummaryField && !isFollowupQuestion && grade.evaluateAnsweredQuestion(sn, answer.data[sn], utskicksprojekt.survey.questions[sn]);
        });
        const getAnswer = (question: QuestionMetadata) => {
            return utskicksprojekt.config.answerFormatter ? utskicksprojekt.config.answerFormatter({
                answer,
                question,
                surveyMetadata: utskicksprojekt.survey,
                defaultFormatter: getValueAsText,
            }) : getValueAsText(answer, question, utskicksprojekt.survey);
        };

        questions.forEach(shortname => {
            const question = utskicksprojekt.survey.questions[shortname];

            sb.append(getMarkdownPlainText(question.label));
            sb.append(getAnswer(question) || '-');
            sb.append('');

            (followupQuestions[shortname] || []).forEach(sn => {
                const followupQuestion = utskicksprojekt.survey.questions[sn];

                sb.append(followupQuestion.label);
                sb.append(getAnswer(followupQuestion) || '-');
                sb.append('');
            });
        });

        if (!questions.length) {
            sb.append('Inga frågor besvarade i denna kategori.');
            sb.append('');
        }

        conditionSummaryFields.filter(s => s.gradeGroup === grade.name).forEach((s) => {
            const ans = s.condition(answer, utskicksprojekt.survey) ? s.formatter(answer, utskicksprojekt.survey).text : '';

            sb.append(s.label);
            sb.append(ans || '-');
            sb.append('');
        });

        sb.append('');
    });

    return (
        <Modal show={true} onHide={onHide} className="printable-modal" bsSize="xl">
            <Modal.Body>
                <div className="text print-visible" ref={containerRef}>
                    <div className="text-pre-wrap">{sb.getString()}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} bsStyle="link">Stäng</Button>
                <Button bsStyle="primary" onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} className="me-2" />Kopiera text</Button>
            </Modal.Footer>
        </Modal>
    );
};
