import type { ReactElement } from 'react';
import { useMemo } from 'react';
import classnames from 'classnames';
import type { HookDataActions, RowModel } from '@cancercentrum/rcc-react';
import { AjaxErrorAlert, LoadingAlert, useDataActions } from '@cancercentrum/rcc-react';
import { StandardTabHeader } from '../../components/StandardTabHeader';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import useLayoutSettings from '../../hooks/useLayoutSettings';
import type { LayoutSettings } from '../../../types';
import type { Läkemedel, LäkemedelSubTable } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { DisplayName, ModuleName, TableName } from './constants';
import { useGetLakemedelSubTable } from './useGetLakemedelSubTable';
import type { LakemedelPromiseResult } from './useLakemedelVDPromise';
import { useLakemedelVDPromise } from './useLakemedelVDPromise';

export interface RenderLakemedelOptions {
    settings: LayoutSettings;
    subTable: LäkemedelSubTable;
    dataActions: HookDataActions<Läkemedel>;
    lakemedelPromiseResult: LakemedelPromiseResult;
}

const rowFilter = (row: RowModel) => !row.getRegvarValue('Lkm_exkluderad') && !row.getRegvarValue('Lkm_migreringsdublett');

export const LakemedelTab = (props: {
    children: (options: RenderLakemedelOptions) => ReactElement;
    highlight?: boolean;
}): ReactElement => {
    const { children, highlight } = props;
    const lakemedelPromise = useLakemedelVDPromise();
    const settings = useLayoutSettings();
    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
            tableName: TableName,
        };
    }, []);
    const dataActions = useDataActions<Läkemedel>();
    const subTable = useGetLakemedelSubTable();
    const header = (
        <StandardTabHeader
            displayName={DisplayName}
            rows={subTable.items}
            className={classnames({
                'tab-highlight': highlight,
            })}
            rowFilter={rowFilter}
        />
    );

    return (
        <HTab eventKey={ModuleName} header={header}>
            <ModuleContext.Provider value={ctx}>
                {lakemedelPromise.isLoading && <LoadingAlert />}
                {lakemedelPromise.error && <AjaxErrorAlert error={lakemedelPromise.error} />}
                {!!lakemedelPromise.result && (
                    <div data-testid={`Module_${ModuleName}`}>
                        {children({
                            lakemedelPromiseResult: lakemedelPromise.result,
                            subTable,
                            settings,
                            dataActions,
                        })}
                    </div>
                )}
            </ModuleContext.Provider>
        </HTab>
    );
};

LakemedelTab.ModuleName = ModuleName;
