import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        fardigregistrerad: {},
        senastKontrollerad: {},
        komplettLkmAdjuvant: {},
        komplettLkmPalliativ: {},
        selectedVd: {},
    },
    Basdata: {
        Basdata_lunga_vd: {},
    },
    Kontakt: {
        K_sammantagenBedomning: {
            identifiers: ['nd', 'cr', 'pr', 'sd', 'pd', 'of', 'rf', 'rd'],
        },
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_kontaktsjukskoterska: {
            label: 'Har tilldelad kontaktsjuksköterska?',
            identifiers: ['nej', 'ja'],
        },
        K_kontaktsjukskoterska_namn: {},
        K_palliativtTeam: {
            identifiers: ['nej', 'ja'],
        },
        K_inskrivenPalliativHemsjukvard: {
            identifiers: ['nej', 'ja'],
        },
        K_datumPalliativtTeam: {},
        K_brytpunktssamtal: {
            props: {
                label: 'Brytpunktssamtal/Övergång till BSC',
            },
        },
        K_ingenAktivTumorbehandling: {},
    },
    KontaktLokal: {
        KL_target: {
            identifiers: ['primtumor', 'binjure', 'hjarna', '5', 'lymfkortlar', '4', '6', 'ovrig'],
        },
        KL_lokal_spec: {},
    },
    Studie: {
        Stud_stoppdatum: {},
    },
    Kirurgi: {
        Kir_mdk: {
            identifiers: ['nej', 'ja'],
        },
        Kir_behandlingsbeslut: {},
        kir_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        Kir_indikation: {
            identifiers: ['palliativ', 'kurativ'],
        },
        Kir_stadium: {
            identifiers: ['ia', 'ia1', 'ia2', 'ia3', 'ib', 'ii', 'iia', 'iib', 'iiia', 'iiib', 'iiic', 'iv', 'iva', 'ivb'],
        },
        Kir_stadium_mkoga: {
            identifiers: ['i', 'iia', 'iib', 'iii', 'iva', 'ivb'],
        },
        Kir_omfattning: {
            identifiers: ['1', '2', '3', '4', '7', '5', '6', '8', 'ovrig'],
        },
        Kir_omfattning_spec: {},
        Kir_behandlingstyp: {
            identifiers: ['vats', 'oppen_kirurgi'],
        },
        Kir_sida: {
            identifiers: ['1', '2'],
        },
        Kir_resultat: {
            identifiers: ['1', '2'],
        },
        Kir_ickeradikalaResultat: {
            identifiers: ['r1', 'r2'],
        },
        Kir_tstadium: {
            label: 'pT-stadium',
            identifiers: ['t0', 't1a', 't1b', 't1c', 't2a', 't2b', 't3', 't4'],
        },
        Kir_nstadium: {
            label: 'pN-stadium',
            identifiers: ['n0', 'n1', 'n2', 'n3'],
        },
        Kir_mstadium: {
            label: 'pM-stadium',
            identifiers: ['m0', 'm1a', 'm1b', 'm1c'],
        },
        Kir_ptnmstadium: {
            identifiers: [
                'ia1',
                'ia2',
                'ia3',
                'ib',
                'iia',
                'iib',
                'iiia',
                'iiib',
                'iiic',
                'iva',
                'ivb',
            ],
        },
        Kir_FEV1_l_fore: {},
        Kir_FEV1_l_efter: {},
        Kir_FEV1_procent_fore: {},
        Kir_FEV1_procent_efter: {},
        Kir_CODiff_fore: {},
        Kir_CODiff_efter: {},
    },
    KirurgiLokal: {
        Kirlok_target: {
            identifiers: ['mediastinum', 'hjarna', '5', 'lymfkortlar', '4', '6', 'ovrig'],
        },
    },
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_indikation: {
            identifiers: ['2', 'kurativ', 'profylaktisk'],
        },
        Stral_indikation_lunga_kurativ: {
            identifiers: ['0', '1', '3', '4'],
        },
        Stral_symtombedomning: {
            identifiers: ['symtomfri', 'forbattrad', 'ej_forbattrad'],
        },
        Stral_sida: {
            identifiers: ['1', '2'],
        },
        Stral_mdk: {
            identifiers: ['nej', 'ja'],
        },
        Stral_behandlingsbeslut: {},
        Stral_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        Stral_FEV1_l_fore: {},
        Stral_FEV1_procent_fore: {},
        Stral_CODiff_fore: {},
        Stral_stadium_lunga: {
            identifiers: ['i', 'ia', 'ia1', 'ia2', 'ia3', 'ib', 'ii', 'iia', 'iib', 'iiia', 'iiib', 'iiic', 'iv', 'iva', 'ivb'],
        },
        Stral_stadium_mkoga: {
            identifiers: ['i', 'iia', 'iib', 'iii', 'iva', 'ivb'],
        },
        Stral_dosPerFraktionD2: {},
        Stral_antalFraktioner: {},
        Stral_slutdosD2: {},
        Stral_slutdatum: {
            label: 'Datum för sista behandlingsdag',
        },
        Stral_FEV1_l_efter: {},
        Stral_FEV1_procent_efter: {},
        Stral_CODiff_efter: {},
        Stral_responsbedomning: {
            identifiers: ['cr', 'pr', 'sd', 'pd', 'ej'],
        },
        Stral_utsattningsorsak: {
            identifiers: ['0', '1', '3', '4', '5', '6', '7'],
        },
        Stral_toxicitet: {
            identifiers: ['nej', 'ja'],
        },
        Stral_toxicitetTidpunktSen: {},
        Stral_toxicitetTidpunktAkut: {},
        Stral_behandlingstyp: {
            identifiers: ['1', '2', '3', 'brachyterapi', '4', '5'],
        },
    },
    StralTarget: {
        ST_target: {
            identifiers: ['primtumor', 'primtumor_inkl_mediastinum', 'binjure', 'cns', '5' /* lever */, 'lymfkortlar', '4' /* skelett */, 'pleura_lunga_metastas', 'ovrigt'],
        },
        ST_target_spec: {},
    },
    ToxicitetspecAkut: {
        ToxAkut_spec: {
            identifiers: ['0', '1', '9'],
        },
        ToxAkut_annan: {},
        ToxAkut_grad: {
            identifiers: ['0', '1', '2', '3', '4', '5'],
        },
    },
    ToxicitetspecSen: {
        ToxSen_spec: {
            identifiers: ['0', '1', '2', '9'],
        },
        ToxSen_annan: {},
        ToxSen_grad: {
            identifiers: ['0', '1', '2', '3', '4', '5'],
        },
    },
    Läkemedel: {
        Lkm_behandlingslinje: {
            identifiers: ['1', '2', '3', '4', '5', '>5'],
        },
        Lkm_behandlingsbeslut: {},
        Lkm_mdk: {
            identifiers: ['nej', 'ja'],
        },
        Lkm_stadiumVidStart: {
            identifiers: ['ia', 'ia1', 'ia2', 'ia3', 'ib', 'ii', 'iia', 'iib', 'iiia', 'iiib', 'iiic', 'iv', 'iva', 'ivb'],
        },
        Lkm_mkogaVidStart: {
            identifiers: ['i', 'iia', 'iib', 'iii', 'iva', 'ivb'],
        },
        Lkm_behandlingsintention: {
            identifiers: ['1', '2'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '2', '3'],
        },
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    'PROM-åtgärd': {
        PROM_atgard: {
            identifiers: ['tele_ingen_atgard', 'tele_besok', 'tele_radio_besok', 'fritext_ingen_atgard'],
        },
        PROM_enkatsvar_fk: {},
    },
    Patientportal: {},
};

export default dataset;
