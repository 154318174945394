import classnames from 'classnames';
import type { ReactNode } from 'react';

export const StaticField = (props: {
    label: string;
    value?: any;
    formatter?: (value: any) => ReactNode;
    labelClassName?: string;
    wrapperClassName?: string;
}): JSX.Element => {
    const {
        label,
        value,
        formatter,
        labelClassName = 'col-sm-2',
        wrapperClassName = 'col-sm-10',
    } = props;

    return (
        <div className="form-group">
            <label className={classnames('form-label', labelClassName)}>{label}</label>
            <div className={wrapperClassName}>
                <p className="form-control-static">{formatter ? formatter(value) : value}</p>
            </div>
        </div>
    );
};
