import type { ReactElement } from 'react';
import { useContext, useMemo } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { Question } from '@cancercentrum/rcc-react';
import type { ListItem, RegvarMetadata } from '@cancercentrum/inca';
import useDataSet from '../hooks/useDataSet';
import { useGetTableNameByRegvarFn } from '../../useGetTableNameByRegvarFn';
import { RegvarHistory } from './RegvarHistory';
import { ReadOnlyContext } from './ReadOnlyContext';
import { StaticQuestion } from './StaticQuestion';

export interface IPOQuestionProps {
    [key: string]: any;

    regvar: RegvarModel<RegvarMetadata>;
    showRegvarName?: boolean;
    readOnly?: boolean;
    isReadOnly?: boolean;
    id?: string;
    disabled?: boolean;
    onBlur?: (...args: any[]) => void;
    onValueChange?: (value: any, ...rest: any[]) => void;
    onIncludeChange?: (include: boolean) => void;

    minWarning?: number;
    maxWarning?: number;
    listValues?: string[];
}

interface MinMax {
    min?: number;
    max?: number;
}

const REGVAR_DEFAULT_WARNING_LIMITS: Record<string, MinMax> = {
    Labb_LD_1: { max: 25 },
    Labb_Hb_0: { min: 50, max: 220 },
    Labb_calcium_2: { max: 5 },
    Labb_TPK_0: { max: 1499 },
    Labb_neutrofila_1: { min: 0.5, max: 29 },
    Labb_testosteron_2: { min: 0, max: 60 },

    Stral_dosPerFraktionD2: { max: 30 },
    Stral_slutdosD2: { max: 100 },
    Stral_slutdosExklBoostD2: { max: 100 },
    Stral_totdosTargetInklBoostD2: { max: 100 },
    ST_dosPerFraktion: { max: 30 },
    ST_slutdos: { max: 100 },

    // Nya gränsvärden inför lansering av PPC (IPO-383), men som används av andra diagnoser.
    // Oklart om gränsvärdena ska slå igenom även på dem, tills dess är dessa utkommenterade
    // och gränsvärdena istället skrivna i PPC:s RowTemplate.
    // Labb_Krea_0: { min: 30, max: 1500 }, // PPC, myelom, ovarial
    // Labb_ALP_2: { min: 0.5, max: 100 }, // PPC, njure
    // Labb_LPK_2: { min: 0.1, max: 100 }, // PPC, njure, ovarial
};

const useRegvarOptions = (shortname: string, metadata: RegvarMetadata, value: any, limitToIdentifiers: string[] | null | undefined, rest: any): ListItem[] | null => {
    return useMemo(() => {
        if (metadata.dataType === 'list') {
            // Kontrollera i devläge att valen som explicit skickats med ingår i datasetet.
            if (process.env.NODE_ENV === 'development') {
                if (!limitToIdentifiers) {
                    // eslint-disable-next-line no-console
                    console.warn(`${shortname}: specification of valid list value identifiers is missing!`);
                }
            }

            // Begränsa inte urvalet utifrån datasetet ifall options explicit har skickats med.
            if (limitToIdentifiers) {
                const sourceList: ListItem[] = rest.options ? rest.options : metadata.listValues;
                const curValue = value;
                const opts = limitToIdentifiers.reduce<ListItem[]>((r, v) => {
                    const match = sourceList.find(x => x.identifier === v);

                    if (!match) {
                        if (!rest.options) {
                            // eslint-disable-next-line no-console
                            console.warn(`${shortname}: no list item with identifier "${v}" was found.`);
                        }
                        return r;
                    }

                    r.push(match);

                    return r;
                }, []);

                if (curValue && limitToIdentifiers.indexOf(curValue.identifier) === -1) {
                    opts.unshift(curValue);
                }

                return opts;
            }
        }

        return null;
    }, [shortname, metadata, value, limitToIdentifiers, rest.options]);
};

export const IPOQuestion = (props: IPOQuestionProps): ReactElement => {
    const { listValues, staticContent, minWarning, maxWarning, ...rest } = props;
    const regvar = props.regvar;
    const ctxDataSet = useDataSet();
    const isReadOnly = useContext(ReadOnlyContext);
    const extraProps: Record<string, any> = {
        disabled: isReadOnly || rest.disabled,
    };
    const valueLimits = REGVAR_DEFAULT_WARNING_LIMITS[regvar.$rcc.shortname];

    if (!regvar) {
        throw new Error('regvar must be set.');
    }

    const getTableNameByRegvarShortname = useGetTableNameByRegvarFn();

    const shortname = regvar.$rcc.shortname;
    const tableName = getTableNameByRegvarShortname(regvar.$rcc.shortname);
    const md = (ctxDataSet[tableName] as any)?.[shortname] || {};
    const limitToIdentifiers: string[] | null | undefined = listValues || md.identifiers;

    const appendAfterContent = useMemo(() => {
        return (
            <>
                <RegvarHistory
                    shortname={regvar.$rcc.shortname}
                    metadata={regvar.$rcc.regvarMetadata}
                    label={rest.label}
                />
                {rest.appendAfter}
            </>
        );
    }, [regvar.$rcc, rest.label, rest.appendAfter]);

    if (md.label) {
        extraProps.label = md.label;
    }

    if (!isNil(valueLimits?.min) || !isNil(minWarning)) {
        extraProps.minLimit = minWarning ?? valueLimits.min;
        extraProps.minLimitType = 'warning';
    }
    if (!isNil(valueLimits?.max) || !isNil(maxWarning)) {
        extraProps.maxLimit = maxWarning ?? valueLimits.max;
        extraProps.maxLimitType = 'warning';
    }


    const options = useRegvarOptions(regvar.$rcc.shortname, regvar.$rcc.regvarMetadata, regvar.getValue(), limitToIdentifiers, rest);

    if (options) {
        extraProps.options = options;
    }

    if (staticContent && !regvar.value) {
        return (
            <StaticQuestion
                regvar={props.regvar}
                value={staticContent}
                className={props.className}
                labelClassName={props.labelClassName}
                wrapperClassName={props.wrapperClassName}
                labelHelp={props.labelHelp}
                {...extraProps}
            />
        );
    }

    if (process.env.NODE_ENV === 'development') {
        if (!get(ctxDataSet, [tableName, shortname])) {
            throw new Error(`The variable "${shortname}" is not included in the dataset.`);
        }
    }


    return (
        <Question {...md.props} {...rest} {...extraProps} appendAfter={appendAfterContent} />
    );
};

export default IPOQuestion;
