import type { Chart, PlotScatterDataLabelsOptions, Point, PointOptionsObject, SeriesPieOptions, SeriesScatterOptions } from 'highcharts';
import merge from 'lodash/merge';
import type { MutableRefObject } from 'react';
import { format } from 'date-fns';
import { getPointPosition } from '../enkat2/Chart/utils';
import type { ExportedChart } from './PatientIncaToolbar';
import type { HighchartsRefType, UserOptions } from './types';


export function tryParseJson<T>(x: string) {
    try {
        return JSON.parse(x) as T;
    } catch (err) { // eslint-disable-line no-empty
    }

    return undefined;
}

export const createExportedChart = (chart: Chart, graphVersion: string): ExportedChart => {
    const cu = chart.userOptions;
    const su = chart.series.map(s => merge({}, s.userOptions, {
        cursor: 'default',
    })).filter(x => x.type !== 'pie');

    const config = merge({}, cu, {
        chart: {
            renderTo: null,
            height: chart.chartHeight,
            panning: false,
            zoomType: 'x',
            resetZoomButton: {
                // För att reset zoom inte ska överlappa översta serien.
                position: {
                    x: 0,
                    y: 0,
                },
            },
        },
        plotOptions: {
            series: {
                cursor: 'default',
            },
        },
        yAxis: (chart.yAxis).map(axis => {
            return {
                ...axis.options,
                title: axis.options.title || '',
                children: null,
            };
        }),
        xAxis: (chart.xAxis).map(axis => {
            return {
                ...axis.options,
                title: axis.options.title || '',
                children: null,
            };
        }),
        title: '',
        series: su,
    });

    return {
        version: graphVersion,
        graph: config,
        extremes: chart.xAxis[0].getExtremes(),
    };
};

export const parseExportedChartJSON = (json: string, chartRef: MutableRefObject<HighchartsRefType | null>): ExportedChart | undefined => {
    const cfg = tryParseJson<ExportedChart>(json);

    if (cfg && cfg.graph) {
        cfg.graph.tooltip!.formatter = function () {
            const o = (this.point.options as PointOptionsObject & UserOptions);
            return o.tooltipPatientINCA || o.tooltip || `<div>${this.key} - ${format(this.y, 'yyyy-MM-dd')}</div>`;
        };

        cfg.graph.chart!.events!.load = function () {
            const { userMin, userMax } = cfg.extremes;
            this.xAxis[0].setExtremes(userMin, userMax);
        };

        cfg.graph.series!.forEach(s => {
            const options = s as SeriesScatterOptions;

            if (options.dataLabels) {
                (options.dataLabels as PlotScatterDataLabelsOptions).formatter = function () {
                    return (this.point as Point & UserOptions).labelText;
                };
            }

            if (options.custom?.pieHandler) {
                options.events = {
                    click: e => {
                        const disablePie = (e.point as any).custom?.disablePie;
                        if (!disablePie) {
                            const customPie = (e.point as any).custom?.seriesOptions as SeriesPieOptions;
                            const existingSeries = chartRef.current?.chart.series.find(x => x.userOptions.id === customPie.id);

                            if (existingSeries) {
                                existingSeries.remove(true);
                            } else {
                                const center = getPointPosition(e.point);
                                chartRef.current?.chart.addSeries({ ...customPie, center: [center.x, center.y] }, true);
                            }
                        }
                    },
                };
            }
        });

    }

    return cfg;
};
