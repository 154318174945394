import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        senastKontrollerad: {},
        komplettLkmPalliativ: {},
        komplettLkmAdjuvant: {},
        selectedVd: {},
    },
    Basdata: {
        Basdata_ovarial_vd: {},
    },
    Kontakt: {
        K_vardgivare: {},
        K_besokstyp: {
            identifiers: ['1', 'slutenvard', '2', 'digitalt_vardmote', '3'],
        },
        K_yrkesroll: {
            identifiers: ['1', '2', '3'],
        },
        K_ecogEjBedomt: {},
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_sammantagenBedomning: {
            identifiers: ['pd', 'sd', 'pr', 'cr', 'of', 'eb'],
        },
        K_kontaktsjukskoterska: {
            label: 'Har tilldelad kontaktsjuksköterska?',
            identifiers: ['nej', 'ja'],
        },
        K_kontaktsjukskoterska_namn: {},
    },
    KontaktPAL: {
        KP_patientansvarigLakare: {},
    },
    Studie: {},
    Labbprov: {
        Labb_Krea_0: {},
        Labb_LPK_2: {},
        Labb_neutrofila_1: {},
        Labb_albumin_0: {},
        Labb_S_CA125: {},
        Labb_TPK_0: {},
        Labb_Hb_0: {},
        Labb_AFP_1: {},
        Labb_Inhibin_1: {},
        Labb_HE4: {},
        Labb_CEA_1: {},
        Labb_hCG_1: {},
        Labb_betahcg: {},
        Labb_CA199: {},
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['7', '3', '6', '1', 'pet-mr', '5', '4', '2', '8'],
        },
        Bild_metod_specificera: {},
        Bild_resultat: {
            identifiers: ['p1', 'rf', 'm1', 'cr', 'pr', 'sd', 'pd', 'of', 'ab', 'r', 'mr', 'eb'],
        },
    },
    Läkemedel: {
        Lkm_behandlingsintention: {
            identifiers: ['1', '2'],
        },
        Lkm_beredning: {
            identifiers: ['per_os', 'parenteral', 'hormonspiral'],
        },
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
        Lkm_startdos_lista: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_slutdatum: {
            label: 'Datum för sista behandlingsdag',
        },
        Stral_indikation: {
            identifiers: ['primar', 'postoperativ', '2'],
        },
        Stral_behandlingstyp: {
            identifiers: ['brachyterapi', 'extern'],
        },
    },
    StralTarget: {
        ST_target: {
            identifiers: ['gyn', 'binjure', 'kvarnjure', '4' /* skelett */, '5' /* lever */, 'lunga', 'lymfkortlar', 'mediastinum', 'muskel', 'njureloge_lokrec', 'pleura', 'urinblasa', 'hjarna', 'annan'],
        },
        ST_target_spec: {},
    },
    Patientportal: {},
    Dosjustering: {
        Dj_datum: {},
        Dj_justering: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Dj_orsak: {
            identifiers: ['enligt_plan', '2', '1', '3', '5'],
        },
    },
};

export default dataset;
