import type { ReactElement } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import { isValidDate } from '../../utils/date';
import { EcogPoint } from '../../EcogAxis';
import * as LkmConstants from './constants';
import { useGetLakemedelSubTable } from './useGetLakemedelSubTable';

export const LakemedelEcogPoints = () => {
    const subTable = useGetLakemedelSubTable();

    const points = subTable.getRows().filter(x => !x.getRegvarValue('Lkm_exkluderad')).toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('Lkm_startdatum');
        const ecog = row.getRegvarValue('Lkm_ecog') as ListItem | null;
        if (!isValidDate(datum) || !ecog) {
            return red;
        }

        red.push((
            <EcogPoint
                key={row.getId()}
                date={datum!}
                rowId={row.getId()}
                ecog={ecog}
                moduleName={LkmConstants.ModuleName}
                displayName={LkmConstants.DisplayName}
            />
        ));

        return red;
    }, []);

    return (
        <>{points}</>
    );
};
