import type { ListItem } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { isValidDate } from '../../utils/date';
import { useGetKontaktSubTable } from './useGetKontaktSubTable';
import KliniskBedomningPoint from './KliniskBedomningPoint';

const CATEGORIES = ['Klinisk bedömning'];

export const KliniskBedomningAxis = (): ReactElement => {
    const subTable = useGetKontaktSubTable();
    const points = subTable.getRows().toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('K_besoksdatum');
        const klinbed = row.getRegvarValue('K_kliniskBedomning') as ListItem | null;
        if (!isValidDate(datum) || !klinbed) {
            return red;
        }

        red.push(<KliniskBedomningPoint key={row.getId()} row={row} y={0} />);

        return red;
    }, []);

    return (
        <CategoriesAxis categories={CATEGORIES} plotBands={true}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};
