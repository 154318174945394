import { Alert } from '@cancercentrum/rcc-bootstrap';
import type { Basdata } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useAutoConnectAllBasdata } from './useAutoConnectBasdata';

export interface BasdataAutoConnectorProps {
    basdataName?: string;
    vdShortname: keyof Basdata['regvars'];
}

export const BasdataAutoConnector = (props: BasdataAutoConnectorProps) => {
    const { basdataName = 'rotdata', vdShortname } = props;

    const { hasBeenAutomaticallySet } = useAutoConnectAllBasdata(vdShortname, basdataName);

    if (hasBeenAutomaticallySet) {
        return (
            <Alert>
                Översikten har automatiskt kopplats till alla registreringar i kvalitetsregistret.
            </Alert>
        );
    }

    return null;
};
