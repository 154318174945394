import { type ReactNode, useEffect, useRef } from 'react';
import { useAtomValue } from 'jotai';
import debounce from 'lodash/debounce';
import Chart from '../../components/Chart';
import { selectedTabAtom } from '../../atoms';
import type { ChartRef } from '../../components/Chart/types.ts';
import { ModuleName } from './constants';

export const OverviewChart = (props: {
    activeTab?: string;
    initialScale?: string;
    children?: ReactNode;
}) => {
    const { initialScale, children } = props;
    const activeTab = useAtomValue(selectedTabAtom);
    const chartRef = useRef<ChartRef | null>(null);
    const isVisible = activeTab === ModuleName;
    const isVisibleRef = useRef(isVisible);

    useEffect(() => {
        const onResize = debounce(() => {
            chartRef.current?.chart.setSize(null, undefined);
        }, 500);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    if (isVisible && !isVisibleRef.current) {
        chartRef.current?.chart.setSize(null, undefined);
    }

    return (
        <Chart
            initialScale={initialScale}
            isVisible={isVisible}
            setChartRef={ref => chartRef.current = ref}
        >
            {children}
        </Chart>
    );
};
