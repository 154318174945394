import { useRef } from 'react';
import { useIncaForm } from '@cancercentrum/rcc-react';
import type { Metadata } from './offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';

export const useGetTableNameByRegvarFn = () => {
    const mappings = useRef<Record<string, string> | undefined>(undefined);
    const inca = useIncaForm();

    return (shortname: string): keyof Metadata => {
        if (!mappings.current) {
            const md = inca.form.metadata;

            mappings.current = Object.keys(md).reduce<Record<string, string>>((reduction, tableName) => {
                const tableMetadata = md[tableName];

                return Object.keys(tableMetadata.regvars).reduce((r, sn) => {
                    r[sn] = tableName;

                    return r;
                }, reduction);
            }, {});
        }

        return mappings.current[shortname] as keyof Metadata;
    };
};
