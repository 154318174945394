import type { QuestionMapping } from './utils';
import { Severity } from './utils';

export const QLQC30: QuestionMapping[] = [
    {
        shortnames: [
            'QLQC30_Q1',
            'QLQC30_Q2',
            'QLQC30_Q3',
            'QLQC30_Q4',
            'QLQC30_Q5',
            'QLQC30_Q6',
            'QLQC30_Q7',
            'QLQC30_Q8',
            'QLQC30_Q9',
            'QLQC30_Q10',
            'QLQC30_Q11',
            'QLQC30_Q12',
            'QLQC30_Q13',
            'QLQC30_Q14',
            'QLQC30_Q15',
            'QLQC30_Q16',
            'QLQC30_Q17',
            'QLQC30_Q18',
            'QLQC30_Q19',
            'QLQC30_Q20',
            'QLQC30_Q21',
            'QLQC30_Q22',
            'QLQC30_Q23',
            'QLQC30_Q24',
            'QLQC30_Q25',
            'QLQC30_Q26',
            'QLQC30_Q27',
            'QLQC30_Q28',
        ],
        mappings: {
            4: Severity.VeryBad,
            3: Severity.Bad,
            2: Severity.SomewhatGood,
            1: Severity.Great,
        },
    },
];

