import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        senastKontrollerad: {},
        komplettLkmAdjuvant: {},
        komplettLkmPalliativ: {},
        selectedVd: {},
    },
    Responsbedömning: {
        Resp_datum: {},
        Resp_respons: {
            identifiers: [
                'crem', 'cremi', 'pd', 'prem', 'premi', 'sd',
            ],
        },
        Resp_ejUtford: {},
        Resp_orsak: {
            identifiers: [
                '2', '5', '6', '7', '9',
            ],
        },
        Resp_orsakSpec: {},

    },
    Utvärderingsmetod: {
        Resputv_cr: {
            identifiers: ['nej', 'ja'],
        },
        Resputv_metod: {
            identifiers: [
                'endast_rutin',
                'benmarg_morfologi',
                'dt_mrt_ultra',
                'flodescytometri_benmarg',
                'flodescytometri_blod',
                'immunohistokemi_benmarg',
            ],
        },
    },
    Läkemedel: {
        Lkm_antalCyklerGivna: {},
        Lkm_startdos_lista: {
            identifiers: ['eskalering', 'full', 'reducerad'],
        },
        Lkm_stopporsak: {
            identifiers: [
                '1', 'enligt_plan', // '1' Kan tas bort efter R12 när identifier ändrats
                '2', 'toxicitet', // '2' Kan tas bort efter R12 när identifier ändrats
                '3', 'progress', // '3' Kan tas bort efter R12 när identifier ändrats
                '4', 'transformation', // '4' Kan tas bort efter R12 när identifier ändrats
                '5', 'patientens_val', // '5' Kan tas bort efter R12 när identifier ändrats
                'dod_progress',
                'dod_toxicitet',
                'dod_annan',
                'otillracklig_effekt',
                '9', 'annan_orsak', // '9' Kan tas bort efter R12 när identifier ändrats
            ],
        },
        Lkm_behandlingslinje: {
            identifiers: ['1', '2', '3', '4', '5', '>5'],
        },
    },
};

export default dataset;
