import { useDataActions } from '@cancercentrum/rcc-react';
import noop from 'lodash/noop';
import type { RegvarStringMetadata, RegvarValueDomainMetadata } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import Question from '../../components/IPOQuestion';
import useDataSet from '../../hooks/useDataSet';
import { trim } from './utils';
import { LkmStudieQuestion } from './LkmStudieQuestion';
import type { SetIsCustomSubstanceCallback } from './types';

type StudieLakemedelSpecQuestionProps = {
    studieLkmRegvar: RegvarModel<RegvarStringMetadata>;
    setIsCustomSubstance?: SetIsCustomSubstanceCallback;
    labelClassName?: string;
    wrapperClassName?: string;
    studieRegvar?: RegvarModel<RegvarValueDomainMetadata>;
    required?: boolean;
}

const StudieLakemedelSpecQuestion = (props: StudieLakemedelSpecQuestionProps): ReactElement => {
    const {
        studieLkmRegvar,
        setIsCustomSubstance = noop,
        labelClassName = 'col-sm-2',
        wrapperClassName = 'col-sm-10',
        studieRegvar,
        required,
    } = props;
    const dataActions = useDataActions();
    const dataset = useDataSet();

    const clearStudie = (value: string | null) => {
        if (!value && dataset.Läkemedel!.Lkm_studie_vd) {
            dataActions.updateRow(x => x.clearRegvarValue('Lkm_studie_vd'), { skipHistory: true });
        }
    };

    return (
        <>
            <Question
                regvar={studieLkmRegvar}
                labelClassName={labelClassName}
                className="input-long"
                wrapperClassName={wrapperClassName}
                onValueChange={val => {
                    clearStudie(val);
                    setIsCustomSubstance(!!val);
                }}
                valueParser={trim}
                required={required}
            />

            {!!studieRegvar && (
                <LkmStudieQuestion
                    regvar={studieRegvar}
                    labelClassName={labelClassName}
                    wrapperClassName={wrapperClassName}
                    disabled={!studieLkmRegvar.getValue()}
                />
            )}
        </>
    );
};

export default StudieLakemedelSpecQuestion;
