import type { DataSet } from '../../types';

const dataset: DataSet = {
    Läkemedel: {
        Lkm_lymfomtyp: {
            identifiers: ['hodgin', 'lag', 'hog', 'ovrigt'],
        },
        Lkm_stopporsak: {
            identifiers: [
                '1', 'enligt_plan', // '1' Kan tas bort efter R12 när identifier ändrats
                '2', 'toxicitet', // '2' Kan tas bort efter R12 när identifier ändrats
                '3', 'progress', // '3' Kan tas bort efter R12 när identifier ändrats
                '4', 'transformation', // '4' Kan tas bort efter R12 när identifier ändrats
                '5', 'patientens_val', // '5' Kan tas bort efter R12 när identifier ändrats
                'dod_progress',
                'dod_toxicitet',
                'dod_annan',
                '9', 'annan_orsak', // '9' Kan tas bort efter R12 när identifier ändrats
            ],
        },
        Lkm_ablatioTestis: {},
    },
};

export default dataset;
