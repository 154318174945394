import head from 'lodash/head';
import partition from 'lodash/partition';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { isValid, parseISO } from 'date-fns';
import type { RowModel } from '@cancercentrum/rcc-react';
import type { ListItem } from '@cancercentrum/inca';
import type { FunctionComponent, ReactElement } from 'react';
import { useAtomValue } from 'jotai';
import type { LineItem } from '../../components/TextRepresentation';
import { Section } from '../../components/TextRepresentation';
import { formatIncaDate, isBetweenDaysInclusive, isSameDayOrAfter } from '../../utils/date';
import useDataSet from '../../hooks/useDataSet';
import type { DosjusteringRowModel, LäkemedelRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { defaultFormatLakemedelDate, getRegistrations, getStartdos, useGetDefaultSubstanceNameCb } from './utils';
import type { HierarchifiedSubstansItem, RegimItem, Registration, SubstanskategoriVDItem } from './types';
import { useGetLakemedelSubTable } from './useGetLakemedelSubTable';
import { lakemedelVdsAtom } from './useLakemedelVDPromise';
import { Line } from './Line';

export interface LakemedelRowProps {
    title: string;
    category: SubstanskategoriVDItem;
    subs: RowModel[];
    startdatum: Date;
    stoppdatum?: Date;
    startdos?: string;
    stopporsak?: string;
    linje?: string;
}

export interface DosjusteringRowProps {
    row: DosjusteringRowModel;
}

const getStartDate = (rows: RowModel[]): Date => {
    const firstDate = head(
        orderBy(
            rows.filter(x => x.getRegvarValue('Lkm_startdatum')).map<string>((x) => x.getRegvarValue('Lkm_startdatum')),
            [x => x],
            ['asc'],
        ),
    );

    const d = firstDate ? parseISO(firstDate) : undefined;

    if (!d || !isValid(d)) {
        throw new Error('Startdatum på läkemedel saknas.');
    }

    return d;
};

const getStopDate = (rows: RowModel[]): Date | undefined => {
    if (rows.some(x => !x.getRegvarValue('Lkm_stoppdatum'))) {
        return undefined;
    }

    return head(
        orderBy(
            rows
                .map<Date>((x) => parseISO(x.getRegvarValue('Lkm_stoppdatum') as string | null || ''))
                .filter(x => isValid(x)),
            [x => x],
            ['desc'],
        ),
    );
};

const getStopporsak = (rows: RowModel[]): string => {
    const orsaker = rows.reduce<string[]>((reduction, row) => {
        const orsak: string | null | undefined = row.getRegvarValue('Lkm_stopporsak')?.text;
        const spec: string | null = row.getRegvarValue('Lkm_stopporsakSpec');

        if (spec) {
            reduction.push(orsak ? `${orsak} (${spec})` : spec);
        } else if (orsak) {
            reduction.push(orsak);
        }

        return reduction;
    }, []);

    return orderBy(uniq(orsaker), [x => x], ['asc']).join(', ');
};

const getLinje = (rows: RowModel[]): string | undefined => {
    if (rows.every(x => !x.getRegvarValue('Lkm_behandlingslinje'))) {
        return undefined;
    }

    return head(
        rows
            .filter(x => !!x.getRegvarValue('Lkm_behandlingslinje'))
            .map<string>((x) => (x.getRegvarValue('Lkm_behandlingslinje') as ListItem | null)?.value || ''),
    );
};

const DefaultLakemedelRow = (props: LakemedelRowProps): JSX.Element => {
    const { title, category, subs, startdatum, stoppdatum, startdos, stopporsak, linje } = props;

    return (
        <Line
            parts={[
                title,
                !category.data.k_understodjande && category.data.k_namn.toLowerCase(),
                defaultFormatLakemedelDate(startdatum, stoppdatum),
                stopporsak,
                startdos,
                !!linje && `Linje ${linje}`,
                subs.some(x => x.getRegvarValue('Lkm_gesInomRamenAvStudie')) && 'Gavs inom ramen av en studie',
            ]}
        />
    );
};

const DefaultDosjusteringRow = (props: DosjusteringRowProps): JSX.Element => {
    const { row } = props;

    return (
        <Line
            parts={[
                row.getRegvarValue('Dj_datum'),
                row.getRegvarValue('Dj_justering')?.text,
                row.getRegvarValue('Dj_orsak')?.text,
            ]}
        />
    );
};

const LakemedelSection = (props: {
    header: string;
    insattningar: Registration[];
    regimerById: Record<number, RegimItem>;
    substanserById: Record<number, HierarchifiedSubstansItem>;
    components?: {
        LakemedelRow?: FunctionComponent<LakemedelRowProps>;
        DosjusteringRow?: FunctionComponent<DosjusteringRowProps>;
    }
    lineComp?: FunctionComponent<LakemedelRowProps>
    sortOrder: 'asc' | 'desc'
}): ReactElement | null => {
    const {
        header,
        insattningar,
        regimerById,
        substanserById,
        components,
        sortOrder,
    } = props;
    const result: LineItem[] = [];
    const getSubstanceName = useGetDefaultSubstanceNameCb();
    const dataset = useDataSet();
    const LakemedelLine = components?.LakemedelRow || DefaultLakemedelRow;
    const DosjusteringLine = components?.DosjusteringRow || DefaultDosjusteringRow;

    const createRowText = (title: string, category: SubstanskategoriVDItem, subs: LäkemedelRowModel[]): LineItem => {
        const start = getStartDate(subs);
        const stop = getStopDate(subs);
        const stopporsak = getStopporsak(subs);
        const startdos = (dataset.Läkemedel!.Lkm_startdos_lista) ? getStartdos(subs) : undefined;
        const linje = dataset.Läkemedel!.Lkm_behandlingslinje ? getLinje(subs) : undefined;

        const lkm: LineItem = {
            text: (
                <LakemedelLine
                    title={title}
                    category={category}
                    subs={subs}
                    startdatum={start}
                    stoppdatum={stop}
                    startdos={startdos}
                    stopporsak={stopporsak}
                    linje={linje}
                />
            ),
            sortValue: `${formatIncaDate(start)}-${stop ? formatIncaDate(stop) : 'pågående'}`,
        };

        const dosjusteringar = dataset.Dosjustering ? subs.flatMap(x => x.getSubTableRows('Dosjustering').toArray()).reduce<LineItem[]>((red, dj) => {
            red.push({
                sortValue: dj.getRegvarValue('Dj_datum')!,
                text: <DosjusteringLine row={dj} />,
            });

            return red;
        }, []) : [];

        lkm.children = orderBy(dosjusteringar, [x => x.sortValue], ['asc']);

        return lkm;
    };

    insattningar.forEach(x => {
        if (x.type === 'regim' && x.isCompleteAndSamePeriod) {
            const namn = regimerById[x.regimId]?.data.reg_namn || `Regim: ${x.regimId}`;

            result.push(createRowText(namn, x.category, x.substances.map(s => s.row)));
        } else {
            const subs = x.type === 'regim' ? x.substances : [x];

            subs.forEach(sub => {
                const subId = sub.row.getRegvarValue('Lkm_substans_vd') as number | null;
                const vd = substanserById[subId || -1];
                const namn = getSubstanceName(sub.row, vd);

                result.push(createRowText(namn, sub.category, [sub.row]));
            });
        }
    });

    if (!result.length) {
        return null;
    }

    const ordered = orderBy(result, [x => x.sortValue], [sortOrder]);

    return (
        <div>
            <div><u>{header}</u></div>
            <ol>
                {ordered.map((x, i) => {
                    return (
                        <li key={i}>
                            {x.text}
                            <ul className="list-unstyled ms-3">
                                {(x.children || []).map((y, i2) => {
                                    return <li key={i2}>{y.text}</li>;
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

const LakemedelAblatio = (props: {
    row: RowModel;
}): ReactElement => {
    const { row } = props;
    const start = parseISO(row.getRegvarValue('Lkm_startdatum') as string || '');

    return (
        <p>Ablatio testis, {defaultFormatLakemedelDate(start, undefined, { skipStop: true })}.</p>
    );
};

const defaultGetGroupName = (row: RowModel): string => {
    return (row.getRegvarValue('Lkm_behandlingsintention') as ListItem | null)?.text || 'Okänd behandlingsintention';
};

export const LakemedelTextRepresentation = (props: {
    dateStart?: Date;
    dateEnd?: Date;
    header?: string;
    getGroupName?: (row: RowModel) => string;
    sortOrder?: 'asc' | 'desc',
    components?: {
        LakemedelRow?: FunctionComponent<LakemedelRowProps>;
        DosjusteringRow?: FunctionComponent<DosjusteringRowProps>;
    };
}): ReactElement | null => {
    const {
        dateStart,
        dateEnd,
        header = 'Systembehandling',
        getGroupName = defaultGetGroupName,
        sortOrder = 'asc',
        components,
    } = props;
    const dataset = useDataSet();
    const lakemedelPromise = useAtomValue(lakemedelVdsAtom);
    const lkmSubTable = useGetLakemedelSubTable();

    // Vänta tills ajaxdata har laddats.
    if (!lakemedelPromise.result) {
        return null;
    }

    const { substanserById, regimerById, vdSubstanskategorier } = lakemedelPromise.result;

    // Läkemedelsrader exkluderat ablatio testis.
    const lkmRows2 = lkmSubTable
        .getRows()
        .filter(row => {
            const start = parseISO(row!.getRegvarValue('Lkm_startdatum') as string || '');

            // Filtrering på Lkm_exkluderad är flyttad till <see>convertToInsattningar</see> för att kunna avgöra om det är en inkomplett
            // regim som behöver splittas isär.
            return !row.getRegvarValue('Lkm_migreringsdublett')
                && (
                    (!dateStart && !dateEnd)
                    || (dateStart && dateEnd && isBetweenDaysInclusive(start, dateStart, dateEnd))
                    || (dateStart && isSameDayOrAfter(start, dateStart))
                );
        })
        .toArray();

    const [lkmRows, lkmRowsAblatio] = partition(lkmRows2, x => {
        if (!dataset.Läkemedel!.Lkm_ablatioTestis) {
            return true;
        }

        return !x.getRegvarValue('Lkm_ablatioTestis') as boolean | null;
    });

    // Vänta tills ajaxladdningen är klar.
    if (!vdSubstanskategorier.length) {
        return null;
    }

    const insattningar = getRegistrations({
        rows: lkmRows,
        vdSubstanskategorier,
    });
    const [understodjande, ejUnderstodjande] = partition(insattningar, x => x.category.data.k_understodjande);

    const insattningarByBehInt = groupBy(ejUnderstodjande, x => {
        const row = x.type === 'regim' ? x.substances[0].row : x.row;

        return getGroupName(row);
    });

    return (
        <Section header={header}>
            {Object.keys(insattningarByBehInt).map(x => (
                <LakemedelSection
                    key={x}
                    header={x}
                    insattningar={insattningarByBehInt[x]}
                    regimerById={regimerById}
                    substanserById={substanserById}
                    sortOrder={sortOrder}
                    components={components}
                />
            ))}

            <LakemedelSection
                header="Understödjande behandling"
                insattningar={understodjande}
                regimerById={regimerById}
                substanserById={substanserById}
                sortOrder={sortOrder}
                components={components}
            />

            {lkmRowsAblatio.map(x => {
                return <LakemedelAblatio key={x.getId()} row={x} />;
            })}
        </Section>
    );
};
