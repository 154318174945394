/* eslint no-console: "off" */

export const log = (...args: any[]) => {
    console.log(...args);
};

export function logOnce(key: string, ...args: any[]): void {
    if (!(window.top as any)[key]) {
        (window.top as any)[key] = true;

        log(...args);
    }
}

