import { useCallback, useRef, useState } from 'react';

export function useToggle<T>(): [T | null, (value: T) => void, () => void] {
    const [state, setState] = useState<T | null>(null);
    const stateRef = useRef(state);
    stateRef.current = state;

    const cb = useCallback((val: T) => {
        if (stateRef.current) {
            setState(null);
        } else {
            setState(val);
        }
    }, []);

    const clear = useCallback(() => {
        setState(null);
    }, []);

    return [state, cb, clear];
}
