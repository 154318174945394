import type { RowModel } from '@cancercentrum/rcc-react';
import { Explanation, useRowModel } from '@cancercentrum/rcc-react';
import classnames from 'classnames';
import { Badge } from '@cancercentrum/rcc-bootstrap';
import type { FC, ReactElement } from 'react';
import HTab from '../../../components/HTabs/HTab';
import Question from '../../components/IPOQuestion';
import type { Oversikt } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { DisplayName, ModuleName } from './constants';

const CustTabHeader = (props: {
    displayName: string;
    isDirty: boolean;
    count: number;
    className?: string;
}): ReactElement => {
    const { displayName, isDirty, count, className } = props;
    const name = count ? <span>{displayName} <Badge bsStyle="primary">{count}</Badge></span> : displayName;

    return (
        <div className={className}>
            {isDirty && (
                <span className="me-1">
                    <Explanation>Innehåller osparade ändringar</Explanation>
                </span>
            )}
            {name}
        </div>
    );
};

export const EngangsregistreringarTab = (props: {
    dateShortname?: keyof Oversikt['regvars'];
    statusShortnames?: (keyof Oversikt['regvars'])[];
    extraShortnames?: (keyof Oversikt['regvars'])[];
    highlight?: boolean;
    components?: {
        Extra?: FC<{
            shortnames: (keyof Oversikt['regvars'])[];
            row: RowModel;
        }>;
    };
}): JSX.Element => {
    const { dateShortname, statusShortnames, extraShortnames, components, highlight } = props;
    const row = useRowModel();

    const allShortnames: (keyof Oversikt['regvars'])[] = [
        ...(dateShortname ? [dateShortname] : []),
        ...(statusShortnames || []),
        ...(extraShortnames || []),
    ];

    const isDirty = !!row && allShortnames.some(x => row.getRegvar(x).$isDirty);
    const usedSettings = row ? allShortnames.filter(x => row.getRegvarValue(x)).length : 0;
    const Extra = components?.Extra;

    const header = (
        <CustTabHeader
            displayName={DisplayName}
            className={classnames({
                'tab-highlight': highlight,
            })}
            isDirty={isDirty}
            count={usedSettings}
        />
    );

    return (
        <HTab eventKey={ModuleName} header={header}>
            <div className="my-4">
                {!!dateShortname && (
                    <Question regvar={row.getRegvar('senastKontrollerad')} />
                )}
                {!!statusShortnames && (
                    <fieldset className={dateShortname ? 'mt-4' : undefined}>
                        <legend className="text-label">Status för registrering</legend>

                        {statusShortnames.map(x => <Question key={x} regvar={row.getRegvar(x)} inline={true} />)}
                    </fieldset>
                )}
                {!!Extra && <Extra row={row} shortnames={extraShortnames || []} />}
            </div>
        </HTab>
    );
};

export default EngangsregistreringarTab;
