import noop from 'lodash/noop';
import { shallowEqual, useSelector } from 'react-redux';
import { isAfter, isFuture } from 'date-fns';
import type { ListItem } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import type { PointMarkerOptionsObject } from 'highcharts';
import { useAtomValue, useSetAtom } from 'jotai';
import Series from '../../components/Chart/Series';
import { Point } from '../../components/Chart/Point';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { formatIncaDate, getUTCStartOfDayTime } from '../../utils/date';
import { divIf } from '../../utils/tooltipUtils';
import type { OverviewState } from '../../types';
import useDataSet from '../../hooks/useDataSet';
import { EtjansterSection, Tooltip } from '../../components/Chart/Tooltip';
import { useVDStudier } from '../studie';
import { selectedTabAtom } from '../../atoms';
import { getColor, getPattern } from './categories';
import { getOrderedCategories, useGetDefaultSubstanceNameCb } from './utils';
import { getSeries } from './selectors/lakemedelChartConfigSelector';
import { ModuleName } from './constants';
import type { GetCategoryCallback, IsStripedCallback, RenderLkmSeriesCallback, RenderLkmTooltipCallback, SerieData } from './types';
import { getDosMarker } from './Dosjusteringsoptions';
import { useLakemedelGraphMode } from './useLakemedelGraphMode';
import { lakemedelVdsAtom } from './useLakemedelVDPromise';

const defaultIsStripedCallback: IsStripedCallback = (rows) => rows.some(x => (x.getRegvarValue('Lkm_behandlingsintention') as ListItem | null)?.identifier === '1');

const defaultRenderTooltip: RenderLkmTooltipCallback = (opts) => {
    const item = opts.data;
    const firstRow = item.rows[0].row;

    let highDateString: string;

    if (item.end) {
        if (isFuture(item.end)) {
            highDateString = `Planerad utsättning ${formatIncaDate(item.end)}`;
        } else {
            highDateString = formatIncaDate(item.end);
        }
    } else {
        highDateString = 'Pågående';
    }

    const orsak = firstRow.getRegvarValue('Lkm_stopporsak')?.text;
    const orsakSpec = firstRow.getRegvarValue('Lkm_stopporsakSpec');

    return (
        <Tooltip>
            <Tooltip.Header>
                <EtjansterSection>Läkemedel: </EtjansterSection>{item.name}
            </Tooltip.Header>
            <Tooltip.Body>
                <div>Datum: {formatIncaDate(item.start)} - {highDateString}</div>
                {!!firstRow.getRegvarValue('Lkm_gesInomRamenAvStudie') && <div>Ges inom ramen för studie</div>}
                {divIf(firstRow.getRegvarValue('Lkm_ecog')?.value, t => `ECOG vid insättning: ${t}`)}
                <div>Beh.intention: {firstRow.getRegvarValue('Lkm_behandlingsintention')?.text}</div>
                {divIf(firstRow.getRegvarValue('Lkm_behandlingsintention_spec')?.text, t => `Beh.intention (spec): ${t}`)}
                {divIf(item.startdos?.text, t => `Startdos: ${t}`)}
                {divIf(orsak, () => `Stopporsak: ${orsak}${orsakSpec ? ` (${orsakSpec})` : ''}`)}
                <div>Kategori: {item.category?.data.k_namn ?? 'Uppgift saknas'}</div>
            </Tooltip.Body>
        </Tooltip>
    );
};

const MARKERS = {
    standard: {
        enabled: true,
        symbol: 'circle',
        radius: 6,
        lineColor: '#fff',
        lineWidth: 1,
    },
    ongoing: {
        enabled: true,
        symbol: 'arrow',
        radius: 4,
        lineColor: '#fff',
        lineWidth: 1,
    },
};

const LakemedelAxis = (props: {
    renderSeries?: RenderLkmSeriesCallback;
    isStripedCallback?: IsStripedCallback;
    getCategory?: GetCategoryCallback;
    renderTooltip?: RenderLkmTooltipCallback;
}): ReactElement => {
    const getSubstanceName = useGetDefaultSubstanceNameCb();
    const {
        renderSeries = noop,
        isStripedCallback = defaultIsStripedCallback,
        getCategory,
        renderTooltip = defaultRenderTooltip,
    } = props;
    const setActiveTab = useSetAtom(selectedTabAtom);
    const [lkmMode] = useLakemedelGraphMode();
    const dataset = useDataSet();
    const lakemedelPromise = useAtomValue(lakemedelVdsAtom);
    const series = useSelector((state: OverviewState) => {
        return getSeries(state as any, { getSubstanceName, getCategory, includeStartdos: !!dataset.Läkemedel!.Lkm_startdos_lista, mode: lkmMode, lakemedelPromise }) as SerieData[] || [];
    }, shallowEqual);
    const vdStudier = useVDStudier();
    const now = new Date();

    const categories = getOrderedCategories({ seriedata: series });
    return (
        <CategoriesAxis
            reversed={true}
            categories={categories}
            visible={categories.length > 0}
        >
            <>
                {series.map(s => {
                    const isOngoing = !s.end || isAfter(s.end, now);
                    const seriesColor = getColor(s.category.data.k_farg, isOngoing);
                    const color = isStripedCallback(s.rows.map(x => x.row)) ? getPattern(seriesColor) : seriesColor;
                    const tooltip = () => renderTooltip({
                        data: s,
                        regimerById: lakemedelPromise.result?.regimerById || {},
                        substanserById: lakemedelPromise.result?.substanserById || {},
                        color: seriesColor,
                        vdStudier,
                        dataset,
                    });

                    let startMarker: PointMarkerOptionsObject = MARKERS.standard;
                    if (s.startdos) {
                        startMarker = getDosMarker(seriesColor, s.startdos);
                    }

                    let endPointDate = s.end || now;

                    // Om det är ett framtida startdatum behöver stoppdatum flyttas fram till det datumet.
                    if (isAfter(s.start, endPointDate)) {
                        endPointDate = s.start;
                    }

                    return (
                        <Series key={s.key} name={s.name} type="line" showInLegend={false} color={color} lineWidth={8}>
                            <Point
                                id={`${s.key}_start`}
                                x={getUTCStartOfDayTime(s.start)}
                                y={categories.indexOf(s.name)}
                                renderTooltip={tooltip}
                                marker={startMarker}
                                events={{
                                    click: () => {
                                        setActiveTab(ModuleName);
                                    },
                                }}
                            />
                            <Point
                                id={`${s.key}_stopp`}
                                x={getUTCStartOfDayTime(endPointDate)}
                                y={categories.indexOf(s.name)}
                                renderTooltip={tooltip}
                                marker={s.end ? MARKERS.standard : MARKERS.ongoing}
                                events={{
                                    click: () => {
                                        setActiveTab(ModuleName);
                                    },
                                }}
                            />
                        </Series>
                    );
                })}
                {renderSeries({ categories, series })}
            </>
        </CategoriesAxis>
    );
};

export default LakemedelAxis;
