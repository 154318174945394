import type { ReactElement } from 'react';
import { memo } from 'react';
import get from 'lodash/get';
import type { RowModel } from '@cancercentrum/rcc-react';
import { GridActions } from '@cancercentrum/rcc-react';
import { useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import type { ListItem } from '@cancercentrum/inca';
import { useSetAtom } from 'jotai';
import { valueAndTextFormatter } from '../../../utils/formatters';
import { Point } from '../../components/Chart/Point';
import useDataSet from '../../hooks/useDataSet';
import { SammantagenBedomningColors } from '../../../constants';
import { getUTCStartOfDayTime } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';

const KliniskBedomningPoint = (props: {
    row: RowModel;
    y: number;
    formatter?: (item: ListItem) => string;
}): ReactElement => {
    const { row, y } = props;
    const ctxDataSet = useDataSet();
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const date = getUTCStartOfDayTime(parseISO(row.getRegvarValue('K_besoksdatum') || ''));
    const ds = ctxDataSet.Kontakt!.K_kliniskBedomning;
    const formatter = props.formatter || get(ds, 'props.formatter') || valueAndTextFormatter;
    const label = get(ds, 'props.label') || row.getRegvar('K_kliniskBedomning').$rcc.regvarMetadata.label;
    const kliniskBedomning = row.getRegvarValue('K_kliniskBedomning');
    const color = SammantagenBedomningColors[kliniskBedomning.identifier] || '#fff';

    const tooltip = (
        <LegacyTooltip headerText={`Kontakt ${row.getRegvarValue('K_besoksdatum')}`}>
            {label}: {formatter(kliniskBedomning)}
        </LegacyTooltip>
    );

    const marker = {
        symbol: 'circle',
        radius: 8,
        lineWidth: 1,
        lineColor: '#000',
    };

    return (
        <Point
            x={date}
            y={y}
            marker={marker}
            renderTooltip={() => tooltip}
            color={color}
            events={{
                click() {
                    setActiveTab(ModuleName);
                    dispatch(GridActions.setSelectedItemId(GridId, row.getId()));
                },
            }}
        />
    );
};

export default memo(KliniskBedomningPoint);
