import { InputGroup } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import type { IPOQuestionProps } from '../../components/IPOQuestion';
import Question from '../../components/IPOQuestion';
import { LabUnits } from './config';
import { LabUnit } from './LabUnit';

const LabQuestion = (props: IPOQuestionProps): ReactElement => {
    const { regvar } = props;
    const unit = LabUnits[regvar.$rcc.shortname];
    const append = unit ? (
        <InputGroup.Addon>
            <div style={{ minWidth: '75px' }}><LabUnit unit={unit} /></div>
        </InputGroup.Addon>
    ) : undefined;

    return (
        <Question {...props} append={append} />
    );
};

export default LabQuestion;
