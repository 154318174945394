import { memo } from 'react';
import { useSelector } from 'react-redux';
import type { OverviewState } from '../types';
import { CriteriaFieldset } from './CriteriaFieldset';
import { InfoFieldset } from './InfoFieldset';
import { ChangeFieldset } from './ChangeFieldset';
import { RestrictedDataFieldset } from './RestrictedDataFieldset';
import usePDL from './usePDL';

const isModifiedSelector = (state: OverviewState) => !!state.data.past.length;

const PdlHeaderSection = () => {
    const isModified = useSelector(isModifiedSelector);
    const { currentUser, samtycke, positions } = usePDL();

    return (
        <div className="patientdatalagen__info">
            <div className="row">
                <div className="col-md-6">
                    <CriteriaFieldset currentUser={currentUser} samtycke={samtycke} />
                    <InfoFieldset currentUser={currentUser} samtycke={samtycke} positions={positions} />
                    <ChangeFieldset isModified={isModified} samtycke={samtycke} />
                </div>

                <div className="col-md-6">
                    <RestrictedDataFieldset />
                </div>
            </div>
        </div>
    );
};

export default memo(PdlHeaderSection);
