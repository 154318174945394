import { useDispatch } from 'react-redux';
import type { RowModel } from '@cancercentrum/rcc-react';
import { GridActions } from '@cancercentrum/rcc-react';
import { parseISO } from 'date-fns';
import { useSetAtom } from 'jotai';
import { Point } from '../../components/Chart/Point';
import { getUTCStartOfDayTime } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';

export const KarnofskyPoint = (props: {
    row: RowModel;
    y: number;
}) => {
    const { row, y } = props;
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const date = getUTCStartOfDayTime(parseISO(row.getRegvarValue('K_besoksdatum') || ''));
    const karnofsky = row.getRegvarValue('K_karnofsky');
    const value = karnofsky.value;
    const marker = {
        symbol: 'circle',
        radius: 8,
        lineWidth: 1,
        lineColor: '#fff',
    };

    return (
        <Point
            x={date}
            y={y}
            labelText={value}
            color="#000"
            marker={marker}
            renderTooltip={() => (
                <LegacyTooltip headerText={`Kontakt ${row.getRegvarValue('K_besoksdatum')}`}>
                    <div>Karnofsky: {karnofsky.text}</div>
                </LegacyTooltip>
            )}
            events={{
                click() {
                    setActiveTab(ModuleName);
                    dispatch(GridActions.setSelectedItemId(GridId, row.getId()));
                },
            }}
        />
    );
};
