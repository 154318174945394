import type { ReactElement } from 'react';
import { memo } from 'react';
import { GridActions } from '@cancercentrum/rcc-react';
import { useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import type { ListItem } from '@cancercentrum/inca';
import { useSetAtom } from 'jotai';
import { Point } from '../../components/Chart/Point';
import { getUTCStartOfDayTime } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import { selectedTabAtom } from '../../atoms';
import * as constants from './constants';

const BilddiaPoint = (props: {
    id: string | number;
    date: string;
    color: string;
    tooltipContent: ReactElement;
    sammanvagdBedomning?: ListItem | null;
}): ReactElement => {
    const { id, date, color, tooltipContent, sammanvagdBedomning } = props;
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const marker = {
        symbol: 'circle',
        radius: 8,
        lineWidth: 1,
        lineColor: '#000',
    };
    const t = (
        <LegacyTooltip headerText={`Bilddiagnostik ${date}`}>
            <div>{tooltipContent}</div>
        </LegacyTooltip>
    );
    const labelText = sammanvagdBedomning?.identifier === 'm1' ? 'M' : undefined;

    return (
        <Point
            key={id}
            x={getUTCStartOfDayTime(parseISO(date))}
            y={0}
            marker={marker}
            renderTooltip={() => t}
            color={color}
            labelText={labelText}
            events={{
                click() {
                    setActiveTab(constants.ModuleName);
                    dispatch(GridActions.setSelectedItemId(constants.GridId, id));
                },
            }}
        />
    );
};

export default memo(BilddiaPoint);
