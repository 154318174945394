import values from 'lodash/values';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import type { PointMarkerOptionsObject } from 'highcharts';
import type { ReactElement } from 'react';
import { Tooltip } from '../../components/Chart/Tooltip';
import { divIf } from '../../utils/tooltipUtils';
import type { GetValueDomainValues } from '../../../types';
import type { StudieRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { ExternalStudieVDItem, StudieVDData, StudieVDItem } from './types';
import type { ExternalStudiesResult } from './useExternalStudieData';

export const getVDStudier = async (data: {
    getValueDomainValues: GetValueDomainValues;
}): Promise<StudieVDItem[]> => {
    const res = await data.getValueDomainValues<StudieVDData>('Stud_studie_vd');

    return orderBy(
        values(groupBy(res, studie => studie.id)).map(studieGroup => {
            const firstStudie = studieGroup[0];

            return {
                ...firstStudie,
                diagnoser: studieGroup.filter(x => x.data.diagnos_Beskrivning).map(studie => studie.data.diagnos_Beskrivning!),
            };
        }),
        [x => x.data.namn],
        ['asc'],
    );
};

export const getScatterMarker = (color: string): PointMarkerOptionsObject => ({
    symbol: 'circle',
    radius: 6,
    lineWidth: 1,
    lineColor: color === '#000' ? '#fff' : '#000',
});

type ScatterInfo = {
    tooltip: ReactElement;
    marker: PointMarkerOptionsObject;
    color: string;
};

type ExternalStates = 'REGISTRATION_MISSING' | 'NO_CONSENT' | 'NOT_INCLUDED' | 'INCLUDED';

export const getExternalStudyRegistrationState = (extStudie: ExternalStudieVDItem | undefined): ExternalStates => {
    if (!extStudie) {
        return 'REGISTRATION_MISSING';
    }

    if (extStudie.data.inkl_kriterie_samtycke_Värde === '0') {
        return 'NO_CONSENT';
    }

    if (extStudie.data.inkluderad_studie) {
        return 'INCLUDED';
    }

    return 'NOT_INCLUDED';
};

export function getStudyStopDate(options: {
    // row: StudieRowModel;
    studieId: number | null;
    vdStudier: StudieVDItem[];
    externalStudiesData: ExternalStudiesResult;
    // dataset: DataSet;

    /**
     * Värde på Stud_stoppdatum för de diagnoser som har den.
     */
    regvarStopDate: string | null;
}) {
    const { studieId, vdStudier, externalStudiesData, regvarStopDate } = options;

    const vdStudie = vdStudier.find(x => x.id === studieId);
    if (vdStudie?.data.formularkortnamn) {
        return externalStudiesData.get(vdStudie.id)?.data.datum_avslutad || null;
    }

    return regvarStopDate;
}

export const getScatterInfo = (studie: StudieRowModel, vdStudier: StudieVDItem[], showEndDate: boolean, extStudiesData: ExternalStudiesResult): ScatterInfo => {
    const dateStr = studie.getRegvarValue('Stud_startdatum');
    const stud = studie.getRegvar('Stud_studie_vd');
    const extData = extStudiesData.get(stud.getValue() || -1);
    const state = getExternalStudyRegistrationState(extData);
    const vdStudie = vdStudier.find(x => x.id === stud.value);
    const studienamn = vdStudie?.data.namn || studie.getRegvarValue('Stud_studienamn') || '[uppgift saknas]';

    let color = '#000';

    if (state === 'NO_CONSENT') {
        color = '#f88311';
    } else if (state === 'NOT_INCLUDED') {
        color = '#f35269';
    }

    const stopDate = getStudyStopDate({
        studieId: studie.getRegvarValue('Stud_studie_vd'),
        regvarStopDate: showEndDate ? studie.getRegvarValue('Stud_stoppdatum') : null,
        vdStudier,
        externalStudiesData: extStudiesData,
    });

    let endDateStr = '';
    if (showEndDate && (!vdStudie?.data.formularkortnamn || extData?.data.inkluderad_studie)) {
        endDateStr = ` - ${stopDate || 'Pågående'}`;
    }

    const tooltip = (
        <Tooltip>
            <Tooltip.Header>{studienamn} {dateStr}{endDateStr}</Tooltip.Header>
            <Tooltip.Body>
                {divIf(state === 'NO_CONSENT', () => 'Patienten avböjer deltagande i studien')}
                {divIf(state === 'NOT_INCLUDED', () => 'Patienten har ej uppfyllt krav för inklusion i studie')}
                {divIf(extData?.data.orsak_avslutad_Beskrivning, (x) => `Orsak till avslut: ${x}`)}
            </Tooltip.Body>
        </Tooltip>
    );

    const marker = getScatterMarker(color);

    return { tooltip, marker, color };
};
