import { atom, useAtomValue } from 'jotai';
import type { StudieVDItem } from './types';

export const EmptyStudier: StudieVDItem[] = [];
export const vdStudierAtom = atom<StudieVDItem[]>(EmptyStudier);

/**
 * Returnerar alla studier från VD-anropet.
 */
export const useVDStudier = (): StudieVDItem[] => {
    return useAtomValue(vdStudierAtom);
};
