import { useReducer, useEffect } from 'react';
import { apiRequest } from '@cancercentrum/inca';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import type { Reducer } from 'react';
import type { HistoryResult } from '../types';

interface LoadingAction {
    type: 'loading';
    rrId: number;
}

interface ResultAction {
    type: 'result';
    rrId: number;
    result: HistoryResult;
}

type Actions = LoadingAction | ResultAction;

type State = Record<number, {
    loading: boolean;
    result: HistoryResult | null;
}>;

const initialState: State = {};
const reducer: Reducer<State, Actions> = (state, action) => {
    switch (action.type) {
        case 'loading':
            return { ...state, [action.rrId]: { loading: true, result: null } };
        case 'result':
            return { ...state, [action.rrId]: { loading: false, result: action.result } };
    }

    return state;
};

export default (rrId: number, needsTriggering: boolean): HistoryResult | null => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (rrId && needsTriggering && !state[rrId]) {
            dispatch({ rrId, type: 'loading' });

            apiRequest<HistoryResult>(`/RegisterRecords/${rrId}/history`).then(res => {
                const h = mapValues(res, table => orderBy(table, ['date'], ['desc']));

                dispatch({ rrId, type: 'result', result: h });
            });
        }
    }, [rrId, needsTriggering, state]);

    return get(state, [rrId, 'result'], null);
};
