import type { ReactElement } from 'react';
import { useMemo } from 'react';
import type { DataLabelsOptions, SeriesPieOptions } from 'highcharts';
import CategoriesAxis from '../../../components/Chart/CategoriesAxis';
import ScatterSeries from '../../../components/Chart/ScatterSeries';
import type { SurveysPromise } from '../types';
import useChartContext from '../../../components/Chart/ChartContext/useChartContext';
import { useIPO } from '../../../hooks/useIPO';
import type { SurveysOldPromise } from '../../enkat/types';
import { OldSurveySeries } from '../../enkat';
import { Point } from './Point';
import { getPointPosition } from './utils';

export interface EnkatAxisProps {
    surveysPromise: SurveysPromise;
    surveysOldPromise?: SurveysOldPromise;
}

const DATALABELS: DataLabelsOptions = {
    enabled: true,
    allowOverlap: true,
    align: 'center',
    verticalAlign: 'top',
    padding: 0,
    style: {
        color: '#222',
        textOutline: undefined,
    },
    x: -1,
    y: -8,
    formatter() {
        return this.point.options.label;
    },
};

const ChartCategories = ['Enkät'];

export const Axis = (props: EnkatAxisProps): ReactElement => {
    const { surveysPromise, surveysOldPromise } = props;
    const ipo = useIPO();
    const utskicksprojekt = useMemo(() => surveysPromise.result || [], [surveysPromise.result]);
    const projekt = useMemo(() => {
        return utskicksprojekt.filter(u => !u.config.isVisible || u.config.isVisible({
            surveys: u.answers,
            registersWithRegistrationAccess: ipo.registersWithRegistrationAccess,
            ipo,
        }));
    }, [utskicksprojekt, ipo]);

    const chartContext = useChartContext();
    const repositionPieCharts = () => {
        chartContext.chart.series.forEach(x => {
            if (x.type === 'pie') {
                const originPoint = chartContext.chart.series.flatMap(s => s.data).find(p => p.custom?.seriesOptions?.id === x.userOptions.id);
                if (originPoint) {
                    const center = getPointPosition(originPoint);
                    if (x.center![0] !== center.x || x.center![1] !== center.y) {
                        x.update({ center: [center.x, center.y] } as SeriesPieOptions, true);
                    }
                }
            }
        });
    };

    return (
        <CategoriesAxis
            categories={ChartCategories}
            onRedraw={repositionPieCharts}
        >
            {projekt.map(s => (
                <ScatterSeries key={s.config.id} dataLabels={DATALABELS} zIndex={1} custom={{ pieHandler: true }}>
                    {s.answers.filter(x => !!x.endDate).map(a => {
                        return (
                            <Point
                                key={`${s.config.id}_${s.svarRootTableId}_${a.id}`}
                                y={0}
                                utskicksprojekt={s}
                                answer={a}
                                chartInfo={s.config.chartInfo}
                            />
                        );
                    })}
                </ScatterSeries>
            ))}
            {!!surveysOldPromise && <OldSurveySeries surveysOldPromise={surveysOldPromise} />}
        </CategoriesAxis>
    );
};
