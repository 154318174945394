import type { ReactElement } from 'react';
import { memo } from 'react';
import { Panel } from '@cancercentrum/rcc-bootstrap';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import type { RegvarStringMetadata } from '@cancercentrum/inca';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import Question from './components/IPOQuestion';

const Kommentar = (props: {
    kommentar: RegvarModel<RegvarStringMetadata>;
    className?: string;
}): ReactElement => {
    const { kommentar, className } = props;
    const icon = kommentar.value ? faCommentDots : faComment;
    const color: string | undefined = kommentar.value ? '#FFB117' : undefined;

    return (
        <Panel defaultExpanded={false} className={className}>
            <Panel.Heading>
                <Panel.Title toggle={true}><FontAwesomeIcon icon={icon} color={color} /> Kommentar</Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body>
                    <form>
                        <Question
                            hideLabel={true}
                            regvar={kommentar}
                            textarea={true}
                            rows={6}
                            placeholder="Ange kommentar"
                            className="w-auto"
                        />
                    </form>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    );
};

export default memo(Kommentar);
