import type { ReactNode } from 'react';
import { Component } from 'react';
import noop from 'lodash/noop';
import { sub } from 'date-fns';
import groupBy from 'lodash/groupBy';
import clone from 'lodash/cloneDeep';
import { HighchartsReact } from 'highcharts-react-official';
import type { AxisPlotLinesOptions } from 'highcharts';
import Highcharts from 'highcharts';
import without from 'lodash/without';
import debounce from 'lodash/debounce';
import { getUTCStartOfDayTime } from '../../utils/date';
import { log } from '../../../utils/logger';
import { configuration, ranges, scales } from './configuration';
import { time } from './utils';
import type { ChartContext as ChartContextType, ChartRange, ChartRef, ChartScale } from './types';
import ChartContext from './ChartContext';

Highcharts.wrap(Highcharts.Chart.prototype, 'showResetZoom', noop);
Highcharts.AST.allowedAttributes.push('viewBox');
Highcharts.AST.allowedAttributes.push('transform');
Highcharts.AST.allowedAttributes.push('translate');
Highcharts.AST.allowedTags.push('g');

type UnwrapArray<T> = T extends Array<infer U> ? U : T;

export interface RccAxis extends Highcharts.Axis {
    visible: boolean;

    options: Highcharts.Axis['options'] & {
        height?: number;
    }

    userOptions: Highcharts.Axis['userOptions'] & {
        yOffset?: number;
    }
}

export interface ChartProps {
    isVisible: boolean;
    initialConfig: Highcharts.Options;
    initialScale?: string;

    chartRef?: (chart: Chart) => void;
    setChartRef?: (chartRef: ChartRef) => void;

    children?: ReactNode;
}

export type AddAxisCallbackOptions = Parameters<Highcharts.Chart['addAxis']>;
export type UpdateAxisCallbackOptions = [axisId: string, args: Parameters<Highcharts.Axis['update']>];
export type AddSeriesCallbackOptions = Parameters<Highcharts.Chart['addSeries']>;
export type UpdateSeriesCallbackOptions = [seriesId: string, args: Parameters<Highcharts.Series['update']>];
export type RemoveSeriesCallbackOptions = [seriesId: string, args: Parameters<Highcharts.Series['remove']>];
export type AddPointCallbackOptions = [serieId: string, args: Parameters<Highcharts.Series['addPoint']>];
export type UpdatePointCallbackOptions = [serieId: string, pointId: string, args: Parameters<Highcharts.Point['update']>];
export type RemovePointCallbackOptions = [serieId: string, pointId: string, args: Parameters<Highcharts.Point['remove']>];
export type AddPlotLineCallbackOptions = [options: AxisPlotLinesOptions];
export type UpdatePlotLineCallbackOptions = [plotlineId: string, options: AxisPlotLinesOptions];
export type RemovePlotLineCallbackOptions = [plotlineId: string];
export type RegisterEventCallbackOptions = [eventName: string, handler: () => unknown];
export type SetScaleCallbackOptions = [scale: ChartScale];
export type SetRangeCallbackOptions = [range: ChartRange];
export type RegisterXAxisEventCallbackOptions = [eventName: string, handler: () => unknown];

export interface ChartState {
    chartContext?: ChartContextType
    config: Highcharts.Options;

    chartRef?: ChartRef;

    callbacks: {
        addAxis: AddAxisCallbackOptions[],
        updateAxis: UpdateAxisCallbackOptions[],
        addSeries: AddSeriesCallbackOptions[],
        updateSeries: UpdateSeriesCallbackOptions[],
        removeSeries: RemoveSeriesCallbackOptions[],
        addPoint: AddPointCallbackOptions [],
        updatePoint: UpdatePointCallbackOptions [],
        removePoint: RemovePointCallbackOptions [],
        addPlotLine: AddPlotLineCallbackOptions [],
        updatePlotLine: UpdatePlotLineCallbackOptions [],
        removePlotLine: RemovePlotLineCallbackOptions [],
        registerEvent: RegisterEventCallbackOptions [],
        setScale: SetScaleCallbackOptions[],
        setRange: SetRangeCallbackOptions[],
        registerXAxisEvent: RegisterXAxisEventCallbackOptions[],
    };

    events: {
        [eventName: string]: (() => void)[]
    }
}

const updateAxisOptionsAndChartHeight = (chart: Highcharts.Chart) => {
    time('updateAxisOptionsAndChartHeight', () => {
        const headerOffset = 50;
        const footerOffset = 44;
        let top = headerOffset;

        const groupedAxes = groupBy(chart.yAxis as RccAxis[], x => (x.userOptions as any).xAxisId);
        const axisSettings: {
            [index: string]: {
                visible: boolean;
                top: number;
            }
        } = {};
        for (let i = 0; i < chart.xAxis.length; i++) {
            const xAxis = chart.xAxis[i];

            if (xAxis.options.id) {
                let visible = false;

                const yAxes = groupedAxes[xAxis.options.id];
                for (let j = 0; j < yAxes.length; j++) {
                    const a = yAxes[j];
                    top += a.userOptions.yOffset || 0;

                    a.update({ ...a.options, top: top }, false);

                    if (a.visible) {
                        visible = true;
                        const axisOffset = 1;
                        top += (a.options.height || 0) + axisOffset;
                    }
                }

                axisSettings[xAxis.options.id] = { visible, top };
                if (visible) {
                    const axisOffset = 1;
                    top += 40 + axisOffset;
                }
            }
        }

        for (let i = chart.xAxis.length - 1; i >= 0; i--) {
            const xAxis = chart.xAxis[i];
            const settings = axisSettings[xAxis.options.id!];
            const axisOptions = { ...xAxis.options, visible: settings.visible, offset: settings.top - top, linkedTo: i > 0 ? 0 : undefined };

            xAxis.update(axisOptions, false);
        }

        if (top + footerOffset !== chart.chartHeight) {
            chart.setSize(null, top + footerOffset);
        }
    });
};


class Chart extends Component<ChartProps, ChartState> {
    static defaultProps = {
        initialConfig: configuration,
    };

    state = {} as ChartState;

    constructor(props: ChartProps) {
        super(props);

        const conf = props.initialConfig;
        this.deuncedRedraw = debounce(this.deuncedRedraw, 1000);

        conf!.chart!.events!.selection = () => {
            this.setState(s => {
                return {
                    ...s,
                    chartContext: {
                        ...s.chartContext!,
                        isZoomed: true,
                    },
                };
            });

            return undefined;
        };

        this.state = {
            config: conf,
            callbacks: {
                addAxis: [],
                updateAxis: [],
                addSeries: [],
                updateSeries: [],
                removeSeries: [],
                addPoint: [],
                updatePoint: [],
                removePoint: [],
                addPlotLine: [],
                updatePlotLine: [],
                removePlotLine: [],
                registerEvent: [],
                setScale: [],
                setRange: [],
                registerXAxisEvent: [],
            },
            events: {},
        };
    }

    deuncedRedraw() {
        // console.log('[dbg] redrawing graph');
        this.state.chartRef?.chart.redraw();
    }

    componentDidUpdate() {
        const redraw = time('handling updates', this.handleUpdates);

        if (this.props.isVisible || redraw) {
            // console.log('[dbg] graph requested redraw');
            this.deuncedRedraw();
        }
    }

    handleUpdates = () => {
        const { chartRef, chartContext } = this.state;
        if (!chartContext) {
            return;
        }

        const callbacks = this.state.callbacks;
        const methodNames = Object.keys(callbacks) as (keyof ChartState['callbacks'])[];

        let needsAxisUpdate = false;
        let needsRedraw = false;

        const handleCallback = (callbackName: keyof ChartState['callbacks'], callback: () => void) => {
            if (callbacks[callbackName].length) {
                time(`running callback - ${callbackName}`, callback);
            }
        };

        handleCallback('addAxis', () => {
            callbacks.addAxis.forEach(args => {
                chartRef?.chart.addAxis(...args);
                needsAxisUpdate = true;
            });
        });

        handleCallback('updateAxis', () => {
            callbacks.updateAxis.forEach(([axisId, args]) => {
                const axis = chartRef?.chart.axes.find(x => x.options.id === axisId);
                axis!.update(...args);
                needsAxisUpdate = true;
            });
        });

        handleCallback('addSeries', () => {
            callbacks.addSeries.forEach(args => {
                chartRef?.chart.addSeries(...args);
            });
        });

        handleCallback('removeSeries', () => {
            callbacks.removeSeries.forEach(([seriesId, args]) => {
                const serie = chartRef?.chart.series.find(x => x.options.id === seriesId);

                if (serie) {
                    serie.remove(...args);
                } else {
                    log('removeSeries - series not found', seriesId, args);
                }
            });
        });

        handleCallback('removePoint', () => {
            callbacks.removePoint.forEach(([serieId, pointId, args]) => {
                needsRedraw = true;
                const serie = chartRef?.chart.series.find(x => x.options.id === serieId);
                if (serie) {
                    const point = serie.data.filter(x => x).find(x => x.options.id === pointId);

                    if (point) {
                        point.remove(...args);
                    } else {
                        log('removePoint - point not found', serie, pointId, args);
                    }

                }

                if (!serie) {
                    log('removePoint - series not found', serieId, args);
                }
            });
        });

        handleCallback('addPoint', () => {
            callbacks.addPoint.forEach(([serieId, args]) => {
                const serie = chartRef?.chart.series.find(x => x.options.id === serieId);
                if (serie) {
                    serie.addPoint(...args);
                }

                if (!serie) {
                    log('addPoint - series not found', serieId, args);
                }
            });

            needsRedraw = true;
        });

        handleCallback('updatePoint', () => {
            callbacks.updatePoint.forEach(([seriesId, pointId, args]) => {
                const serie = chartRef?.chart.series.find(x => x.options.id === seriesId);
                if (serie) {
                    const point = serie.data.filter(x => x).find(x => x.options.id === pointId);
                    if (point) {
                        // IPO-1459. Punkterna (åtminstone inte tooltips) uppdateras inte om inte redraw görs.
                        needsRedraw = true;
                        point.update(...args);
                    } else {
                        log('updatePoint - point not found', serie, pointId, args);
                    }
                }

                if (!serie) {
                    log('updatePoint - series not found', seriesId, pointId, args);
                }
            });
        });

        handleCallback('updateSeries', () => {
            callbacks.updateSeries.forEach(([seriesId, args]) => {
                const serie = chartRef?.chart.series.find(x => x.options.id === seriesId);
                if (serie) {
                    serie.update(...args);
                } else {
                    log('updateSeries - series not found', seriesId, args);
                }
            });
        });

        handleCallback('removePlotLine', () => {
            callbacks.removePlotLine.forEach(([plotlineId]) => {
                chartRef?.chart.xAxis.forEach(xAxis => {
                    xAxis.removePlotLine(plotlineId);
                });
            });
        });

        handleCallback('addPlotLine', () => {
            callbacks.addPlotLine.forEach(([plotlineOptions]) => {
                chartRef?.chart.xAxis.forEach(xAxis => {
                    xAxis.addPlotLine(plotlineOptions);
                });
            });
        });

        handleCallback('updatePlotLine', () => {
            callbacks.updatePlotLine.forEach(([plotlineId, plotlineOptions]) => {
                chartRef?.chart.xAxis.forEach(xAxis => {
                    xAxis.removePlotLine(plotlineId);
                    xAxis.addPlotLine(plotlineOptions);
                });
            });
        });

        handleCallback('registerEvent', () => {
            callbacks.registerEvent.forEach(([eventName, callback]) => {
                Highcharts.addEvent(chartRef!.chart, eventName, callback);
            });
        });

        handleCallback('registerXAxisEvent', () => {
            callbacks.registerXAxisEvent.forEach(([eventName, callback]) => {
                chartRef?.chart.xAxis.forEach(xAxis => Highcharts.addEvent(xAxis, eventName, callback));
            });
        });

        handleCallback('setScale', () => {
            callbacks.setScale.forEach(([scale]) => {
                this.setState(s => ({
                    chartContext: {
                        ...s.chartContext!,
                        scale: {
                            ...s.chartContext!.scale,
                            selected: scale,
                        },
                    },
                }));
            });

            needsRedraw = true;
        });

        handleCallback('setRange', () => {
            callbacks.setRange.forEach(([range]) => {
                this.setState(s => ({
                    chartContext: {
                        ...s.chartContext!,
                        range: {
                            ...s.chartContext!.range,
                            selected: range,
                        },
                    },
                }));

                const newMinValue = range.num ? getUTCStartOfDayTime(sub(new Date(), { [range.unit]: range.num })) : undefined;
                chartRef?.chart.xAxis[0].setExtremes(newMinValue, range.num ? getUTCStartOfDayTime(new Date()) : undefined);
            });
        });

        if (needsAxisUpdate) {
            updateAxisOptionsAndChartHeight(chartRef!.chart);
        }

        time('reset callbacks', () => {
            if (methodNames.some(prop => callbacks[prop].length)) {
                this.setState(s => {
                    return ({
                        ...s,
                        callbacks: {
                            ...s.callbacks,
                            ...methodNames.reduce((res, name) => ({ ...res, [name]: without(s.callbacks[name], ...callbacks[name]) }), {}),
                        },
                    });
                });
            }
        });

        return needsRedraw;
    };

    setChartRef = (c: ChartRef) => {
        if (!c) {
            return;
        }

        (this.props.setChartRef || noop)(c);
        (this.props.chartRef || noop)(this);

        const updateCallback = <T extends keyof ChartState['callbacks']>(callbackName: T, value: UnwrapArray<ChartState['callbacks'][T]>, modifyState?: (s: ChartState) => Partial<ChartState>) => {
            this.setState(s => ({
                ...s,
                callbacks: {
                    ...s.callbacks,
                    [callbackName]: [...s.callbacks[callbackName], clone(value)],
                },
                ...(modifyState || (() => ({})))(s),
            }));
        };

        this.setState(s => {
            return {
                ...s,
                chartRef: c,
                chartContext: {
                    addAxis: (...args) => {
                        updateCallback('addAxis', args);
                    },
                    updateAxis: (axisId, ...args) => {
                        updateCallback('updateAxis', [axisId, args]);
                    },
                    addSeries: (...args) => {
                        updateCallback('addSeries', args);
                    },
                    updateSeries: (seriesId, ...args) => {
                        updateCallback('updateSeries', [seriesId, args]);
                    },
                    removeSeries: (seriesId, ...args) => {
                        updateCallback('removeSeries', [seriesId, args]);
                    },
                    addPoint: (seriesId, ...args) => {
                        updateCallback('addPoint', [seriesId, args]);
                    },
                    updatePoint: (seriesId, pointId, ...args) => {
                        updateCallback('updatePoint', [seriesId, pointId, args]);
                    },
                    removePoint: (seriesId, pointId, ...args) => {
                        updateCallback('removePoint', [seriesId, pointId, args]);
                    },
                    addPlotLine: plotlineOptions => {
                        updateCallback('addPlotLine', [plotlineOptions]);
                    },
                    updatePlotLine: (plotlineId, plotlineOptions) => {
                        updateCallback('updatePlotLine', [plotlineId, plotlineOptions]);
                    },
                    removePlotLine: plotlineId => {
                        updateCallback('removePlotLine', [plotlineId]);
                    },
                    registerEvent: (eventName: string, callback: () => void) => {
                        updateCallback('registerEvent', [eventName, callback], state => ({
                            events: {
                                ...state.events,
                                [eventName]: [...(state.events[eventName] || []), callback],
                            },
                        }));
                    },
                    registerXAxisEvent: (eventName: string, callback: () => void) => {
                        updateCallback('registerXAxisEvent', [eventName, callback], state => ({
                            events: {
                                ...state.events,
                                [eventName]: [...(state.events[eventName] || []), callback],
                            },
                        }));
                    },
                    scale: {
                        selected: scales.find(x => x.value === this.props.initialScale) || scales[0],
                        set: (scale: ChartScale) => {
                            updateCallback('setScale', [scale]);
                        },
                        scales: scales,
                    },
                    range: {
                        selected: ranges[ranges.length - 1],
                        set: range => {
                            updateCallback('setRange', [range]);
                        },
                        ranges: ranges,
                    },
                    chart: c.chart,
                    isZoomed: false,
                    zoomOut: () => {
                        this.state.chartContext!.chart.zoomOut();
                        this.state.chartContext!.range.set(this.state.chartContext!.range.selected!);
                        this.setState(s2 => {
                            return {
                                ...s2,
                                chartContext: {
                                    ...s2.chartContext!,
                                    isZoomed: false,
                                },
                            };
                        });
                    },
                },
            };
        });
    };

    render() {
        const { children } = this.props;
        const { config, chartRef, chartContext } = this.state;

        const chart = chartRef ? chartRef.chart : null;

        return (
            <div className="chart-container">
                {chart && !!chartContext && (
                    <ChartContext.Provider value={chartContext}>
                        {children}
                    </ChartContext.Provider>
                )}

                <HighchartsReact
                    highcharts={Highcharts}
                    options={config}
                    isPureConfig={true}
                    ref={this.setChartRef}
                />
            </div>
        );
    }
}

export default Chart;
