import { parseISO } from 'date-fns';
import type { ListItem } from '@cancercentrum/inca';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import type { AdditionalInfoScatterSeriesOptionsProps } from './AdditionalInfoScatterSeries';
import type { AdditionalInfoScatterSeriesDataBase } from './AdditionalInfoScatterSeriesBulk';

const symbolMap: Record<string, string> = {
    'Full dos': 'triangleRight',
    'Reducerad dos': 'triangle-down',
    'Doseskalering': 'triangle',
};

export const getDosMarker = (color: string, listItem: ListItem | undefined) => {
    const justering = listItem?.text;

    const symbol = symbolMap[justering || ''] || 'circle';
    return {
        symbol: symbol,
        radius: 6,
        lineWidth: 1,
        lineColor: '#000',
        fillColor: '#ddd',
    };
};

const options: {
    dataSelector: AdditionalInfoScatterSeriesOptionsProps['dataSelector'],
    options: AdditionalInfoScatterSeriesOptionsProps['options'],
} = {
    dataSelector: (item, color, ds) => {
        const hasSpec = !!ds.Dosjustering!.Dj_orsakSpec;

        return item.rows.flatMap(sr => {
            return sr.row.getSubTableRows('Dosjustering').map(dj => {
                const date = parseISO(dj.getRegvarValue('Dj_datum') || '');
                const specText = hasSpec && dj.getRegvarValue('Dj_orsakSpec') && ` (${dj.getRegvarValue('Dj_orsakSpec')})`;

                const res: AdditionalInfoScatterSeriesDataBase = {
                    id: `dj_${dj.getId()}`,
                    date: date,
                    row: dj,
                    color: color,
                    tooltip: (
                        <LegacyTooltip headerText={`Dosjustering (${sr.name})`} color={color}>
                            <div>Datum: {dj.getRegvarValue('Dj_datum')}</div>
                            <div>Justering: {dj.getRegvarValue('Dj_justering')?.text}</div>
                            <div>
                            Orsak: {dj.getRegvarValue('Dj_orsak')?.text}{specText}
                            </div>
                        </LegacyTooltip>
                    ),
                };

                return res;
            }).toArray();
        });
    },
    options: {
        getMarker: (data) => {
            return getDosMarker(data.color, data.row.getRegvarValue('Dj_justering') as ListItem);
        },
    },
};

export default options;
