import classnames from 'classnames';
import type { ReactNode } from 'react';
import isNil from 'lodash/isNil';

export const StaticField = (props: {
    label: ReactNode;
    value: ReactNode;
    className?: string;
    labelClassName?: string;
    wrapperClassName?: string;
    hideIfNil?: boolean;
}): JSX.Element | null => {
    const { label, value, className, labelClassName, wrapperClassName, hideIfNil } = props;

    if (isNil(value) && hideIfNil) {
        return null;
    }

    return (
        <div className={classnames('form-group mb-3', className, { row: labelClassName || wrapperClassName })}>
            <label className={classnames('form-label mb-0', labelClassName)}>{label}</label>
            <div className={wrapperClassName}>{value}</div>
        </div>
    );
};
