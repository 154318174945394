import { Condition } from '@cancercentrum/rcc-react';
import type { ReactElement, ReactNode } from 'react';

const IPOCondition = (props: {
    if: any;
    children: ReactNode;
}): ReactElement => {
    let defaultValue = false;

    if (process.env.NODE_ENV !== 'production') {
        const query = new URLSearchParams(location.search);
        defaultValue = !!query.get('getUsedRegvars');
    }

    return <Condition {...props} if={defaultValue || props.if} />;
};

export default IPOCondition;
