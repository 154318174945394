import { useCallback } from 'react';
import type { DataSet } from '../../types';
import useDataSet from '../../hooks/useDataSet';
import { getColor } from './categories';
import type { AdditionalInfoScatterSeriesDataBase, AdditionalInfoScatterSeriesOptionsBaseProps, AdditionalInfoScatterSeriesOptionsBulkProps } from './AdditionalInfoScatterSeriesBulk';
import AdditionalInfoScatterSeriesBulk from './AdditionalInfoScatterSeriesBulk';
import type { SerieData } from './types';

export interface AdditionalInfoScatterSeriesOptionsProps extends AdditionalInfoScatterSeriesOptionsBaseProps {
    dataSelector: (item: SerieData, seriesColor: string, dataset: DataSet) => AdditionalInfoScatterSeriesDataBase[];
}

const useDataSelector = (dataSelector: AdditionalInfoScatterSeriesOptionsProps['dataSelector']): AdditionalInfoScatterSeriesOptionsBulkProps['dataSelector'] => {
    const dataset = useDataSet();
    return useCallback((items: SerieData[], categories: string[]) => {
        return items.flatMap(item => {
            const seriesColor = getColor(item.category.data.k_farg, !item.end);
            const data = dataSelector(item, seriesColor, dataset);
            return data.map(x => {
                return {
                    ...x,
                    y: categories.indexOf(item.name),
                };
            });
        });
    }, [dataSelector, dataset]);
};

const AdditionalInfoScatterSeries = (props: AdditionalInfoScatterSeriesOptionsProps) => {
    const { lakemedelSeries, options, categories, dataSelector } = props;
    const wrappedSelector = useDataSelector(dataSelector);

    return (
        <AdditionalInfoScatterSeriesBulk options={options} lakemedelSeries={lakemedelSeries} categories={categories} dataSelector={wrappedSelector} />
    );
};

export default AdditionalInfoScatterSeries;
