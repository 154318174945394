import { memo, useEffect, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import type { ReactElement, ReactNode } from 'react';
import type { AxisOptions } from 'highcharts';
import useChartContext from './ChartContext/useChartContext';
import AxisContext from './AxisContext';
import type { AxisContext as AxisContextType } from './types';

interface XAxisProps {
    id?: string;
    showTicks?: boolean;
    children: ReactNode;
}

const XAxis = (props: XAxisProps): ReactElement => {
    const { id, showTicks = true, children, ...rest } = props;
    const idProp = useMemo(() => id || uniqueId('XAxis_'), [id]);
    const chartContext = useChartContext();

    useEffect(() => {
        const opts: AxisOptions = {
            title: undefined,
            type: 'datetime',
            labels: {
                format: '{value:%Y-%m-%d}',
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                    overflow: 'visible',
                },
                overflow: 'allow',
            },
            minTickInterval: 1000 * 60 * 60 * 24 * 3,
            // maxZoom: 1000 * 60 * 60 * 24,
            // pointRange: 1000 * 60 * 60 * 24,
            tickPixelInterval: 300,
            minRange: 1,
            lineColor: '#000000',
            id: idProp,
            ...rest,
        };
        if (!showTicks) {
            opts.labels = opts.labels || {};
            opts.labels.style = { visibility: 'hidden' };
            opts.tickColor = 'rgba(0,0,0,0)';
            //opts.lineColor = 'rgba(0,0,0,0)';
        }

        chartContext.addAxis(opts, true, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ctx: AxisContextType = useMemo(() => ({
        id: idProp,
    }), [idProp]);

    return <AxisContext.Provider value={ctx}>{children}</AxisContext.Provider>;
};

export default memo(XAxis);
