import { parseISO } from 'date-fns';
import orderBy from 'lodash/orderBy';
import type { ListItem } from '@cancercentrum/inca';
import { createSelector } from '../../../utils/reselectUtils';
import { LakemedelChartDisplayMode } from '../../oversikt/constants';
import { getGroupCategory, getRegistrations } from '../utils';
import type { GetSeriesOptions, SerieData } from '../types';
import type { LäkemedelRowModel } from '../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { getLakemedelSubTableSelector } from './index';

interface GroupedRows {
    type: 'regim' | 'substans';
    start: Date,
    end?: Date,
    rows: LäkemedelRowModel[];
}

const passOptions = (state: unknown, options: GetSeriesOptions): GetSeriesOptions => options;

const getGroupedRows = createSelector([getLakemedelSubTableSelector, passOptions], (subTable, opts: GetSeriesOptions) => {
    // Vänta till ajax laddats.
    if (!opts.lakemedelPromise.result) {
        return [];
    }

    const { vdSubstanskategorier } = opts.lakemedelPromise.result;
    const lakemedelRows = subTable.getRows().filter(x => x.getRegvarValue('Lkm_startdatum')).toArray();
    const insattningar = getRegistrations({
        rows: lakemedelRows,
        vdSubstanskategorier,
    });

    const createSubstanceGroup = (lkmRow: LäkemedelRowModel): GroupedRows => {
        return {
            type: 'substans',
            start: parseISO(lkmRow.getRegvarValue('Lkm_startdatum') || ''),
            end: lkmRow.getRegvarValue('Lkm_stoppdatum') ? parseISO(lkmRow.getRegvarValue('Lkm_stoppdatum') || '') : undefined,
            rows: [lkmRow],
        };
    };

    return insattningar.reduce<GroupedRows[]>((red, item) => {
        if (item.type === 'regim') {
            if (!item.isCompleteAndSamePeriod || opts.mode === LakemedelChartDisplayMode.Substanser) {
                item.substances.forEach(sub => {
                    red.push(createSubstanceGroup(sub.row));
                });
            } else {
                const firstRow = item.substances[0].row;
                red.push({
                    type: 'regim',
                    start: parseISO(firstRow.getRegvarValue('Lkm_startdatum') || ''),
                    end: firstRow.getRegvarValue('Lkm_stoppdatum') ? parseISO(firstRow.getRegvarValue('Lkm_stoppdatum') || '') : undefined,
                    rows: item.substances.map(x => x.row),
                });
            }
        } else {
            red.push(createSubstanceGroup(item.row));
        }

        return red;
    }, []);
});

export const getSeries = createSelector(
    [passOptions, getGroupedRows],
    (
        options: GetSeriesOptions,
        groupedRows,
    ): SerieData[] => {
        const {
            lakemedelPromise,
            getSubstanceName,
        } = options;

        if (!lakemedelPromise.result) {
            return [];
        }

        const vds = lakemedelPromise.result;

        return orderBy(groupedRows.map(grouped => {
            const x = grouped.rows[0];
            const regimName = vds.regimerById[x.getRegvarValue('Lkm_regim_vd') as number]?.data.reg_namn;
            const substanceName = getSubstanceName(x, vds.substanserById[x.getRegvarValue('Lkm_substans_vd') as number]);
            let startdos: ListItem | undefined;

            if (options.includeStartdos && grouped.rows.every(r => r.getRegvarValue('Lkm_startdos_lista')?.id === x.getRegvarValue('Lkm_startdos_lista')?.id)) {
                startdos = x.getRegvarValue('Lkm_startdos_lista') as ListItem;
            }

            return {
                id: x.getId(),
                key: `${grouped.type}_${x.getId()}`,
                rows: grouped.rows.map(row => {
                    return {
                        name: getSubstanceName(row, vds.substanserById[row.getRegvarValue('Lkm_substans_vd') as number]),
                        row: row,
                    };
                }),
                name: grouped.type === 'substans' ? substanceName : regimName,
                regimName: regimName,
                start: grouped.start,
                end: grouped.end,
                category: getGroupCategory(grouped.rows, vds.vdSubstanskategorier, options),
                startdos: startdos,

            };
        }), [x => x.start], ['desc']);
    },
);
