import isNil from 'lodash/isNil';
import type { EnkatOld } from './types';

export const hsv2rgb = function (hue: number, s: number, v: number) {
    // adapted from http://schinckel.net/2012/01/10/hsv-to-rgb-in-javascript/
    let rgb, i, data = [];
    if (s === 0) {
        rgb = [v, v, v];
    } else {
        const h = hue / 60;
        i = Math.floor(h);
        data = [v * (1 - s), v * (1 - s * (h - i)), v * (1 - s * (1 - (h - i)))];
        switch (i) {
            case 0:
                rgb = [v, data[2], data[0]];
                break;
            case 1:
                rgb = [data[1], v, data[0]];
                break;
            case 2:
                rgb = [data[0], v, data[2]];
                break;
            case 3:
                rgb = [data[0], data[1], v];
                break;
            case 4:
                rgb = [data[2], data[0], v];
                break;
            default:
                rgb = [v, data[0], data[1]];
                break;
        }
    }
    return '#' + rgb.map(x => {
        return ('0' + Math.round(x * 239).toString(16)).slice(-2);
    }).join('');
};

export const getRGB = (hue: number, v: number | null | undefined) => {
    return isNil(v) ? '#ccc' : hsv2rgb(hue, 1, v);
};

export const defaultGetScore = (answer: string, maxAnswer = 4) => {
    const a = (parseInt(answer, 10) || 0);

    return Math.floor((maxAnswer - a) * 120 / maxAnswer);
};

export const getColor = (score: number | null | undefined) => {
    if (isNil(score)) {
        return '#ccc';
    }

    return getRGB(score, 0.9);
};

export const getLineColor = (score: number | null | undefined) => {
    if (isNil(score)) {
        return '#8d8d8d';
    }

    return getRGB(score, 0.67);
};

export const unescape = (str: string | null | undefined) => (str || '').replace(/&lt;/gi, '<').replace(/&gt;/gi, '>');

export const getGridRowId = (item: EnkatOld): string => {
    return `enkat_old_${item.id}`;
};
