import './style.scss';
import Highcharts from 'highcharts';
import HighchartsPatternFillPlugin from 'highcharts/modules/pattern-fill';
import { ErrorBoundary } from '@cancercentrum/rcc-react';
import type { ReactElement, ReactNode } from 'react';
import HTab from '../../../components/HTabs/HTab';
import { DisplayName, ModuleName } from './constants';

HighchartsPatternFillPlugin(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.arrow = (x: number, y: number, w: number, h: number) => {
    const startX = x + w / 2 + 1;
    return [
        'M', startX, y,
        'L', startX + w / 2, y,
        'L', startX + w, y + h / 2,
        'L', startX + w / 2, y + h,
        'L', startX, y + h,
        'z',
    ];
};


Highcharts.SVGRenderer.prototype.symbols.triangleRight = (x: number, y: number, w: number, h: number) => {
    const startX = x + w / 2 + 1;
    return [
        'M', startX, y,
        'L', startX + w, y + h / 2,
        'L', startX, y + h,
        'z',
    ];
};

Highcharts.SVGRenderer.prototype.symbols.star = function (x: number, y: number, w: number, h: number) {
    const alpha = (2 * Math.PI) / 10;
    const starX = x + w / 2;
    const starY = y + h / 2;
    let points: number[] = [];
    for (let i = 11; i !== 0; i--) {
        const r = (h / 2) * (i % 2 + 1) / 2;
        const omega = alpha * i;
        points = points.concat([r * Math.sin(omega) + starX, (r * Math.cos(omega)) + starY]);
    }
    return ['M', points[0], points[1], 'L'].concat(points).concat('Z');
};

Highcharts.SVGRenderer.prototype.symbols.cross = function (x: number, y: number, w: number, h: number) {
    return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

const defaultAxisConfig = {
    dateTimeLabelFormats: {
        millisecond: '%e %b<br/>%Y',
        second: '%e %b<br/>%Y',
        minute: '%e %b<br/>%Y',
        hour: '%e %b<br/>%Y',
        day: '%e %b<br/>%Y',
        week: '%e %b<br/>%Y',
        month: '%b <br /> %Y',
    },
};

Highcharts.setOptions({
    lang: {
        resetZoom: 'Nollställ zoom',
        resetZoomTitle: 'Nollställ zoom',
    },
    xAxis: defaultAxisConfig,
});

export const OversiktTab = (props: {
    children?: ReactNode;
}): ReactElement => {
    const { children } = props;

    return (
        <HTab eventKey={ModuleName} header={DisplayName}>
            { /* @ts-ignore ErrorBoundary är inte TS:ad i ramverket */}
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </HTab>
    );
};


export { ModuleName };
export default OversiktTab;
