import { valueAndTextFormatter } from '../../../utils/formatters';
import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        senastKontrollerad: {},
        migreringsdata: {},
        migreringKontrollerad: {},
        komplettRegistreringLkm: {},
        komplettEttAr: {},
        selectedVd: {},
    },
    Basdata: {
        Basdata_myelom_vd: {},
    },
    Kontakt: {
        K_besoksdatum: {
            props: {
                label: 'Datum',
            },
        },
        K_vardgivare: {},
        K_besokstyp: {
            identifiers: ['1', 'digitalt_vardmote', '2', '3'],
        },
        K_yrkesroll: {
            identifiers: ['1', '2', '3'],
        },
        K_ecogEjBedomt: {},
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_sammantagenBedomning: {
            props: {
                label: 'Sammanvägd bedömning',
                formatter: valueAndTextFormatter,
            },
            identifiers: ['pd', 'sd', 'pr', 'vgpr', 'cr', 'skr', 'of', 'eb'],
        },
        K_diskuteratsMultidisciplinarKonferens: {
            identifiers: ['nej', 'ja'],
        },
        K_ryggmargskompression: {
            identifiers: ['nej', 'ja'],
        },
        K_dialyskravandeNjursvikt: {
            identifiers: ['nej', 'ja'],
        },
        K_symtomgivandeAmyloidos: {
            identifiers: ['nej', 'ja'],
        },
        K_venosTromboembolism: {
            identifiers: ['nej', 'ja'],
        },
        K_ryggmargskompression_datum: {},
        K_dialyskravandeNjursvikt_datum: {},
        K_symtomgivandeAmyloidos_datum: {},
        K_venosTromboembolism_datum: {},
        K_negativtBenmargsprov: {
            identifiers: ['nej', 'ja'],
        },
        K_vikt: {},
        K_langd_cm: {},
        K_mrd_flow: {
            identifiers: ['ej_utf', '1', '2'],
        },
        K_mrd_ngs: {
            identifiers: ['ej_utf', '1', '2'],
        },
        K_mrd_pet: {
            identifiers: ['ej_utf', '1', '2'],
        },
    },
    KontaktPAL: {
        KP_patientansvarigLakare: {},
    },
    Studie: {
        Stud_stoppdatum: {},
    },
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_indikation: {
            identifiers: ['2', 'kurativ', 'postop', 'lokalkontroll'],
        },
        Stral_slutdosD2: {},
    },
    StralTarget: {
        ST_target: {
            identifiers: ['4', 'ovrig'],
        },
        ST_target_spec: {},
    },
    Labbprov: {
        Labb_koncentrationMkomponentSerum: {},
        Labb_koncentrationKappakedjor: {},
        Labb_koncentrationLambdakedjor: {},
        Labb_KappaLambdaRatio: {},
        Labb_koncentrationAvLattaKedjorUrin_mg24h: {},
        Labb_koncentrationAvLattaKedjorUrin_mgl: {},
        Labb_koncentrationAvLattaKedjorUrin_mgmmol: {},
        Labb_TPK_0: {},
        Labb_Hb_0: {},
        Labb_Krea_0: {},
        Labb_calcium_2: {},
        Labb_joniserande_calcium: {},
        Labb_LD_1: {},
        Labb_mikroglobulin: {},
        Labb_albumin_0: {},
        Labb_sammantagenBedomning: {
            props: {
                label: 'Sammanvägd bedömning',
                formatter: valueAndTextFormatter,
            },
            identifiers: ['pd', 'sd', 'pr', 'vgpr', 'cr', 'skr', 'of', 'eb'],
        },
        Labb_negativtBenmargsprov: {
            identifiers: ['nej', 'ja'],
        },
        Labb_plasmainf: {},
        Labb_fishUtford: {
            identifiers: ['nej', 'ja'],
        },
        Labb_fish_t4_14: {
            identifiers: ['pavisat', 'ej_pavisat', 'ej_undersokt'],
        },
        Labb_fish_t11_14: {
            identifiers: ['pavisat', 'ej_pavisat', 'ej_undersokt'],
        },
        Labb_fish_t4_16: {
            identifiers: ['pavisat', 'ej_pavisat', 'ej_undersokt'],
        },
        Labb_fish_del17p: {
            identifiers: ['pavisat', 'ej_pavisat', 'ej_undersokt'],
        },
        Labb_fish_1q21: {
            identifiers: ['pavisat', 'ej_pavisat', 'ej_undersokt'],
        },
        Labb_mrd_flow: {
            identifiers: ['ej_utf', '1', '2'],
        },
        Labb_mrd_ngs: {
            identifiers: ['ej_utf', '1', '2'],
        },
        Labb_mrd_pet: {
            identifiers: ['ej_utf', '1', '2'],
        },
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['7', '3', '1', '4', '8'],
            props: {
                label: 'Modalitet',
            },
        },
        Bild_resultat: {
            identifiers: ['pd', 'sd', 'r', 'of'],
        },
    },
    Läkemedel: {
        Lkm_stopporsak: {
            identifiers: [
                'tillracklig_sjukdomskontroll',
                'terapisvikt',
                '1', 'enligt_plan', // '1' Kan tas bort efter R12 när identifier ändrats
                '2', 'toxicitet', // '2' Kan tas bort efter R12 när identifier ändrats
                '3', 'progress', // '3' Kan tas bort efter R12 när identifier ändrats
                '5', 'patientens_val', // '5' Kan tas bort efter R12 när identifier ändrats
                'dod_progress',
                'dod_toxicitet',
                'dod_annan',
                '9', 'annan_orsak', // '9' Kan tas bort efter R12 när identifier ändrats
            ],
        },
        Lkm_behandlingsintention: {
            identifiers: ['symtomatisk', 'remissionssyftande'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['induktion', 'konsolidering', 'underhall'],
        },
        Lkm_hogdosbeh: {
            identifiers: ['nej', 'ja'],
        },
        Lkm_hogdosbeh_spec: {
            identifiers: ['auto', 'tandem_auto', 'auto_allo', 'allo'],
        },
        Lkm_hogdosbeh_allo_spec: {},
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
        Lkm_startdos_lista: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
    },
    Kirurgi: {
        Kir_indikation: {
            identifiers: ['palliativ', 'kurativ', 'diagnostisk'],
        },
        Kir_kirurgityp: {
            identifiers: ['operation', 'kirurgisk_biopsi'],
        },
    },
    KirurgiLokal: {
        Kirlok_target: {
            identifiers: ['4', 'ovrig'],
        },
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    AnnanBehandling: {
        ab_datum: {},
        ab_typ: {
            identifiers: ['trombosprofylax', 'infektionsprofylax'],
        },
        ab_trombosprofylax: {
            identifiers: ['trombocythammare', 'lagmolekylart_heparin', 'noak', 'warfarin'],
        },
    },
    AnnanBehandling_spec: {
        abs_spec: {
            identifiers: ['herpesprofylax', 'pneumocystisprofylax', 'lg_substutition'],
        },
    },
    Patientportal: {},
    Dosjustering: {
        Dj_datum: {},
        Dj_justering: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Dj_orsak: {
            identifiers: ['enligt_plan', '2', '1', '3', '5'],
        },
    },
};

export default dataset;
