import { useEffect, useMemo } from 'react';
import { Alert, Tab, Tabs } from '@cancercentrum/rcc-bootstrap';
import { AjaxErrorAlert, LoadingAlert, useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import { useAtom } from 'jotai';
import { useIPO } from '../../hooks/useIPO';
import DefaultTabTitle from './DefaultTabTitle';
import { ProjectViewer } from './ProjectViewer/ProjectViewer';
import type { SparrarVDData, Utskicksprojekt } from './types';
import { selectedSurveyPillAtom } from './atoms';

export interface DefaultTabContentProps {
    utskicksprojekt: Utskicksprojekt[];
    reload: () => void;
}

const DefaultTabContent = (props: DefaultTabContentProps) => {
    const {
        utskicksprojekt,
        reload,
    } = props;
    const getValueDomainValues = useGetValueDomainValues();
    const ipo = useIPO();
    const { isLoading, error, result: vdSparrar } = usePromise(() => getValueDomainValues<SparrarVDData>('VD_enkatsparrar', {
        parameters: {
            diagnos: [ipo.diagnosis.diagnos_varde || '-1'],
            patientId: [ipo.patient.id],
        },
    }));
    const projekt = useMemo(() => {
        return utskicksprojekt.filter(u => !u.config.isVisible || u.config.isVisible({
            surveys: u.answers,
            registersWithRegistrationAccess: ipo.registersWithRegistrationAccess,
            ipo,
        }));
    }, [utskicksprojekt, ipo]);

    const [selectedPill, setSelectedPill] = useAtom(selectedSurveyPillAtom);

    useEffect(() => {
        if (!selectedPill && projekt.length) {
            setSelectedPill(projekt[0].config.id.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        return <AjaxErrorAlert error={error} />;
    }

    if (isLoading || !vdSparrar) {
        return <LoadingAlert />;
    }

    return (
        <div>
            {!!projekt.length && (
                <div>
                    {!!vdSparrar.length && (
                        <Alert bsStyle="warning" className="d-flex">
                            <div>
                                <div>Patienten har spärrat deltagande i enkätutskick. Funktionaliteten att skapa enkätkod och skicka notifieringar via 1177 har därför inaktiverats.</div>
                                <div className="mt-3">Begär att spärren hävs genom att skicka ett e-postmeddelande till
                                    <a
                                        rel="noreferrer"
                                        href={`mailto:rcc-prom-support@incanet.se?subject=${encodeURIComponent(`${ipo.diagnosis.diagnos}: häv spärr för patient-id ${ipo.patient.id}`)}`}
                                        target="_blank"
                                        className="alert-link ms-1"
                                    ><strong>rcc-prom-support@incanet.se</strong></a>. Inkludera patient-id {ipo.patient.id} samt diagnosen i meddelandet.
                                </div>
                            </div>
                        </Alert>
                    )}

                    <Tabs id="Surveys" bsStyle="pills" animation={false} activeKey={selectedPill || 'dia'} onSelect={(e: any) => setSelectedPill(e)}>
                        <hr />
                        {projekt.map(u => {
                            return (
                                <Tab
                                    key={u.config.id}
                                    eventKey={u.config.id.toString()}
                                    title={<DefaultTabTitle utskicksprojekt={u} />}
                                >
                                    <ProjectViewer utskicksprojekt={u} otherProjectsForSameSurvey={projekt.filter(x => x !== u && x.survey.id === u.survey.id)} reload={reload} sparrad={!!vdSparrar.length} />
                                </Tab>
                            );
                        })}
                    </Tabs>
                </div>
            )}

            {!projekt.length && (
                <Alert>Enkäter saknas för inloggad roll.</Alert>
            )}
        </div>
    );
};

export default DefaultTabContent;
