import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import useLayoutSettings from '../../hooks/useLayoutSettings';
import TextRepresentationModal from './TextRepresentationModal';

export interface TextSummaryButtonProps {
    contentClass: (props: any) => ReactElement | null;
    contentClassProps?: any;
}

const TextRepresentationButton = (props: TextSummaryButtonProps) => {
    const { contentClass, contentClassProps } = props;
    const [open, setOpen] = useState(false);
    const settings = useLayoutSettings();
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    if (!settings.showTextextraktion) {
        return null;
    }

    return (
        <>
            {open && (
                <TextRepresentationModal onClose={onClose} contentClass={contentClass} contentClassProps={contentClassProps} />
            )}
            <Button onClick={onOpen} bsSize="sm" data-testid="VisaTextrepresentation">
                <FontAwesomeIcon icon={faFileAlt} /> Visa i textform
            </Button>
        </>
    );
};

export default TextRepresentationButton;
