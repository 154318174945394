import isNil from 'lodash/isNil';
import { getValueAsText } from '../utils';
import type { SummaryField as SF, SurveyAnswer, SurveyMetadata } from '../types';
import { MarkdownQuestion } from './MarkdownQuestion';

export const shouldShowSummaryFieldHeader = (ans: SurveyAnswer, metadata: SurveyMetadata, summaryFields: SF[]) => {
    return summaryFields.some(x => {
        if ('shortname' in x) {
            return !!ans.data[x.shortname] && !!metadata.questions[x.shortname];
        }

        return x.condition(ans, metadata);
    });
};

const defaultFormatter: SF['formatter'] = (answer, question, metadata) => {
    return {
        text: getValueAsText(answer, question, metadata),
    };
};

export const SummaryField = (props: {
    summaryField: SF;
    answer: SurveyAnswer;
    metadata: SurveyMetadata;
}) => {
    const { summaryField, answer, metadata } = props;

    if ('shortname' in summaryField) {
        const question = metadata.questions[summaryField.shortname];
        const formatter: SF['formatter'] = summaryField.formatter || defaultFormatter;

        if (!question || isNil(answer.data[summaryField.shortname])) {
            return null;
        }

        const res = formatter(answer, question, metadata);

        return (
            <div className="mb-4" data-shortname={summaryField.shortname}>
                <div><MarkdownQuestion text={summaryField.label ?? question.label} /></div>
                <div>{res.icon} {res.text}</div>
            </div>
        );
    }

    if (!summaryField.condition(answer, metadata)) {
        return null;
    }

    const res = summaryField.formatter(answer, metadata);

    return (
        <div className="mb-4">
            <div><MarkdownQuestion text={summaryField.label} /></div>
            <div>{res.icon} {res.text}</div>
        </div>
    );
};
