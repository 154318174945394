import type { HTMLAttributes, JSX, MouseEventHandler } from 'react';
import { memo, useState } from 'react';
import isNil from 'lodash/isNil';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { Explanation, GenderIcon, useRowModel } from '@cancercentrum/rcc-react';
import type { Patient, RegvarBooleanMetadata } from '@cancercentrum/inca';
import { Alert, Button, DropdownButton, Label, MenuItem, Modal, OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useAtomValue } from 'jotai';
import { SearchAndNavigateToPatient } from '../components/SearchAndNavigateToOverview';
import { ErrorReportHeaderItem } from '../components/ErrorReportHeaderItem';
import { renderOptionsAtom } from '../startpage-oversikt/atoms';
import Kommentar from './Kommentar';
import PersonuppgifterSticky from './PersonuppgifterSticky';
import useLayoutSettings from './hooks/useLayoutSettings';
import PdlHeaderSection from './pdl/PdlHeaderSection';
import { calculateAge } from './utils/date';
import useConfig from './hooks/useConfig';
import useCurrentUser from './hooks/useCurrentUser';
import usePDL from './pdl/usePDL';
import { useIPO } from './hooks/useIPO';
import { FavoriteToggle } from './FavoriteToggle.tsx';

const isExternalDemo = process.env.RCC_ENV === 'external_demo';

const PersonuppgifterHeader = (props: {
    patient: Patient,
} & HTMLAttributes<HTMLDivElement>): JSX.Element => {
    const { patient, ...rest } = props;
    const age = calculateAge(patient.dateOfBirth, patient.dateOfDeath);

    return (
        <div {...rest}>
            <GenderIcon gender={patient.gender} /> {patient.lastName}, {patient.firstName} - <strong>{patient.ssn}</strong> {isNil(age) ? '- ålder okänd' : `- ${age} år`}
        </div>
    );
};

const PersonuppgifterSectionComp = (props: {
    patient: Patient;
    className?: string;
}): JSX.Element => {
    const { patient, className } = props;
    const patientOverlay = (
        <Popover id="Persuppgifter" className="mw-100">
            <div className="header-section">
                <GenderIcon gender={patient.gender} size="3x" />
                <div className="ms-3 name-section">
                    <span className="name">{patient.lastName}, {patient.firstName}</span>
                    <small>{patient.ssn}</small>
                </div>
            </div>
            <table className="table table-condensed">
                <tbody>
                <tr>
                    <td className="td-label">Kön:</td>
                    <td>{patient.gender}</td>
                    <td className="td-label">{patient.dateOfDeath && 'Dödsdatum:'}</td>
                    <td>{patient.dateOfDeath}</td>
                </tr>
                <tr>
                    <td className="td-label">LK:</td>
                    <td>{patient.county}{patient.municipality}</td>
                    <td className="td-label">{patient.district && 'Distriktskod:'}</td>
                    <td>{patient.district}</td>
                </tr>
                <tr>
                    <td className="td-label">Gatuadress:</td>
                    <td colSpan={3}>{patient.streetAddress}</td>
                </tr>
                <tr>
                    <td className="td-label">Postnummer:</td>
                    <td colSpan={3}>{patient.postalCode}</td>
                </tr>
                <tr>
                    <td className="td-label">Postadress:</td>
                    <td colSpan={3}>{patient.city}</td>
                </tr>
                </tbody>
            </table>
        </Popover>
    );

    return (
        <div className={className}>
            <div className="d-flex align-items-center personuppgifter-header">
                <div className="ms-1 me-2">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={patientOverlay}
                        trigger="click"
                        popperConfig={{ strategy: 'fixed' }}
                        rootClose={true}
                    >
                        <PersonuppgifterHeader patient={patient} style={{ cursor: 'pointer' }} />
                    </OverlayTrigger>
                </div>
                {!!patient.dateOfDeath && <Label bsStyle="danger" className="me-2" style={{ padding: '4px 5px' }}><FontAwesomeIcon icon={faCross} /> {patient.dateOfDeath}</Label>}
            </div>
            <PersonuppgifterSticky bottom={30} />
        </div>
    );
};

const PersonuppgifterSection = memo(PersonuppgifterSectionComp);

const NavigateToPatientModal = (props: {
    onClose: () => void;
    isDirty: boolean;
}): JSX.Element => {
    const { isDirty } = props;
    const currentConfig = useConfig();
    const currentUser = useCurrentUser();

    const onHide = () => {
        props.onClose();
    };

    return (
        <Modal show={true} onHide={onHide} bsSize="lg">
            <Modal.Header closeButton={true}>
                <Modal.Title componentClass="h3">
                    {currentConfig.diagnos}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isExternalDemo && <div>Det går inte att byta patient i demomiljön.</div>}
                {!isExternalDemo && (
                    <div>
                        {isDirty && (
                            <Alert bsStyle="warning">
                                Sidan har osparade ändringar. Försäkra dig om att posten är sparad innan du navigerar till en annan patient.
                            </Alert>
                        )}
                        <SearchAndNavigateToPatient currentUser={currentUser} selectedDiagnos={currentConfig} vdname="VD_registerposter" isActive={true} useCreateCheckboxToggle={true} />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Stäng</Button>
            </Modal.Footer>
        </Modal>
    );
};

const Manual = (props: {
    manualUrl: string;
    promManualUrl?: string;
}): JSX.Element => {
    const { manualUrl, promManualUrl } = props;

    return (
        <div>
            <DropdownButton
                title={<span><FontAwesomeIcon icon={faFileAlt} className="me-1" />Manualer</span>}
                id="ManualsDropdown"
            >
                {!!manualUrl && (
                    <MenuItem eventKey="1" target="_blank" rel="noopener noreferrer" href={manualUrl}>
                        IPÖ-manual
                    </MenuItem>
                )}
                {!!promManualUrl && (
                    <MenuItem eventKey="2" target="_blank" rel="noopener noreferrer" href={promManualUrl}>
                        PROM-manual
                    </MenuItem>
                )}
            </DropdownButton>
        </div>
    );
};

const Header = (props: {
    diagnos: string;
    isDirty: boolean;
    completeLkmRegvars?: RegvarModel<RegvarBooleanMetadata, boolean>[];
    completeLkmCalcBehavior?: 'one' | 'all';
}): JSX.Element => {
    const { isDirty, completeLkmRegvars, completeLkmCalcBehavior = 'all' } = props;
    const row = useRowModel();
    const renderOpts = useAtomValue(renderOptionsAtom);
    const settings = useLayoutSettings();
    const ipo = useIPO();
    const { hasPDLInaccessibleChildren } = usePDL();
    const [showModal, setShowModal] = useState(false);
    const onNewPatient: MouseEventHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setShowModal(true);
    };

    let isIncomplete;
    if (completeLkmCalcBehavior === 'all') {
        isIncomplete = !!completeLkmRegvars && !completeLkmRegvars.every(x => x.getValue());
    } else {
        isIncomplete = !!completeLkmRegvars && !completeLkmRegvars.some(x => x.getValue());
    }

    return (
        <div className="header mb-2">
            {showModal && <NavigateToPatientModal onClose={() => setShowModal(false)} isDirty={isDirty} />}

            <div className="d-flex align-items-center">
                <div className="header__item">
                    <h3 className="d-flex align-items-center gap-2 m-0">
                        {!!row.$extra.registerRecordId && (
                            <div style={{ fontSize: '.8em' }}>
                                <FavoriteToggle registerRecordId={row.$extra.registerRecordId} />
                            </div>
                        )}
                        <div>{props.diagnos}</div>
                    </h3>
                </div>
                <PersonuppgifterSection patient={ipo.patient} className="header__item" />
                {!renderOpts.loginFromExternalSystem && (
                    <div className="header__item">
                        <a href="src/form-oversikt" onClick={onNewPatient} className="header-link">
                            <FontAwesomeIcon icon={faSearch} /> Byt patient
                        </a>
                    </div>
                )}
                <div className="header__item"><PdlHeaderSection /></div>
                {!!settings.manualUrl && (
                    <div className="header__item">
                        <Manual manualUrl={settings.manualUrl} promManualUrl={settings.promManualUrl} />
                    </div>
                )}
                <ErrorReportHeaderItem />
                {isDirty && (
                    <div className="header__item">
                        <div className="header__warning border-bottom-warning">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="color-warning" /> Sidan har osparade ändringar
                        </div>
                    </div>
                )}
                {hasPDLInaccessibleChildren && (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={(
                            <Popover id="BlocksTooltip" title="Spärrad data">
                                Det finns data för patienten som är spärrad till andra vårdenheter.
                                Det går att kontrollera vilka enheter som har spärrad data i fliken "Datarättigheter".
                            </Popover>
                        )}
                        trigger="click"
                    >
                        <div className="header__item">
                            <div className="header__warning border-bottom-warning pointer">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="color-warning" /> Spärrad data
                            </div>
                        </div>
                    </OverlayTrigger>
                )}
                {isIncomplete && (
                    <div className="header__item">
                        <div className="border-bottom-info ">
                            <Explanation placement="bottom">
                                <p>
                                    Ej komplett läkemedelsregistrering innebär att patienten kan ha stått på fler läkemedel än vad som har rapporterats in till IPÖ.
                                </p>
                                <p className="mb-0">
                                    För att justera detta går du till fliken "<strong>Inställningar/urval</strong>" och bockar i kryssrutorna vid "Komplett registrering".
                                </p>
                            </Explanation>
                            <span className="ms-1">Läkemedelsrapporteringen är ej komplett</span>
                        </div>
                    </div>
                )}
            </div>
            <Kommentar kommentar={row.getRegvar('kommentar')} className="mt-2 mb-3" />
        </div>
    );
};

export { PersonuppgifterHeader };

export default memo(Header);
