import type { ReactElement } from 'react';
import { memo } from 'react';
import get from 'lodash/get';
import { GridActions } from '@cancercentrum/rcc-react';
import { useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import { useSetAtom } from 'jotai';
import { valueAndTextFormatter } from '../../../utils/formatters';
import { Point } from '../../components/Chart/Point';
import { SammantagenBedomningColors } from '../../../constants';
import useDataSet from '../../hooks/useDataSet';
import { getUTCStartOfDayTime } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import type { KontaktRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';

export interface KontaktPointProps {
    row: KontaktRowModel;
    y: number;
    label?: string;
    formatter?: () => string;
    tooltipParams?: any;
    getTooltip?: (row: KontaktRowModel) => ReactElement;
}

const dataLabels = {
    enabled: true,
    allowOverlap: true,
    x: -1,
    y: -7,
};

const KontaktPoint = (props: KontaktPointProps) => {
    const {
        row,
        y,
        getTooltip,
    } = props;
    const ctxDataSet = useDataSet();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const dispatch = useDispatch();
    const date = getUTCStartOfDayTime(parseISO(row.getRegvarValue('K_besoksdatum') || ''));
    const sammantagenBedomning = row.getRegvarValue('K_sammantagenBedomning');
    const color = SammantagenBedomningColors[sammantagenBedomning?.identifier || ''] || '#fff';
    const ds = ctxDataSet.Kontakt!.K_sammantagenBedomning;
    const formatter = props.formatter || get(ds, 'props.formatter') || valueAndTextFormatter;
    const label = props.label || get(ds, 'props.label') || row.getRegvar('K_sammantagenBedomning').$rcc.regvarMetadata.label;

    const sammantagenBedomingText = sammantagenBedomning ? `${label}: ${formatter(sammantagenBedomning)}` : '';
    const tooltip = getTooltip ? getTooltip(row) : (
        <LegacyTooltip headerText={`Kontakt ${row.getRegvarValue('K_besoksdatum')}`}>
            <div>
                {!!sammantagenBedomingText && <div>{sammantagenBedomingText}</div>}
            </div>
        </LegacyTooltip>
    );

    const marker = {
        symbol: 'circle',
        radius: 8,
        lineWidth: 1,
        lineColor: sammantagenBedomning ? '#000' : '#fff',
    };

    return (
        <Point
            x={date}
            y={y}
            marker={marker}
            renderTooltip={() => tooltip}
            color={sammantagenBedomning ? color : '#000'}
            dataLabels={dataLabels}
            events={{
                click() {
                    setActiveTab(ModuleName);
                    dispatch(GridActions.setSelectedItemId(GridId, row.getId()));
                },
            }}
        />
    );
};

export default memo(KontaktPoint);
