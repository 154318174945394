import type { ReactElement } from 'react';
import classnames from 'classnames';

type HeaderProps = {
    text: string;
    title?: string;
    className?: string
}

const Header = (props: HeaderProps): ReactElement => {
    const { text, title, className } = props;

    return (
        <h1 className={classnames('text__header fs-4', className)} title={title}><strong>{text}</strong></h1>
    );
};

export default Header;
