import type { MouseEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import usePDL from '../../pdl/usePDL';
import MeddelandeModal from '../meddelande/MeddelandeModal';

const helpStyle = { cursor: 'help' };
const buttonStyle = { cursor: 'pointer' };

export interface ReadOnlyIconProps {
    dataRowOwnerId: number;
    messageTitle: string;
}

const ReadOnlyIconButton = (props: ReadOnlyIconProps): ReactElement => {
    const { dataRowOwnerId, messageTitle } = props;
    const pdlContext = usePDL();
    const contributingPosition = pdlContext.positions.find(x => x.id === dataRowOwnerId);
    const [modalVisible, setModalVisible] = useState(false);
    const showModal: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalVisible(true);
    };
    const hideModal = () => setModalVisible(false);

    if (!contributingPosition) {
        return (
            <div className="text-center">
                <FontAwesomeIcon icon={faLock} fixedWidth={true} title="Denna registrering är gjord av en annan klinik och är därför skrivskyddad." style={helpStyle} />
            </div>
        );
    }

    const title = `Denna registrering är gjord av av ${contributingPosition.data.PosNameWithCode} och är därför skrivskyddad.

Klicka här för att skicka ett meddelande till kliniken.`;

    return (
        <>
            <Button bsStyle="link" onClick={showModal} title={title} className="py-0">
                <FontAwesomeIcon icon={faLock} fixedWidth={true} style={buttonStyle} />
            </Button>

            <MeddelandeModal visible={modalVisible} onClose={hideModal} position={contributingPosition} defaultTitle={messageTitle} />
        </>
    );
};

export default ReadOnlyIconButton;
