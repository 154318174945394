import type { FormEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IncaAjaxError } from '@cancercentrum/inca';
import { apiRequest, incaAccessor } from '@cancercentrum/inca';
import { AjaxErrorAlert } from '@cancercentrum/rcc-react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useGlobalInca } from '../../components/GlobalIncaProxy';
import VardrelationExplanation from '../VardrelationExplanation';
import { useIPO } from '../hooks/useIPO';
import usePDL from './usePDL';

export const ChangeFieldset = (props: {
    isModified: boolean;
    samtycke: boolean;
}): ReactElement => {
    const { isModified, samtycke } = props;
    const [updSamtycke, setUpdSamtycke] = useState(samtycke);
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState<Error | IncaAjaxError | null>(null);
    const pdlContext = usePDL();
    const [harVardrelation, setHarVardrelation] = useState(true);
    const settingsChanged = samtycke !== updSamtycke || !harVardrelation;
    const ipo = useIPO();
    const incaProxy = useGlobalInca();

    const curPos = pdlContext.positions.find(x => x.id === ipo.currentUser.position.id);
    const samtyckeDisabled = pdlContext.rootPosition?.data.CareGiverId !== curPos?.data.CareGiverId;

    const onSubmitChanges: FormEventHandler = e => {
        e.stopPropagation();
        e.preventDefault();

        const actions = [];

        if (!harVardrelation) {
            actions.push(apiRequest(`/Users/Current/Patients/${ipo.patient.id}/ActiveChoice`, {
                method: 'PUT',
                json: {
                    hasActiveChoice: harVardrelation,
                },
            }));
        }

        if (updSamtycke !== samtycke) {
            actions.push(apiRequest(`/Patients/${ipo.patient.id}/AggregatedPatientRecord`, {
                method: 'PUT',
                json: {
                    aggregatedPatientRecord: updSamtycke,
                },
            }));
        }

        if (actions.length) {
            setIsUpdating(true);
            setError(null);

            const inca = incaAccessor();

            Promise.all(actions).then(() => {
                // Om vårdrelation har kryssats ur så skickas användaren till startsidan. Annnars kommer INCA:s GUI direkt när posten laddas om
                // visa en modal där användaren får intyga vårdrelation, vilket blir lite förvirrande.
                if (!harVardrelation) {
                    window.top!.location.href = inca.getBaseUrl();
                } else {
                    if (inca?.overview?.supportsTabs) {
                        inca.overview.tabs.add(tabId => {
                            inca.overview.tabs.setContent(tabId, 'rr', inca.form.data.registerRecordId!);
                            inca.overview.tabs.remove(inca.overview.tabs.getCurrentTabId());
                            inca.overview.tabs.activate(tabId);
                        });
                    } else {
                        incaProxy.reload();
                    }
                }
            }).catch(err => {
                setIsUpdating(false);
                setError(err);
            });
        }
    };

    return (
        <fieldset>
            <legend><FontAwesomeIcon icon={faPencilAlt}  className="me-2" /> Ändra kriterier</legend>

            {(isModified && !isUpdating) && (
                <em>Formuläret innehåller osparade ändringar. Spara och ladda om för att ändra patientens samtycke eller din vårdrelation till patienten.</em>
            )}

            {(!isModified || isUpdating) && (
                <form onSubmit={onSubmitChanges}>
                    <div className="checkbox mt-0">
                        <label>
                            <input
                                type="checkbox"
                                checked={harVardrelation}
                                onChange={e => setHarVardrelation(e.target.checked)}
                                className="me-1"
                            /> Jag, {ipo.currentUser.user.firstName} {ipo.currentUser.user.lastName}, intygar att jag har en vårdrelation <VardrelationExplanation /> till den valda patienten.
                        </label>
                    </div>

                    <div className="checkbox form-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={updSamtycke}
                                onChange={e => setUpdSamtycke(e.target.checked)}
                                disabled={samtyckeDisabled}
                                className="me-1"
                            /> Patienten har lämnat sitt samtycke till sammanhållen journalföring.
                        </label>
                        {samtyckeDisabled && (
                            <div className="help-block ms-4">Samtycke kan endast ändras av {pdlContext.rootPosition?.data.PosNameWithCode || '[uppgift saknas]'}.</div>
                        )}
                    </div>

                    <div className="d-flex align-items-center">
                        <Button type="submit" disabled={!settingsChanged || isUpdating}>Uppdatera</Button>
                        {isUpdating && <div className="ms-3"><FontAwesomeIcon icon={faSpinner} spin={true} /> Laddar, var god vänta...</div>}
                    </div>

                    {!!error && <AjaxErrorAlert error={error} className="mt-3 mb-2" />}
                </form>
            )}
        </fieldset>
    );
};
