import { usePromise } from '@cancercentrum/rcc-react';
import { apiRequest } from '@cancercentrum/inca';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import type { CSSProperties } from 'react';

export const getFavoriteIds = async () => {
    const res = await apiRequest<{ rrIds: number[]; } | null>('/CurrentUser/Get/ipo_pinned_overviews_v1');

    return res?.rrIds || [];
};

const Pin = (props: {
    fill: boolean;
    className?: string;
}) => {
    const { fill, className } = props;
    const style: CSSProperties = {
        height: '1em',
    };

    return fill
        ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={style} fill="currentColor" className={classNames('bi bi-pin-fill', className)} viewBox="0 0 16 16">
                <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354z" />
            </svg>
        )
        : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={style} fill="currentColor" className={classNames('bi bi-pin', className)} viewBox="0 0 16 16">
                <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001m-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282" />
            </svg>
        );
};

export const FavoriteToggle = (props: {
    registerRecordId: number;
}) => {
    const { registerRecordId } = props;

    const p = usePromise<number[]>(() => {
        return getFavoriteIds();
    });

    const onToggle = () => {
        p.exec(async () => {
            // Get the current state to reduce the risk of overwriting newer state if the user has multiple tabs open.
            let res = await getFavoriteIds();

            if (res.includes(registerRecordId)) {
                res = res.filter(x => x !== registerRecordId);
            } else {
                res.push(registerRecordId);
            }

            await apiRequest<number[] | null>('/CurrentUser/Set/ipo_pinned_overviews_v1', {
                method: 'PUT',
                json: {
                    rrIds: res,
                },
            });

            return res;
        });
    };

    if (p.error) {
        return <FontAwesomeIcon icon={faExclamationCircle} fixedWidth={true} title={p.error.message} />;
    }

    if (p.isLoading) {
        return <FontAwesomeIcon icon={faSpinner} fixedWidth={true} spin={true} />;
    }

    const isPinned = (p.result || []).includes(registerRecordId);
    const tooltip = isPinned ? 'Klicka för att ta bort från "Min lista" på startsidan.' : 'Klicka för att lägga till i "Min lista" på startsidan.';

    return (
        <div title={tooltip} onClick={onToggle} className="pointer"><Pin fill={isPinned} className="fa-fw" /></div>
    );
};
