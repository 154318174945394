import { useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import { useCallback } from 'react';
import type { Patient } from '@cancercentrum/inca';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useAppSettings } from '../../../components/AppSettings';
import { useIPO } from '../../hooks/useIPO';
import type { GetValueDomainValues } from '../../../types';
import type { EnkatfragaVDData, EnkatlistaVDData, EnkatregisterdeltagareVDData, EnkatregisterdeltagareVDItem, EnkatsvarVDData, QuestionMetadata, SurveyAnswer, SurveyList, SurveyMetadata, SurveysPromise, Utskicksprojekt, UtskicksprojektConfig, UtskicksprojektVDData, UtskicksprojektVDItem } from './types';
import { getDisplayName } from './utils';

const getSurveyMetadatas = async (utskicksprojekt: UtskicksprojektVDItem[], getValueDomainValues: GetValueDomainValues): Promise<SurveyMetadata[]> => {
    const enkater = keyBy(utskicksprojekt, x => x.data.enkat_vd);
    const enkatIds = uniq(utskicksprojekt.map(x => x.data.enkat_vd)).sort();

    const [vdFragor, vdListor] = await Promise.all([
        getValueDomainValues<EnkatfragaVDData>('VD_Enkat_def_fragor', {
            parameters: {
                enkatIds: [-1, ...enkatIds],
            },
        }),

        getValueDomainValues<EnkatlistaVDData>('VD_Enkat_def_listor', {
            parameters: {
                enkatIds: [-1, ...enkatIds],
            },
        }),
    ]);

    const fragorByEnkatId = groupBy(vdFragor, x => x.data.ENKAT_ID);
    const listorByEnkatId = groupBy(vdListor, x => x.data.ENKAT_ID);

    return enkatIds.map((enkatId: number): SurveyMetadata => {
        const e = enkater[enkatId];
        const enkatensListorMedSvar = listorByEnkatId[enkatId];
        const listUuids = uniq(enkatensListorMedSvar.map(x => x.data.list_uuid));
        const mappedLists: Record<string, SurveyList> = {};
        listUuids.forEach(uuid => {
            const listValues = enkatensListorMedSvar.filter(x => x.data.list_uuid === uuid);
            mappedLists[uuid] = {
                uuid: uuid,
                id: listValues[0].data.LISTA_ID,
                shortname: listValues[0].data.list_kortnamn,
                listValues: listValues.filter(x => x.data.listvarde_sprak_Värde === null).map(lv => {
                    return {
                        id: lv.data.LISTVARDE_ID,
                        text: lv.data.listvarde_text,
                        value: lv.data.listvarde,
                    };
                }),

            };
        });

        const enkatensFragorMedSprak = groupBy(fragorByEnkatId[enkatId], x => x.data.fraga_kortnamn);
        const mappedQuestions: Record<string, QuestionMetadata> = Object.values(enkatensFragorMedSprak).reduce((red, arr) => {
            const defaultLang = arr.find(x => x.data.fraga_sprak_Värde === null) ?? arr[0];
            const question: QuestionMetadata = {
                dataType: defaultLang.data.fraga_datatyp_Värde,
                id: defaultLang.data.FRAGA_ID,
                label: defaultLang.data.fraga_text,
                listUuid: defaultLang.data.fraga_list_uuid ?? undefined,
                multipleChoice: !!defaultLang.data.fraga_flerval,
                shortname: defaultLang.data.fraga_kortnamn,
            };
            return {
                ...red,
                [arr[0].data.fraga_kortnamn]: question,
            };
        }, {});

        return {
            id: enkatId,
            name: e.data.enkat_namn,
            version: e.data.enkat_version,
            version_kommentar: e.data.enkat_version_kommentar,
            surveyCode: e.data.enkat_enkatkod,
            allowMultilpleAnswers: !!e.data.enkat_tillatFleraSvar,
            htmlDesign: !!e.data.enkat_avanceradDesign,
            maxAge: e.data.enkat_maxalder,
            minNumberOfDaysBetweenAnswers: e.data.enkat_intervallMellanSvar ?? undefined,
            lists: mappedLists,
            questions: mappedQuestions,
        };
    });
};

const getAnswers = async (utskicksprojekt: UtskicksprojektVDItem[], patient: Patient, getValueDomainValues: GetValueDomainValues): Promise<Record<number, { deltagare: EnkatregisterdeltagareVDItem, rotId?: number, personalCode?: string, answers: SurveyAnswer[] }>> => {
    const deltagare = await getValueDomainValues<EnkatregisterdeltagareVDData>('VD_Patientoversikt_Enkätregistretdeltagare', {
        parameters: {
            patientId: [patient.id],
            utskicksprojektIds: [-1, ...utskicksprojekt.map(x => x.id)],
        },
    });

    const svarIds = uniq(deltagare.map(x => x.data && x.data.enkatsvar_vd).filter(x => x));

    const enkatsvar = await getValueDomainValues<EnkatsvarVDData>('VD_enkatsvar_vd', {
        parameters: {
            rotIds: [-1, ...svarIds],
        },
    });
    const enkatsvarByProj = groupBy(enkatsvar, x => x.data.utskicksprojekt_vd);

    return utskicksprojekt.reduce<Record<number, { deltagare: EnkatregisterdeltagareVDItem, rotId?: number, answers: SurveyAnswer[] }>>((red, projekt) => {
        const projektsvar = enkatsvarByProj[projekt.id] ?? [];
        return {
            ...red,
            [projekt.id]: {
                deltagare: deltagare.find(x => x.data.utskicksprojekt_vd === projekt.id)!,
                rotId: projektsvar[0]?.data.ROT_ID,
                personalCode: projektsvar[0]?.data.kod,
                answers: orderBy(projektsvar.map((ps): SurveyAnswer => {
                    return {
                        id: ps.data.SVAR_ID,
                        participantCode: ps.data.kod,
                        startDate: ps.data.startdatum,
                        endDate: ps.data.slutdatum ?? undefined,
                        lastModified: ps.data.senastModifierad,
                        data: JSON.parse(ps.data.svar_json || '{}'),
                        extra: JSON.parse(ps.data.extra_json || '{}'),
                        enkatversion: ps.data.enkatversion,
                    };
                }), [x => x.endDate, x => x.startDate], ['desc', 'desc']),
            },
        };
    }, {});
};

export const useSurveys = (definitions: UtskicksprojektConfig[]): SurveysPromise => {
    const ipo = useIPO();
    const appSettings = useAppSettings();
    const getValueDomainValues = useGetValueDomainValues();

    const promiseCreator = useCallback(async () => {
        const projekt = orderBy(await getValueDomainValues<UtskicksprojektVDData>('VD_enkatregistret_utskicksprojekt', {
            parameters: {
                multisearch: [1],
                ids: [-1, ...definitions.map(x => x.id)],
            },
        }), [x => definitions.findIndex(y => y.id === x.id)], ['asc']);

        const surveyMetadatas = await getSurveyMetadatas(projekt, getValueDomainValues);
        const answers = await getAnswers(projekt, ipo.patient, getValueDomainValues);

        return projekt.map((projektVd): Utskicksprojekt => {
            const res: Utskicksprojekt = {
                name: projektVd.data.namn,
                survey: surveyMetadatas.find(x => projektVd.data.enkat_vd === x.id)!,
                config: definitions.find(x => projektVd.id === x.id)!,
                deltagare: answers[projektVd.id].deltagare,
                answers: answers[projektVd.id].answers,
                svarRootTableId: answers[projektVd.id].rotId,
                personalCode: answers[projektVd.id].personalCode,
                ipoName: projektVd.data.ipo_namn,
                ipoDescription: projektVd.data.ipo_beskrivning,
            };

            if (res.personalCode) {
                const patient = ipo.patient;
                res.participantParams = {
                    SurveyCode: res.survey.surveyCode,
                    PersonalCode: res.personalCode,
                    ParticipantCode: `${res.survey.surveyCode} - ${res.personalCode}`,
                    FirstName: patient.firstName,
                    LastName: patient.lastName,
                    Name: `${patient.firstName} ${patient.lastName}`,
                    StreetAddress: patient.streetAddress,
                    ZipCode: patient.postalCode,
                    City: patient.city,
                    SurveyURL: (new URL(`enkater/v2/redigera/${encodeURIComponent(res.survey.surveyCode)}/${encodeURIComponent(res.personalCode)}`, appSettings.etjanster_url)).href,
                    SurveyName: getDisplayName(res),
                };
            }

            return res;
        });
    }, [definitions, ipo.patient, appSettings, getValueDomainValues]);

    const {
        result,
        isLoading,
        error,
        exec,
    } = usePromise(promiseCreator);

    const reload = useCallback(() => {
        exec(promiseCreator);
    }, [promiseCreator, exec]);

    return {
        isLoading: isLoading,
        error: error,
        reload,
        result: result ?? undefined,
    };
};
