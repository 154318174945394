import type { ReactElement } from 'react';
import { useMemo } from 'react';
import { marked } from 'marked';

export interface MarkdownQuestionProps {
    text?: string;
    className?: string
}

export const MarkdownQuestion = (props: MarkdownQuestionProps): ReactElement => {
    const { text, className = '' } = props;
    const html = useMemo(() => {
        return marked.parseInline(text || '') || '';
    }, [text]);

    return <div className={`markdown-container ${className}`} dangerouslySetInnerHTML={{ __html: html }} />;
};
