import type { ListItem } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import type { RowModel } from '@cancercentrum/rcc-react';
import { SammantagenBedomningIcon } from '../form-oversikt/components/SammantagenBedomningIcon';
import { SammantagenBedomningDescriptions } from '../constants';
import { EcogIcon } from '../form-oversikt/components/EcogIcon';

export const valueAndTextFormatter = (item: ListItem): string => {
    if (item.value === item.text) {
        return item.text;
    }

    return `${item.value}. ${item.text}`;
};

export interface ObjectWithText {
    text: string
}

export interface ObjectWithValue {
    value: string
}

export const textFormatter = (item?: ObjectWithText | null) => item?.text;

export const valueFormatter = (item?: ObjectWithValue | null) => item?.value;

export const conditionalTextFormatter = (shortname: string, truthyText: string, falsyText: string) => (row: RowModel): string => {
    return row.getRegvarValue(shortname) && truthyText || falsyText;
};

export const ecogFormatter = (item: ListItem): ReactElement => {
    return (
        <div className="d-flex align-items-center">
            <EcogIcon value={item.value} className="me-2" />
            <div>{valueAndTextFormatter(item)}</div>
        </div>
    );
};

export const samBehFormatter = (item: ListItem): ReactElement => {
    const descr = SammantagenBedomningDescriptions[item.identifier];

    return (
        <div className="d-flex">
            <div className="me-2"><SammantagenBedomningIcon identifier={item.identifier} /></div>
            <div className="d-flex flex-column">
                <div>{valueAndTextFormatter(item)}</div>
                {!!descr && <div className="text-muted">{descr}</div>}
            </div>
        </div>
    );
};
