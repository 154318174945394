import { useSelector } from 'react-redux';
import type { ReactElement } from 'react';
import type { OverviewState } from '../../types';
import { Section } from '../../components/TextRepresentation';

const kommentarSelector = (state: OverviewState) => state.data.present.getRegvarValue('kommentar') as string | null;

export const KommentarTextRepresentation = (): ReactElement | null => {
    const kommentar = useSelector(kommentarSelector);
    if (!kommentar) {
        return null;
    }

    return (
        <Section header="Kommentar">
            <pre className="pretty-pre">{kommentar}</pre>
        </Section>
    );
};
