import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { SammantagenBedomningColors } from '../../constants';

const blackBorder = { stroke: '#000000', strokeWidth: 40 };

export const SammantagenBedomningIcon = (props: {
    identifier: string;
}): JSX.Element => {
    const color = SammantagenBedomningColors[props.identifier] || '#fff';

    return (
        <FontAwesomeIcon icon={faCircle} color={color} style={blackBorder} className="me-2" />
    );
};
