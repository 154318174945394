import type { FormEvent, ReactElement } from 'react';
import { memo, useMemo, useRef, useState } from 'react';
import type { HookDataActions } from '@cancercentrum/rcc-react';
import { dataUtils, DetachedRow, useIncaForm } from '@cancercentrum/rcc-react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import useDataSet from '../../../../hooks/useDataSet';
import { StartdatumQuestion } from '../../StartdatumQuestion';
import { ReadOnlyContext } from '../../../../components/ReadOnlyContext';
import type { HierarchifiedSubstansItem, OnBeforeRowAddCallback, OnClearCallback, RenderAddContentCallback, RenderHeaderSectionCallback, SelectedRegim, SetIsCustomSubstanceCallback } from '../../types';
import { createRowHook } from '../../../../utils';
import type { Läkemedel, LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { LakemedelPromiseResult } from '../../useLakemedelVDPromise';

const defaultOnBeforeAdd: OnBeforeRowAddCallback = (x) => x;
const defaultRenderHeaderSection: RenderHeaderSectionCallback = () => null;
const defaultOnClear: OnClearCallback = (prevRow, newRow) => newRow;

const RegimTemplate = (props: {
    dataActions: HookDataActions<Läkemedel>;
    onBeforeAddRow?: OnBeforeRowAddCallback;
    renderAddContent: RenderAddContentCallback;
    renderHeaderSection?: RenderHeaderSectionCallback;
    keepShortnamesOnClear?: (keyof Läkemedel['regvars'])[];
    onInitializeDetachedRow?: (row: LäkemedelRowModel) => LäkemedelRowModel;
    onClear?: OnClearCallback;
    lakemedelPromiseResult: LakemedelPromiseResult
}): ReactElement => {
    const {
        dataActions,
        onBeforeAddRow = defaultOnBeforeAdd,
        keepShortnamesOnClear,
        renderAddContent,
        onInitializeDetachedRow,
        renderHeaderSection = defaultRenderHeaderSection,
        onClear = defaultOnClear,
        lakemedelPromiseResult,
    } = props;
    const inca = useIncaForm();
    const onInitRef = useRef(onInitializeDetachedRow);
    onInitRef.current = onInitializeDetachedRow;

    const { regimerById, substanserById, vdUtvaldaSubstanser } = lakemedelPromiseResult;

    const initialRow = useMemo(() => {
        const newRow = dataUtils.createDataRow('Läkemedel', inca.form.metadata);
        let r = dataUtils.createViewModel(newRow, inca.form.metadata,'Läkemedel') as LäkemedelRowModel;

        // Sätter variabler som i vanliga fall sätts om subTable.createRow hade använts.
        r = createRowHook(r, inca.currentUser) as LäkemedelRowModel;

        if (onInitRef.current) {
            r = onInitRef.current(r);
        }

        return r;
    }, [onInitRef, inca]);
    const [isCustomSubstance, setIsCustomSubstance2] = useState<boolean>();
    const dataset = useDataSet();
    const onStartdatumChange = (detachedDataActions: HookDataActions<Läkemedel>, date: string | null) => {
        const keep = keepShortnamesOnClear || [];
        // Ändring av datum ska rensa resterande fält för att försäkra sig om att inte data för tidigare registrering råkar slinka med.
        detachedDataActions.updateRow(r => {
            let newRowWithPreservedData = initialRow.setRegvarValue('Lkm_startdatum', date, { markAsDirty: true });

            newRowWithPreservedData = keep.reduce((reduction, shortname) => {
                return reduction.setRegvarValue(shortname, r.getRegvar(shortname).value);
            }, newRowWithPreservedData);

            let res = onClear(r, newRowWithPreservedData);

            if (onInitRef.current) {
                res = onInitRef.current(res);
            }

            return res;
        });
    };

    const setIsCustomSubstance = (value: boolean, detachedDataActions: HookDataActions<Läkemedel>) => {
        if (!value) {
            detachedDataActions.updateRow(x => {
                return ([
                    'Lkm_studielakemedel_spec',
                    'Lkm_studie_vd',
                ] as (keyof Läkemedel['regvars'])[]).reduce((reduction, shortname) => {
                    if (!dataset.Läkemedel![shortname]) {
                        return reduction;
                    }

                    return reduction.clearRegvarValue(shortname);
                }, x);
            }, { skipHistory: true });
        }

        setIsCustomSubstance2(value);
    };

    const getSelectedRegim = (detachedRow: LäkemedelRowModel): SelectedRegim | null => {
        const selectedRegimId = detachedRow.getRegvarValue('Lkm_regim_vd') as number;

        return selectedRegimId ? {
            vd: regimerById[selectedRegimId],
            substanser: regimerById[selectedRegimId].substanser.map(x => substanserById[x]),
        } : null;
    };

    const getSelectedSubstance = (detachedRow: LäkemedelRowModel): HierarchifiedSubstansItem | null => {
        const selectedLakemedelId = detachedRow.getRegvarValue('Lkm_substans_vd') as number;

        return selectedLakemedelId ? substanserById[selectedLakemedelId] : null;
    };

    const addLkm = (e: FormEvent, dr: LäkemedelRowModel, validate: () => boolean, detachedDataActions: HookDataActions<Läkemedel>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!validate()) {
            return;
        }

        const selectedRegimId = dr.getRegvarValue('Lkm_regim_vd') as number;
        const selectedLakemedelId = dr.getRegvarValue('Lkm_substans_vd') as number;
        const sourceSystemName = 'inca';
        const instattningsid = crypto.randomUUID();
        const isUtvald = (substansId: number): boolean => vdUtvaldaSubstanser.some(x => x.data.substans_sub_pk === substansId);
        if (selectedRegimId) {
            const selectedRegim = regimerById[selectedRegimId];

            dataActions.updateRow(row => {
                return selectedRegim.substanser.reduce((reduction, subId) => {
                    const sub = substanserById[subId];
                    const newRow = (dr
                        .clone() as LäkemedelRowModel)
                        .setRegvarValue('Lkm_insattningsuuid', instattningsid)
                        .setRegvarValue('Lkm_substans_vd', sub.id)
                        .setRegvarValue('Lkm_utvalt', isUtvald(subId))
                        .setRegvarValue('Lkm_sourceSystemName', sourceSystemName)
                        .setRegvarValue('Lkm_sourceSystemId', crypto.randomUUID());

                    return reduction.addSubTableRow(onBeforeAddRow(newRow, sub));
                }, row);
            });
        } else if (selectedLakemedelId) {
            const subVd = getSelectedSubstance(dr);

            dataActions.updateRow(row => {
                const newRow = (dr
                    .clone() as LäkemedelRowModel)
                    .setRegvarValue('Lkm_insattningsuuid', instattningsid)
                    .setRegvarValue('Lkm_utvalt', isUtvald(selectedLakemedelId))
                    .setRegvarValue('Lkm_sourceSystemName', sourceSystemName)
                    .setRegvarValue('Lkm_sourceSystemId', crypto.randomUUID());

                return row.addSubTableRow(onBeforeAddRow(newRow, subVd));
            });
        } else {
            dataActions.updateRow(row => {
                const newRow = (dr
                    .clone() as LäkemedelRowModel)
                    .setRegvarValue('Lkm_insattningsuuid', instattningsid)
                    .setRegvarValue('Lkm_sourceSystemName', sourceSystemName)
                    .setRegvarValue('Lkm_sourceSystemId', crypto.randomUUID());

                return row.addSubTableRow(onBeforeAddRow(newRow, null));
            });
        }

        detachedDataActions.updateRow(x => {
            return ([
                'Lkm_regim_vd',
                'Lkm_substans_vd',
                'Lkm_ablatioTestis',
                'Lkm_gesInomRamenAvStudie',
                'Lkm_studielakemedel_spec',
                'Lkm_studie_vd',
            ] as (keyof Läkemedel['regvars'])[]).reduce((reduction, shortname) => {
                if (!dataset.Läkemedel![shortname]) {
                    return reduction;
                }

                return reduction.clearRegvarValue(shortname);
            }, x);
        });

        setIsCustomSubstance2(false);
    };

    return (
        <ReadOnlyContext.Provider value={false}>
            <DetachedRow id="NewLkm" initialRow={initialRow}>
                {({ row: dr, dataActions: detachedDataActions, validate }) => {
                    const selRegim = getSelectedRegim(dr);
                    const selSub = getSelectedSubstance(dr);
                    const isDisabled = !dr.getRegvarValue('Lkm_regim_vd') && !dr.getRegvarValue('Lkm_substans_vd') && !dr.getRegvarValue('Lkm_studielakemedel_spec') && !isCustomSubstance;
                    const setCustCb: SetIsCustomSubstanceCallback = (val) => {
                        setIsCustomSubstance(val, detachedDataActions);
                    };

                    return (
                        <section className="block-section">
                            <form className="form-horizontal" onSubmit={e => addLkm(e, dr, validate, detachedDataActions)}>
                                {renderHeaderSection({
                                    row: dr,
                                })}
                                <fieldset>
                                    <legend>Lägg till läkemedel</legend>
                                    <StartdatumQuestion
                                        regvar={dr.getRegvar('Lkm_startdatum')}
                                        onValueChange={val => onStartdatumChange(detachedDataActions, val)}
                                        labelClassName="col-sm-2"
                                        wrapperClassName="col-sm-10"
                                    />

                                    {renderAddContent({
                                        row: dr,
                                        dataActions: detachedDataActions,
                                        selectedRegim: selRegim,
                                        selectedLakemedel: selSub,
                                        setIsCustomSubstance: setCustCb,
                                        lakemedelPromiseResult: lakemedelPromiseResult,
                                    })}

                                    <div className="form-group">
                                        <div className="d-inline-block offset-sm-2 col-sm-10">
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                disabled={isDisabled}
                                            >
                                                Lägg till vald regim/läkemedel
                                            </Button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </section>
                    );
                }}
            </DetachedRow>
        </ReadOnlyContext.Provider>
    );
};

export default memo(RegimTemplate);
