import { hierarchifyVDResult } from '@cancercentrum/inca';
import type { UsePromiseResult } from '@cancercentrum/rcc-react';
import { useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import type { CTCAEItem, CTCAEVDData } from './types';

const EMPTY_ARRAY: CTCAEItem[] = [];

export const ctcaeAtom = atom<CTCAEItem[]>(EMPTY_ARRAY);

export const useCtcaeLoader = (): UsePromiseResult<CTCAEItem[]> => {
    const getValueDomainValues = useGetValueDomainValues();
    const setCtcaeAtom = useSetAtom(ctcaeAtom);
    const p = usePromise<CTCAEItem[]>(async () => {
        const rows = await getValueDomainValues<CTCAEVDData>('Bv_ctcae_grade_vd');

        return hierarchifyVDResult(rows, [{
            tableName: 'Term',
            idCol: 'TERM_ID',
            columns: [/^term_/],
            subTables: [
                {
                    tableName: 'Grade',
                    idCol: 'GRADE_ID',
                    columns: [/^grade_/],
                },
            ],
        }], { getId: x => x.data.AREA_ID });
    });

    const { result } = p;

    useEffect(() => {
        setCtcaeAtom(result || EMPTY_ARRAY);
    }, [result, setCtcaeAtom]);

    return p;
};
