import { Alert } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import { useAppSettings } from './AppSettings';

export const ImportantAlertBanner = (): ReactElement | null => {
    const appSettings = useAppSettings();

    if (!appSettings.bannerHTML) {
        return null;
    }

    return (
        <Alert bsStyle="info" className="d-flex align-items-center" style={{ padding: '.5em' }} iconProps={{ size: '2x' }}>
            <div className="ms-3" dangerouslySetInnerHTML={{ __html: appSettings.bannerHTML }} />
        </Alert>
    );
};
