import groupBy from 'lodash/groupBy';
import { parseISO } from 'date-fns';
import range from 'lodash/range';
import orderBy from 'lodash/orderBy';
import { getUTCStartOfDayTime } from '../../utils/date';
import type { PlotlineInfo } from '../../types';
import { PlotLine } from '../../components/Chart/PlotLine';

const createPlot = (key: string, timestamp: number, html: string) => ({
    color: '#ccc',
    dashStyle: 'ShortDash',
    label: {
        align: 'left',
        text: html,
        useHTML: true,
        rotation: 0,
        style: {
            color: '#777',
        },
    },
    width: 2,
    key,
    value: timestamp,
});

const getPlots = (...plotInfos: PlotlineInfo[]) => {
    const byDate = groupBy(plotInfos, 'date');
    const dates = Object.keys(byDate).sort();

    return dates.map((date, i) => {
        const ts = getUTCStartOfDayTime(parseISO(date));

        // Rita ut texten för plotlines på separata rader för att undvika att den överlappar
        const prefix = range(-1, i).map(() => '').join('<div><br /></div>');
        const html = orderBy(byDate[date], [x => x.stringVal]).map(x => x.text).join(', ');
        return createPlot(date, ts, prefix + html);
    });
};

export interface PlotLinesProps {
    plotLines: PlotlineInfo[];
}

export const PlotLines = (props: PlotLinesProps) => {
    const plotLines = getPlots(...props.plotLines);
    return <>{plotLines.map(({ key, ...rest }) => <PlotLine key={key} {...rest}/>)}</>;
};
