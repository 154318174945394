import { createSelector } from 'reselect';
import { isBefore, isSameDay, isValid, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { ListItem } from '@cancercentrum/inca';
import type { RowModel } from '@cancercentrum/rcc-react';
import type { OverviewState } from '../../types';
import type { KontaktSubTable } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';

export interface LastEcogResult {
    date: string;
    ecog: ListItem;
}

const tryParseDate = (input: string | null): Date | null => {
    if (!input) {
        return null;
    }

    const d = parseISO(input);

    return isValid(d) ? d : null;
};

const getLatestEcog = (subTable: KontaktSubTable, startdatum: Date | null) => {
    return subTable
        .getRows()
        .filter(x => {
            if (!x || !x.getRegvar('K_ecog').value) {
                return false;
            }

            const besoksdatum = x.getRegvarValue('K_besoksdatum') as string | null;
            if (!besoksdatum) {
                return false;
            }

            const date = parseISO(besoksdatum);
            if (!isValid(date)) {
                return false;
            }

            return !startdatum || isSameDay(date, startdatum) || isBefore(date, startdatum);
        })
        .sortBy(x => x!.getRegvarValue('K_besoksdatum'))
        .last();
};

const getLastKontaktRow = (state: OverviewState, startdatum: string | null): RowModel | undefined => {
    const date = tryParseDate(startdatum);

    return getLatestEcog(state.data.present.getSubTable('Kontakt'), date);
};

export const useGetEcogData = (startdatum: string | null): LastEcogResult | undefined => {
    const selector = useMemo(() => {
        return createSelector(
            [getLastKontaktRow],
            (row) => {
                return row ? {
                    date: (row.getRegvarValue('K_besoksdatum') as string),
                    ecog: (row.getRegvarValue('K_ecog') as ListItem),
                } as LastEcogResult : undefined;
            },
        );
    }, []);

    return useSelector((state: OverviewState) => selector(state as any, startdatum), shallowEqual);
};
