import type { RowModel } from '@cancercentrum/rcc-react';
import { useIncaForm } from '@cancercentrum/rcc-react';
import type { ReactElement } from 'react';
import type { GridColumn } from '../../types';
import type { LabbprovRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { LabUnit } from './LabUnit';
import { LabUnits } from './config';

export const useGetLabprovLabel = () => {
    const inca = useIncaForm();

    return (shortname: string): string => {
        return inca.form.metadata.Labbprov.regvars[shortname].label;
    };
};

export const createLabGridColumn = (options: {
    header: string | ReactElement;
    field: string;
    fallbackValue?: string;
    getStringValue?: (item: RowModel) => string;
}): GridColumn<LabbprovRowModel, string> => {
    const unit = LabUnits[options.field];

    return {
        header: unit ? <span>{options.header} (<LabUnit unit={unit} />)</span> : options.header,
        field: options.field,
        fallbackValue: options.fallbackValue || '-',
        getStringValue: options.getStringValue,
    };
};
