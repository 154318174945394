import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import type { YAxisOptions } from 'highcharts';
import YAxis from './YAxis';

export const CATEGORY_HEIGHT = 25;

type CategoriesAxisProps = {
    categories: string[];
    plotBands?: boolean;
    children: ReactNode;
} & Omit<YAxisOptions, 'categories' | 'plotBands'>;

function CategoriesAxis(props: CategoriesAxisProps) {
    const { categories, plotBands, ...rest } = props;
    const axisProps = useMemo(() => {
        const res: YAxisOptions = {
            type: 'category',
            min: 0,
            max: categories.length - 1,
            title: undefined,
            categories: categories,
            opposite: true,
            height: categories.length * CATEGORY_HEIGHT,
            offset: 0,
            labels: {
                overflow: 'allow',
                useHTML: true,
                format: '<span style="overflow: hidden; display: inline-block; text-overflow: ellipsis; width: 230px;">{value}</span>',
            },
            ...rest,
        };

        if (plotBands) {
            res.plotBands = [{
                color: '#f5f5f5',
                from: -1,
                to: Number.MAX_VALUE,
                zIndex: -1,
            }];
        }
        return res;
    }, [categories, plotBands, rest]);

    return (
        <YAxis {...axisProps} />
    );
}

export default memo(CategoriesAxis);
