import { AjaxErrorAlert } from '@cancercentrum/rcc-react';
import { Alert, Button } from '@cancercentrum/rcc-bootstrap';
import { StaticField } from '../../components/StaticField';
import ExternalStudyRegisterModal from './ExternalStudyRegisterModal';
import type { StudieregisterInfo } from './useStudieregister';
import { getExternalStudyRegistrationState } from './utils.tsx';

export function Studieregister(props: {
    studieregister: StudieregisterInfo;
    modalVisible: boolean;
    hideModal: () => void;
}) {
    const {
        studieregister,
        hideModal,
        modalVisible,
    } = props;
    const studyInfo = studieregister.externalStudyData;
    const extStudyState = getExternalStudyRegistrationState(studieregister.externalStudyData);
    const exclusionReasons = (studieregister.externalStudyData?.data.exklusionsorsak || '').split('\t');

    return (
        <>
            <div className="form-group row">
                <div className="offset-md-3 col-md-9">
                    <div>
                        {modalVisible && !!studieregister.selectedStudie && (
                            <ExternalStudyRegisterModal hide={hideModal} studieregister={studieregister} studie={studieregister.selectedStudie} />
                        )}
                        {!modalVisible && !studyInfo && (
                            <>
                                {studieregister.initForm.error && <AjaxErrorAlert error={studieregister.initForm.error} />}
                                {studieregister.initForm.hasOpened && studieregister.initForm.errand && (
                                    <>
                                        {studieregister.initForm.popupBlocked && (
                                            <div className="alert alert-warning">
                                                Registreringen kunde inte öppnas automatiskt. Klicka på länken nedan för att öppna registreringen.
                                            </div>
                                        )}

                                        En registrering i studieregistret har påbörjats.
                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                        <a href={studieregister.initForm.errand?.selfUrl} target="_blank" rel="opener" className="ms-1">
                                            Klicka här för att öppna registreringen.
                                        </a>
                                    </>
                                )}

                                {!studieregister.initForm.hasOpened && (
                                    <>
                                        {studieregister.externalStudyData && studieregister.externalStudyData.data.inkl_kriterie_samtycke_Värde !== '1' && (
                                            <Alert bsStyle="info">Patienten avböjer deltagande i studien.</Alert>
                                        )}
                                        {!studieregister.externalStudyData && (
                                            <div>
                                                <Alert bsStyle="warning" className="mt-3">
                                                    <p><strong>Patienten har inte registrerats i studieformuläret för denna studie.</strong></p>
                                                    <p className="mb-0">Observera att även information om att patienten inte vill delta görs i den utökade studieregistreringen.</p>
                                                </Alert>

                                                <div className="mt-2">
                                                    <Button bsStyle="primary" onClick={() => studieregister.initForm.open()}>
                                                        Öppna utökad registrering för studien
                                                    </Button>
                                                </div>

                                                <hr />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {!!studyInfo && (
                            <>
                                {extStudyState === 'NO_CONSENT' && (
                                    <Alert bsStyle="info">
                                        Patienten har avböjt att delta i studien.
                                    </Alert>
                                )}
                                {extStudyState === 'NOT_INCLUDED' && (
                                    <Alert bsStyle="info">
                                        <p>Patienten har ej uppfyllt krav för inklusion i studie.</p>
                                        <ul className="mb-0">
                                            {exclusionReasons.map(((x, i) => <li key={i}>{x}</li>))}
                                        </ul>
                                    </Alert>
                                )}

                                {extStudyState === 'INCLUDED' && (
                                    <div>
                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                        <a href={`/RegisterRecord/Details/${studyInfo.data.REGISTERRECORD_ID}`} target="_blank" rel="opener">
                                            Patienten finns registrerad i studieregistret. Klicka här för att öppna registreringen.
                                        </a>

                                        {studieregister.cancelForm.error && <AjaxErrorAlert error={studieregister.cancelForm.error} />}
                                        {studieregister.cancelForm.hasOpened && studieregister.cancelForm.errand && (
                                            <div className="mt-4">
                                                {studieregister.cancelForm.popupBlocked && (
                                                    <div className="alert alert-warning">
                                                        Registreringen kunde inte öppnas automatiskt. Klicka på länken nedan för att öppna registreringen.
                                                    </div>
                                                )}

                                                En registrering för utträde i studieregistret har påbörjats.
                                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                <a href={studieregister.cancelForm.errand?.selfUrl} target="_blank" rel="opener" className="ms-1">
                                                    Klicka här för att öppna registreringen.
                                                </a>
                                            </div>
                                        )}

                                        {!studieregister.cancelForm.hasOpened && !studyInfo.data.datum_avslutad && (
                                            <div>
                                                <p className="mt-4">Avslut av studien görs i studieregistret.</p>
                                                <Button bsStyle="primary" onClick={() => studieregister.cancelForm.open()}>
                                                    Klicka här för att öppna utträdesformuläret
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {!!studieregister.externalStudyData?.data.datum_avslutad && (
                <div>
                    <StaticField
                        label="Slutdatum"
                        value={studieregister.externalStudyData.data.datum_avslutad}
                        labelClassName="col-sm-3"
                        wrapperClassName="col-sm-9"
                    />
                    <StaticField
                        label="Orsak studieavslut"
                        value={studieregister.externalStudyData?.data.orsak_avslutad_annan || studieregister.externalStudyData?.data.orsak_avslutad_Beskrivning}
                        labelClassName="col-sm-3"
                        wrapperClassName="col-sm-9"
                    />
                </div>
            )}
        </>
    );
}
