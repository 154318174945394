import type { Errand, IncaAjaxError } from '@cancercentrum/inca';
import { errandUtils } from '@cancercentrum/inca';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { HookDataActions } from '@cancercentrum/rcc-react';
import { usePromise } from '@cancercentrum/rcc-react';
import { useIPO } from '../../hooks/useIPO';
import type { StudieRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { ExternalStudieVDItem, StudieVDData, StudieVDItem } from './types';
import type { ExternalStudiesResult } from './useExternalStudieData';

type ErrorLike = Error | IncaAjaxError;

interface StudieregisterMessage {
    registerRecordId: number | null;
    errandId: number | null;
    type: string;
    status: {
        code: number;

        unregisterDate?: string;
        unregisterReasonIdentifier?: string;
        unregisterReasonOtherText?: string;
    }
}

export interface ExternalFormInfo {
    open: (studie?: StudieVDItem) => void;
    hasOpened: boolean;
    errand: Errand | null;
    popupBlocked: boolean;
    error: ErrorLike | null;
    shouldHandleMessage: (e: MessageEvent<StudieregisterMessage>) => boolean;
    onMessageHandled: () => void;
}

export interface StudieregisterInfo {
    hasStudieregister: boolean;
    selectedStudie?: StudieVDItem;
    externalStudyData?: ExternalStudieVDItem;

    initForm: ExternalFormInfo;
    cancelForm: ExternalFormInfo;
}

const useExternalForm = (forceUpdate: () => void, formProp: keyof StudieVDData, selectedStudie?: StudieVDItem): ExternalFormInfo => {
    const ipo = useIPO();
    const [hasInitialized, setHasInitialized] = useState(false);
    const winRef = useRef<Window | null>(null);
    const errandRef = useRef<Errand | null>(null);
    const popupBlocked = !!(hasInitialized && errandRef.current && !winRef.current);
    const { error, exec } = usePromise();

    useEffect(() => {
        winRef.current = null;
        errandRef.current = null;
        setHasInitialized(false);
    }, [selectedStudie]);

    const open = useCallback((studie = selectedStudie) => {
        setHasInitialized(true);
        exec(async () => {
            const errand = await errandUtils.createErrandByShortname(studie!.data.registerkortnamn!, studie!.data[formProp]!, ipo.patient.id);
            errandRef.current = errand;
            winRef.current = window.open(errand.selfUrl, '_blank');
            if (winRef.current) {
                winRef.current.focus();
            }
            forceUpdate();
        });
    }, [selectedStudie, forceUpdate, exec, formProp, ipo.patient.id]);

    const shouldHandleMessage = useCallback((e: MessageEvent<StudieregisterMessage>) => {
        return e?.data?.type === 'errandTransition' && e?.data?.errandId === errandRef.current?.id;
    }, []);

    const onMessageHandled = useCallback(() => {
        errandRef.current = null;
        winRef.current = null;

        setHasInitialized(false);
    }, []);

    return {
        error,
        popupBlocked,
        open,
        errand: errandRef.current,
        hasOpened: hasInitialized,
        shouldHandleMessage,
        onMessageHandled,
    };
};

export function useStudieregister(row: StudieRowModel, studier: StudieVDItem[], dataActions: HookDataActions, externalStudiesData: ExternalStudiesResult, reloadExternalStudiesData: () => void): StudieregisterInfo {
    const selectedStudie = row.getRegvar('Stud_studie_vd').findValue(studier) as StudieVDItem | undefined;
    const externalStudyData = externalStudiesData.get(selectedStudie?.id || -1);
    const reloadRef = useRef(reloadExternalStudiesData);

    reloadRef.current = reloadExternalStudiesData;

    const [, setUpdateTrigger] = useState<symbol>(Symbol());
    const forceUpdate = useCallback(() => {
        setUpdateTrigger(Symbol());
    }, []);

    const initFormInfo = useExternalForm(forceUpdate, 'formularkortnamn', selectedStudie);
    const cancelFormInfo = useExternalForm(forceUpdate, 'avslutsformularkortnamn', selectedStudie);

    useEffect(() => {
        const handler = (e: MessageEvent<StudieregisterMessage>) => {
            // console.log('message handler', e.data);
            if (initFormInfo.shouldHandleMessage(e)) {
                initFormInfo.onMessageHandled();

                reloadRef.current();
            } else if (cancelFormInfo.shouldHandleMessage(e)) {
                if (e.data.status.code === 4) {
                    dataActions.updateRow(r => r
                        .setRegvarValue('Stud_stoppdatum', e.data.status.unregisterDate),
                    );
                }
                cancelFormInfo.onMessageHandled();

                reloadRef.current();
            }
        };

        window.addEventListener('message', handler);

        return () => {
            window.removeEventListener('message', handler);
        };
    }, [cancelFormInfo, dataActions, initFormInfo]);

    const hasStudieregister = !!(selectedStudie?.data.registerkortnamn && selectedStudie?.data.formularkortnamn && selectedStudie?.data.avslutsformularkortnamn && selectedStudie?.data.vdnamn);

    return {
        hasStudieregister,
        selectedStudie,
        cancelForm: cancelFormInfo,
        initForm: initFormInfo,
        externalStudyData,
    };
}
