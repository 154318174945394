import type { ReactElement } from 'react';
import { memo, useMemo } from 'react';
import type { RowModel } from '@cancercentrum/rcc-react';
import { HighlightText } from '@cancercentrum/rcc-react';
import { useAtomValue } from 'jotai';
import type { Biverkningsrapportering, BiverkningsrapporteringRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import AddModuleRowButton from '../../AddModuleRowButton';
import ModuleRowPanels from '../../ModuleRowPanels';
import ModuleGrid from '../../ModuleGrid';
import type { GridColumn } from '../../types';
import { lakemedelVdsAtom } from '../lakemedel';
import RowTemplate from './RowTemplate';
import { getBiverkanText, getExtStatusText } from './utils';
import type { CTCAEItem, CTCAETerm, RenderBiverkanCallback, RenderRowCallback } from './types';
import { biverkningarExtAtom } from './useExtBiverkningLoader';
import { useGetSubstanceData } from './useGetSubstanceData';

const defaultRenderRow: RenderRowCallback = (data) => {
    const { dataActions, row, availableSubstanser, ctcae, renderBiverkan } = data;

    return <RowTemplate dataActions={dataActions} row={row} availableSubstanser={availableSubstanser} ctcae={ctcae} renderBiverkan={renderBiverkan} />;
};

const TabContent = (props: {
    renderRow?: RenderRowCallback;
    renderBiverkan?: RenderBiverkanCallback;
    ctcae: CTCAEItem[];
}): JSX.Element => {
    const {
        ctcae,
        renderRow = defaultRenderRow,
        renderBiverkan,
    } = props;
    const usedSubstanser = useGetSubstanceData();
    const lkmAjaxLoader = useAtomValue(lakemedelVdsAtom);
    const allTerms = useMemo(() => {
        return ctcae.reduce<CTCAETerm[]>((red, area) => [...red, ...area.subTables.Term], []);
    }, [ctcae]);
    const { result: bivLoaderResult } = useAtomValue(biverkningarExtAtom);

    const columns: GridColumn<BiverkningsrapporteringRowModel, string>[] = useMemo(() => {
        const substanserById = lkmAjaxLoader.result?.substanserById || {};
        return [
            {
                header: 'Datum',
                field: 'Biv_datum',
                fallbackValue: '-',
                defaultSortOrder: 'desc',
            },
            {
                header: 'Orsak',
                field: 'Biv_orsak',
                fallbackValue: '-',
                getStringValue(row) {
                    const spec = row.getRegvarValue('Biv_orsak_annan');

                    return spec || row.getRegvarValue('Biv_orsak')?.text || '-';
                },
            },
            {
                id: 'BiverkanSubstans',
                header: 'Läkemedel',
                getStringValue(row) {
                    return row.getSubTableRows('BiverkanSubstans').map(x => {
                        return x.getRegvarValue('BivSub_substans_text') || substanserById[x.getRegvarValue('BivSub_substans_vd') as number]?.data.sub_subnamnrek || x.getRegvarValue('BivSub_substans_vd');
                    }).join(', ');
                },
            },
            {
                header: 'Biverkningar',
                id: 'Biverkningar',
                getStringValue(row) {
                    const biverkningar = row.getSubTableRows('Biverkan')
                        .filter(x => x!.getRegvarValue('Bv_ctcae_term_vd') || x!.getRegvarValue('Bv_biverkan_alt'))
                        .map(x => ({ id: x!.getId(), text: getBiverkanText(x!, allTerms) }))
                        .sortBy(x => x!.text);

                    return biverkningar.map(x => x!.text).join('');
                },
                renderCell(data) {
                    const { row, filter } = data;
                    const biverkningar = row.getSubTableRows('Biverkan')
                        .filter(x => x!.getRegvarValue('Bv_ctcae_term_vd') || x!.getRegvarValue('Bv_biverkan_alt'))
                        .map(x => ({ id: x!.getId(), text: getBiverkanText(x!, allTerms) }))
                        .sortBy(x => x!.text);

                    return (
                        <ul className="list-unstyled mb-0">
                            {biverkningar.map(x => (
                                <li key={x!.id}>
                                    <HighlightText search={filter} text={x!.text} />
                                </li>
                            ))}
                        </ul>
                    );
                },
            },
            {
                header: 'Utökad rapport till LV',
                id: 'extended',
                getStringValue(row) {
                    const uuid = row.getRegvarValue('Biv_uuid') as string;
                    const errands = bivLoaderResult?.errands.filter(x => x.data.r_ipo_uuid === uuid) || [];
                    const rr = bivLoaderResult?.registerRecords.find(x => x.data.r_ipo_uuid === uuid);

                    return getExtStatusText(errands, rr) || '';
                },
            },
        ];
    }, [lkmAjaxLoader, allTerms, bivLoaderResult]);

    return (
        <div>
            <div className="mt-3 mb-3">
                <AddModuleRowButton text="Registrera biverkning" />
            </div>

            <ModuleRowPanels<Biverkningsrapportering>
                renderHeader={(data: {
                    row: RowModel;
                }): string => `Biverkningsregistrering ${data.row.getRegvarValue('Biv_datum') || '(datum saknas)'}`}
                renderContent={(data): ReactElement => {
                    const { row, dataActions } = data;

                    return renderRow({
                        dataActions,
                        row,
                        availableSubstanser: usedSubstanser,
                        ctcae: props.ctcae,
                        renderBiverkan,
                    });
                }}
            />

            <ModuleGrid columns={columns} />
        </div>
    );
};

export default memo(TabContent);
