import { memo } from 'react';
import type { HookDataActions } from '@cancercentrum/rcc-react';
import { Card } from '@cancercentrum/rcc-bootstrap';
import type { Biverkan, BiverkanRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { RemoveRowButton } from '../../components/RemoveRowButton';
import { BiverkningPicker } from './BiverkningPicker';
import type { CTCAEItem } from './types';

export const BiverkningRowTemplate = memo(function BiverkningRowTemplate(props: {
    row: BiverkanRowModel;
    dataActions: HookDataActions<Biverkan>;
    ctcae: CTCAEItem[];
    isReadOnly: boolean;
    index: number;
}): JSX.Element {
    const { row, ctcae, dataActions, isReadOnly, index } = props;

    return (
        <Card>
            <Card.Header>Misstänkt biverkning {index + 1}</Card.Header>
            <Card.Body>
                <BiverkningPicker ctcae={ctcae} row={row} />

                {!isReadOnly && index > 0 && (
                    <div className="d-flex flex-row-reverse">
                        <RemoveRowButton onClick={() => dataActions.removeRow()} text="Ta bort biverkning" />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
});
