import { useEffect } from 'react';

export default (className: string): void => {
    useEffect(() => {
        document.body.classList.add(className);

        return () => {
            document.body.classList.remove(className);
        };
    }, [className]);
};
