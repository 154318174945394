import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import isNil from 'lodash/isNil';
import { useDispatch } from 'react-redux';
import { GridActions } from '@cancercentrum/rcc-react';
import { parseISO } from 'date-fns';
import type { PointClickEventObject, YAxisOptions } from 'highcharts';
import { useSetAtom } from 'jotai';
import { generateHexColor } from '../../utils/colorUtils';
import YAxis from '../../components/Chart/YAxis';
import Series from '../../components/Chart/Series';
import { Point } from '../../components/Chart/Point';
import useChartContext from '../../components/Chart/ChartContext/useChartContext';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import { selectedTabAtom } from '../../atoms';
import { formatDecimal } from '../../utils/number.ts';
import { GridId, ModuleName } from './constants';
import { useGetLabprovLabel } from './utils';
import { LabUnits } from './config';
import type { LabprovPickerOption, PickerOption } from './Picker';
import { useGetLabprovSubTable } from './useGetLabprovSubTable';

const LAB_MARKER = {
    symbol: 'circle',
    radius: 4,
};

const ENABLED_LAB_MARKER = {
    symbol: 'circle',
    radius: 4,
    enabled: true,
};

const AXIS_DEFAULT_OPTS = {
    reversed: false,
    height: 150,
};


export interface LabprovExtraRow {
    id: number | string;
    date: string;
    value: number;
    onClick: (e: PointClickEventObject) => void;
}

export interface LabprovAxisProps extends YAxisOptions {
    opposite: boolean;
    labprov?: PickerOption | null;

    extraRows?: LabprovExtraRow[];
    onPointClick?: (shortname: string, e: PointClickEventObject) => any;
    min?: number;
    tickInterval?: number;
    alwaysShowMarkers?: boolean;
    children?: ReactNode;
    yOffset?: number;
}

const specialNoop = () => undefined;

export const LabprovAxis = (props: LabprovAxisProps) => {
    const { children, min: minProp = 0, tickInterval: tickIntervalProp, onPointClick, labprov, extraRows = [], alwaysShowMarkers, ...rest } = props;
    const ctxChart = useChartContext();
    const subTable = useGetLabprovSubTable();
    const dispatch = useDispatch();
    const getLabprovLabel = useGetLabprovLabel();
    const setActiveTab = useSetAtom(selectedTabAtom);

    const labprovRows = subTable.getRows().filter(x => labprov?.type === 'LABPROV' && isValidDate(x.getRegvarValue('Labb_datum')) && !!labprov && !isNil(x.getRegvarValue(labprov.shortname)));

    const min = ctxChart.scale.selected!.value === 'logarithmic' ? 0.01 : minProp;
    const tickInterval = ctxChart.scale.selected!.value === 'logarithmic' ? 1 : tickIntervalProp;

    const color = (labprov && generateHexColor(labprov.shortname)) || '#000';
    const label = (labprov && (labprov.label || getLabprovLabel(labprov.shortname))) || '[namn saknas]';
    const unit = LabUnits[labprov?.shortname ?? ''];
    const title = useMemo(() => ({ text: labprov ? unit ? `${labprov.label} (${unit})` : labprov.label : '', useHTML: true }), [labprov, unit]);
    return (
        <YAxis
            id={`labprov_${props.opposite}`}
            title={title}
            tickInterval={tickInterval}
            type={ctxChart.scale.selected!.value ?? undefined}
            min={min}
            {...AXIS_DEFAULT_OPTS}
            {...rest}
        >
            <Series
                key={labprov?.shortname}
                name={label}
                color={color}
                type="line"
                cursor="pointer"
                showInLegend={false}
            >
                {extraRows.map(r => {
                    const tooltip = (
                        <LegacyTooltip headerText={`${labprov?.label || ''} ${r.date}`}>
                            <div>
                                <div>{r.value}</div>
                            </div>
                        </LegacyTooltip>
                    );
                    const id = `extra_${labprov?.shortname}_${r.id}`;

                    return (
                        <Point
                            key={id}
                            custom={{
                                labRowId: id,
                            }}
                            x={getUTCStartOfDayTime(parseISO(r.date))}
                            y={r.value}
                            marker={alwaysShowMarkers ? ENABLED_LAB_MARKER : LAB_MARKER}
                            renderTooltip={() => tooltip}
                            events={{
                                click(e) {
                                    if ((onPointClick || specialNoop)(labprov!.shortname, e) !== false) {
                                        r.onClick(e);
                                    }
                                },
                            }}
                        />
                    );
                })}

                {labprovRows.map(r => {
                    const sn = (labprov as LabprovPickerOption).shortname;
                    const d = getUTCStartOfDayTime(parseISO(r.getRegvarValue('Labb_datum') || ''));
                    const value = r.getRegvarValue(sn) as number | null;
                    const precision = r.getRegvar(sn).$rcc.regvarMetadata.precision || 0;

                    const tooltip = (
                        <LegacyTooltip headerText={`${labprov!.label} ${r.getRegvarValue('Labb_datum')}`}>
                            <div>
                                <div>{formatDecimal(value, precision)}</div>
                            </div>
                        </LegacyTooltip>
                    );

                    return (
                        <Point
                            key={r.getId()}
                            custom={{
                                labRowId: r.getId(),
                            }}
                            x={d}
                            y={r.getRegvarValue(sn) as number}
                            marker={alwaysShowMarkers ? ENABLED_LAB_MARKER : LAB_MARKER}
                            renderTooltip={() => tooltip}
                            events={{
                                click(e) {
                                    if ((onPointClick || specialNoop)(sn, e) !== false) {
                                        setActiveTab(ModuleName);
                                        dispatch(GridActions.setSelectedItemId(GridId, r.getId()));
                                    }
                                },
                            }}
                        />
                    );
                })}
            </Series>

            {children}
        </YAxis>
    );
};

export default memo(LabprovAxis);
