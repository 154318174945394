import type { ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import { GridActions } from '@cancercentrum/rcc-react';
import type { ListItem } from '@cancercentrum/inca';
import { useSetAtom } from 'jotai';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import { Point } from '../../components/Chart/Point';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import type { StralbehandlingRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';
import { useGetStralbehandlingSubTable } from './useGetStralbehandlingSubTable';

const CATEGORIES = ['Strålbehandling'];

const defaultRenderTooltip = (row: StralbehandlingRowModel) => {
    const indikation = row.getRegvarValue('Stral_indikation') as ListItem | null;

    return (
        <LegacyTooltip headerText={`Strålbehandling ${row.getRegvarValue('Stral_startdatum')}`}>
            <div>
                {!!indikation && <div>Indikation: {indikation.text}</div>}
            </div>
        </LegacyTooltip>
    );
};

export const StralbehandlingAxis = (props: {
    hideIfEmpty?: boolean;
    dateShortname?: 'Stral_startdatum' | 'Stral_slutdatum';
    children?: ReactNode;
    renderTooltip?: (row: StralbehandlingRowModel) => ReactElement;
}): ReactElement => {
    const {
        dateShortname = 'Stral_startdatum',
        renderTooltip = defaultRenderTooltip,
        children,
        hideIfEmpty,
    } = props;
    const subTable = useGetStralbehandlingSubTable();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const dispatch = useDispatch();

    const points = subTable.getRows().toArray().filter(x => isValidDate(x.getRegvarValue(dateShortname))).map(x => {
        const key = x.getId();
        const strDate = x.getRegvarValue(dateShortname)!;
        const date = getUTCStartOfDayTime(parseISO(strDate));

        const marker = {
            symbol: 'circle',
            radius: 6,
            lineWidth: 1,
            lineColor: '#fff',
        };

        return (
            <Point
                key={key}
                x={date}
                y={0}
                color="#000"
                marker={marker}
                renderTooltip={() => renderTooltip(x)}
                events={{
                    click() {
                        setActiveTab(ModuleName);
                        dispatch(GridActions.setSelectedItemId(GridId, x.getId()));
                    },
                }}
            />
        );
    });

    const visible = !hideIfEmpty || points.length > 0;

    return (
        <CategoriesAxis
            categories={CATEGORIES}
            visible={visible}
        >
            <ScatterSeries>
                {points}
            </ScatterSeries>
            {children}
        </CategoriesAxis>
    );
};
