import { Condition, ListButtonGroup } from '@cancercentrum/rcc-react';
import type { ReactElement } from 'react';
import Question from '../../../../components/IPOQuestion';
import type { LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import useDataSet from '../../../../hooks/useDataSet';
import { ShowStopSpecIdentifiers } from '../../constants.ts';
import Kurlangd from './Kurlangd';
import { NumSkippedHelpText } from './RegimRow';

export interface SattUtRegimProps {
    row: LäkemedelRowModel;
    minDate: string;
    numSkipped: number;
}

const RegimSattUt = (props: SattUtRegimProps): ReactElement => {
    const { row, minDate, numSkipped } = props;
    const dataset = useDataSet();

    return (
        <form className="form-horizontal">
            <fieldset>
                <legend>Avsluta behandling</legend>
                <Kurlangd row={row} />
                <Question
                    regvar={row.getRegvar('Lkm_stoppdatum')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    beforeToday={false}
                    required={true}
                    minDate={minDate}
                    help={<NumSkippedHelpText numSkipped={numSkipped} />}
                />
                <Condition if={row.getRegvarValue('Lkm_stoppdatum')}>
                    {!!dataset.Läkemedel?.Lkm_antalCyklerGivna && (
                        <Question
                            regvar={row.getRegvar('Lkm_antalCyklerGivna')}
                            labelClassName="col-md-3"
                            wrapperClassName="col-md-9"
                        />
                    )}
                    <Question
                        regvar={row.getRegvar('Lkm_stopporsak')}
                        labelClassName="col-md-3"
                        wrapperClassName="col-md-9"
                        componentClass={ListButtonGroup}
                        required={true}
                        vertical={true}
                    />

                    <Condition if={ShowStopSpecIdentifiers.includes(row.getRegvarValue('Lkm_stopporsak')?.identifier)}>
                        <div className="followup-questions">
                            <Question
                                regvar={row.getRegvar('Lkm_stopporsakSpec')}
                                className="input-long"
                                labelClassName="col-md-3"
                                wrapperClassName="col-md-9"
                            />
                        </div>
                    </Condition>
                </Condition>
            </fieldset>
        </form>
    );
};

export default RegimSattUt;
