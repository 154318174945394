import type { ReactElement } from 'react';
import { memo } from 'react';
import type { RowModel } from '@cancercentrum/rcc-react';
import { Condition, ListButtonGroup, useDataActions } from '@cancercentrum/rcc-react';
import without from 'lodash/without';
import { Card } from '@cancercentrum/rcc-bootstrap';
import Question from '../../components/IPOQuestion';
import useDataSet from '../../hooks/useDataSet';
import { RemoveRowButton } from '../../components/RemoveRowButton';

export const DosjusteringRow = (props: {
    row: RowModel;
    startdatum?: string | null;
    stoppdatum?: string | null;
}): ReactElement => {
    const { row, startdatum, stoppdatum } = props;

    const dataActions = useDataActions();
    const ds = useDataSet();
    const orsakOptions = row.getRegvarValue('Dj_justering')?.identifier === 'reducerad' ?
        without(ds.Dosjustering!.Dj_orsak!.identifiers, '1') :
        ds.Dosjustering!.Dj_orsak!.identifiers;

    const hasSpec = !!ds.Dosjustering!.Dj_orsakSpec;

    return (
        <Card className="mb-3">
            <Card.Body>
                <Question
                    regvar={row.getRegvar('Dj_datum')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    minDate={startdatum || undefined}
                    maxDate={stoppdatum || undefined}
                    required={true}
                />

                <Question
                    regvar={row.getRegvar('Dj_justering')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    componentClass={ListButtonGroup}
                    required={true}
                />

                <Question
                    regvar={row.getRegvar('Dj_orsak')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    componentClass={ListButtonGroup}
                    required={true}
                    listValues={orsakOptions}
                />
                {hasSpec && (
                    <Condition if={row.getRegvarValue('Dj_orsak')?.identifier === '5'}>
                        <div className="followup-questions">
                            <Question
                                regvar={row.getRegvar('Dj_orsakSpec')}
                                labelClassName="col-md-3"
                                wrapperClassName="col-md-9"
                            />
                        </div>
                    </Condition>
                )}
                <div className="d-flex justify-content-end mt-3">
                    <RemoveRowButton onClick={() => dataActions.removeRow()} text="Ta bort dosjustering" />
                </div>
            </Card.Body>
        </Card>
    );
};

export default memo(DosjusteringRow);
