import type { RowModel } from '@cancercentrum/rcc-react';
import { SubTableList, useDataActions } from '@cancercentrum/rcc-react';
import { AddRowButton } from '../../components/AddRowButton';
import { DosjusteringRow } from './DosjusteringRow';

export const DosjusteringSection = (props: { row: RowModel }) => {
    const { row } = props;
    const dataActions = useDataActions();

    const addHandelse = () => {
        const newRow = row.getSubTable('Dosjustering').createRow();

        dataActions.updateRow(x => x.addSubTableRow(newRow));
    };

    return (
        <fieldset className="mt-4">
            <legend>Dosjusteringar</legend>
            <SubTableList
                subTable={row.getSubTable('Dosjustering')}
                renderSeparator={() => <div />}
                renderRow={data => <DosjusteringRow {...data} startdatum={row.getRegvarValue('Lkm_startdatum')} stoppdatum={row.getRegvarValue('Lkm_stoppdatum')} />}
            />

            <div className="form-group row">
                <div className="offset-md-3 col-md-9">
                    <AddRowButton onClick={addHandelse}>Lägg till dosjustering</AddRowButton>
                </div>
            </div>
        </fieldset>
    );
};
