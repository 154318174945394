import type { WritableAtom } from 'jotai';
import { atom } from 'jotai';
import { useRef } from 'react';

export const selectedSurveyPillAtom = atom<string | null>(null);

/**
 * Atom containing utskicksprojektId and the selected answer row.
 */
export const selectedSvarIdAtom = atom<Record<number, number | null>>({});

/**
 * Atom with get/set of selected answer row for a utskicksprojektId.
 * @param utskicksprojektId
 */
export const useGetSelectedSvarAtom = (utskicksprojektId: number) => {
    const atomRef = useRef<Record<number, WritableAtom<number | null, [number | null], number | null>>>({});

    if (!atomRef.current[utskicksprojektId]) {
        atomRef.current[utskicksprojektId] = atom<number | null, [number | null], number | null>(
            (get) => {
                return get(selectedSvarIdAtom)[utskicksprojektId] || null;
            },
            (get, set, num) => {
                set(selectedSvarIdAtom, x => ({
                    ...x,
                    [utskicksprojektId]: num,
                }));

                return num;
            },
        );
    }

    return atomRef.current[utskicksprojektId];
};
