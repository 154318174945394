import { memo } from 'react';
import type { ReactNode } from 'react';
import type { SeriesScatterOptions } from 'highcharts';
import Series from './Series';


const SERIES_OPTIONS = {
    dataLabels: {
        padding: 0,
        x: 15,
        y: 9,
        enabled: true,
        format: '{point.labelText}',
    },
    showInLegend: false,
    cursor: 'pointer',
};

interface ScatterSeriesProps extends Omit<SeriesScatterOptions, 'type'> {
    children: ReactNode;
}

function ScatterSeries(props: ScatterSeriesProps) {
    const { children, ...rest } = props;

    return (
        <Series<SeriesScatterOptions> {...SERIES_OPTIONS} {...rest} type="scatter">
            {children}
        </Series>
    );
}

export default memo(ScatterSeries);
