import { useDataActions } from '@cancercentrum/rcc-react';
import { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import { shallowEqual, useSelector } from 'react-redux';
import difference from 'lodash/difference';
import type { ValueDomainItem } from '@cancercentrum/inca';
import type { OverviewState } from '../../types';
import type { Basdata } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useGetBasdataResult } from './index';

export const useAutoConnectAllBasdata = (shortName: keyof Basdata['regvars'], basdataName: string): { hasBeenAutomaticallySet: boolean } => {
    const data = useGetBasdataResult<ValueDomainItem>(basdataName);
    const connectedRows = useSelector((state: OverviewState) => state.data.present.getSubTableRows('Basdata').toArray(), shallowEqual);

    const dataActions = useDataActions();
    const [showAuto, setShowAuto] = useState(false);

    useEffect(() => {
        const uniqueIds = uniq(data.map(x => x.id));
        const selectedIds = connectedRows.map(x => x.getRegvarValue(shortName));
        const newConnections = difference(uniqueIds, selectedIds);

        if (newConnections.length) {
            dataActions.updateRow(x => {
                const withConnections = newConnections.reduce((red, id) => {
                    return red.addSubTableRow(
                        red.getSubTable('Basdata').createRow().setRegvarValue(shortName, id),
                    );
                }, x);

                if (uniqueIds.length === 1) {
                    return withConnections.setRegvarValue('selectedVd', uniqueIds[0]);
                }

                return withConnections;
            });
            setShowAuto(true);
        }

    }, [data, dataActions, connectedRows, shortName]);

    return {
        hasBeenAutomaticallySet: showAuto,
    };
};
