import { useContext } from 'react';
import { Button as BsButton } from '@cancercentrum/rcc-bootstrap';
import type { ButtonProps as BsButtonProps } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import { ReadOnlyContext } from './ReadOnlyContext';

type ButtonProps = Omit<BsButtonProps, 'disabled'>;

export const Button = (props: ButtonProps): ReactElement => {
    const isReadOnly = useContext(ReadOnlyContext);

    return <BsButton disabled={isReadOnly} {...props} />;
};
