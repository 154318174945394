import type { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export interface GridDisableRemoveIconProps {
    message: string;
}

export const GridDisabledRemoveIcon = (
    props: GridDisableRemoveIconProps,
): ReactElement => {
    const { message } = props;

    const buttonStyling = {
        fontSize: '1rem',
        cursor: 'help',
        opacity: '0.4',
    };

    {
        return (
            <FontAwesomeIcon
                icon={faTrashAlt}
                title={message}
                fixedWidth={true}
                style={buttonStyling}
            />
        );
    }
};
