import { useDispatch } from 'react-redux';
import type { RowModel } from '@cancercentrum/rcc-react';
import { GridActions } from '@cancercentrum/rcc-react';
import { parseISO } from 'date-fns';
import type { ReactElement } from 'react';
import { useSetAtom } from 'jotai';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import { Point } from '../../components/Chart/Point';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { Tooltip } from '../../components/Chart/Tooltip';
import type { KirurgiRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';
import { useGetKirurgiSubTable } from './useGetKirurgiSubTable';

const DATE = 'Kir_datum';
const INDIKATION = 'Kir_indikation';
const CATEGORIES = ['Kirurgi'];

export interface KirurgiAxisProps {
    renderTooltip?: (row: KirurgiRowModel) => ReactElement;
}

const defaultRenderTooltip = (row: KirurgiRowModel) => {
    return (
        <Tooltip>
            <Tooltip.Header>Kirurgi {row.getRegvarValue(DATE)}</Tooltip.Header>
            <Tooltip.Body>
                <div>Operationsdatum: {row.getRegvarValue(DATE)}</div>
                <div>Indikation: {row.getRegvarValue(INDIKATION)?.text}</div>
            </Tooltip.Body>
        </Tooltip>
    );
};

const KirurgiAxis = (props: KirurgiAxisProps) => {
    const {
        renderTooltip,
    } = props;
    const setActiveTab = useSetAtom(selectedTabAtom);
    const subTable = useGetKirurgiSubTable();
    const dispatch = useDispatch();

    const points = subTable.getRows().filter((x?: RowModel) => isValidDate(x!.getRegvarValue(DATE))).map(x => {
        const row = x!;
        const key = row.getId();
        const date = getUTCStartOfDayTime(parseISO(row.getRegvarValue(DATE)!));
        const marker = {
            symbol: 'circle',
            radius: 6,
            lineWidth: 1,
            lineColor: '#fff',
        };

        return (
            <Point
                key={key}
                x={date}
                y={0}
                color="#000"
                marker={marker}
                renderTooltip={() => (renderTooltip || defaultRenderTooltip)(row)}
                events={{
                    click() {
                        setActiveTab(ModuleName);
                        dispatch(GridActions.setSelectedItemId(GridId, row.getId()));
                    },
                }}
            />
        );
    });

    return (
        <CategoriesAxis categories={CATEGORIES}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};

export default KirurgiAxis;
