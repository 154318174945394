import type { ReactNode } from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { Button, OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import classnames from 'classnames';
import type { RegvarMetadata } from '@cancercentrum/inca';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { useGetTableNameByRegvarFn } from '../../useGetTableNameByRegvarFn';
import RRHistoryContext from './RRHistoryContext';

interface RegvarHistoryProps {
    shortname: string;
    metadata: RegvarMetadata;
    label?: string;
    className?: string;
}

export const RegvarHistory = (props: RegvarHistoryProps): JSX.Element | null => {
    const { shortname, metadata } = props;
    const label = props.label || metadata.label;
    let res = null;
    const ctxRRHistory = useContext(RRHistoryContext);
    const getTableNameByRegvarShortname = useGetTableNameByRegvarFn();
    const tableName = getTableNameByRegvarShortname(shortname);
    const tableHistory = ctxRRHistory && ctxRRHistory[tableName];
    const regvarHistory = tableHistory && tableHistory.filter(x => x.changes && x.changes.regvars && x.changes.regvars[shortname]);

    if (regvarHistory && regvarHistory.length) {
        res = (
            <OverlayTrigger
                rootClose={true}
                trigger="click"
                placement="bottom"
                popperConfig={{ strategy: 'fixed' }}
                overlay={(
                    <Popover id={`popover_history_${shortname}`} className="mw-100">
                        <h3 className="m-0">Ändringshistorik</h3>
                        <div className="fs-5 text-muted mb-2">{label}</div>
                        <table className="table table-responsive table-condensed m-0">
                            <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Användare</th>
                                <th>Tidigare värde</th>
                                <th>Nytt värde</th>
                            </tr>
                            </thead>
                            <tbody>
                            {regvarHistory.map(h => {
                                const date = format(parseISO(h.date), 'yyyy-MM-dd HH:mm');
                                const change = h.changes.regvars[shortname];
                                const formatValue = (val: string | number | boolean | null): ReactNode => {
                                    let formatted = val === null ? '-' : val;

                                    if (metadata.dataType === 'list') {
                                        const match = metadata.listValues.find(x => x.id === val);
                                        if (match) {
                                            formatted = match.text;
                                        }
                                    } else if (metadata.dataType === 'datetime' && val) {
                                        formatted = format(parseISO(val as string), 'yyyy-MM-dd');
                                    } else if (metadata.dataType === 'boolean') {
                                        formatted = val ? 'Ja' : 'Nej';
                                    }

                                    return formatted;
                                };

                                return (
                                    <tr key={h.versionId}>
                                        <td>{date}</td>
                                        <td>{h.savedBy}</td>
                                        <td>{formatValue(change.oldValue)}</td>
                                        <td>{formatValue(change.value)}</td>
                                    </tr>
                                );
                            })}

                            </tbody>
                        </table>
                    </Popover>
                )}
            >
                <Button className={classnames('ms-2 regvar-history', props.className)} bsStyle="link" title="Visa historik"><FontAwesomeIcon icon={faHistory} /></Button>
            </OverlayTrigger>
        );
    }

    return res;
};
