import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { TooltipModeContext } from './TooltipModeContext';

export interface TooltipProps {
    headerText: string;
    color?: string;

    children: ReactNode;
}

/**
 * Tooltip-content som endast renderas på IPÖ.
 */
export const IpoSection = (props: {
    children: ReactNode;
}): JSX.Element | null => {
    const { children } = props;
    const mode = useContext(TooltipModeContext);
    if (mode !== 'ipo') {
        return null;
    }

    return <>{children}</>;
};

/**
 * Tooltip-content som endast renderas på e-tjanster.
 */
export const EtjansterSection = (props: {
    children: ReactNode;
}): JSX.Element | null => {
    const { children } = props;
    const mode = useContext(TooltipModeContext);
    if (mode !== 'e-tjanster') {
        return null;
    }

    return <>{children}</>;
};

/**
 * Legacy tooltip. Change to Tooltip, Tooltip.Header and Tooltip.Body instead.
 */
export const LegacyTooltip = (props: TooltipProps) => {
    const { headerText, color, children } = props;

    return (
        <>
            <div className="tooltip-header">
                {headerText} {!!color && <FontAwesomeIcon icon={faCircle} color={color} />}
            </div>
            <div className="tooltip-content">
                {children}
            </div>
        </>
    );
};

const TooltipHeader = (props: {
    children: ReactNode;
}) => {
    const { children } = props;

    return (
        <div className="tooltip-header">{children}</div>
    );
};

const TooltipBody = (props: {
    children: ReactNode;
}) => {
    const { children } = props;

    return (
        <div className="tooltip-content">{children}</div>
    );
};

export const Tooltip = (props: {
    children: ReactNode;
}) => {
    const { children } = props;

    return (
        <div className="ipo-tooltip">
            {children}
        </div>
    );
};

Tooltip.Header = TooltipHeader;
Tooltip.Body = TooltipBody;
