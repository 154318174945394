import { useEffect, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { isEqualExclFunctions } from './utils';
import useChartContext from './ChartContext/useChartContext';

export const PlotLine = (props: {
    id?: string;
    [key: string]: any;
}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const id = useMemo(() => props.id || uniqueId('PlotLine_'), []);
    const ctxChart = useChartContext();
    const prevProps = useRef(props);

    useEffect(() => {
        ctxChart.addPlotLine({
            ...props,
            id,
        });

        return () => {
            ctxChart.removePlotLine(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (prevProps.current && !isEqualExclFunctions(props, prevProps.current)) {
            ctxChart.updatePlotLine(id, {
                ...props,
                id,
            });
        }

        prevProps.current = props;
    });

    return null;
};
