import { useDispatch } from 'react-redux';
import type { RowModel } from '@cancercentrum/rcc-react';
import { GridActions } from '@cancercentrum/rcc-react';
import type { ReactElement } from 'react';
import { useSetAtom } from 'jotai';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import { Point } from '../../components/Chart/Point';
import { getUTCStartOfDayTime } from '../../utils/date';
import { selectedTabAtom } from '../../atoms';
import { ModuleName } from './constants';
import type { SerieData } from './types';

export interface AdditionalInfoScatterSeriesDataBase {
    id: string;
    date: Date;
    tooltip: ReactElement;
    color: string;
    row: RowModel;
}

export interface AdditionalInfoScatterSeriesBulkData extends AdditionalInfoScatterSeriesDataBase {
    y: number;
}

export interface AdditionalInfoScatterSeriesOptionsBaseProps {
    categories: string[];
    lakemedelSeries: SerieData[];

    options: {
        symbol?: string;
        getMarker?: (data: AdditionalInfoScatterSeriesDataBase, opts: AdditionalInfoScatterSeriesOptionsBaseProps['options']) => Highcharts.PointMarkerOptionsObject;
        color?: string;
        fillColor?: string;
        moduleName?: string;
        gridId?: string;
    }
}

export interface AdditionalInfoScatterSeriesOptionsBulkProps extends AdditionalInfoScatterSeriesOptionsBaseProps {
    dataSelector: (items: SerieData[], categories: string[]) => AdditionalInfoScatterSeriesBulkData[];
}

const usePointGetter = (options: AdditionalInfoScatterSeriesOptionsBulkProps['options'], dataSelector: AdditionalInfoScatterSeriesOptionsBulkProps['dataSelector']) => {
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const defaultMarker = {
        symbol: options.symbol,
        radius: 10,
        lineWidth: 3,
        fillColor: options.fillColor || options.color,
        lineColor: options.color,
        states: {
            hover: {
                fillColor: options.color,
                lineColor: options.color,
            },
        },
    };

    return (items: SerieData[], categories: string[]) => {
        const data = dataSelector(items, categories);

        return data.map(d => (
            <Point
                key={d.id}
                x={getUTCStartOfDayTime(d.date)}
                y={d.y}
                marker={options?.getMarker?.(d, options) || defaultMarker}
                renderTooltip={() => d.tooltip}
                events={{
                    click() {
                        setActiveTab(options.moduleName ?? ModuleName);
                        if (options.gridId && d.row) {
                            dispatch(GridActions.setSelectedItemId(options.gridId, d.row.getId()));
                        }
                    },
                }}
            />
        ));
    };
};

const AdditionalInfoScatterSeriesBulk = (props: AdditionalInfoScatterSeriesOptionsBulkProps) => {
    const { lakemedelSeries, options, categories, dataSelector } = props;
    const getPoints = usePointGetter(options, dataSelector);
    return (
        <ScatterSeries zIndex={100}>
            {getPoints(lakemedelSeries, categories)}
        </ScatterSeries>
    );
};

export default AdditionalInfoScatterSeriesBulk;
