import type { ReactElement } from 'react';
import HTab from '../../../components/HTabs/HTab';
import PdlTabContent from '../../pdl/PdlTabContent';
import { DisplayName, ModuleName } from './constants';

export const BasdataTab = (): ReactElement => {
    return (
        <HTab eventKey={ModuleName} header={DisplayName}>
            <div className="mt-4 mb-4">
                <PdlTabContent/>
            </div>
        </HTab>
    );
};

export default BasdataTab;
