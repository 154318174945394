import { createSelectorCreator, lruMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

const equals = (a: any, b: any) => {
    if (a && a.equals) {
        return a.equals(b) || isEqual(a, b);
    }

    return isEqual(a, b);
};

export const createSelector = createSelectorCreator(lruMemoize, equals);
