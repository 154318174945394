import { useContext, useEffect, useState } from 'react';
import { useDataActions } from '@cancercentrum/rcc-react';
import { formatISO, parseISO } from 'date-fns';
import Question from '../../components/IPOQuestion';
import type { StudieRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { ReadOnlyContext } from '../../components/ReadOnlyContext';
import type { StudieVDItem } from './types';
import { SelectStudie } from './SelectStudie';
import { useStudieregister } from './useStudieregister';
import { Studieregister } from './Studieregister';
import type { ExternalStudiesResult } from './useExternalStudieData';
import { getExternalStudyRegistrationState } from './utils.tsx';

const dateWithoutTime = (input: string | null | undefined) => {
    if (!input) {
        return null;
    }

    return formatISO(parseISO(input), { representation: 'date' });
};

const RowTemplate = (props: {
    row: StudieRowModel;
    vdStudier: StudieVDItem[];
    externalStudiesData: ExternalStudiesResult
    reloadExternalStudiesData: () => void;

    showStoppdatum?: boolean;
}) => {
    const { row, vdStudier, showStoppdatum, externalStudiesData, reloadExternalStudiesData } = props;
    const dataActions = useDataActions();
    const isRowReadOnly = useContext(ReadOnlyContext);
    const studieregister = useStudieregister(row, vdStudier, dataActions, externalStudiesData, reloadExternalStudiesData);
    const { externalStudyData } = studieregister;
    const startdatumLabel = (externalStudyData && !externalStudyData.data.inklusion_screening_dat) ? 'Datum' : row.getRegvar('Stud_startdatum').$rcc.regvarMetadata.label;
    const [studieModalVisible, setStudieModalVisible] = useState(false);
    const onStudieChange = (id: number, studie: StudieVDItem) => {
        const extData = externalStudiesData.get(studie?.id || -1);

        // Open modal unless the patient already is registered.
        // Possible race condition here if the external ajax data isn't loaded yet. Worst case is a message in the external
        // register that the patient already is registered. If so IPO will sync data as soon as the ajax request is done.
        if (studie?.data.registerkortnamn && studie?.data.formularkortnamn && !extData) {
            setStudieModalVisible(true);
        }
    };
    useEffect(() => {
        const extData = externalStudyData;
        if (!isRowReadOnly && extData) {
            dataActions.updateRow(x => {
                let res = x;
                if (extData.data.inklusion_screening_dat) {
                    res = res.setRegvarValue('Stud_startdatum', dateWithoutTime(extData.data.inklusion_screening_dat));
                }

                // För externa studier ska stoppdatum hämtas och visas från VD. Av historiska skäl har vi dock synkat över värdet.
                if (showStoppdatum) {
                    res = res.setRegvarValue('Stud_stoppdatum', extData.data.datum_avslutad);
                }

                return res;
            });
        }
    }, [isRowReadOnly, externalStudyData, dataActions, showStoppdatum]);
    const hideStudieregisterModal = () => {
        setStudieModalVisible(false);
    };
    const extStudyState = getExternalStudyRegistrationState(externalStudyData);
    const shouldShowStoppdatum = showStoppdatum && (!studieregister.hasStudieregister || extStudyState === 'INCLUDED');

    return (
        <div>
            <SelectStudie
                onChange={onStudieChange}
                row={row}
                studier={vdStudier}
            />

            <Question
                regvar={row.getRegvar('Stud_startdatum')}
                labelClassName="col-sm-3"
                wrapperClassName="col-sm-9"
                required={true}
                label={startdatumLabel}
            />

            {studieregister.hasStudieregister && (
                <Studieregister
                    studieregister={studieregister}
                    hideModal={hideStudieregisterModal}
                    modalVisible={studieModalVisible}
                />
            )}

            {shouldShowStoppdatum && !studieregister.hasStudieregister && (
                <Question
                    regvar={row.getRegvar('Stud_stoppdatum')}
                    labelClassName="col-sm-3"
                    wrapperClassName="col-sm-9"
                    minDate={(!studieregister.hasStudieregister && row.getRegvarValue('Stud_startdatum')) || undefined}
                    disabled={!!externalStudyData}
                />
            )}
        </div>
    );
};

export default RowTemplate;
