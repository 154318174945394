import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { getAllFollowUpQuestions, getValueAsText } from '../utils';
import type { QuestionMetadata, SeverityGrade as SG, SummaryField, SummaryFieldCondition, SummaryFieldRegvar, SurveyAnswer, SurveyAnswerWithUtskicksprojekt, Utskicksprojekt } from '../types';
import { MarkdownQuestion } from './MarkdownQuestion';
import { AnswerColumnHeader } from './AnswerColumnHeader.tsx';

export const SeverityGrade = (props: {
    grade: SG;
    answers: SurveyAnswerWithUtskicksprojekt[];
    utskicksprojekt: Utskicksprojekt;
    showSurveyVersion: boolean;
    showProjectName: boolean;
}) => {
    const { answers, grade, utskicksprojekt, showSurveyVersion, showProjectName } = props;
    const metadata = utskicksprojekt.survey;
    const config = utskicksprojekt.config;
    const { hiddenQuestions, followupQuestions = {}, severityGrades: allGrades, summaryFields } = config;
    const mainAnswer = answers[0];

    const regvarSummaryFields = summaryFields.filter((x: SummaryField): x is SummaryFieldRegvar => 'shortname' in x);
    const conditionSummaryFields = summaryFields.filter((x: SummaryField): x is SummaryFieldCondition => 'condition' in x);
    const allFollowupQuestions = getAllFollowUpQuestions(config);

    const questions = Object.keys(metadata.questions).filter(sn => {
        const isHidden = !!hiddenQuestions?.includes(sn);
        const isSummaryField = regvarSummaryFields.some(summ => summ.shortname === sn && !summ.showInGradeGroup);
        const isFollowupQuestion = allFollowupQuestions.includes(sn);
        return !isHidden && !isSummaryField && !isFollowupQuestion && grade.evaluateAnsweredQuestion(sn, mainAnswer.data[sn], metadata.questions[sn]);
    });

    const renderAnswer = (shortname: string, question: QuestionMetadata, ans: SurveyAnswer) => {
        const severityGrade = allGrades.find(gr => gr.evaluateAnsweredQuestion(shortname, ans.data[shortname], question));

        return (
            <td key={ans.id}>
                {!!severityGrade && (
                    <div className="d-flex">
                        <span>
                            {!!severityGrade.color && <FontAwesomeIcon icon={faCircle} color={severityGrade.color} className="me-1" />}
                        </span>
                        <span>
                            {config.answerFormatter ? config.answerFormatter({
                                answer: ans,
                                question,
                                surveyMetadata: metadata,
                                defaultFormatter: getValueAsText,
                            }) : getValueAsText(ans, question, metadata)}
                        </span>
                    </div>
                )}
            </td>
        );
    };
    return (
        <table className="table table-striped mt-5 mb-5 survey-table">
            <thead>
            <tr>
                <th className="question-col">
                    {!!grade.color && <FontAwesomeIcon icon={faCircle} color={grade.color} />} {grade.name}
                </th>
                {answers.map(x => (
                    <th key={x.id} className="answer-col">
                        <AnswerColumnHeader
                            answer={x}
                            utskicksprojekt={utskicksprojekt}
                            showSurveyVersion={showSurveyVersion}
                            showProjectName={showProjectName}
                        />
                    </th>
                ))}
                <th />
            </tr>
            </thead>
            <tbody>
            {!questions.length && (
                <tr>
                    <td colSpan={2 + answers.length}>Inga frågor besvarade i denna kategori.</td>
                </tr>
            )}
            {questions.map(sn => {
                const question = metadata.questions[sn];
                return [
                    <tr key={sn} data-shortname={sn}>
                        <td>
                            <MarkdownQuestion text={question.label} />
                        </td>
                        {answers.map(ans => renderAnswer(sn, question, ans))}
                        <td />
                    </tr>,
                    ...(followupQuestions[sn] || []).map(followupQuestionShortname => {
                        const followupQuestion = metadata.questions[followupQuestionShortname];
                        return (
                            <tr key={followupQuestionShortname} data-shortname={followupQuestionShortname} className="followup-question">
                                <td>
                                    <MarkdownQuestion text={followupQuestion.label} />
                                </td>
                                {answers.map(ans => renderAnswer(followupQuestionShortname, followupQuestion, ans))}
                                <td />
                            </tr>
                        );
                    }),
                ];
            })}
            {conditionSummaryFields.filter(s => s.gradeGroup === grade.name).map((s, i) => {
                return (
                    <tr key={s.label || i}>
                        <td>{s.label}</td>
                        {answers.map(ans => {
                            if (!s.condition(ans, metadata)) {
                                return (
                                    <td key={ans.id} />
                                );
                            }

                            const res = s.formatter(ans, metadata);
                            return (
                                <td key={ans.id}>
                                    {res.icon} {res.text}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};
