import type { QuestionMetadata, SurveyList, SurveyMetadata } from '../types';
import { MarkdownQuestion } from './MarkdownQuestion';

const Svarsalternativ = (props: {
    question: QuestionMetadata;
    list?: SurveyList;
}) => {
    const { question, list } = props;

    switch (question.dataType) {
        case 'number': {
            return <span>Nummer</span>;
        }

        case 'string': {
            return <span>Text</span>;
        }

        case 'date': {
            return <span>Datum</span>;
        }
    }

    if (question.dataType === 'list' && list) {
        return (
            <ul className="list-unstyled mb-0">
                {list.listValues.map((x) => {
                    return <li key={x.id}>{x.text}</li>;
                })}
            </ul>
        );
    }

    return <span>?</span>;
};

export const SurveyInfo = (props: {
    surveyMetadata: SurveyMetadata;
}) => {
    const { surveyMetadata } = props;

    return (

        <div>
            <h4>Enkätfrågor</h4>
            <p>Nedan är en lista på samtliga frågor som ingår i enkäten. Frågorna visas inte nödvändigtvis i den ordning patienten besvarar dem.</p>

            <table className="table w-auto">
                <thead>
                <tr>
                    <th>Fråga</th>
                    <th>Svarsalternativ</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(surveyMetadata.questions).map(([shortname, question]) => {
                    return (
                        <tr key={shortname}>
                            <td><MarkdownQuestion text={question.label} /></td>
                            <td><Svarsalternativ question={question} list={surveyMetadata.lists[question.listUuid || '']} /></td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};
