import type { RowModel, RowModelTableMetadata } from '@cancercentrum/rcc-react';
import { Explanation, TabTitleSubTable } from '@cancercentrum/rcc-react';
import type Immutable from 'immutable';
import type { ReactElement, ReactNode } from 'react';
import { isDirty } from '../utils/rowmodel';

export interface StandardTabHeaderProps<T extends RowModelTableMetadata> {
    rows: Immutable.List<RowModel<T>>;
    displayName: ReactNode;
    rowFilter?: (val: RowModel<T>) => boolean;
    className?: string;
}

const defaultRowFilter = () => true;

export const StandardTabHeader = <T extends RowModelTableMetadata>(props: StandardTabHeaderProps<T>): ReactElement => {
    const {
        rows,
        displayName,
        rowFilter = defaultRowFilter,
        className,
    } = props;
    const dirty = rows.some(x => isDirty(x!));

    const title = (
        <>
            {dirty && (
                <span className="me-1">
                    <Explanation>Innehåller osparade ändringar</Explanation>
                </span>
            )}
            {displayName}
        </>
    );

    return (
        <TabTitleSubTable rows={rows.filter((x) => !x!._destroy && rowFilter(x!))} title={title} className={className} />
    );
};
