import { useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { DropdownList, useRegvar } from '@cancercentrum/rcc-react';
import type { RegvarValueDomainMetadata } from '@cancercentrum/inca';
import { substansFormatter } from '../../formatters';
import type { HierarchifiedSubstansItem, UtvaldSubstansVDItem } from '../../types';

const LakemedelPicker = (props: {
    vdSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
    showChosenSubtances: boolean;
    regvar: RegvarModel<RegvarValueDomainMetadata>;
    disabled: boolean;
    startdatum: string | null;
    id?: string;
    required?: boolean;
}) => {
    const { vdSubstanser, vdUtvaldaSubstanser, showChosenSubtances, regvar, disabled, startdatum } = props;
    const id = useMemo(() => {
        return props.id || uniqueId('LakemedelPicker_');
    }, [props.id]);
    const [setValue] = useRegvar(regvar, props);
    const topLevelSubstances = useMemo(() => vdSubstanser.filter(x => !x.data.sub_barn), [vdSubstanser]);
    const value = regvar.value;
    let searchableSubstances: (HierarchifiedSubstansItem | UtvaldSubstansVDItem)[] = topLevelSubstances;

    if (showChosenSubtances) {
        searchableSubstances = startdatum ? vdUtvaldaSubstanser.filter(x => x.id === value || ((!x.data.datumFrom || x.data.datumFrom <= startdatum) && (!x.data.datumTom || x.data.datumTom >= startdatum))) : vdUtvaldaSubstanser;
    }

    const onChange = (val: number | null) => {
        setValue(val, undefined);
    };

    return (
        <div className="lakemedel-dropdown">
            <DropdownList
                id={id}
                disabled={disabled}
                value={value}
                onChange={onChange}
                options={searchableSubstances}
                formatter={substansFormatter}
            />
        </div>
    );
};

export default LakemedelPicker;
