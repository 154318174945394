import type { MouseEvent } from 'react';
import { type DataState, GridActions, useDataActions } from '@cancercentrum/rcc-react';
import type { ListItem } from '@cancercentrum/inca';
import { Alert } from '@cancercentrum/rcc-bootstrap';
import { useDispatch, useStore } from 'react-redux';
import { useSetAtom } from 'jotai';
import { selectedTabAtom } from '../../atoms.ts';
import { GridId as BiverkningGridId, ModuleName as BiverkningModuleName } from '../biverkan/constants.ts';

export const RegisterSideEffectAlert = (props: {
    stralUuid: string;
}) => {
    const { stralUuid } = props;
    const dataActions = useDataActions();
    const dispatch = useDispatch();
    const store = useStore<{
        data: DataState;
    }>();
    const setActiveTab = useSetAtom(selectedTabAtom);

    const createSideEffect = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        let newRow = store.getState().data.present
            .getSubTable('Biverkningsrapportering')
            .createRow()
            .setRegvarValue('Biv_orsak', (x: ListItem) => x.identifier === 'stralbehandling')
            .setRegvarValue('Biv_stral_uuid', stralUuid);

        const biv = newRow.getSubTable('Biverkan').createRow();
        newRow = newRow.addSubTableRow(biv);

        dataActions.updateRow(x => x.addSubTableRow(newRow), { path: [] });

        dispatch(GridActions.setSelectedItemId(BiverkningGridId, newRow.getId()));

        setActiveTab(BiverkningModuleName);
    };

    return (
        <Alert className="mt-4 mb-0">
            Du har satt up p.g.a. toxicitet. <a href="" className="alert-link" onClick={createSideEffect}>Klicka här</a> för att registrera en biverkning.
        </Alert>
    );
};
