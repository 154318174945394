import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        senastKontrollerad: {},
        komplettLkmAdjuvant: {},
        komplettLkmPalliativ: {},
        selectedVd: {},
    },
    Läkemedel: {
        Lkm_behandlingsintention: {
            identifiers: ['1', '2'],
        },
        Lkm_beredning: {
            identifiers: ['per_os', 'parenteral', 'hormonspiral'],
        },
        Lkm_startdos_lista: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
    },
    Dosjustering: {
        Dj_datum: {},
        Dj_justering: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Dj_orsak: {
            identifiers: ['enligt_plan', '2', '1', '3', '5'],
        },
    },
};

export default dataset;
