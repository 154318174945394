import { Explanation, type HookDataActions, useClearable, Validation, ValidationGroup } from '@cancercentrum/rcc-react';
import Select from 'react-select';
import { useAtomValue } from 'jotai';
import type { BiverkanSubstansSubTable, Biverkningsrapportering } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { lakemedelVdsAtom } from '../lakemedel';
import type { AvailableSubstansItem } from './types';

const LakemedelExplanation = (
    <div>
        <p>Ange det/de läkemedel du misstänker orsakat biverkningen.</p>
        <p className="mb-0">Endast läkemedel insatta före startdatumet för misstänkt biverkning visas i listan.</p>
    </div>
);

export const BiverkningSubstanceSelector = (props: {
    dataActions: HookDataActions<Biverkningsrapportering>;
    disabled: boolean;
    availableSubstanser: AvailableSubstansItem[];
    subTable: BiverkanSubstansSubTable;
    biverkanDate: string | null;
}): JSX.Element => {
    const { dataActions, availableSubstanser, disabled, subTable, biverkanDate } = props;
    const substanserById = useAtomValue(lakemedelVdsAtom).result?.substanserById || {};

    useClearable(row => {
        return row.clearSubTable('BiverkanSubstans');
    });

    const onChange = (newValue: readonly AvailableSubstansItem[]) => {
        dataActions.updateRow(storedRow => {
            let res = storedRow;

            // Ta bort de som inte längre ska vara valbara.
            res = res.removeSubTableRows('BiverkanSubstans', x => {
                return !newValue.some(val => (val.type === 'vd' && val.substanceId === x.getRegvarValue('BivSub_substans_vd')) || (val.type === 'name' && val.substanceText === x.getRegvarValue('BivSub_substans_text')));
            });

            return newValue.reduce((red, val) => {
                if (red.getSubTableRows('BiverkanSubstans').some(x => (val.type === 'vd' && val.substanceId === x.getRegvarValue('BivSub_substans_vd')) || (val.type === 'name' && val.substanceText === x.getRegvarValue('BivSub_substans_text')))) {
                    return red;
                }

                let newRow = red.getSubTable('BiverkanSubstans').createRow();

                if (val.type === 'vd') {
                    newRow = newRow.setRegvarValue('BivSub_substans_vd', val.substanceId);
                } else if (val.type === 'name') {
                    newRow = newRow.setRegvarValue('BivSub_substans_text', val.substanceText);
                }

                return red.addSubTableRow(newRow);
            }, res);
        });
    };

    const [allAvailableSubstances, selectedValues] = subTable.getRows().toArray().reduce<[AvailableSubstansItem[], AvailableSubstansItem[]]>((acc, r) => {
        const [all, selected] = acc;

        const subId = r.getRegvarValue('BivSub_substans_vd');

        // Endast satt om koppling till substans saknas, dvs om det är ett studieläkemedel.
        const subText = r.getRegvarValue('BivSub_substans_text');

        let match = subId ? all.find(x => x.type === 'vd' && x.substanceId === subId) : all.find(x => x.type === 'name' && x.substanceText === subText);

        // Ovanligt, men detta kan uppstå om ett läkemedel på läkemedelsfliken har tagits bort efter biverkningen skapats.
        if (!match) {
            match = subId
                ? {
                    type: 'vd',
                    id: r.getId().toString(),
                    substanceId: subId,
                    substanceText: substanserById[subId]?.data.sub_subnamnrek || `substans-id ${subId}`,
                    startdatum: '0001-01-01',
                }
                : {
                    type: 'name',
                    id: r.getId().toString(),
                    substanceText: subText || 'Uppgift saknas',
                    startdatum: '0001-01-01',
                };
            all.push(match);
        }

        selected.push(match);

        return [all, selected];
    }, [[...availableSubstanser], []]);

    const selectableSubstances = allAvailableSubstances.filter(x => !selectedValues.includes(x) && (!biverkanDate || x.startdatum <= biverkanDate));
    const invalidValues = biverkanDate ? selectedValues.filter(x => x.startdatum && x.startdatum > biverkanDate) : [];
    const label = 'Misstänkta läkemedel';
    const errNoSubtance = 'Minst ett läkemedel måste läggas till.';
    const errInvalidSubstances = `Följande läkemedel sattes in efter datumet för misstänkt biverkning: ${invalidValues.map(x => x.substanceText).join(', ')}.`;

    return (
        <div>
            <ValidationGroup name="bivsub">
                <div className="form-group row">
                    <label className="form-label col-form-label pt-2 col-md-3">{label} <Explanation>{LakemedelExplanation}</Explanation></label>
                    <div className="col-md-9">
                        <Select<AvailableSubstansItem, true>
                            value={selectedValues}
                            onChange={onChange}
                            getOptionLabel={x => x.substanceText}
                            getOptionValue={x => x.id}
                            isMulti={true}
                            options={selectableSubstances}
                            className="biverkan-multi-select"
                            classNamePrefix="biverkan-select"
                            placeholder="Klicka och välj ett eller flera misstänkta läkemedel"
                            isDisabled={disabled}
                            noOptionsMessage={() => 'Inga valbara läkemedel'}
                        />
                        <Validation isValid={selectedValues.length > 0} errorProps={{ name: label, message: errNoSubtance }}>
                            {!selectedValues.length && <span>{errNoSubtance}</span>}
                        </Validation>
                        <Validation isValid={!invalidValues.length} errorProps={{ name: label, message: errInvalidSubstances }}>
                            {!!invalidValues.length && <span>{errInvalidSubstances}</span>}
                        </Validation>
                    </div>
                </div>
            </ValidationGroup>
        </div>
    );
};
