import type { ReactElement } from 'react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { RowModel, RowModelTableMetadata } from '@cancercentrum/rcc-react';
import { Explanation } from '@cancercentrum/rcc-react';
import { useModuleContext } from './ModuleContext';
import IPOSubTableGrid from './components/IPOSubTableGrid';
import { isDirty } from './utils/rowmodel';
import ReadOnlyIconButton from './modules/pdl/ReadOnlyIconButton';
import type { GridColumn, OverviewState } from './types';
import { useIPO } from './hooks/useIPO';
import type { ExternalStudiesResult } from './modules/studie';

export interface ModuleGridProps<SubTableMetadata extends RowModelTableMetadata> {
    columns: GridColumn<RowModel<SubTableMetadata>, string>[];
    dateColumnIndex?: number;
    renderHeader?: any;
    allowEditingForAllPositions?: boolean;
    externalStudiesData?: ExternalStudiesResult;

    /**
     * Defines default sort. If not set default sorting is based on columns' 'defaultSortOrder' prop.
     */
    defaultSortFn?: (a: RowModel<SubTableMetadata>, b: RowModel<SubTableMetadata>) => number;
}

const ModuleGrid = <SubTableMetadata extends RowModelTableMetadata>(props: ModuleGridProps<SubTableMetadata>): ReactElement => {
    const {
        columns,
        dateColumnIndex = 0,
        renderHeader,
        allowEditingForAllPositions,
        defaultSortFn,
        externalStudiesData,
    } = props;
    const ctx = useModuleContext();

    if (!ctx.tableName || !ctx.gridId) {
        throw new Error('The module context is missing tableName and gridId.');
    }

    const selector = useCallback((state: OverviewState) => state.data.present.getSubTable(ctx.tableName as any), [ctx.tableName]);
    const subTable = useSelector(selector);
    const ipo = useIPO();
    const allColumns: GridColumn<RowModel<SubTableMetadata>, string>[] = useMemo(() => {
        return [
            {
                id: 'row_status',
                searchable: false,
                sortable: false,
                tdProps: {
                    className: 'nostretch',
                },
                renderCell(data) {
                    const { row } = data;
                    if (isDirty(row)) {
                        return <Explanation>Raden har osparade ändringar.</Explanation>;
                    }

                    if (!allowEditingForAllPositions && row.$extra.dataRowOwnerPositionId !== ipo.currentUser.position.id) {
                        const dateCol = columns[dateColumnIndex];
                        let date;

                        if (dateCol.getStringValue) {
                            date = dateCol.getStringValue(row);
                        } else if (dateCol.field) {
                            date = row.getRegvarValue(dateCol.field);
                        }

                        let title = ctx.displayName;
                        if (date) {
                            title += ` ${date}`;
                        }

                        return <ReadOnlyIconButton dataRowOwnerId={row.$extra.dataRowOwnerPositionId} messageTitle={title} />;
                    }

                    return null;
                },
            },
            ...columns,
        ];
    }, [columns, allowEditingForAllPositions, ctx.displayName, dateColumnIndex, ipo.currentUser.position.id]);

    return (
        <IPOSubTableGrid<SubTableMetadata>
            allowEditingForAllPositions={allowEditingForAllPositions}
            gridId={ctx.gridId}
            subTable={subTable}
            columns={allColumns}
            dateColumn={columns[dateColumnIndex]}
            renderHeader={renderHeader}
            defaultSortFn={defaultSortFn}
            externalStudiesData={externalStudiesData}
        />
    );
};

export default ModuleGrid;
