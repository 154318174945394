import { Fragment } from 'react';
import type { ReactElement } from 'react';
import { Section } from './index';
import type { TextRepresentation } from './index';

export  const GroupedSection = (props: {
    data: TextRepresentation[],
    header: string;
}): ReactElement | null => {
    const { header, data } = props;

    if (!data.length) {
        return null;
    }

    return (
        <Section header={header}>
            {data.map((x, i) => {
                if (x.header) {
                    return (
                        <Fragment key={i}>
                            <div>{x.header}.</div>
                            {x.rows.map((r, ri) => <div key={ri} className="ms-4">{r}.</div>)}
                        </Fragment>
                    );
                }

                return x.rows.map((r, ri) => <div key={ri}>{r}.</div>);
            })}
        </Section>
    );
};
