import type { ReactElement } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { isValidDate } from '../../utils/date';
import { LegacyTooltip } from '../../components/Chart/Tooltip';
import { useGetKontaktSubTable } from './useGetKontaktSubTable';
import type { PointDef } from './SjukdomsstadiumPoint';
import SjukdomsstadiumPoint from './SjukdomsstadiumPoint';

const CATEGORIES = ['Sjukdomsstadium'];

export const SjukdomsstadiumAxis = (): ReactElement => {
    const subTable = useGetKontaktSubTable();
    const relevantRows = subTable.getRows().toArray().reduce<PointDef[]>((red, row) => {
        const date = row.getRegvarValue('K_besoksdatum') as string;
        const stadium = row.getRegvarValue('K_sjukdomsstadium') as ListItem | null;
        if (!isValidDate(date) || !stadium) {
            return red;
        }

        // Lägg bara till en punkt per datum.
        const cur = red.find(x => x.date === date!);
        if (cur) {
            return red;
        }

        const spec = row.getRegvarValue('K_sjukdomsstadiumSpec') as ListItem | null;

        red.push({
            date,
            listValue: stadium,
            tooltip: (
                <LegacyTooltip headerText={`Kontakt ${date}`}>
                    <div>
                        {!!spec && <div>Sjukdomsstadium: {stadium.text} ({spec.text})</div>}
                        {!spec && <div>Sjukdomsstadium: {stadium.text}</div>}
                    </div>
                </LegacyTooltip>
            ),
        });

        return red;
    }, []);

    const points = relevantRows.reduce<ReactElement[]>((red, item) => {
        red.push(<SjukdomsstadiumPoint key={item.date} point={item} y={0} />);

        return red;
    }, []);

    return (
        <CategoriesAxis categories={CATEGORIES} plotBands={true}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};
