import type { MouseEvent, ReactElement, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import type { RenderTabOptions } from '../../types';
import { ActiveTabContext } from '../ActiveTabContext';
import HTabsContext from './HTabsContext';

const HTabs = (props: {
    className?: string;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    children?: ReactNode;
    hiddenTabs?: string[];
}): ReactElement => {
    const {
        className,
        activeTab,
        setActiveTab,
        hiddenTabs = [],
        children,
    } = props;
    const [, forceReload] = useState<symbol>();
    const [tabs, setTabs] = useState(new Map<string, RenderTabOptions>());
    const renderTab = useCallback((id: string, options: RenderTabOptions) => {
        setTabs(prev => prev.set(id, options));
        forceReload(Symbol());
    }, []);
    const removeTab = useCallback((id: string) => {
        setTabs(s => {
            s.delete(id);

            return s;
        });
        forceReload(Symbol());
    }, []);
    const ctx = useMemo(() => ({
        renderTab,
        removeTab,
    }), [removeTab, renderTab]);
    const onClick = (e: MouseEvent, eventKey: string) => {
        e.stopPropagation();
        e.preventDefault();

        setActiveTab(eventKey);
    };
    const allTabs = [...tabs.entries()].filter(([tabId]) => !hiddenTabs.includes(tabId));

    // Kontrollera att activeTab finns med bland angivna flikar, välj annars den första.
    const selectedTab = allTabs.some(([tabId]) => tabId === activeTab) ? activeTab : allTabs[0]?.[0];

    return (
        <HTabsContext.Provider value={ctx}>
            <ActiveTabContext.Provider value={selectedTab || ''}>
                {children}

                <div className={classnames('htabs', className)}>
                    <ul className="nav nav-tabs">
                        {allTabs.filter(([, tabData]) => !tabData.hide).map(([tabId, tabData]) => {
                            const key = tabId;
                            return (
                                <li key={key} data-tab={key} className={classnames('nav-item', { active: key === selectedTab })}>
                                    <a href="#" onClick={e => onClick(e, key)} draggable={false} className={classnames('nav-link', { active: key === selectedTab })}>{tabData.header}</a>
                                    <div className="tab-spillover" />
                                </li>
                            );
                        })}
                    </ul>

                    <div className="tab-content">
                        {allTabs.map(([tabId, tabData]) => {
                            const key = tabId;

                            return (
                                <div key={key} className={classnames('tab-pane', { active: key === selectedTab })} data-tab={key} id={`Tabs-pane-${key}`}>{tabData.content}</div>
                            );
                        })}
                    </div>
                </div>
            </ActiveTabContext.Provider>
        </HTabsContext.Provider>
    );
};

export default HTabs;
