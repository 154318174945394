import type { ReactElement } from 'react';
import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import type { RowModel, RowModelTableMetadata, SubTableModel } from '@cancercentrum/rcc-react';
import { SubTableGrid, useDataActions, useIncaForm } from '@cancercentrum/rcc-react';
import orderBy from 'lodash/orderBy';
import useDataSet from '../hooks/useDataSet';
import { isRowReadOnly } from '../utils/row';
import { useModuleContext } from '../ModuleContext';
import { PDLGridRemoveIcon } from '../modules/pdl/PDLGridRemoveIcon';
import type { DataSet, GridColumn } from '../types';
import type { ExternalStudiesResult } from '../modules/studie';
import { fancyConfirm } from '../../utils/fancyConfirm';
import { GridRemoveIcon } from './GridRemoveIcon';
import { GridDisabledRemoveIcon } from './GridDisabledRemoveIcon';

export interface IPOSubTableGridProps<SubTableMetadata extends RowModelTableMetadata> {
    gridId: string;
    renderHeader: any;
    dateColumn: GridColumn<RowModel<SubTableMetadata>, string>;
    columns: GridColumn<RowModel<SubTableMetadata>, string>[];
    subTable: SubTableModel<SubTableMetadata>;
    externalStudiesData?: ExternalStudiesResult;
    searchable?: boolean;
    onRowClick?: (e: any) => void;
    renderRow?: (data: {
        row: RowModel<SubTableMetadata>;
    }) => void;
    renderBody?: (data: any) => void;
    allowEditingForAllPositions?: boolean;
    defaultSortFn?: (a: RowModel<SubTableMetadata>, b: RowModel<SubTableMetadata>) => number;
}

const isEmpty = (input: unknown): boolean => isNil(input) || input === '';

const IPOSubTableGrid = <SubTableMetadata extends RowModelTableMetadata>(props: IPOSubTableGridProps<SubTableMetadata>): ReactElement => {
    const { renderHeader, dateColumn, allowEditingForAllPositions, defaultSortFn, externalStudiesData } = props;
    const dataActions = useDataActions();
    const ctxDataSet = useDataSet();
    const ctxModule = useModuleContext();
    const { currentUser } = useIncaForm();

    const columns: GridColumn<RowModel<SubTableMetadata>, string>[] = useMemo(() => {
        return [
            ...props.columns,
            {
                id: 'Actions',
                sortable: false,
                searchable: false,
                tdProps: {
                    className: 'nostretch text-center',
                },
                header: 'Radera',
                renderCell(data) {
                    const dis = isRowReadOnly(data.row, currentUser);

                    const extData = externalStudiesData?.get(data.row.getRegvarValue('Stud_studie_vd') || -1);

                    const onClick = () => {
                        // eslint-disable-next-line react/prop-types
                        const header = renderHeader ? renderHeader(data) : `${ctxModule.displayName}, ${dateColumn.header}: ${data.row.getRegvarValue(dateColumn.field || '') || dateColumn.fallbackValue}`;

                        fancyConfirm({ header, content: 'Är du säker på att du vill ta bort raden?' }).then(ok => {
                            ok && dataActions.updateRow(x => x.removeSubTableRow(data.row));
                        });
                    };

                    if (allowEditingForAllPositions) {
                        const header = `${ctxModule.displayName}: ${data.row.getRegvarValue(dateColumn.field || '') || dateColumn.fallbackValue}`;
                        return <PDLGridRemoveIcon row={data.row} onClick={onClick} messageTitle={header} />;
                    }

                    if (dis) {
                        return null;
                    }

                    if (extData) {
                        return <GridDisabledRemoveIcon message="Den här registrering kan inte tas bort då den finns i det externa studieregistret" />;
                    }
                    return <GridRemoveIcon onClick={onClick} />;
                },
            },
        ];
    }, [props.columns, dataActions, dateColumn, ctxModule, renderHeader, allowEditingForAllPositions, externalStudiesData, currentUser]);

    if (process.env.NODE_ENV === 'development') {
        const tableName = props.subTable.$rcc.tableName as keyof DataSet;

        if (!ctxDataSet[tableName]) {
            throw new Error(`The table "${tableName}" is not included in the dataset.`);
        }
    }

    const sortCols = orderBy(props.columns.filter(x => x.defaultSortOrder), x => (x as any).sortPrio);
    let sortFn = defaultSortFn;

    if (!sortFn && sortCols.length) {
        sortFn = (a, b) => {
          return sortCols.reduce((comparisonResult, column) => {
              let aVal, bVal;

              const stringify = (x: any): string => (x === null || x === undefined) ? '' : x.toString();

              if (column.getStringValue) {
                  aVal = stringify(column.getStringValue(a));
                  bVal = stringify(column.getStringValue(b));
              } else if (column.field) {
                  aVal = stringify(a.getRegvarValue(column.field));
                  bVal = stringify(b.getRegvarValue(column.field));
              } else {
                  return 0;
              }

              const modifier = column.defaultSortOrder === 'desc' ? -1 : 1;
              return comparisonResult || (
                  (isEmpty(aVal) && !isEmpty(bVal)) ? -1
                      : (!isEmpty(aVal) && isEmpty(bVal)) ? 1
                          : aVal.localeCompare(bVal) * modifier);
          }, 0);
        };
    }

    return (
        <SubTableGrid
            id={props.gridId}
            subTable={props.subTable}
            selectable={true}
            columns={columns}
            renderRow={props.renderRow}
            renderBody={props.renderBody}
            // sharedCellProps={props.sharedCellProps}
            searchable={props.searchable}
            sortFn={sortFn}
            onRowClick={props.onRowClick}
        />
    );
};

export default IPOSubTableGrid;
