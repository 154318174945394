import { logOnce } from '../utils/logger';

// eslint-disable-next-line
logOnce('__INCA_OVERSIKT__', `               _ _
              (_|_)
    ____ ___  ____
   /  _/ __ \\/ __ \\
   / // /_/ / / / /
 _/ // ____/ /_/ /
/___/_/    \\____/


`,
    `v${process.env.RCC_VERSION} - ${process.env.RCC_BUILD_DATE}`);
