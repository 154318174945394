import { useDispatch } from 'react-redux';
import { GridActions } from '@cancercentrum/rcc-react';
import { parseISO } from 'date-fns';
import { useSetAtom } from 'jotai';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import { Point } from '../../components/Chart/Point';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import useDataSet from '../../hooks/useDataSet';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName, TableName } from './constants';
import { getScatterInfo } from './utils';
import { useGetExternalStudies } from './useExternalStudieData';
import { useVDStudier } from './useVDStudier';
import { useGetStudieSubTable } from './useGetStudieSubTable';

const DATE = 'Stud_startdatum';
const END_DATE = 'Stud_stoppdatum';
const CATEGORIES = ['Studier'];

export const StudieAxis = () => {
    const subTable = useGetStudieSubTable();
    const vdStudier = useVDStudier();
    const extStudyData = useGetExternalStudies();
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const ds = useDataSet();
    const hasEndDate = !!ds[TableName]![END_DATE];

    const points = !vdStudier ? null : subTable.getRows().filter(x => isValidDate(x.getRegvarValue(DATE) as string | null) && x.getRegvar('Stud_studie_vd').value || x.getRegvarValue('Stud_studienamn')).map(x => {
        const dateStr = x.getRegvarValue(DATE) as string;
        const scatterInfo = getScatterInfo(x, vdStudier, hasEndDate, extStudyData);

        return (
            <Point
                key={x.getId()}
                x={getUTCStartOfDayTime(parseISO(dateStr))}
                y={0}
                color={scatterInfo.color}
                marker={scatterInfo.marker}
                renderTooltip={() => scatterInfo.tooltip}
                events={{
                    click() {
                        setActiveTab(ModuleName);
                        dispatch(GridActions.setSelectedItemId(GridId, x.getId()));
                    },
                }}
            />
        );
    });

    return (
        <CategoriesAxis categories={CATEGORIES}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};
