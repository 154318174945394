import type { ReactElement } from 'react';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';
import { Button, Modal } from '@cancercentrum/rcc-bootstrap';
import type { ReduxGridColumn } from '@cancercentrum/rcc-react';
import { Grid, GridActions, HighlightText } from '@cancercentrum/rcc-react';
import { ModalWrapper } from '../../../../../components/ModalWrapper';
import type { HierarchifiedSubstansItem, RegimItem } from '../../types';
import type { OverviewState } from '../../../../types';

type RegimGridItem = RegimItem & {
    vdSubstanser: HierarchifiedSubstansItem[];
}

const GRID_ID = 'SearchRegimBySubstans';
const columns: ReduxGridColumn<RegimGridItem>[] = [
    {
        header: 'Regim',
        field: 'reg_namn',
    },
    {
        header: 'Diagnos',
        field: 'reg_diagnosgrupp_Beskrivning',
    },
    {
        header: 'Läkemedel',
        getStringValue(row) {
            return row.vdSubstanser.map(x => x.data.sub_subnamnrek).join('');
        },
        renderCell(data) {
            return (
                <ul className="list-unstyled mb-0">
                    {data.row.vdSubstanser.map(x => <ul key={x.id}><HighlightText text={x.data.sub_subnamnrek} search={data.filter} /></ul>)}
                </ul>
            );
        },
    },
];

const mapStateToProps = (state: OverviewState): number | null => {
    return (state.grid[GRID_ID]?.selectedItemId as number) || null;
};

const PageSizes = [5, 10, 15, 20];

const SearchRegimByLakemedelModal = (props: {
    vdRegimer: RegimItem[];
    substanserById: Record<number, HierarchifiedSubstansItem>;
    onClose: (regimId: number | null) => void;
}): ReactElement => {
    const { vdRegimer, substanserById } = props;
    const selectedRegimId = useSelector(mapStateToProps);
    const dispatch = useDispatch();

    const regimerById = useMemo<Record<number, RegimItem>>(() => {
        return keyBy(vdRegimer, x => x.id);
    }, [vdRegimer]);
    const regimerWithSubs = useMemo<RegimGridItem[]>(() => {
        return vdRegimer.map((regim) => {
            return {
                ...regim,
                vdSubstanser: regim.substanser.map(x => substanserById[x]),
            };
        });
    }, [vdRegimer, substanserById]);

    const close = () => {
        props.onClose(null);

        dispatch(GridActions.clear(GRID_ID));
    };

    const selectRegim = () => {
        props.onClose(selectedRegimId);
        dispatch(GridActions.clear(GRID_ID));
    };

    const selectedRegim = regimerById[selectedRegimId || -1];

    return (
        <ModalWrapper show={true} onHide={close} keyboard={false} bsSize="xl">
            <Modal.Header closeButton>
                <Modal.Title>Sök regim</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid
                    id={GRID_ID}
                    selectable={true}
                    columns={columns}
                    rows={regimerWithSubs}
                    availablePageSizes={PageSizes}
                    defaultPageSize={5}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close} bsStyle="link">Avbryt</Button>
                <Button onClick={selectRegim} bsStyle="primary" disabled={!selectedRegim}>OK</Button>
            </Modal.Footer>
        </ModalWrapper>
    );
};

export default memo(SearchRegimByLakemedelModal);
