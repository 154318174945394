import type { ReactElement } from 'react';
import { useMemo } from 'react';
import classnames from 'classnames';
import { StandardTabHeader } from '../../components/StandardTabHeader';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import { DisplayName, GridId, ModuleName, TableName } from './constants';
import { useGetMutationsanalysSubTable } from './useGetMutationsanalysSubTable';

export const MutationsanalysTab = (props: {
    children: ReactElement;
    highlight?: boolean;
}) => {
    const { children, highlight } = props;
    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
            tableName: TableName,
            gridId: GridId,
        };
    }, []);
    const subTable = useGetMutationsanalysSubTable();
    const header = (
        <StandardTabHeader
            displayName={DisplayName}
            rows={subTable.items}
            className={classnames({
                'tab-highlight': highlight,
            })}
        />
    );

    return (
        <HTab eventKey={ModuleName} header={header}>
            <ModuleContext.Provider value={ctx}>
                <div data-testid={`Module_${ModuleName}`}>
                    {children}
                </div>
            </ModuleContext.Provider>
        </HTab>
    );
};
