import { createContext, useContext } from 'react';

export interface ModuleCtx {
    moduleName: string;
    displayName: string;
    tableName?: string;
    gridId?: string;
}

export const ModuleContext = createContext<ModuleCtx | null>(null);

export const useModuleContext = (): ModuleCtx => {
    const ctx = useContext(ModuleContext);
    if (!ctx) {
        throw new Error('ModuleContext is missing.');
    }

    return ctx;
};
