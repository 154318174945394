import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { incaAccessor } from '@cancercentrum/inca';
import type { MouseEvent, ReactElement } from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const onClick = (e: MouseEvent, rrId: number, url: string) => {
    const inca = incaAccessor();
    e.stopPropagation();

    if (e.ctrlKey || e.shiftKey) {
        return;
    }

    e.preventDefault();

    // The guards are when opened embedded on the startpage. inca.overview doesn't exist then.
    if (inca?.overview?.supportsTabs) {
        inca.overview.tabs.add(tabId => {
            inca.overview.tabs.setContent(tabId, 'rr', rrId);
            inca.overview.tabs.activate(tabId);
        });
    } else {
        window.open(url, '_blank');
    }
};

export const OpenRegisterRecordLink = (props: {
    rrId: number;
    title?: string;
}): ReactElement => {
    const { rrId, title } = props;
    const url = `/RegisterRecord/Details/${rrId}`;

    return (
        <a href={url} title={title} onClick={e => onClick(e, rrId, url)} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
    );
};
