import type { SetStateAction } from 'jotai';
import { atom, useAtom } from 'jotai';
import type { LakemedelChartDisplayModes } from '../oversikt/constants';
import { LakemedelChartDisplayMode } from '../oversikt/constants';
import { useIPO } from '../../hooks/useIPO';

const graphModeAtom = atom<LakemedelChartDisplayModes | null>(null);

export const useLakemedelGraphMode = (): [LakemedelChartDisplayModes, (value: SetStateAction<LakemedelChartDisplayModes | null>) => void] => {
    const ipo = useIPO();
    const [value, setValue] = useAtom(graphModeAtom);

    return [
        value || ipo.diagnosis.layout.settings.defaultLakemedelGraphMode || LakemedelChartDisplayMode.Regimer,
        setValue,
    ];
};
