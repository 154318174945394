import type { ChangeEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import type { RegvarValueDomainModel } from '@cancercentrum/rcc-react';
import { Condition, useDataActions } from '@cancercentrum/rcc-react';
import { useAtomValue } from 'jotai';
import Question from '../../../../components/IPOQuestion';
import useDataSet from '../../../../hooks/useDataSet';
import { lakemedelVdsAtom, LkmStudieQuestion } from '../../index';
import { trim } from '../../utils';
import type { LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import LakemedelPicker from './LakemedelPicker';
import RegimSelector from './RegimSelector';

export const RegimSubstanceSelector = (props: {
    row: LäkemedelRowModel;
    showOnlyChosenQuestion?: boolean;
    hideStudielakemedel?: boolean;
}): ReactElement | null => {
    const {
        row,
        showOnlyChosenQuestion,
        hideStudielakemedel,
    } = props;
    const [showChosen, setShowChosen] = useState(false);
    const dataset = useDataSet();
    const dataActions = useDataActions();
    const lakemedelPromise = useAtomValue(lakemedelVdsAtom);
    const {
        substanserById,
        vdUtvaldaSubstanser,
        vdRegimer,
        vdRegimerForDiagnosis,
        availableSubstanser,
    } = lakemedelPromise.result!;
    const studieSpecRegvar = dataset.Läkemedel!.Lkm_studielakemedel_spec ? row.getRegvar('Lkm_studielakemedel_spec') : undefined;
    const studieRegvar = dataset.Läkemedel!.Lkm_studie_vd ? row.getRegvar('Lkm_studie_vd') as RegvarValueDomainModel : undefined;
    const allowStudieSelection = !!(row.getRegvarValue('Lkm_gesInomRamenAvStudie') || (studieSpecRegvar && studieSpecRegvar.getValue()));
    const isCustomSubstance = !!(studieSpecRegvar && studieSpecRegvar.getValue());

    const onChosenChange: ChangeEventHandler<HTMLInputElement> = e => {
        setShowChosen(e.target.checked);
    };

    const clearStudie = () => {
        if (studieRegvar) {
            dataActions.updateRow(x => x.clearRegvarValue('Lkm_studie_vd'), { skipHistory: true });
        }
    };

    return (
        <div>
            <Condition if={!isCustomSubstance}>
                <div className="form-group row">
                    <label htmlFor="LakRegimSelector" className="col-sm-2 form-label">Ange regim</label>
                    <div className="col-sm-10 d-flex align-items-center">
                        <RegimSelector
                            id="LakRegimSelector"
                            regvar={row.getRegvar('Lkm_regim_vd')}
                            disabled={!!row.getRegvarValue('Lkm_substans_vd')}
                            vdRegimer={vdRegimer}
                            vdRegimerForDiagnosis={vdRegimerForDiagnosis}
                            substanserById={substanserById}
                        />
                        <div className="mb-0-all-children">
                            <Question
                                regvar={row.getRegvar('Lkm_gesInomRamenAvStudie')}
                                wrapperClassName="nostretch ms-2"
                                inline={true}
                                onValueChange={(val: any) => {
                                    if (!val) {
                                        clearStudie();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="LakSubSelector" className="col-sm-2 form-label">Ange läkemedel</label>
                    <div className="col-sm-10 d-flex align-items-center">
                        <div>
                            <LakemedelPicker
                                id="LakSubSelector"
                                disabled={!!row.getRegvarValue('Lkm_regim_vd')}
                                regvar={row.getRegvar('Lkm_substans_vd')}
                                vdSubstanser={availableSubstanser}
                                vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                                showChosenSubtances={showChosen}
                                startdatum={row.getRegvarValue('Lkm_startdatum')}
                            />
                        </div>
                        <div className="mb-0-all-children">
                            {showOnlyChosenQuestion && (
                                <div className="ms-2">
                                    <input type="checkbox" onChange={onChosenChange} checked={showChosen} className="form-check-input" id="AddSubstanceChosen" />
                                    <label className="form-check-label ms-2" htmlFor="AddSubstanceChosen">
                                        Visa endast nya utvalda cancerläkemedel
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="mb-0-all-children">
                            <Question
                                regvar={row.getRegvar('Lkm_gesInomRamenAvStudie')}
                                wrapperClassName="nostretch ms-2"
                                inline={true}
                                onValueChange={(val: any) => {
                                    if (!val) {
                                        clearStudie();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Condition>

            <div>
                {!!studieSpecRegvar && !hideStudielakemedel && (
                    <Question
                        regvar={studieSpecRegvar}
                        labelClassName="col-sm-2"
                        className="input-long"
                        wrapperClassName="col-sm-10"
                        onValueChange={val => {
                            if (!val) {
                                clearStudie();
                            }
                        }}
                        valueParser={trim}
                    />
                )}

                {!!studieRegvar && (
                    <LkmStudieQuestion
                        regvar={studieRegvar}
                        labelClassName="col-sm-2"
                        wrapperClassName="col-sm-10"
                        disabled={!allowStudieSelection}
                    />
                )}
            </div>
        </div>
    );
};
