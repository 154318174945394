import { valueAndTextFormatter } from '../../../utils/formatters';
import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        prom_deltagarkod: {},
        senastKontrollerad: {},
        komplettLkmPalliativ: {},
        komplettLkmAdjuvant: {},
        selectedVd: {},
    },
    Basdata: {
        Basdata_prostata_vd: {},
    },
    Kontakt: {
        K_vardgivare: {},
        K_besokstyp: {
            identifiers: ['1', 'digitalt_vardmote', '2', '4'],
        },
        K_yrkesroll: {
            identifiers: ['1', '2'],
        },
        K_ecogEjBedomt: {},
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_crpc_ny: {
            identifiers: ['nej', 'ja', 'uppgift_saknas'],
        },
        K_harLokalstatus: {},
        K_lokalstatus_palpation: {
            identifiers: ['t1c', 't2', 't3', 't4', 'tx', 'inget', 'lokalrecidiv', 'ej_bedombart'],
        },
        K_lokalstatus_trulvolym: {},
        K_psadensitet: {},
        K_terapibeslut: {
            identifiers: [
                'avvakta',
                'aktiv_monitorering',
                'expektans_utan_kurativ_intenti',
                'operation',
                'stralbehandling_prim',
                'stralbehandling_efter_op',
                'systemisk_behandling',
                'alt_lokal_behandling',
            ],
        },
        K_uppfoljning: {},
        K_kontaktsjukskoterska: {
            label: 'Har tilldelad kontaktsjuksköterska?',
            identifiers: ['nej', 'ja'],
        },
        K_kontaktsjukskoterska_namn: {},
        K_sammantagenBedomning: {
            props: {
                formatter: valueAndTextFormatter,
            },
            identifiers: ['nd', 'rf', 'sd', 'r', 'pd', 'of', 'ab', 'eb'],
        },
        K_sammantagenBedomningNySpec: {
            identifiers: ['l1', 'r1', 'm1'],
        },
        K_brytpunktssamtal: {},
        K_diskuteratsMultidisciplinarKonferens: {
            identifiers: ['nej', 'ja'],
        },
        K_inskrivenPalliativHemsjukvard: {
            identifiers: ['nej', 'ja'],
        },
        K_kastrationsresistentdatum: {},
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['5', '7', '3', '1', '8'],
            props: {
                label: 'Modalitet',
            },
        },
        Bild_petct_typ: {
            identifiers: ['psma-pet', 'fdg', 'acetat', 'choline', 'annan'],
        },
        Bild_sammanvagdRadiologiskBedomning: {
            identifiers: ['m0', 'ny', 'cr', 'pr', 'mr', 'sd', 'pd', 'ab'],
        },
        Bild_bsi: {},
        Bild_metod_specificera: {},
        Bild_tumlok_hjarna: {
            identifiers: ['m0', 'm1', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_skelett: {
            identifiers: ['m0', 'm1', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_lunga_pleura: {
            identifiers: ['m0', 'm1', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_lever: {
            identifiers: ['m0', 'm1', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_buk: {
            identifiers: ['m0', 'm1', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_lymfkortlar: {
            identifiers: ['m0', 'm1', 'n', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_ovrigt: {
            identifiers: ['m0', 'm1', 'cr', 'pr', 'mr', 'sd', 'pd', 'of', 'ab'],
        },
        Bild_tumlok_ovrigt_spec: {},
    },
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_slutdatum: { props: { label: 'Stoppdatum' } },
        Stral_dosPerFraktionD2: {},
        Stral_slutdosD2: {},
    },
    StralTarget: {
        ST_target: {
            identifiers: [
                '4', 'lymfkortlar', 'prostata', 'urinblasa', 'hjarna', 'annan',
            ],
        },
        ST_target_spec: {},
    },
    SSE: {},
    SSEHandelse: {
        SSEH_handelse: {
            identifiers: ['0', '1', '2', '3'],
        },
    },
    Labbprov: {
        Labb_PSA_2: {},
        Labb_Krea_0: {},
        Labb_ALP_2: {},
        Labb_Hb_0: {},
        Labb_LPK_2: {},
        Labb_neutrofila_1: {},
        Labb_TPK_0: {},
        Labb_testosteron_2: {},
    },
    Läkemedel: {
        Lkm_behandlingsintention: {
            identifiers: ['1', '2', 'ovrig'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '2', '3'],
        },
        Lkm_startdos_lista: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Lkm_ablatioTestis: {},
        Lkm_antalCyklerGivna: {
            label: 'Antal givna kurer',
        },
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
    },
    Biobank: {
        Bb_universitetssjukhus: {
            identifiers: ['akademiska', 'st_goran', 'falun', 'halmstad', 'karolinska', 'norrland', 'sahlgrenska', 'skane', 'sös', 'uddevalla', 'linkoping', 'orebro'],
        },
    },
    Biobanksprov: {
        Bbp_provtyp: {
            identifiers: ['blod', 'vavnad'],
        },
        Bbp_target: {
            identifiers: ['prostata', 'lymfkortlar', 'benmarg', '4', '5', 'lunga', 'benbiopsi', 'lgii', 'ovrig'],
        },
    },
    Dosjustering: {
        Dj_datum: {},
        Dj_justering: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Dj_orsak: {
            identifiers: ['enligt_plan', '2', '1', '3', '5'],
        },
        Dj_orsakSpec: {},
    },
    Studie: {
        Stud_stoppdatum: {},
    },
    PSAPåverkandeBehandling: {
        PSA_behandlingstyp: {
            identifiers: ['lokalbehandling', 'kad', 'uvi/prostatit', 'annat'],
        },
        PSA_behandlingstyp_spec: {},
        PSA_datum: {},
        PSA_lokalbehandlingstyp: {
            identifiers: ['turp', 'tumt', 'rezum'],
        },
        PSA_startdatum: {},
        PSA_stoppdatum: {},
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    Patientportal: {},
    Mutationsanalys: {
        Mut_provtyp: {
            identifiers: ['1', '2'],
        },
        Mut_datum: {},
        Mut_provtagningsdatum: {},
        Mut_BRCA1: {
            identifiers: ['ja', 'nej', 'ej_utf'],
        },
        Mut_BRCA2: {
            identifiers: ['ja', 'nej', 'ej_utf'],
        },
        Mut_ovrigt_spec: {
            identifiers: ['ja', 'nej', 'ej_utf'],
        },
    },
};

export default dataset;
