import type { ReactElement } from 'react';
import { useMemo, useRef } from 'react';
import { parseISO } from 'date-fns';
import Color from 'color';
import type { Chart, PointClickEventObject, SeriesPieOptions } from 'highcharts';
import { useSetAtom } from 'jotai';
import { getUTCStartOfDayTime } from '../../../utils/date';
import { Point as ChartPoint } from '../../../components/Chart/Point';
import { ModuleName } from '../constants';
import type { ChartInfo, SurveyAnswer, Utskicksprojekt } from '../types';
import useChartContext from '../../../components/Chart/ChartContext/useChartContext';
import { selectedTabAtom } from '../../../atoms';
import { selectedSurveyPillAtom, useGetSelectedSvarAtom } from '../atoms';
import { getPoints } from './PieSeries';
import { getPointPosition } from './utils';

interface CustomPie {
    seriesOptions: SeriesPieOptions
}

export const Point = (props: {
    utskicksprojekt: Utskicksprojekt;
    chartInfo: ChartInfo;
    answer: SurveyAnswer;
    y: number;
}): ReactElement => {
    const {
        utskicksprojekt,
        chartInfo,
        answer,
        y,
    } = props;
    const setActiveTab = useSetAtom(selectedTabAtom);
    const x = getUTCStartOfDayTime(parseISO(answer.endDate || ''));
    const { disablePie } = chartInfo;
    const { color, label, tooltip, patientPortalTooltip } = chartInfo.getChartPointInfo(answer, utskicksprojekt, chartInfo);
    const linecolor = Color(color).darken(0.7).hex();

    const marker = useMemo(() => ({
        symbol: 'circle',
        lineColor: linecolor,
        lineWidth: 2,
        radius: 8,
        states: {
            hover: {
                fillColor: color,
                lineColor: linecolor,
                lineWidth: 4,
            },
        },
    }), [color, linecolor]);
    const setSelectedPill = useSetAtom(selectedSurveyPillAtom);
    const svarAtom = useGetSelectedSvarAtom(utskicksprojekt.config.id);
    const setSelectedSurveyId = useSetAtom(svarAtom);
    const pieId = `pie_${utskicksprojekt.config.id}_${answer.id}_${y}`;
    const ctx = useChartContext();
    const chartRef = useRef<Chart>();
    chartRef.current = ctx.chart;

    const events = useMemo(() => {
        return {
            click: (e: PointClickEventObject) => {
                e.stopPropagation();
                e.preventDefault();

                if (!disablePie && (e.shiftKey || e.ctrlKey)) {
                    const customPie = (e.point as any).custom as CustomPie;
                    const existingSeries = chartRef.current?.series.find(s => s.userOptions.id === customPie.seriesOptions.id);

                    if (existingSeries) {
                        existingSeries.remove(true);
                    } else {
                        const center = getPointPosition(e.point);
                        chartRef.current?.addSeries({ ...customPie.seriesOptions, center: [center.x, center.y] }, true);
                    }

                } else {
                    setSelectedPill(utskicksprojekt.config.id.toString());
                    setSelectedSurveyId(answer.id);
                    setActiveTab(ModuleName);
                }
            },
        };
    }, [answer, setActiveTab, setSelectedPill, setSelectedSurveyId, utskicksprojekt, disablePie]);

    const pieInfo = useMemo<CustomPie>(() => {
        return {
            seriesOptions: {
                id: pieId,
                size: 60,
                type: 'pie',
                innerSize: '50%',
                showInLegend: false,
                zIndex: 500,
                dataLabels: { enabled: false },
                slicedOffset: 0,
                data: getPoints(utskicksprojekt, answer, chartInfo),
            },
            disablePie: chartInfo.disablePie,
        };
    }, [answer, utskicksprojekt, chartInfo, pieId]);

    return (
        <ChartPoint
            x={x}
            y={y}
            color={color}
            marker={marker}
            renderTooltip={() => tooltip}
            renderTooltipPatientINCA={() => patientPortalTooltip}
            labelText={label}
            label={label}
            events={events}
            custom={pieInfo}
        />
    );
};
