import type { ReactElement } from 'react';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { Condition } from '@cancercentrum/rcc-react';
import type { RegvarBooleanMetadata, RegvarStringMetadata, RegvarValueDomainMetadata } from '@cancercentrum/inca';
import type { HierarchifiedSubstansItem, UtvaldSubstansVDItem } from '../types';

import Question from '../../../components/IPOQuestion';
import useDataSet from '../../../hooks/useDataSet';
import { LkmStudieQuestion, StudieLakemedelSpecQuestion, SubstanceQuestion } from '../index';
import type { LäkemedelRowModel } from '../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';

export const EditLakemedelQuestion = (props: {
    row: LäkemedelRowModel;
    showOnlyChosenQuestion?: boolean;
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
    showStudieLkmSpec: boolean;
    labelClassName?: string;
    wrapperClassName?: string;
    label?: string;
    required?: boolean;
}): ReactElement | null => {
    const {
        row,
        showOnlyChosenQuestion,
        vdUtvaldaSubstanser,
        availableSubstanser,
        showStudieLkmSpec,
        labelClassName = 'col-md-3',
        wrapperClassName = 'col-md-9',
    } = props;
    const gesInomRamenAvStudie: RegvarModel<RegvarBooleanMetadata> = row.getRegvar('Lkm_gesInomRamenAvStudie');
    const dataset = useDataSet();

    if (row.getRegvarValue('Lkm_regim_vd')) {
        return (
            <Question
                regvar={gesInomRamenAvStudie}
                labelClassName={labelClassName}
                wrapperClassName={wrapperClassName}
            />
        );
    }

    const startdatum: string | null = row.getRegvarValue('Lkm_startdatum');
    const substanceRegvar: RegvarModel<RegvarValueDomainMetadata> = row.getRegvar('Lkm_substans_vd');
    const studielkmSpecRegvar: RegvarModel<RegvarStringMetadata> = row.getRegvar('Lkm_studielakemedel_spec');
    const studieRegvar: RegvarModel<RegvarValueDomainMetadata> | undefined = dataset.Läkemedel!.Lkm_studie_vd ? row.getRegvar('Lkm_studie_vd') : undefined;

    return (
        <div>
            <Condition if={!studielkmSpecRegvar.getValue()}>
                <SubstanceQuestion
                    availableSubstanser={availableSubstanser}
                    vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                    substanceRegvar={substanceRegvar}
                    startdatum={startdatum}
                    gesInomRamenAvStudie={gesInomRamenAvStudie}
                    labelClassName={labelClassName}
                    wrapperClassName={wrapperClassName}
                    required={true}
                    showOnlyChosenQuestion={showOnlyChosenQuestion}
                />
            </Condition>

            {showStudieLkmSpec && (
                <StudieLakemedelSpecQuestion
                    studieLkmRegvar={studielkmSpecRegvar}
                    labelClassName={labelClassName}
                    wrapperClassName={wrapperClassName}
                />
            )}

            {!!studieRegvar && (
                <LkmStudieQuestion
                    regvar={studieRegvar}
                    labelClassName={labelClassName}
                    wrapperClassName={wrapperClassName}
                    disabled={!gesInomRamenAvStudie.getValue() && !studielkmSpecRegvar.getValue()}
                />
            )}
        </div>
    );
};
