import orderBy from 'lodash/orderBy';
import { Explanation } from '@cancercentrum/rcc-react';
import { getDisplayName } from './utils';
import type { Utskicksprojekt } from './types';

export interface DefaultTabTitleProps {
    utskicksprojekt: Utskicksprojekt;
}

const DefaultTabTitle = (props: DefaultTabTitleProps) => {
    const { utskicksprojekt } = props;
    const s = utskicksprojekt.answers.filter(x => !!x.endDate);
    const count = s.length;
    const last = orderBy(s, [x => x.endDate, x => x.startDate], ['desc', 'desc'])[0];
    return (
        <div className="d-flex flex-column">
            <div>
                <strong>{getDisplayName(utskicksprojekt)}</strong>
                {!!utskicksprojekt.ipoDescription && <span className="ms-2"><Explanation>{utskicksprojekt.ipoDescription}</Explanation></span>}
            </div>
            {!!count && <small><strong>{count}</strong> inskickade enkäter</small>}
            {!!last && <small>Senast inskickad: <strong>{last.endDate}</strong></small>}
            {!last && <small>Ingen enkät inskickad</small>}
        </div>
    );
};

export default DefaultTabTitle;
