import type { ReactElement, ReactNode } from 'react';
import { useContext } from 'react';
import orderBy from 'lodash/orderBy';
import type { HookDataActions, RowModel, RowModelTableMetadata, SubTableModel } from '@cancercentrum/rcc-react';
import { MultiSelectList, useValidationGroupable } from '@cancercentrum/rcc-react';
import intersection from 'lodash/intersection';
import type { ListItem } from '@cancercentrum/inca';
import useDataSet from '../hooks/useDataSet';
import type { Metadata } from '../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { DataSetRegvar } from '../types';
import { isDirty } from '../utils/rowmodel';
import { ReadOnlyContext } from './ReadOnlyContext';
import { MultiSelectListHistory } from './MultiSelectListHistory';

export interface IPOMultiSelectListProps<T extends RowModelTableMetadata> {
    rowId: number | string;
    datasetValuesSubset?: string[];
    subTable: SubTableModel<T>;
    shortname: keyof T['regvars'];
    availableListValues?: string;
    label?: ReactNode;
    labelClassName?: ReactNode;
    wrapperClassName?: ReactNode;
    renderRow?: (data: {
        row: RowModel<T>;
        dataActions: HookDataActions<T>;
    }) => ReactElement | null;
    help?: ReactNode;
    labelHelp?: ReactNode;
    onToggle?: (listItem: ListItem, added: boolean) => void;
    vertical?: boolean;
    isOptionDisabled?: (option: ListItem) => boolean;
}

const IPOMultiSelectList = <T extends RowModelTableMetadata>(props: IPOMultiSelectListProps<T>): ReactElement => {
    const { rowId, datasetValuesSubset, ...rest } = props;
    const ctxDataSet = useDataSet();
    const isReadOnly = useContext(ReadOnlyContext);
    const subTable = props.subTable;
    const shortname = props.shortname;
    const extraProps: Record<string, any> = {};
    const hasSomethingDirty = props.subTable.items.some(x => isDirty(x));

    useValidationGroupable(hasSomethingDirty, [], {});

    if (rest.availableListValues) {
        // eslint-disable-next-line no-console
        console.warn('availableListValues was passed to IPOMultiSelectList. Use datasetValuesSubset instead.', shortname);
    }

    if (!subTable) {
        throw new Error('subTable must be set.');
    }

    if (!shortname) {
        throw new Error('shortname must be set.');
    }

    const tableName = props.subTable.$rcc.tableName as keyof Metadata;

    if (process.env.NODE_ENV === 'development') {

        if (!ctxDataSet[tableName]) {
            throw new Error(`The table "${tableName}" is not included in the dataset.`);
        }
    }

    const md = ((ctxDataSet[tableName] as any)?.[shortname] || {}) as DataSetRegvar;
    const identifiers = datasetValuesSubset ? orderBy(intersection(datasetValuesSubset, md.identifiers), [x => datasetValuesSubset.indexOf(x)], ['asc']) : md.identifiers;
    const rvMetadata = subTable.$rcc.tableMetadata.regvars[shortname as string];

    if (!identifiers || !identifiers.length) {
        // eslint-disable-next-line no-console
        console.warn('No list items specified!', tableName, shortname);
    }

    if (rvMetadata.dataType !== 'list') {
        throw new Error('IPOMultiSelectList expects shortname to be a list.');
    }

    const availableListValues = (identifiers || []).reduce<string[]>((reduction, x) => {
        const match = rvMetadata.listValues.find(li => li.identifier === x);
        if (!match) {
            // eslint-disable-next-line no-console
            console.warn(`No list item with value "${x}" was found.`);
            return reduction;
        }

        reduction.push(match.value);

        return reduction;
    }, []);

    subTable.getRows().forEach(x => {
        const identifier = x!.getRegvarValue(shortname).identifier;
        if (!availableListValues.includes(identifier)) {
            availableListValues.push(identifier);
        }
    });

    const regvarMetadata = subTable.$rcc.tableMetadata.regvars[shortname as string];
    const label = rest.label || regvarMetadata.label || '[etikett saknas]';

    if (isReadOnly) {
        extraProps.isOptionDisabled = () => true;
    }

    return (
        <MultiSelectList
            availableListValues={availableListValues}
            {...md.props}
            {...extraProps}
            {...rest}
            appendAfter={<MultiSelectListHistory parentId={rowId} subTable={subTable} shortname={shortname} label={label} />}
        />
    );
};

export default IPOMultiSelectList;
