import type { SurveyAnswerWithUtskicksprojekt, Utskicksprojekt } from '../types';

export const AnswerColumnHeader = (props: {
    answer: SurveyAnswerWithUtskicksprojekt;
    utskicksprojekt: Utskicksprojekt;
    showSurveyVersion: boolean;
    showProjectName: boolean;
}) => {
    const { answer, showSurveyVersion, showProjectName } = props;

    return (
        <div className="d-flex flex-column">
            <div>{answer.endDate}</div>
            {showProjectName && <small className="text-muted fs-6 fw-normal">{answer.utskicksprojekt.ipoName || answer.utskicksprojekt.name}</small>}
            {showSurveyVersion && !!answer.enkatversion && (
                <small className="text-muted fs-6 fw-normal">Enkätversion {answer.enkatversion}</small>
            )}
        </div>
    );
};
