import type { RowModel } from '@cancercentrum/rcc-react';
import { AjaxErrorAlert } from '@cancercentrum/rcc-react';
import { Panel } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import { LoadingAlert } from '@cancercentrum/rcc-react';
import { useExtBiverkningLoader } from './useExtBiverkningLoader';
import { CreateNewExtBiverkningPanel } from './CreateNewExtBiverkningPanel';
import { ExtBiverkningErrandPanel } from './ExtBiverkningErrandPanel';
import { ExtBiverkningRegisterRecordPanel } from './ExtBiverkningRegisterRecordPanel';

export const ExtBiverkningInfo = (props: {
    row: RowModel;
    className?: string;
}): ReactElement => {
    const { row, className } = props;
    const { error, isLoading, result: vdBiverkan, reload } = useExtBiverkningLoader();
    const uuid: string = row.getRegvarValue('Biv_uuid');

    if (error) {
        return (
            <div className={className}>
                <Panel bsStyle="danger">
                    <Panel.Heading>Utökad biverkningsrapportering till Läkemedelsverket</Panel.Heading>
                    <Panel.Body>
                        <AjaxErrorAlert error={error} />
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

    if (isLoading || !vdBiverkan) {
        return (
            <div className={className}>
                <Panel className={className}>
                    <Panel.Heading>Utökad biverkningsrapportering till Läkemedelsverket</Panel.Heading>
                    <Panel.Body>
                        <LoadingAlert />
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

    // Det finns index på r_ipo_uuid men eftersom INCA endast applicerar indexet på registerposter kan det teoretist finnas flera ärenden.
    const errands = vdBiverkan.errands.filter(x => x.data.r_ipo_uuid === uuid);
    const rr = vdBiverkan.registerRecords.find(x => x.data.r_ipo_uuid === uuid);
    const onExtCreated = () => {
        reload();
    };

    return (
        <div className={className}>
            {!errands.length && !rr && <CreateNewExtBiverkningPanel row={row} onCreated={onExtCreated} />}
            {!!errands.length && <ExtBiverkningErrandPanel errands={errands} />}
            {!!rr && <ExtBiverkningRegisterRecordPanel registerRecord={rr} collapsible={!!errands.length} />}
        </div>
    );
};
