import type { ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { isFuture, parseISO } from 'date-fns';
import { GridActions } from '@cancercentrum/rcc-react';
import min from 'lodash/min';
import max from 'lodash/max';
import { useSetAtom } from 'jotai';
import Series from '../../components/Chart/Series';
import { Point } from '../../components/Chart/Point';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { Tooltip } from '../../components/Chart/Tooltip';
import type { StralbehandlingRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';
import { useGetStralbehandlingSubTable } from './useGetStralbehandlingSubTable';

const CATEGORIES = ['Strålbehandling'];

export const StralbehandlingAxis = (props: {
    hideIfEmpty?: boolean;
    children?: ReactNode;
    renderTooltipContent?: (row: StralbehandlingRowModel) => ReactElement;
}): ReactElement => {
    const {
        renderTooltipContent,
        children,
        hideIfEmpty,
    } = props;
    const startDateShortname = 'Stral_startdatum';
    const subTable = useGetStralbehandlingSubTable();
    const dispatch = useDispatch();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const series = subTable.getRows().toArray().filter(x => isValidDate(x.getRegvarValue(startDateShortname))).map(x => {
        const key = x.getId();
        const strStartDate = x.getRegvarValue(startDateShortname)!;
        const startDate = getUTCStartOfDayTime(parseISO(strStartDate));
        const strEndDate = x.getRegvarValue('Stral_slutdatum');
        const endDate = isValidDate(strEndDate) ? getUTCStartOfDayTime(parseISO(strEndDate!)) : max([getUTCStartOfDayTime(new Date()), startDate]);
        const ongoingString = isFuture(startDate) ? 'Inget stoppdatum planerat' : 'Pågående';
        const color = '#000';
        const tooltipRenderer = () => (
            <Tooltip>
                <Tooltip.Header>Strålbehandling {strStartDate} - {strEndDate || ongoingString}</Tooltip.Header>
                <Tooltip.Body>
                    {renderTooltipContent?.(x)}
                </Tooltip.Body>
            </Tooltip>
        );

        const marker = {
            symbol: 'circle',
            radius: 6,
            lineWidth: 1,
            lineColor: '#fff',
        };

        return (
            <Series
                key={key}
                type="line"
                color={color}
                lineWidth={8}
                showInLegend={false}
            >
                <Point
                    id={`${key}_start`}
                    x={min([startDate, endDate])}
                    y={0}
                    marker={marker}
                    renderTooltip={tooltipRenderer}
                    events={{
                        click() {
                            setActiveTab(ModuleName);
                            dispatch(GridActions.setSelectedItemId(GridId, x.getId()));
                        },
                    }}
                />
                <Point
                    id={`${key}_end`}
                    x={max([startDate, endDate])}
                    y={0}
                    marker={marker}
                    renderTooltip={tooltipRenderer}
                    events={{
                        click() {
                            setActiveTab(ModuleName);
                            dispatch(GridActions.setSelectedItemId(GridId, x.getId()));
                        },
                    }}
                />
            </Series>
        );
    });

    const visible = !hideIfEmpty || series.length > 0;

    // Tom serie för att undvika renderingsbugg i highcharts där axeln visas kollapsad när data saknas
    const EMPTY_SERIES = (
        <Series
            type="line"
            lineWidth={8}
            showInLegend={false}
        />
    );

    return (
        <CategoriesAxis
            categories={CATEGORIES}
            visible={visible}
        >
            {EMPTY_SERIES}
            {series}
            {children}
        </CategoriesAxis>
    );
};
