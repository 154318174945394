import type { ReactElement } from 'react';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import type { RowModelTableMetadata, SubTableModel } from '@cancercentrum/rcc-react';
import { useModuleContext } from './ModuleContext';
import type { RenderCallback, RowPanelRenderer } from './components/RowPanel';
import { RowPanel } from './components/RowPanel';
import type { OverviewState } from './types';

interface ModuleRowPanelsProps<SubTableMetadata extends RowModelTableMetadata> {
    renderHeader: RenderCallback<SubTableMetadata>;
    renderContent: RenderCallback<SubTableMetadata>;
    allowEditingForAllPositions?: boolean;
}

const ModuleRowPanels = <SubTableMetadata extends RowModelTableMetadata>(props: ModuleRowPanelsProps<SubTableMetadata>): ReactElement => {
    const { renderHeader, renderContent, allowEditingForAllPositions } = props;
    const ctx = useModuleContext();
    const { tableName, gridId } = ctx;
    const selector = useCallback((state: OverviewState) => {
        return {
            subTable: state.data.present.getSubTable(tableName as any) as unknown as SubTableModel<SubTableMetadata>,
            selectedId: get(state.grid, [gridId!, 'selectedItemId'], null),
        };
    }, [tableName, gridId]);
    const { subTable, selectedId } = useSelector(selector, isEqual);

    const renderer = useRef<RowPanelRenderer<SubTableMetadata>>({
        renderContent,
        renderHeader,
    });

    renderer.current = {
        renderHeader,
        renderContent,
    };

    return (
        <div>
            {subTable.getRows().map((subtableRow) => {
                return (
                    <RowPanel<SubTableMetadata>
                        allowEditingForAllPositions={allowEditingForAllPositions}
                        key={subtableRow.getId()}
                        row={subtableRow}
                        renderer={renderer}
                        visible={subtableRow.getId() === selectedId}
                    />
                );
            })}
        </div>
    );
};

export default ModuleRowPanels;
