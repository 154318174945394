import { useMemo } from 'react';
import { useIPO } from '../hooks/useIPO';
import type { PdlContextType } from './PdlContext';

export default (): PdlContextType => {
    const { currentUser, contributingPositions, rootPosition, samtycke, patientPortal, hasPDLInaccessibleChildren } = useIPO();
    return useMemo<PdlContextType>(() => {
        return {
            currentUser,
            positions: contributingPositions,
            rootPosition,
            samtycke,
            patientPortal,
            hasPDLInaccessibleChildren,
        };
    }, [currentUser, contributingPositions, rootPosition, samtycke, patientPortal, hasPDLInaccessibleChildren]);
};
