import { useEffect, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import type { SeriesLineOptions, SeriesOptionsType, SeriesPieOptions, SeriesScatterOptions } from 'highcharts';
import type { ReactElement, ReactNode } from 'react';
import SeriesContext from './SeriesContext';
import { isEqualExclFunctions } from './utils';
import useChartContext from './ChartContext/useChartContext';
import useAxisContext from './AxisContext/useAxisContext';

type SeriesProps = {
    children?: ReactNode,
};

function Series<T extends SeriesScatterOptions | SeriesLineOptions | SeriesPieOptions>(props: SeriesProps & T): ReactElement {
    const { children, ...rest } = props;
    const id = useMemo(() => uniqueId('Series_'), []);
    const chartContext = useChartContext();
    const yAxisContext = useAxisContext();
    const prevConfig = useRef<SeriesOptionsType | null>(null);

    useEffect(() => {
        const seriesConfig = Object.assign({
            id: id,
            yAxis: yAxisContext.id,
            xAxis: 0,
        } as T, rest);

        chartContext.addSeries(seriesConfig, false);
        prevConfig.current = seriesConfig;

        return () => {
            chartContext.removeSeries(id, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const seriesConfig = Object.assign({
            id: id,
            yAxis: yAxisContext.id,
            xAxis: 0,
        } as T, rest);

        if (!isEqualExclFunctions(seriesConfig, prevConfig.current)) {
            chartContext.updateSeries(id, seriesConfig, false);
            prevConfig.current = seriesConfig;
        }
    }, [rest, chartContext, id, yAxisContext.id]);


    return (
        <SeriesContext.Provider value={id}>
            {children}
        </SeriesContext.Provider>
    );
}

export default Series;
