import { useContext } from 'react';
import invariant from 'invariant';
import type { AxisContext as AxisContextType } from '../types';
import AxisContext from './index';

export default (): AxisContextType => {
    const axisContext = useContext(AxisContext);
    invariant(axisContext, 'AxisContext cannot be null.');

    return axisContext;
};
