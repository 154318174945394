import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { RccBsStyle } from '@cancercentrum/rcc-bootstrap';
import { Panel } from '@cancercentrum/rcc-bootstrap';
import last from 'lodash/last';
import type { ReactElement } from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import type { BivExtItemRR } from './types';
import { BiverkningStatusIcon } from './BiverkningStatusIcon';

export const ExtBiverkningRegisterRecordPanel = (props: {
    registerRecord?: BivExtItemRR;
    collapsible: boolean;
}): ReactElement | null => {
    const { registerRecord, collapsible } = props;
    if (!registerRecord) {
        return null;
    }

    const statusToStyle: Record<string, RccBsStyle> = {
        'skickad': 'success',
        'misslyckad_overforing': 'danger',
    };
    const lastSafetyReport = last(registerRecord.subTables.SafetyReport)!;
    const style: RccBsStyle = statusToStyle[lastSafetyReport.data.send_status_Värde] || 'info';
    const panelBody = (
        <Panel.Body>
            <h4 className="mb-4">{lastSafetyReport.data.send_status_Beskrivning} {lastSafetyReport.data.send_date}</h4>
            {!!lastSafetyReport.data.send_error && <div className="text-pre mb-3 ps-2" style={{ borderLeft: '4px solid #f2dede', paddingLeft: '.5em', paddingTop: '.5em' }}>{lastSafetyReport.data.send_error}</div>}
            <div>
                <label>Ansvarig rapportör:</label> {lastSafetyReport.data.primarysource_reportergivename} {lastSafetyReport.data.primarysource_reporterfamilyname}
            </div>

            <div className="mt-4">
                {lastSafetyReport.data.send_status_Värde === 'ej_skickad' && (
                    <p><em>Registreringen kommer att skickas inom 24h.</em></p>
                )}
                {lastSafetyReport.data.send_status_Värde === 'overforing_initierad' && (
                    <p><em>Överföringen till Läkemedelsverket har påbörjats.</em></p>
                )}
                {lastSafetyReport.data.send_status_Värde === 'skickad' && (
                    <p><em>Den utökade rapporten är skickad. Om du vill göra ändringar måste du öppna den befintliga registreringen och sedan klicka på "Ändra inmatade uppgifter".</em></p>
                )}

                {process.env.RCC_ENV !== 'external_demo' && (
                    <div>
                        <FontAwesomeIcon icon={faExternalLinkAlt} className="me-1" />
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={`/RegisterRecord/Details/${registerRecord.data.REGISTERRECORD_ID}`} target="_blank" rel="opener">Öppna registering</a>
                    </div>
                )}
                {process.env.RCC_ENV === 'external_demo' && <div><em>Länken är inte tillgänglig i demomiljön.</em></div>}
            </div>
        </Panel.Body>
    );

    return (
        <Panel bsStyle={style} defaultExpanded={!collapsible}>
            <Panel.Heading>
                <Panel.Title toggle={collapsible}>
                    <BiverkningStatusIcon value={lastSafetyReport.data.send_status_Värde} /> Utökad biverkningsrapportering till Läkemedelsverket
                </Panel.Title>

            </Panel.Heading>
            {collapsible && (
                <Panel.Collapse>{panelBody}</Panel.Collapse>
            )}
            {!collapsible && panelBody}
        </Panel>
    );
};
