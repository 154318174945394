import type { ReactElement } from 'react';
import { useContext } from 'react';
import type { ButtonProps } from '@cancercentrum/rcc-bootstrap';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ReadOnlyContext } from './ReadOnlyContext';

export const RemoveRowButton = (props: {
    text?: string;
} & ButtonProps): ReactElement | null => {
    const {
        text = 'Ta bort',
        ...rest
    } = props;
    const isReadOnly = useContext(ReadOnlyContext);

    if (isReadOnly) {
        return null;
    }

    return <Button bsStyle="danger" {...rest}><FontAwesomeIcon icon={faTrashAlt} className="me-2" />{text}</Button>;
};
