import { type ReactElement, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { SubTableModel, useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { StandardTabHeader } from '../../components/StandardTabHeader';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import { useIPO } from '../../hooks/useIPO';
import type { KontaktRowModel, KontaktSubTable } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { DisplayName, GridId, ModuleName, TableName } from './constants';
import { useGetKontaktSubTable } from './useGetKontaktSubTable';
import { LatestPalsContext } from './LatestPalsContext';
import { getPreviousKontaktRegistration } from './kontaktUtils.ts';

const EMPTY_ARRAY: string[] = [];

interface SenastePALVDData {
    KP_patientansvarigLakare: string;
}

const KontaktTab = (props: {
    highlight?: boolean;
    children: ReactElement;
}): ReactElement => {
    const { children, highlight } = props;
    const ipo = useIPO();
    const palsPromise = usePromise<string[]>();
    const getValueDomainValues = useGetValueDomainValues();
    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
            tableName: TableName,
            gridId: GridId,
        };
    }, []);
    const subTable = useGetKontaktSubTable();
    const subTableRef = useRef<KontaktSubTable>(subTable);
    subTableRef.current = subTable;

    const header = (
        <StandardTabHeader
            displayName={DisplayName}
            rows={subTable.items}
            className={classnames({
                'tab-highlight': highlight,
            })}
        />
    );
    useEffect(() => {
        const handler = (row: KontaktRowModel) => {
            if (row.$rcc.tableName !== TableName) {
                return row;
            }

            const latest = getPreviousKontaktRegistration(subTableRef.current.getRows().toArray());
            if (!latest) {
                return row;
            }

            let res = row;

            // Om diagnosen har kontaktsjuksköterska + namn ska de förifyllas.
            if (ipo.dataset[TableName]?.K_kontaktsjukskoterska && ipo.dataset[TableName]?.K_kontaktsjukskoterska_namn) {
                res = res
                    .setRegvarValue('K_kontaktsjukskoterska', latest.getRegvarValue('K_kontaktsjukskoterska')?.id || null)
                    .setRegvarValue('K_kontaktsjukskoterska_namn', latest.getRegvarValue('K_kontaktsjukskoterska_namn'));
            }

            if (ipo.dataset.KontaktPAL) {
                res = latest.getSubTableRows('KontaktPAL').reduce((acc, r) => acc.addSubTableRow(r.clone()), res);
            }

            return res;
        };

        // console.log('[dbg] adding row handler for kontakt');
        SubTableModel.hooks.createRow.add(handler);

        return () => {
            // console.log('[dbg] removing row handler for kontakt');
            SubTableModel.hooks.createRow.remove(handler);
        };
    }, [subTableRef, ipo]);

    useEffect(() => {
        // Ladda bara senast registrerade PAL:s om diagnosen använder den barntabellen.
        if (ipo.dataset.KontaktPAL) {
            palsPromise.exec(async () => {
                const res = await getValueDomainValues<SenastePALVDData>('VD_senaste_pals', {
                    parameters: {
                        diagnoskonfigurationId: [ipo.diagnosis.id],
                        positionId: [ipo.currentUser.position.id],
                    },
                });

                const pals = res.filter(x => x.data.KP_patientansvarigLakare).map(x => x.data.KP_patientansvarigLakare);

                return uniq(orderBy(pals, [x => x]));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HTab eventKey={ModuleName} header={header}>
            <LatestPalsContext.Provider value={palsPromise.result || EMPTY_ARRAY}>
                <ModuleContext.Provider value={ctx}>
                    <div data-testid={`Module_${ModuleName}`}>
                        {children}
                    </div>
                </ModuleContext.Provider>
            </LatestPalsContext.Provider>
        </HTab>
    );
};

KontaktTab.ModuleName = ModuleName;

export default KontaktTab;
