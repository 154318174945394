import { shallowEqual, useSelector } from 'react-redux';
import type { RowModel } from '@cancercentrum/rcc-react';
import { Alert } from '@cancercentrum/rcc-bootstrap';
import type { MouseEventHandler } from 'react';
import { useSetAtom } from 'jotai';
import { useIPO } from '../../hooks/useIPO';
import { selectedTabAtom } from '../../atoms';
import { ModuleName, TableName } from './constants';

const getUnfinishedLkm = (state: any): RowModel[] => {
    return state.data.present
        .getSubTableRows(TableName)
        .filter((x: RowModel) => !x.getRegvarValue('Lkm_exkluderad') && !x.getRegvarValue('Lkm_migreringsdublett') && !x.getRegvarValue('Lkm_stoppdatum'))
        .toArray();
};

export const UnfinishedLkmNotification = (): JSX.Element | null => {
    const unfinishedLkmRows = useSelector(getUnfinishedLkm, shallowEqual);
    const setActiveTab = useSetAtom(selectedTabAtom);
    const ipo = useIPO();

    const open: MouseEventHandler<HTMLAnchorElement> = (e): void => {
        e.stopPropagation();
        e.preventDefault();

        setActiveTab(ModuleName);
    };

    if (!ipo.patient.dateOfDeath || !unfinishedLkmRows.length) {
        return null;
    }

    return (
        <Alert bsStyle="info">
            <h4>Det finns pågående behandlingar trots att patientens vård är avslutad.</h4>

            <a href="src/form-oversikt/modules/lakemedel" onClick={open} className="alert-link">Läkemedel: {unfinishedLkmRows.length} ej avslutade.</a>
        </Alert>
    );
};
