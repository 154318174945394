import type { ReactElement } from 'react';
import { useMemo } from 'react';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { addWeeks, format } from 'date-fns';
import type { RegvarStringMetadata } from '@cancercentrum/inca';
import type { IPOQuestionProps } from '../../components/IPOQuestion';
import Question from '../../components/IPOQuestion';

interface StartdatumQuestionProps extends IPOQuestionProps {
    regvar: RegvarModel<RegvarStringMetadata>;
}

/**
 * Question för startdatum där maxDate är satt till dagens datum + 4 veckor (IPO-361).
 */
export const StartdatumQuestion = (props: StartdatumQuestionProps): ReactElement => {
    const {
        regvar,
        ...rest
    } = props;
    const maxDate = useMemo(() => {
        return format(addWeeks(new Date(), 4), 'yyyy-MM-dd');
    }, []);

    return (
        <Question
            regvar={regvar}
            required={true}
            maxDate={maxDate}
            beforeToday={false}
            {...rest}
        />
    );
};
