import { useEffect, useState } from 'react';
import { DropdownList } from '@cancercentrum/rcc-react';
import orderBy from 'lodash/orderBy';
import { useAtom } from 'jotai';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import type { SurveyAnswerWithUtskicksprojekt, Utskicksprojekt } from '../types';
import { useGetSelectedSvarAtom } from '../atoms';
import { useIPO } from '../../../hooks/useIPO.ts';
import { SeverityGrade } from './SeverityGrade';
import { shouldShowSummaryFieldHeader, SummaryField } from './SummaryField';
import { Atgardspanel } from './Atgardspanel';
import { SurveyInfo } from './SurveyInfo';
import { SurveyAnswerTextModal } from './SurveyAnswerTextModal.tsx';

export const SurveyViewer = (props: {
    utskicksprojekt: Utskicksprojekt;
    otherProjectsForSameSurvey: Utskicksprojekt[];
}) => {
    const { utskicksprojekt, otherProjectsForSameSurvey } = props;
    // Alla svar för _enkäten_, oavsett utskicksprojekt.
    const answeredSurveys: SurveyAnswerWithUtskicksprojekt[] = orderBy([
        ...utskicksprojekt.answers.map<SurveyAnswerWithUtskicksprojekt>(x => ({
            ...x,
            utskicksprojekt,
        })),
        ...otherProjectsForSameSurvey.flatMap(x => x.answers.map<SurveyAnswerWithUtskicksprojekt>(a => ({
            ...a,
            utskicksprojekt: x,
        }))),
    ].filter(x => !!x.endDate), [x => x.endDate], ['desc']);
    const hasAnswersFromOtherProjects = otherProjectsForSameSurvey.some(x => x.answers.some(a => !!a.endDate));
    const [showText, setShowText] = useState(false);
    const fmt = (item: SurveyAnswerWithUtskicksprojekt) => {
        return (
            <div className="d-flex flex-column">
                <div>Inskickad {item.endDate}</div>
                {hasAnswersFromOtherProjects && <small className="text-muted">{item.utskicksprojekt.ipoName || item.utskicksprojekt.name}</small>}
            </div>
        );
    };
    const svarAtom = useGetSelectedSvarAtom(utskicksprojekt.config.id);
    const [selectedItemId, setSelectedItemId] = useAtom(svarAtom);
    const ipo = useIPO();
    const patient = ipo.patient;

    // Select the newest answer the first time a project is selected.
    useEffect(() => {
        if (!selectedItemId && answeredSurveys[0]?.id) {
            setSelectedItemId(answeredSurveys[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utskicksprojekt]);

    const selectedAnswer = answeredSurveys.find(x => x.id === selectedItemId);

    let olderSurveys: SurveyAnswerWithUtskicksprojekt[] = [];

    if (selectedAnswer) {
        olderSurveys = answeredSurveys.filter(x => x.endDate! <= selectedAnswer.endDate!);
    }

    const onShowText = () => {
        setShowText(true);
    };

    return (
        <div>
            {!!answeredSurveys.length && (
                <div>
                    <div className="d-flex align-items-center gap-2">
                        <DropdownList
                            options={answeredSurveys}
                            onChange={(id: number | null) => setSelectedItemId(id)}
                            value={selectedItemId}
                            formatter={fmt}
                        />
                        {!!selectedItemId && (
                            <div>
                                <Button onClick={onShowText}><FontAwesomeIcon icon={faFileLines} className="pe-2" />Visa svar i textform</Button>
                            </div>
                        )}
                    </div>
                    <hr />
                    {!selectedItemId && (
                        <div>
                            Välj en besvarad enkät i listan ovan.
                        </div>
                    )}

                    {!!selectedItemId && showText && (
                        <div>
                            <SurveyAnswerTextModal
                                patient={patient}
                                onHide={() => setShowText(false)}
                                answer={olderSurveys[0]}
                            />
                        </div>
                    )}

                    {!!selectedItemId && (
                        <>
                            {utskicksprojekt.config.canTakeAction && utskicksprojekt === olderSurveys[0].utskicksprojekt && <Atgardspanel svar={olderSurveys[0]} />}
                            {shouldShowSummaryFieldHeader(olderSurveys[0], utskicksprojekt.survey, utskicksprojekt.config.summaryFields) && <h2>Global hälsa</h2>}
                            {utskicksprojekt.config.summaryFields.map((x, i) => (
                                <SummaryField
                                    key={i}
                                    answer={olderSurveys[0]}
                                    summaryField={x}
                                    metadata={utskicksprojekt.survey}
                                />
                            ))}

                            {utskicksprojekt.config.severityGrades.map((grade, i) => (
                                <SeverityGrade
                                    key={grade.name}
                                    grade={grade}
                                    answers={olderSurveys.map(x => x)}
                                    utskicksprojekt={utskicksprojekt}
                                    showSurveyVersion={i === 0}
                                    showProjectName={i === 0 && hasAnswersFromOtherProjects}
                                />
                            ))}
                        </>
                    )}
                </div>
            )}

            {!answeredSurveys.length && (
                <div>
                    <hr />

                    <p>Enkäten har ännu inte besvarats.</p>
                    <SurveyInfo surveyMetadata={utskicksprojekt.survey} />
                </div>
            )}
        </div>
    );
};
