import { useContext } from 'react';
import { type HookDataActions, ListButtonGroup } from '@cancercentrum/rcc-react';
import Question from '../../components/IPOQuestion';
import Condition from '../../components/IPOCondition.tsx';
import { ReadOnlyContext } from '../../components/ReadOnlyContext';
import useLayoutSettings from '../../hooks/useLayoutSettings';
import type { Biverkningsrapportering, BiverkningsrapporteringRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useIPO } from '../../hooks/useIPO';
import { BiverkningRegistration } from './BiverkningRegistration';
import { BiverkningSubstanceSelector } from './BiverkningSubstanceSelector';
import { BiverkningRowTemplate } from './BiverkningRowTemplate';
import type { AvailableSubstansItem, CTCAEItem, RenderBiverkanCallback } from './types';
import { StralbehandlingSelector } from './StralbehandlingSelector.tsx';
import { ExtBiverkningInfo } from './ExtBiverkningInfo';

const defaultRenderBiverkan: RenderBiverkanCallback = (data) => {
    const { isReadOnly, ctcae, ...rest } = data;

    return <BiverkningRowTemplate isReadOnly={isReadOnly} {...rest} ctcae={ctcae} />;
};

const RowTemplate = (props: {
    row: BiverkningsrapporteringRowModel;
    dataActions: HookDataActions<Biverkningsrapportering>;
    availableSubstanser: AvailableSubstansItem[];
    ctcae: CTCAEItem[];
    renderBiverkan?: RenderBiverkanCallback;
}): JSX.Element => {
    const {
        row,
        dataActions,
        availableSubstanser,
        ctcae,
        renderBiverkan = defaultRenderBiverkan,
    } = props;
    const ipo = useIPO();
    const isReadOnly = useContext(ReadOnlyContext);
    const orsakIdentifier = row.getRegvarValue('Biv_orsak')?.identifier;
    const layoutSettings = useLayoutSettings();
    const enableExtBiverkan = !!layoutSettings.extBiverkan && (!layoutSettings.extBiverkanLimitToPosIds || layoutSettings.extBiverkanLimitToPosIds.includes(ipo.currentUser.position.id));
    const isLkmBiv = orsakIdentifier === 'lakemedel';

    return (
        <div>
            <Question
                regvar={row.getRegvar('Biv_datum')}
                labelClassName="col-sm-3"
                wrapperClassName="col-sm-9"
                label="Startdatum för misstänkt biverkning"

                required={true}
            />

            <Question
                regvar={row.getRegvar('Biv_orsak')}
                labelClassName="col-sm-3"
                wrapperClassName="col-sm-9"
                componentClass={ListButtonGroup}
                labelHelp="Ange den primärt misstänkta orsaken"

                required={true}
            />

            <Condition if={orsakIdentifier === 'annan_orsak'}>
                <div className="followup-questions">
                    <Question
                        regvar={row.getRegvar('Biv_orsak_annan')}
                        labelClassName="col-sm-3"
                        wrapperClassName="col-sm-9"

                        required={true}
                    />
                </div>
            </Condition>

            <Condition if={orsakIdentifier === 'lakemedel'}>
                <BiverkningSubstanceSelector
                    subTable={row.getSubTable('BiverkanSubstans')}
                    biverkanDate={row.getRegvarValue('Biv_datum')}
                    availableSubstanser={availableSubstanser}
                    dataActions={dataActions}
                    disabled={isReadOnly}
                />
            </Condition>

            <Condition if={orsakIdentifier === 'stralbehandling'}>
                <StralbehandlingSelector
                    row={row}
                    dataActions={dataActions}
                    disabled={isReadOnly}
                />
            </Condition>

            <BiverkningRegistration
                subTable={row.getSubTable('Biverkan')}
                renderRow={(data) => renderBiverkan({ ...data, isReadOnly, ctcae })}
            />

            {enableExtBiverkan && isLkmBiv && <ExtBiverkningInfo row={row} className="mt-4 mb-0" />}
        </div>
    );
};

export default RowTemplate;
