import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CurrentUser } from '@cancercentrum/inca';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import StatusCheckbox from '../StatusCheckbox';
import type { OrganisationsenhetVDItem } from '../types';

export const InfoFieldset = (props: {
    currentUser: CurrentUser;
    samtycke: boolean;
    positions: OrganisationsenhetVDItem[];
}) => {
    const { currentUser, samtycke, positions } = props;
    const curPos = positions.find(x => x.id === currentUser.position.id);

    return (
        <fieldset>
            <legend><FontAwesomeIcon icon={faInfoCircle} className="me-2" /> Data som visas</legend>
            <ul className="list-unstyled">
                <li><StatusCheckbox checked={true} className="me-1" /> Data från inloggad användares enhet: {currentUser.position.fullNameWithCode}.</li>
                <li><StatusCheckbox checked={true} className="me-1" /> Skrivskyddad data från andra enheter inom samma vårdgivare: {curPos?.data.CareGiverName || '[uppgift saknas]'}.</li>
                <li><StatusCheckbox checked={samtycke} className="me-1" /> Skrivskyddad data från enheter hos andra vårdgivare.</li>
                <li><StatusCheckbox checked={samtycke} className="me-1" /> Basdata från regionala cancerregister.</li>
            </ul>
        </fieldset>
    );
};
