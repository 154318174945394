import { useState } from 'react';
import { Modal, Button } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import { ModalWrapper } from '../../../../../components/ModalWrapper';

export type Hengs = 'God' | 'Intermediär' | 'Dålig';
export type HengsCloseCallback = (result: {
    hengs: Hengs,
    hengsReason: string,
} | null) => void;

const HengsModal = (props: {
    onClose: HengsCloseCallback;
}): ReactElement => {
    const [timeFromDiagnosis, setTimeFromDiagnosis] = useState(false);
    const [tpk, setTpk] = useState(false);
    const [hb, setHb] = useState(false);
    const [calcium, setCalcium] = useState(false);
    const [neutrofila, setNeutrofila] = useState(false);
    const [karnofsky, setKarnofsky] = useState(false);

    const close = () => {
        props.onClose(null);
    };

    const confirm = () => {
        let count = 0;
        let reason = '';

        if (timeFromDiagnosis) {
            count++;
            reason += 'Tid från diagnos till start av 1:a linjens läkemedelsbehandling < 1 år\n';
        }
        if (tpk) {
            count++;
            reason += 'TPK > övre normalvärde\n';
        }
        if (hb) {
            count++;
            reason += 'HB < undre normalvärde\n';
        }
        if (calcium) {
            count++;
            reason += 'Korrigerat calcium > övre normalvärde\n';
        }
        if (neutrofila) {
            count++;
            reason += 'Neutrofila > övre normalvärde\n';
        }
        if (karnofsky) {
            count++;
            reason += 'Karnofsky < 80\n';
        }

        let hengs: Hengs;

        if (count === 0) {
            hengs = 'God';
        } else if (count <= 2) {
            hengs = 'Intermediär';
        } else {
            hengs = 'Dålig';
        }

        props.onClose({
            hengs: hengs,
            hengsReason: reason,
        });
    };

    return (
        <ModalWrapper show={true} onHide={close} keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Beräkna Hengs kriterie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="checkbox">
                    <label>
                        <input type="checkbox" onChange={e => setTimeFromDiagnosis(e.target.checked)}/> Tid från diagnos till start av 1:a linjens
                        läkemedelsbehandling &lt; 1 år
                    </label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" onChange={e => setTpk(e.target.checked)}/> TPK &gt; övre normalvärde
                    </label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" onChange={e => setHb(e.target.checked)}/> HB &lt; undre normalvärde
                    </label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" onChange={e => setCalcium(e.target.checked)}/> Korrigerat calcium &gt; övre normalvärde
                    </label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" onChange={e => setNeutrofila(e.target.checked)}/> Neutrofila &gt; övre normalvärde
                    </label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" onChange={e => setKarnofsky(e.target.checked)}/> Karnofsky &lt; 80
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close} bsStyle="link">Avbryt</Button>
                <Button onClick={confirm} bsStyle="primary">Beräkna</Button>
            </Modal.Footer>
        </ModalWrapper>
    );
};

export default HengsModal;
