import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Button, InputGroup, OverlayTrigger, Tooltip } from '@cancercentrum/rcc-bootstrap';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { DropdownList, useClearable, useDataActions } from '@cancercentrum/rcc-react';
import type { RegvarValueDomainMetadata } from '@cancercentrum/inca';
import { faExclamationCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { regimFormatter } from '../../formatters';
import type { HierarchifiedSubstansItem, RegimItem } from '../../types';
import SearchRegimByLakemedelModal from './SearchRegimByLakemedelModal';

const searchRegimTooltip = <Tooltip id="SearchRegimTooltip">Sök regim bland samtliga läkemedel.</Tooltip>;

const RegimSelector = (props: {
    regvar: RegvarModel<RegvarValueDomainMetadata>;
    vdRegimer: RegimItem[];
    substanserById: Record<number, HierarchifiedSubstansItem>;
    vdRegimerForDiagnosis: RegimItem[];
    disabled: boolean;
    id?: string;
}) => {
    const {
        regvar,
        vdRegimer,
        substanserById,
        vdRegimerForDiagnosis,
        disabled,
    } = props;
    const [showSearchBySubstans, setShowSearchBySubstans] = useState(false);
    const id = useMemo(() => {
        return props.id || uniqueId('RegimSelector_');
    }, [props.id]);
    const dataActions = useDataActions();

    useClearable((x, options) => x.clearRegvarValue(regvar.$rcc.shortname, options));

    const regimItems = useMemo(() => {
        if (regvar.value && !vdRegimerForDiagnosis.some(x => x.id === regvar.value)) {
            const selectedRegimItem = vdRegimer.find(x => x.id === regvar.value);
            if (selectedRegimItem) {
                return [...vdRegimerForDiagnosis, selectedRegimItem];
            }
        }
        return vdRegimerForDiagnosis;
    }, [vdRegimer, vdRegimerForDiagnosis, regvar.value]);

    const searchRegimBySubstance = () => {
        setShowSearchBySubstans(true);
    };

    const closeSearchRegimBySubstance = (regimId: number | null) => {
        if (regimId) {
            dataActions.updateRow(x => x.setRegvarValue(regvar.$rcc.shortname, regimId));
        }

        setShowSearchBySubstans(false);
    };

    const onSelectRegim = (selectedRegimId: number | null) => {
        dataActions.updateRow(x => x.setRegvarValue(regvar.$rcc.shortname, selectedRegimId));
    };

    const hasRegims = vdRegimerForDiagnosis.length > 0;

    return (
        <div>
            {showSearchBySubstans && <SearchRegimByLakemedelModal vdRegimer={vdRegimer} substanserById={substanserById} onClose={closeSearchRegimBySubstance} />}

            <InputGroup className="regim-dropdown">
                <DropdownList
                    id={id}
                    disabled={disabled || !hasRegims}
                    value={regvar.value}
                    onChange={onSelectRegim}
                    options={regimItems}
                    formatter={regimFormatter}
                />
                <InputGroup.Button>
                    <OverlayTrigger
                        placement="top"
                        popperConfig={{ strategy: 'fixed' }}
                        overlay={searchRegimTooltip}
                    >
                        <Button onClick={searchRegimBySubstance} disabled={disabled}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Button>
            </InputGroup>
            {!hasRegims && (
                <span className="help-block color-warning"><FontAwesomeIcon icon={faExclamationCircle} /> Saknar fastställd regim i regimbiblioteket.</span>
            )}
        </div>
    );
};

export default RegimSelector;
