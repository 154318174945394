import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { isBefore, parseISO } from 'date-fns';
import { lakemedelVdsAtom, useGetLakemedelSubTable } from '../lakemedel';
import type { AvailableSubstansItem } from './types';

export const useGetSubstanceData = () => {
    const lkmSubTable = useGetLakemedelSubTable();
    const lkmLoader = useAtomValue(lakemedelVdsAtom);

    return useMemo(() => {
        const substancesById = lkmLoader.result?.substanserById || {};

        return lkmSubTable.getRows().toArray().reduce<AvailableSubstansItem[]>((reduction, row) => {
            if (row.getRegvarValue('Lkm_exkluderad') || row.getRegvarValue('Lkm_migreringsdublett')) {
                return reduction;
            }

            const subId = row.getRegvarValue('Lkm_substans_vd');
            const subText = row.getRegvarValue('Lkm_studielakemedel_spec');
            const text = subText || substancesById[subId || -1]?.data.sub_subnamnrek || `Substans-id ${subId}`;
            const startdatum = row.getRegvarValue('Lkm_startdatum')!;
            let item = reduction.find(x => (x.type === 'vd' && subId === x.substanceId) || (x.type === 'name' && text === x.substanceText));

            if (!item) {
                item = subId
                    ? {
                        type: 'vd',
                        id: row.getId().toString(),
                        startdatum,
                        substanceId: subId,
                        substanceText: substancesById[subId]?.data.sub_subnamnrek || `Substans-id ${subId}`,
                    }
                    : {
                        type: 'name',
                        id: row.getId().toString(),
                        startdatum,
                        substanceText: text || 'Uppgift saknas',
                    };

                reduction.push(item);
            } else if (isBefore(parseISO(startdatum), parseISO(item.startdatum))) {
                item.startdatum = startdatum;
            }

            return reduction;
        }, []);

    }, [lkmSubTable, lkmLoader]);
};
