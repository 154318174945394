import type { ReactElement, ReactNode } from 'react';
import { memo, useCallback } from 'react';
import { Alert } from '@cancercentrum/rcc-bootstrap';
import { Explanation } from '@cancercentrum/rcc-react';
import { useSelector } from 'react-redux';
import HTab from '../../../components/HTabs/HTab';
import usePDL from '../../pdl/usePDL';
import { isDirty } from '../../utils/rowmodel';
import type { OverviewState } from '../../types';
import { DisplayName, ModuleName } from './constants';

export const BasdataTab = (props: {
    children: ReactNode;
    vdShortname: string;
}): ReactElement => {
    const { children, vdShortname } = props;
    const pdlCtx = usePDL();
    let header: ReactNode = DisplayName;
    const dirtySelector = useCallback((state: OverviewState) => {
        if (!vdShortname) {
            return false;
        }

        return state.data.present.getSubTableRows('Basdata').some(x => isDirty(x!));
    }, [vdShortname]);
    const dirty = useSelector(dirtySelector);

    if (dirty) {
        header = (
            <>
                <span className="me-1">
                    <Explanation>Innehåller osparade ändringar</Explanation>
                </span>
                {DisplayName}
            </>
        );
    }

    return (
        <HTab eventKey={ModuleName} header={header}>
            {pdlCtx.samtycke && (
                <div className="basdata">
                    {children}
                </div>
            )}
            {!pdlCtx.samtycke && (
                <Alert bsStyle="info">Basdata kan inte laddas eftersom patientens samtycke saknas.</Alert>
            )}
        </HTab>
    );
};

export default memo(BasdataTab);
