import { useDispatch } from 'react-redux';
import { GridActions } from '@cancercentrum/rcc-react';
import { parseISO } from 'date-fns';
import type { ReactElement } from 'react';
import { useSetAtom } from 'jotai';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import { Point } from '../../components/Chart/Point';
import { getUTCStartOfDayTime, isValidDate } from '../../utils/date';
import { selectedTabAtom } from '../../atoms';
import { ModuleName } from './constants';
import { getGridId } from './utils';
import { useGetBasdataResult } from './useGetBasdataResult';
import { basdataTabAtom } from './atoms';

interface VDLike {
    id: number;
    data: any;
}

export type RenderBasdataTooltipCallback<TFilter> = (item: TFilter) => ReactElement

function defaultConvertFn<T extends VDLike, T2 extends VDLike = T>(items: T[]) {
    return items as unknown as T2[];
}

const defaultVdResultFilter = () => true;

export function BasdataSeries<T extends VDLike, TFilter extends VDLike = T>(props: {
    convertFn?: (items: T[]) => TFilter[];
    renderTooltip: RenderBasdataTooltipCallback<TFilter>;
    gridId: string;
    ajaxLoaderResultProp?: string;
    pillEventKey: string;
    labelText?: string;
    dateField?: string;
    getDate?: (item: TFilter) => string | null | undefined;
    vdResultFilter?: (item: T) => boolean;
}): ReactElement {
    const {
        ajaxLoaderResultProp = 'rotdata',
        convertFn = defaultConvertFn,
        renderTooltip,
        gridId,
        pillEventKey,
        dateField,
        labelText,
        getDate,
        vdResultFilter = defaultVdResultFilter,
    } = props;
    const dispatch = useDispatch();
    const items = useGetBasdataResult<T>(ajaxLoaderResultProp);
    const convertedItems = convertFn(items.filter(vdResultFilter));
    const setBasdataTab = useSetAtom(basdataTabAtom);
    const setActiveTab = useSetAtom(selectedTabAtom);

    const points = convertedItems.reduce<ReactElement[]>((reduction, x) => {
        const dateStr = getDate ? getDate(x) : x.data[dateField || '__MISSING__'];
        if (!isValidDate(dateStr)) {
            return reduction;
        }

        const tooltip = renderTooltip(x);
        const marker = {
            symbol: 'circle',
            radius: 8,
            lineWidth: 1,
            lineColor: '#fff',
        };

        reduction.push((
            <Point
                key={x.id}
                x={getUTCStartOfDayTime(parseISO(dateStr || ''))}
                y={0}
                color="#000"
                marker={marker}
                renderTooltip={() => tooltip}
                labelText={labelText}
                events={{
                    click() {
                        setActiveTab(ModuleName);
                        setBasdataTab(pillEventKey);
                        dispatch(GridActions.setSelectedItemId(getGridId(gridId), x.id));
                    },
                }}
            />
        ));

        return reduction;
    }, []);

    return (
        <ScatterSeries>
            {points}
        </ScatterSeries>
    );
}
