import { createSelector } from 'reselect';
import { isValidDate } from '../../utils/date';
import type { OverviewState } from '../../types';
import { TableName } from './constants';

export const getKontaktSubTableSelector = (state: OverviewState) => state.data.present.getSubTable(TableName);

export const getRowsWithDate = createSelector(
    getKontaktSubTableSelector,
    subTable => subTable.getRows().toArray().filter(x => isValidDate(x!.getRegvarValue('K_besoksdatum'))),
);

export const getEcogRows = createSelector(
    getRowsWithDate,
    kontaktRows => kontaktRows.filter(x => x.getRegvar('K_ecog').value),
);
