import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AjaxErrorAlert, Grid } from '@cancercentrum/rcc-react';
import type { IncaAjaxError } from '@cancercentrum/inca';
import { errandUtils, incaAccessor } from '@cancercentrum/inca';
import type { GridColumn, OverviewState } from '../../types';
import { useIPO } from '../../hooks/useIPO';
import { getGridId } from './utils';
import { ButtonActionGroup } from './ButtonActionGroup';
import { useGetBasdataResult } from './useGetBasdataResult';

interface VDLike {
    id: number;
    data: any;
}

function defaultConvertFn<VDType extends VDLike, VDType2 extends VDLike>(items: VDType[]): VDType2[] {
    return items as unknown as VDType2[];
}

export function BasdataTabContent<VDType extends VDLike = VDLike, VDType2 extends VDLike = VDType>(props: {
    convertFn?: (items: VDType[]) => VDType2[];
    gridId: string;
    gridColumns: GridColumn<VDType2>[];
    filter?: (item: VDType2) => boolean;
    renderRow?: (opts: { vdRow: VDType2 }) => ReactNode;
    registerName?: string;
    formName?: string;
    dataProp?: string;
}): JSX.Element {
    const {
        gridColumns,
        renderRow,
        registerName,
        formName,
        filter,
        dataProp = 'rotdata',
        convertFn = defaultConvertFn,
    } = props;
    const ipo = useIPO();
    const gridId = getGridId(props.gridId);
    const selectedGridIdSelector = useCallback((state: OverviewState) => state.grid[gridId]?.selectedItemId ?? null, [gridId]);
    const selectedRowId = useSelector(selectedGridIdSelector);
    const vdResult = useGetBasdataResult<VDType>(dataProp);
    const [error, setError] = useState<IncaAjaxError | Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const convertedRows = convertFn(vdResult);
    const rows = filter ? convertedRows.filter(filter) : convertedRows;

    const createNew = () => {
        setError(null);
        setIsLoading(true);

        errandUtils.createErrand(registerName!, formName!, ipo.patient.id).then(res => {
            // Denna kod körs endast om vi är på INCAs sök/registrera, vi vet således att översiktstabbarna stöds.
            const inca = incaAccessor();
            inca.overview.tabs.add(tabId => {
                inca.overview.tabs.setContent(tabId, 'errand', res.id);
                inca.overview.tabs.activate(tabId);
            });
        }).catch(err => {
            setError(err);
            setIsLoading(false);
        }).then(() => {
            setIsLoading(false);
        });
    };

    const selectedRow = rows.find(x => x.id === selectedRowId);

    return (
        <div>
            <hr />
            {(!!selectedRow && !!renderRow) && renderRow({ vdRow: selectedRow })}
            <Grid id={gridId} columns={gridColumns as any[]} rows={rows} selectable={true} />

            <div className="my-3">
                <ButtonActionGroup
                    canCreateNew={!!(registerName && formName)}
                    onCreateNew={createNew}
                    disabled={isLoading}
                />
            </div>
            {error && <AjaxErrorAlert error={error} />}
        </div>
    );
}
