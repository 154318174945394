import { isFuture } from 'date-fns';
import type { ListItem } from '@cancercentrum/inca';
import { formatIncaDate } from '../../utils/date';
import { buildString, formatIf } from '../../components/TextRepresentation';
import type { RenderTooltipOptions } from './types';

export const formatHeader = (options: RenderTooltipOptions): string => {
    return `<strong>${options.data.name}</strong>`;
};

/**
 * Returnerar stoppdatum som visas i tooltip. Om ingen rad har stoppdatum används dagens datum.
 */
export const getLkmStopDateTooltipString = (endDate?: Date) => {
    let endDateStr: string;
    if (endDate) {
        if (isFuture(endDate)) {
            endDateStr = `Planerad utsättning ${formatIncaDate(endDate)}`;
        } else {
            endDateStr = formatIncaDate(endDate);
        }
    } else {
        endDateStr = 'Pågående';
    }
    return endDateStr;
};

/**
 * Default formatteringsmetod för start - stopp i tooltip.
 */
export const formatDatum = (options: RenderTooltipOptions): string => {
    const start = formatIncaDate(options.data.start);
    const stop = getLkmStopDateTooltipString(options.data.end);

    return `Datum: ${start} - ${stop}`;
};


/**
 * Default formatteringsmetod för läkemedelskategori i tooltip.
 */
export const formatCategory = (options: RenderTooltipOptions): string => {
    return `Kategori: ${options.data.category.text}`;
};

/**
 * Default formatteringsmetod för ECOG i tooltip.
 */
export const formatEcog = (options: RenderTooltipOptions): string | null => {
    const firstRow = options.data.rows[0].row;

    return formatIf(firstRow.getRegvarValue('Lkm_ecog') as ListItem | null, x => `ECOG vid insättning: ${x.value}`);
};


/**
 * Default formatteringsmetod för startdos i tooltip.
 */
export const formatStartdos = (options: RenderTooltipOptions): string | null => {
    const startdos = options.data.startdos;

    return formatIf(startdos, x => `Startdos: ${x.text}`);
};


export const formatBehInt = (options: RenderTooltipOptions): string | null => {
    const behInt = (options.data.rows[0].row.getRegvarValue('Lkm_behandlingsintention') as ListItem | null)?.text;
    const behIntSpec = (options.data.rows[0].row.getRegvarValue('Lkm_behandlingsintention_spec') as ListItem | null)?.text;

    if (!behInt) {
        return null;
    }

    return `Beh.intention: ${buildString(', ', [
        behInt,
        behIntSpec,
    ])}`;
};

export const formatStudie = (options: RenderTooltipOptions): string | null => {
    const vdStudier = options.vdStudier;
    const studieId = options.data.rows[0].row.getRegvarValue('Lkm_studie_vd') as number | null;
    const studie = studieId ? vdStudier.find(x => x.id === studieId) : null;

    return studieId ? `Studie: ${studie?.data.namn || '[namn saknas]'}` : null;
};

export const formatResultatAvblindad = (options: RenderTooltipOptions): string | null => {
    const res = options.data.rows[0].row.getRegvarValue('Lkm_avblindatresultat') as ListItem | null;

    return res ? `Resultat efter avblindad studie: ${res.text}` : null;
};

export const formatStopporsak = (options: RenderTooltipOptions): string | null => {
    const orsak = (options.data.rows[0].row.getRegvarValue('Lkm_stopporsak') as ListItem | null)?.text;
    const orsakSpec = options.data.rows[0].row.getRegvarValue('Lkm_stopporsakSpec') as string | null;

    return orsak ? `Stopporsak: ${orsak + (orsakSpec ? ` (${orsakSpec})` : '')}` : null;
};

export const formatStadium = (options: RenderTooltipOptions): string | null => {
    const stadium = (options.data.rows[0].row.getRegvarValue('Lkm_stadiumVidStart') as ListItem | null)?.text;

    return stadium ? `Stadium vid start: ${stadium}` : null;
};
