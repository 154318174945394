import { memo, useEffect, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import set from 'lodash/set';
import type { YAxisOptions } from 'highcharts';
import type { ReactNode } from 'react';
import AxisContext from './AxisContext';
import { isEqualExclFunctions } from './utils';
import useAxisContext from './AxisContext/useAxisContext';
import useChartContext from './ChartContext/useChartContext';

interface ExtendedAxisOptions extends YAxisOptions {
    xAxisId?: string; // saknas i officiella typings från highcharts, men är en giltig prop som behövs för att lösningen ska fungera
}

type YAxisProps = {
    onSetExtremes?: () => void,
    onRedraw?: () => void,
    children?: ReactNode
} & YAxisOptions;

const YAxis = (props: YAxisProps) => {
    const {
        onSetExtremes,
        onRedraw,
        children,
        labels = {
            style: {
                fontSize: '12px',
            },
        },
        ...axisOptions
    } = props;
    const prevAxisOpts = useRef<YAxisOptions | undefined>();

    const ctxValue = useMemo(() => ({
        id: props.id || uniqueId('YAxis_'),
    }), [props.id]);
    const chartContext = useChartContext();
    const xAxisContext = useAxisContext();

    useEffect(() => {
        const axisProps: ExtendedAxisOptions = {
            id: ctxValue.id,
            labels: labels,
            ...axisOptions,
            xAxisId: xAxisContext.id,
        };

        if (onRedraw) {
            chartContext.registerEvent('redraw', onRedraw);
        }

        if (onSetExtremes) {
            chartContext.registerEvent('selection', onSetExtremes);
            chartContext.registerXAxisEvent('setExtremes', onSetExtremes);
            set(axisProps, 'events.afterSetExtremes', onSetExtremes);
        }

        chartContext.addAxis(axisProps, false, false);
        prevAxisOpts.current = axisProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const curOptions: ExtendedAxisOptions = {
            id: ctxValue.id,
            labels: labels,
            ...axisOptions,
            xAxisId: xAxisContext.id,
        };

        if (onSetExtremes) {
            set(curOptions, 'events.afterSetExtremes', onSetExtremes);
        }

        if (!isEqualExclFunctions(curOptions, prevAxisOpts.current)) {
            chartContext.updateAxis(ctxValue.id, curOptions);
            prevAxisOpts.current = curOptions;
        }
    }, [axisOptions, chartContext, ctxValue.id, labels, onSetExtremes, xAxisContext.id]);

    return (
        <AxisContext.Provider value={ctxValue}>
            {children}
        </AxisContext.Provider>
    );
};

export default memo(YAxis);
