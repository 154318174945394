import { DropdownButton, MenuItem } from '@cancercentrum/rcc-bootstrap';
import { useLakemedelGraphMode } from '../lakemedel/useLakemedelGraphMode';
import type { LakemedelChartDisplayModes } from './constants';
import { LakemedelChartDisplayMode } from './constants';

const texts = {
    [LakemedelChartDisplayMode.Regimer]: 'Regimer och enskilda substanser',
    [LakemedelChartDisplayMode.Substanser]: 'Substanser',
} satisfies Record<LakemedelChartDisplayModes, string>;

export const LakemedelModeSelector = () => {
    const [selected, setSelected] = useLakemedelGraphMode();

    return (
        <DropdownButton
            className="btn-group"
            title={texts[selected]}
            bsSize="sm"
            id="LakemedelModes"
        >
            {[LakemedelChartDisplayMode.Regimer, LakemedelChartDisplayMode.Substanser].map(mode => (
                <MenuItem
                    key={mode}
                    onClick={() => setSelected(mode)}
                >
                    {texts[mode]}
                </MenuItem>
            ))}
        </DropdownButton>
    );
};
