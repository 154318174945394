import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import type { AnswerFormatter, ChartCategory, QuestionMetadata, SeverityGrade, Utskicksprojekt, UtskicksprojektConfig } from './types';

export const getValueAsText: AnswerFormatter = (answer, question, surveyMetadata): string => {
    const rawAnswer = answer.data[question.shortname];
    let res = rawAnswer;

    if (question.dataType === 'list') {
        const l = surveyMetadata.lists[question.listUuid!];
        if (l) {
            if (Array.isArray(rawAnswer)) {
                res = rawAnswer.map(v => l.listValues.find(x => x.value === v)?.text || v).join(', ');
            } else {
                res = l.listValues.find(x => x.value === rawAnswer)?.text || rawAnswer;
            }
        } else {
            res = rawAnswer;
        }
    }

    return res;
};

export const getDisplayName = (proj: Utskicksprojekt): string => proj.ipoName || proj.name;

export const getMessageTitle = (proj: Utskicksprojekt): string => getDisplayName(proj);

export const getDefaultCategory = (sortOrder: number): ChartCategory => {
    return {
        category: proj => getDisplayName(proj),
        sortOrder: sortOrder,
    };
};

export enum Severity {
    VeryBad,
    Bad,
    SomewhatGood,
    Great
}

export type QuestionMapping = { shortnames: string[], mappings: Record<any, Severity> };
export const getGradeColor = (severity: Severity) => {
    switch (severity) {
        case Severity.VeryBad:
            return '#B70904';
        case Severity.Bad:
            return '#FFB117';
        case Severity.SomewhatGood:
            return '#B2D7AF';
        case Severity.Great:
            return '#19975D';
    }

    return '#ccc';
};

export const getSeverityGrades = (questionMappings: QuestionMapping[], ...customGrades: SeverityGrade[]): SeverityGrade[] => {
    const isInCustomGrade = (sn: string, answer: any, metadata: QuestionMetadata) => {
        return !!customGrades.length && customGrades.some(cg => cg.evaluateAnsweredQuestion(sn, answer, metadata));
    };

    return [
        ...customGrades,
        {
            color: getGradeColor(Severity.VeryBad),
            name: 'Svåra problem',
            evaluateAnsweredQuestion: (sn, answer) => questionMappings.some(x => x.shortnames.includes(sn) && x.mappings[answer] === Severity.VeryBad),
        },
        {
            color: getGradeColor(Severity.Bad),
            name: 'Medelsvåra problem',
            evaluateAnsweredQuestion: (sn, answer) => questionMappings.some(x => x.shortnames.includes(sn) && x.mappings[answer] === Severity.Bad),
        },
        {
            color: getGradeColor(Severity.SomewhatGood),
            name: 'Lätta problem',
            evaluateAnsweredQuestion: (sn, answer) => questionMappings.some(x => x.shortnames.includes(sn) && x.mappings[answer] === Severity.SomewhatGood),
        },
        {
            color: getGradeColor(Severity.Great),
            name: 'Inga problem',
            evaluateAnsweredQuestion: (sn, answer) => questionMappings.some(x => x.shortnames.includes(sn) && x.mappings[answer] === Severity.Great),
        },
        {
            color: '#ccc',
            name: 'Ej färgkodade frågor / svar',
            evaluateAnsweredQuestion: (sn, answer, metadata) => !isInCustomGrade(sn, answer, metadata) &&
                !questionMappings.some(x => x.shortnames.includes(sn) && !isNil(x.mappings[answer])),
        },
    ];
};

export const getPosCode = (fullnameWithCode: string): string => {
    const r = /\(([\w\s-]+)\)(?:$| -)/g;
    const res = [];
    let match;
    // eslint-disable-next-line no-cond-assign
    while (match = r.exec(fullnameWithCode || '')) {
        res.push(match[1]);
    }
    return res.length ? res.join(' - ') : fullnameWithCode;
};

export const getAllFollowUpQuestions = (conf: UtskicksprojektConfig) => {
    return uniq(Object.values(conf.followupQuestions ?? {}).reduce((red, arr) => [...red, ...arr], []));
};

export const generatePdfBlob = async (shortname: string, data: {
    namn: string;
    postnr: string;
    adress: string;
    postadress: string;
    enkatkod: string;
    kod: string;
    enkatnamn: string;
}): Promise<Blob> => {
    const res = await fetch('/api/ScriptReports/Shortname/' + shortname + '/Generate', {
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            link: false,
            filename: shortname,
            data: JSON.stringify(data),
            forceRegenerate: true,
        }),
        credentials: 'same-origin',
    });

    if (res.ok) {
        return await res.blob();
    }

    throw await res.text();
};
