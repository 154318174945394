import isNil from 'lodash/isNil';
import type { ListItem, RegvarMetadata } from '@cancercentrum/inca';
import type { RegvarModel } from '@cancercentrum/rcc-react';

export type RegvarValueTypes = string | number | boolean | ListItem;
export type Formatter<T extends RegvarValueTypes> = (input: T) => string;

export const buildStringArray = <T>(parts: (T | null | undefined)[]): T[] => {
    return parts.filter(x => !isNil(x) && (x as unknown as string) !== '') as T[];
};

export const buildString = (sep: string, parts: unknown[], options?: {
    suffix?: string;
}): string => {
    const res = buildStringArray(parts).join(sep);

    if (res && options?.suffix) {
        return res + options.suffix;
    }

    return res;
};

export const cond = <T>(pred: T | null | undefined, truthy: ((value: T) => string | null), falsy: () => string | null): string | null => {
    if (isNil(pred)) {
        return falsy();
    }

    if (typeof pred === 'string' && pred === '') {
        return falsy();
    }

    if (typeof pred === 'boolean' && !pred) {
        return falsy();
    }

    return truthy(pred);
};

export const formatIf = <T>(input: T | null | undefined, fmt: ((value: T) => string | null), defaultValue: string | null = ''): string | null => {
    return cond(input, fmt, () => defaultValue);
};

export const regvarIf = <TVal extends RegvarValueTypes>(rv: RegvarModel<RegvarMetadata>, options?: {
    label?: string;
    formatter: Formatter<TVal>;
}): string | null => {
    const {
        label,
        formatter = ((v: TVal): string => v.toString()),
    } = options || {};
    const val = rv.getValue() as TVal | null;
    const fmt = (v: TVal): string => `${label || rv.$rcc.regvarMetadata.label}: ${formatter(v)}`;

    return formatIf<TVal>(val, fmt);
};
