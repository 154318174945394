import { parseISO } from 'date-fns';
import type { ListItem } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import { useSetAtom } from 'jotai';
import { Point } from '../../components/Chart/Point';
import { getUTCStartOfDayTime } from '../../utils/date';
import { selectedTabAtom } from '../../atoms';
import { ModuleName } from './constants';

export interface PointDef {
    date: string;
    listValue: ListItem;
    tooltip: ReactElement;
}

const SjukdomsstadiumPoint = (props: {
    point: PointDef;
    y: number;
}): ReactElement => {
    const setActiveTab = useSetAtom(selectedTabAtom);
    const { point, y } = props;
    const date = getUTCStartOfDayTime(parseISO(point.date));
    const lv = point.listValue;
    const value = lv.value;
    const tooltip = point.tooltip;
    const marker = {
        symbol: 'circle',
        radius: 8,
        lineWidth: 1,
        lineColor: '#fff',
    };

    return (
        <Point
            x={date}
            y={y}
            labelText={value}
            color="#000"
            marker={marker}
            renderTooltip={() => tooltip}
            events={{
                click() {
                    setActiveTab(ModuleName);
                },
            }}
        />
    );
};

export default SjukdomsstadiumPoint;
