import { apiRequest } from '@cancercentrum/inca';
import { AjaxErrorAlert, Explanation, usePromise } from '@cancercentrum/rcc-react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import type { MouseEventHandler, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faLockOpen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useIPO } from '../hooks/useIPO';
import type { CareGiver, PdlBlock } from '../../types';
import { fancyConfirm } from '../../utils/fancyConfirm';
import usePDL from './usePDL';

const CONFIRM_TEXT = `Härmed bekräftar jag att patientens samtycke inte kan inhämtas och informationen kan antas ha betydelse för den vård som patienten oundgängligen behöver.

Nödåtkomsten gäller i 24 timmar.

Översikten behöver laddas om för att eventuellt spärrad data ska visas. All nödåtkomst loggas.`;

const RestrictedRow = (props: {
    item: PdlBlock;
    careGiver?: CareGiver;
}): ReactElement => {
    const { item, careGiver } = props;
    const ipo = useIPO();
    const p = usePromise();

    const onClick = (hsaId: string) => {
        fancyConfirm({
            header: 'Nödåtkomst',
            content: CONFIRM_TEXT,
        }).then(ok => {
            if (ok) {
                p.exec(() => {
                    return apiRequest(`/Users/Current/Patients/${ipo.patient.id}/RevokeBlock`, {
                        method: 'POST',
                        json: {
                            hsaId: hsaId,
                        },
                    }).then(() => true).catch(err => {
                        alert(err.message);
                    });
                });
            }
        });
    };

    return (
        <tr>
            <td className="nostretch">
                {!p.result && item.isInCareGiver && (
                    <Button
                        bsSize="xs"
                        onClick={() => onClick(item.careUnitHsaId)}
                        disabled={p.isLoading}
                    >
                        <FontAwesomeIcon icon={p.isLoading ? faSpinner : faLockOpen} spin={p.isLoading} className="me-2" />
                        Begär nödåtkomst
                    </Button>
                )}
                {!!p.result && <em>Nödåtkomst begärd</em>}
                {!item.isInCareGiver && (
                    <Explanation className="mw-500px">
                        <p>Hävning av spärr är inte möjlig på grund av att spärren hör till en annan vårdgivare.</p>
                    </Explanation>
                )}
            </td>
            <td>
                {!!careGiver && <div data-hsaid={careGiver.hsaId}>{careGiver.name}</div>}
                {!careGiver && <div>{item.careProviderHsaId}</div>}
            </td>
            <td>
                {!!item.position && <div data-careunithsaid={item.careUnitHsaId}>{item.position.fullNameWithCode}</div>}
                {!item.position && <div>{item.careUnitHsaId}</div>}
            </td>
        </tr>
    );
};

const RestrictionTable = (props: {
    blocks: PdlBlock[]
    careGiversByHsa: Record<string, CareGiver>;
}): ReactElement => {
    const { blocks, careGiversByHsa } = props;

    if (!blocks.length) {
        return <p className="mt-3 mb-0"><em>Inga enheter där spärrad data finns hittades.</em></p>;
    }

    return (
        <div className="table-responsive">
            <table className="table mb-0">
                <caption>Enheter där spärrad data för patienten finns</caption>
                <thead>
                <tr>
                    <th />
                    <th>
                        <div className="d-flex align-items-center gap-2">
                            Vårdgivare
                            <Explanation>
                                <p>Om vårdgivaren finns på INCA visas namnet, annars dess HSA-id.</p>
                            </Explanation>
                        </div>
                    </th>
                    <th>
                        <div className="d-flex align-items-center gap-2">
                            Vårdenhet
                            <Explanation>
                                <p>Om vårdenheten/kliniken finns på INCA visas namnet, annars dess HSA-id.</p>
                            </Explanation>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {blocks.map((x, i) => <RestrictedRow key={i} item={x} careGiver={careGiversByHsa[x.careProviderHsaId]} />)}
                </tbody>
            </table>
        </div>
    );
};

export const RestrictedDataFieldset = (props: {
    className?: string;
}): ReactElement => {
    const ipo = useIPO();
    const { className } = props;
    const p = usePromise<{ blocks: PdlBlock[], careGiversByHsa: Record<string, CareGiver> }>();
    const pdl = usePDL();

    const onClick: MouseEventHandler = e => {
        e.stopPropagation();
        e.preventDefault();

        p.exec(async () => {
            const [blocks, careGivers] = await Promise.all([
                apiRequest<PdlBlock[]>(`/Users/Current/Patients/${ipo.patient.id}/Blocks`),

                // The CareGivers API isn't documented. Unfortunately there is no administrative register that can be used.
                // The catch is a guard so this page still is usable even if the API stops working.
                apiRequest<CareGiver[]>(`/CareGivers`).catch(() => {
                    return [] as CareGiver[];
                }),
            ]);

            const careGiversByHsa = careGivers.reduce<Record<string, CareGiver>>((acc, cg) => {
                if (cg.hsaId) {
                    acc[cg.hsaId] = cg;
                }

                return acc;
            }, {});


            const q = new URLSearchParams(window.top!.location.search);

            if (process.env.NODE_ENV === 'development') {
                if (q.get('sparradData') === 'true') {
                    blocks.push(...[
                        {
                            isInCareGiver: true,
                            endDate: '',
                            careProviderHsaId: 'SE2321000131-E000000000001',
                            careUnitHsaId: '1234567',
                            startDate: '2021-01-01',
                            position: {
                                id: 11,
                                name: 'Kirurgen',
                                code: '301',
                                fullNameWithCode: 'OC Demo (0) - SU/Sahlgrenska (500010) - Kirurgen (301)',
                                isEnabled: true,
                            },
                        },
                        {
                            isInCareGiver: true,
                            endDate: '',
                            careProviderHsaId: 'SE2321000131-E000000000001',
                            careUnitHsaId: '1234568',
                            startDate: '2021-03-01',
                            position: {
                                id: 22,
                                name: 'kvinnoklinik ',
                                code: '451',
                                fullNameWithCode: 'OC Demo (0) - Lund (410010) - kvinnoklinik  (451)',
                                isEnabled: true,
                            },
                        },
                        {
                            isInCareGiver: true,
                            endDate: '',
                            careProviderHsaId: 'SE2321000131-E000000000001',
                            careUnitHsaId: '1234568',
                            startDate: '2021-05-01',
                            position: null,
                        },
                        {
                            isInCareGiver: false,
                            endDate: '',
                            careProviderHsaId: '4444',
                            careUnitHsaId: '9999',
                            startDate: '2021-03-01',
                            position: {
                                id: 33,
                                name: 'Oklar klinik någon annanstans',
                                code: '123',
                                fullNameWithCode: 'Oklar klinik någon annanstans (123)',
                                isEnabled: true,
                            },
                        },
                        {
                            isInCareGiver: false,
                            endDate: '',
                            careProviderHsaId: '4444',
                            careUnitHsaId: '9999',
                            startDate: '2021-03-01',
                            position: null,
                        },
                    ]);
                }
            }

            return {
                blocks,
                careGiversByHsa,
            };
        });
    };

    return (
        <fieldset className={className}>
            <legend><FontAwesomeIcon icon={faBan} /> Spärrad data</legend>
            {pdl.hasPDLInaccessibleChildren && (
                <>
                    <p>Det finns data för patienten som är spärrad till andra vårdenheter. Du kan kontrollera vilka vårdenheter som har spärrad data.
                        Samtliga anrop loggas!</p>
                    <p>Denna kontroll visar vilka kliniker som har spärrad data för patienten, inte nödvändigtvis enbart i patientöversikten.</p>
                    <Button onClick={onClick} disabled={p.isLoading}>
                        {p.isLoading && <FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />}
                        Kontrollera vilka spärrar som finns
                    </Button>
                    {!!p.error && <AjaxErrorAlert error={p.error} className="mt-3 mb-0" />}
                    {!!p.result && <RestrictionTable blocks={p.result.blocks} careGiversByHsa={p.result.careGiversByHsa} />}
                </>
            )}

            {!pdl.hasPDLInaccessibleChildren && (
                <p>Det finns inga aktiva spärrar för patienten som påverkar denna registrering.</p>
            )}
        </fieldset>
    );
};
