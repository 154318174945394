import type { ReactElement } from 'react';
import { memo } from 'react';
import { Condition, ListButtonGroup } from '@cancercentrum/rcc-react';
import Question from '../../../../components/IPOQuestion';
import { StartdatumQuestion } from '../../StartdatumQuestion';
import { ShowStopSpecIdentifiers } from '../../constants.ts';
import Kurlangd from './Kurlangd';
import type { RenderEditContentData } from './SubstanceRow';

const EditLakemedel = (props: RenderEditContentData): ReactElement => {
    const { row } = props;

    return (
        <form className="form-horizontal">
            <StartdatumQuestion
                regvar={row.getRegvar('Lkm_startdatum')}
                labelClassName="col-md-3"
                wrapperClassName="col-md-9"
            />

            <fieldset>
                <legend>Avsluta behandling</legend>
                <Kurlangd row={row} />

                <Question
                    regvar={row.getRegvar('Lkm_stoppdatum')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    beforeToday={false}
                    minDate={row.getRegvarValue('Lkm_startdatum')}
                    required={!!row.getRegvarValue('Lkm_stopporsak')}
                />
                <Question
                    regvar={row.getRegvar('Lkm_stopporsak')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    componentClass={ListButtonGroup}
                    required={!!row.getRegvarValue('Lkm_stoppdatum')}
                    vertical={true}
                />

                <Condition if={ShowStopSpecIdentifiers.includes(row.getRegvarValue('Lkm_stopporsak')?.identifier)}>
                    <div className="followup-questions">
                        <Question
                            regvar={row.getRegvar('Lkm_stopporsakSpec')}
                            className="input-long"
                            labelClassName="col-md-3"
                            wrapperClassName="col-md-9"
                        />
                    </div>
                </Condition>
            </fieldset>
        </form>
    );
};

export default memo(EditLakemedel);
