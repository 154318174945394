import isNil from 'lodash/isNil';
import type { ReactElement, ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const cond = (pred: any, textOrGetText?: string | ((predResult: any) => string)): string | undefined => {
    if (pred) {
        if (typeof textOrGetText === 'function') {
            return textOrGetText(pred);
        }

        return textOrGetText || pred.toString();
    }

    return undefined;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ifelse<T>(pred: T | null | undefined, truthy: (x: T) => string, falsy: string): string {
    if (pred) {
        return truthy(pred);
    }

    return falsy;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const regvar = (val: any, label: string): string => {
    return (isNil(val) || val === '') ? '' : `${label}: ${val}`;
};

export const section = (header: ReactElement, items: ReactNode[]): ReactNode[] => {
    if (items.some(x => x)) {
        return [header, ...items];
    }

    return [];
};
