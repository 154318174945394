import { OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import type { MouseEventHandler } from 'react';
import { useMemo } from 'react';
import { useInca } from '@cancercentrum/rcc-react';
import { useAtomValue } from 'jotai';
import { formatIf } from '../form-oversikt/components/TextRepresentation';
import { selectedDiagnosAtom } from '../startpage-oversikt/atoms';

const isExternalDemo = process.env.RCC_ENV === 'external_demo';

const getBrowserInfo = () => {
    const { userAgent } = navigator;

    if (userAgent.includes('Firefox/')) {
        return `Firefox v${userAgent.split('Firefox/')[1]}`;
    }
    if (userAgent.includes('Edg/')) {
        return `Edge v${userAgent.split('Edg/')[1]}`;
    }
    if (userAgent.includes('Chrome/')) {
        return `Chrome v${userAgent.split('Chrome/')[1]}`;
    }
    if (userAgent.includes('Safari/')) {
        return `Safari v${userAgent.split('Safari/')[1]}`;
    }

    return '';
};

const onClick: MouseEventHandler = (e) => {
    e.preventDefault();
};

export const ErrorReportHeaderItem = () => {
    const inca = useInca();
    const selectedConfig = useAtomValue(selectedDiagnosAtom);
    const url = useMemo(() => {
        const { currentUser } = inca;
        const params = new URLSearchParams();

        params.append('customfield_11930', [
            `${currentUser.user.firstName} ${currentUser.user.lastName}`,
            currentUser.user.email,
            currentUser.user.phoneNumber,
        ].filter(x => x).join(', '));

        params.append('customfield_12016', [
            formatIf(currentUser.user.username, x => `Användarnamn: ${x}`),
            formatIf(currentUser.role.name, x => `Roll: ${x}`),
            formatIf(currentUser.position.fullNameWithCode, x => `Placering: ${x}`),
            formatIf(selectedConfig?.diagnos_beskrivning || selectedConfig?.diagnos, x => `Diagnos: ${x}`),
        ].filter(x => x).join('\r\n'));

        params.append('customfield_12021', getBrowserInfo() || navigator.userAgent);

        if (inca.type === 'form') {
            params.append('customfield_11919', 'Individuell patientöversikt');
            params.append('customfield_11920', `Registerpostformulär (utformnings-id ${inca.form.designedFormId})`);

            if (inca.registerRecord) {
                params.append('customfield_12017', `${window.location.origin}/RegisterRecord/Details/${inca.registerRecord.id}`);
            } else {
                params.append('customfield_12017', `${window.location.origin}/Overview/${inca.patient?.id}`);
            }
        } else {
            params.append('customfield_11919', 'Individuell patientöversikt');
            params.append('customfield_11920', 'Startsida');
        }

        params.append('email', currentUser.user.email);

        return 'https://cancercentrum.atlassian.net/servicedesk/customer/portal/1/group/1/create/11?' + params.toString();
    }, [inca, selectedConfig]);

    const SupportContent = isExternalDemo ? (
        <div>Ingen felanmälan kan göras för demomiljön.</div>
    ) : null;

    return (
        <div className="header__item">
            {SupportContent ? (
                <OverlayTrigger
                    overlay={(
                        <Popover id="popFelanmalan" className="mw-100">
                            {SupportContent}
                        </Popover>
                    )}
                    trigger="click"
                    placement="bottom"
                    rootClose={true}
                >
                    <a href="" className="header-link" onClick={onClick}>
                        <FontAwesomeIcon icon={faBug} /> Felanmälan
                    </a>
                </OverlayTrigger>
            ) : (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-link"
                >
                    <FontAwesomeIcon icon={faBug} /> Felanmälan
                </a>
            )}
        </div>
    );
};
