import orderBy from 'lodash/orderBy';
import type { KontaktRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { isValidDate } from '../../utils/date.ts';

/**
 * Hämta den senast registrerade kontaktraden baserat på kontaktdatum.
 */
export function getPreviousKontaktRegistration(rows: KontaktRowModel[]): KontaktRowModel | undefined {
    const rowsWithDate = rows.filter(x => isValidDate(x.getRegvarValue('K_besoksdatum')));
    const rowsDesc = orderBy(rowsWithDate, [x => x.getRegvarValue('K_besoksdatum') as string], ['desc']);

    return rowsDesc[0];
}
