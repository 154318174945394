import { differenceInDays, format } from 'date-fns';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import type { Options } from 'highcharts';
import type { ChartRange, ChartScale, GraphPointObject } from './types';

export const configuration: Options = {
    accessibility: {
        enabled: false,
    },
    title: {
        text: undefined,
    },
    chart: {
        ignoreHiddenSeries: false,
        marginRight: 200,
        type: 'scatter',
        inverted: false,
        events: {},
        panning: {
            enabled: true,
            type: 'x',
        },
        panKey: 'shift',
        zooming: {
            type: 'x',
        },

        // Autoresiza inte automatiskt. Det sköts istället av en annan komponent.
        // Det pga att autosizningen ibland slutade att fungera.
        reflow: false,
    },
    legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 25,
        maxHeight: 150,
    },
    tooltip: {
        hideDelay: 0,
        useHTML: true,
        style: {
            'z-index': 99999,
        },
        outside: true,
        formatter: function () {
            if (!this.point?.series?.yAxis) {
                return (this.point?.options as GraphPointObject)?.tooltip;
            }

            let overlappingPoints;
            if (this.series?.options?.type === 'line' && this.series.data.length === 2) {
                const series = this.series.yAxis.series.filter(x =>
                    x.data.length === 2 &&
                    x.data[0].y === this.y &&
                    x.data[0].x <= (this.x! as number) &&
                    x.data[1].x >= (this.x! as number),
                );

                if (series.length > 1) {
                    overlappingPoints = series.map(x => x.data[0]);
                }
            }

            if (!overlappingPoints) {
                const m = new Date(this.point.x);
                const withinRange = (timestamp: number) => {
                    const m2 = new Date(timestamp);

                    return Math.abs(differenceInDays(m2, m)) === 0;
                };

                overlappingPoints = flatMap(this.point.series.yAxis.series || [], x => x.points || []).filter(x => x.y === this.point.y && withinRange(x.x));
            }

            const tooltips = sortBy(overlappingPoints, x => x.x).map(x => (x.options as GraphPointObject).tooltip || `<div>${this.key} - ${format(x.y!, 'yyyy-MM-dd')}</div>`);
            return `<div>${uniq(tooltips).join('<br /><div>\n </div><br />')}</div>`;
        },
    },
    plotOptions: {
        series: {
            cursor: 'pointer',
            stickyTracking: false,
        },
        line: {
            tooltip: {
                followPointer: true,
            },
        },
    },
    xAxis: [],
    yAxis: [],
    credits: { enabled: false },
};

export const scales: ChartScale[] = [
    { text: 'Standardskala', value: null },
    { text: 'Logaritmisk skala', value: 'logarithmic' },
];

export const ranges: ChartRange[] = [
    { num: 3, unit: 'months', text: '3 månader', key: '3mo' },
    { num: 6, unit: 'months', text: '6 månader', key: '6mo' },
    { num: 1, unit: 'years', text: '1 år', key: '1år' },
    { num: 3, unit: 'years', text: '3 år', key: '3år' },
    { num: 5, unit: 'years', text: '5 år', key: '5år' },
    { num: null, unit: 'years', text: 'Alla registreringar', key: 'alla' },
];

