import type { ListItem } from '@cancercentrum/inca';
import type { RowModel, RowModelTableMetadata } from '@cancercentrum/rcc-react';
import { SammantagenBedomningIcon } from '../components/SammantagenBedomningIcon';
import { EcogIcon } from '../components/EcogIcon';
import type { GridColumn } from '../types';
import type { Kontakt, Stralbehandling } from '../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';

const getEcogColumn = <T extends RowModelTableMetadata>(shortname: keyof T['regvars']): GridColumn<RowModel<T>, string> => {
    return {
        header: 'ECOG',
        field: shortname as string,
        fallbackValue: '-',
        getStringValue(row) {
            const val = row.getRegvarValue(shortname);

            return val ? val.value : '-';
        },
        renderCell(data) {
            const val = data.row.getRegvarValue(shortname);
            if (!val) {
                return data.column.fallbackValue;
            }

            return (
                <div className="d-flex align-items-center">
                    <EcogIcon value={val.value} className="me-2" />
                    <div>{val.value}</div>
                </div>
            );
        },
    };
};

export const stralEcogColumn = getEcogColumn<Stralbehandling>('Stral_ecog');
export const kontaktEcogColumn = getEcogColumn<Kontakt>('K_ecog');

export const createSamBehColumn = <T extends RowModelTableMetadata>(
    shortname: keyof T['regvars'],
    options?: {
        formatter?: (item: ListItem) => string;
    } & GridColumn<RowModel<T>, string>,
): GridColumn<RowModel<T>, string> => {

    const fmt = options?.formatter || ((item: ListItem): string => item.value);

    return {
        header: options?.header || 'Sammantagen bedömning',
        field: shortname as string,
        fallbackValue: options?.fallbackValue || '-',
        getStringValue(row) {
            const val = row.getRegvarValue(shortname);

            return val ? fmt(val) : '-';
        },
        renderCell(data) {
            const val = data.row.getRegvarValue(data.column.field as string);
            if (!val) {
                return data.column.fallbackValue;
            }

            return <span><SammantagenBedomningIcon identifier={val.identifier} /> {fmt(val)}</span>;
        },
        ...options,
    };
};
