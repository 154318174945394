import { valueAndTextFormatter } from '../../../utils/formatters';
import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        fardigregistrerad: {},
        senastKontrollerad: {},
        komplettLkmAdjuvant: {},
        komplettLkmPalliativ: {},
        selectedVd: {},
    },
    Kontakt: {
        K_vardgivare: {},
        K_besokstyp: {
            identifiers: ['1', 'digitalt_vardmote', 'slutenvard', '2', '3'],
        },
        K_yrkesroll: {
            identifiers: ['1', '2', '3'],
        },
        K_ecogEjBedomt: {},
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_sammantagenBedomning: {
            props: {
                // label: 'Sammanvägd bedömning',
                formatter: valueAndTextFormatter,
            },
            identifiers: ['r', 'pd', 'sd', 'rf', 'eb', 'of'],
        },
        K_kliniskTumorprogress: {
            identifiers: ['nej', 'ja', 'ej_aktuellt'],
        },
        K_kontaktsjukskoterska: {
            label: 'Har tilldelad kontaktsjuksköterska?',
            identifiers: ['nej', 'ja'],
        },
        K_kontaktsjukskoterska_namn: {},
        K_diskuteratsMultidisciplinarKonferens: {
            identifiers: ['nej', 'ja'],
        },
        K_diskuteratsMultidisciplinarKonferens_datum: {},
        K_palliativtTeam: {
            identifiers: ['nej', 'ja'],
        },
        K_erbjuditsTTF2: {
            identifiers: ['nej', 'ja', 'avvakta_beslut'],
        },
        K_erbjuditsTTF_svar: {
            identifiers: ['nej', 'ja'],
        },
        K_erbjuditsTTF_orsak: {
            identifiers: ['inklusionskriterier', 'annan'],
        },
        K_erbjuditsTTF_svar_spec: {},
        K_erbjuditsTTF_orsak_spec: {},
    },
    KontaktPAL: {
        KP_patientansvarigLakare: {},
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['3', '7', '1', 'pet-mr', '8'],
        },
        Bild_metod_specificera: {},
        Bild_sammanvagdRadiologiskBedomning: {
            identifiers: ['cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tracer: {
            identifiers: ['fdg', 'met', 'fet', 'annat'],
        },
        Bild_tracer_spec: {},
    },
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_typAvStralbehandling: {
            identifiers: ['primar', 'rebestralning'],
        },
        Stral_indikation: {
            identifiers: ['1', 'kurativ', '2'],
        },
        Stral_slutdatum: {},
        Stral_stopporsak: {
            identifiers: [
                '1', 'enligt_plan', // '1' Kan tas bort efter R12 när identifier ändrats
                '2', 'toxicitet', // '2' Kan tas bort efter R12 när identifier ändrats
                '3', 'progress', // '3' Kan tas bort efter R12 när identifier ändrats
                '5', 'patientens_val', // '5' Kan tas bort efter R12 när identifier ändrats
                'dod_progress',
                'dod_toxicitet',
                'dod_annan',
                '9', 'annan_orsak', // '9' Kan tas bort efter R12 när identifier ändrats
            ],
        },
        Stral_stopporsakSpec: {},
    },
    StralTarget: {
        ST_target: {
            identifiers: ['cns-hemisfar', 'cns-centralt', 'cns-cerebellart', 'cns-spinalt', 'cns-kraniospinal', 'cns-skallbas'],
        },
        ST_sida: {
            identifiers: ['1', '2', '3'],
        },
        ST_dosPerFraktion: {},
        ST_slutdos: {},
        ST_stralslag: {
            identifiers: ['fotoner', 'protoner'],
        },
        ST_behandlingstyp: {
            identifiers: ['3dcrt', 'vmat_imrt', 'tomo', 'srt_gammakniv', 'srt_linjaraccelerator', '5'],
        },
    },
    Mutationsanalys: {
        Mut_datum: {},
        Mut_ovrigt_spec: {
            label: 'Specificera övriga mutationsanalyser',
        },
    },
    AnnanBehandling: {
        ab_datum: {},
        ab_typ: {
            identifiers: ['ttf', 'symtomlindrande_behandling', 'annan'],
        },
        ab_ttf_lopnr: {},
        ab_annanBehandling_spec: {},
        ab_datumAvslut: {},
    },
    Följsamhetsrapport: {
        Folj_datum: {},
        Folj_foljsamhet: {},
    },
    Behandlingsavslutsorsak: {
        Bao_orsak: {
            identifiers: [
                'biverkningar',
                '5', 'patientens_val', // '5' Kan tas bort efter R12 när identifier ändrats
                'bristande_compliance',
                '3', 'progress', // '3' Kan tas bort efter R12 när identifier ändrats
                '1', 'enligt_plan', // '1' Kan tas bort efter R12 när identifier ändrats
            ],
        },
        Bao_orsak_spec: {},
    },
    Behandlingsbiverkan: {
        Bbv_biverkan: {
            identifiers: ['lokal_hudirritation', 'annat'],
        },
        Bbv_biverkan_spec: {},
    },
    Läkemedel: {
        Lkm_behandlingslinje: {
            identifiers: ['1', '2', '3', '4', '5', '>5'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '2', '3', '5'],
        },
        Lkm_startdos_lista: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
        Lkm_antalCyklerGivna: {
            label: 'Antal givna kurer',
        },
    },
    Dosjustering: {
        Dj_datum: {},
        Dj_justering: {
            identifiers: ['full', 'reducerad', 'eskalering'],
        },
        Dj_orsak: {
            identifiers: ['enligt_plan', '2', '1', '3', '5'],
        },
    },
    Kirurgi: {
        // Är med i minidataset men läggs här också för att overrida label.
        Kir_datum: {
            label: 'Datum för reoperation',
        },
    },
    Studie: {},
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_ctcae_term_vd: {},
        Bv_biverkan_alt: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    Basdata: {
        Basdata_cns_vd: {},
    },
    Patientportal: {},
};

export default dataset;
