import type { ReactElement } from 'react';
import { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { AjaxErrorAlert, LoadingAlert, useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import { useSetAtom } from 'jotai';
import { StandardTabHeader } from '../../components/StandardTabHeader';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import type { StudieVDItem } from './types';
import { DisplayName, GridId, ModuleName, TableName } from './constants';
import { getVDStudier } from './utils';
import { useGetStudieSubTable } from './useGetStudieSubTable';
import type { ExternalStudiesResult } from './useExternalStudieData';
import { EmptyExternalStudiesData, useExternalStudieData } from './useExternalStudieData';
import { EmptyStudier, vdStudierAtom } from './useVDStudier';

export interface StudieTabProps {
    vdStudier: StudieVDItem[];
    externalStudiesData: ExternalStudiesResult
    reloadExternalStudiesData: () => void;
}

const StudieTab = (props: {
    highlight?: boolean;
    children: (data: StudieTabProps) => ReactElement;
}): ReactElement => {
    const { children, highlight } = props;
    const getValueDomainValues = useGetValueDomainValues();
    const { isLoading, result, error } = usePromise<StudieVDItem[]>(() => getVDStudier({ getValueDomainValues }));
    const { reload, result: extResult } = useExternalStudieData();
    const setVdStudier = useSetAtom(vdStudierAtom);
    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
            tableName: TableName,
            gridId: GridId,
        };
    }, []);
    const subTable = useGetStudieSubTable();
    const header = (
        <StandardTabHeader
            displayName={DisplayName}
            rows={subTable.items}
            className={classnames({
                'tab-highlight': highlight,
            })}
        />
    );

    useEffect(() => {
        setVdStudier(result || EmptyStudier);
    }, [result, setVdStudier]);

    return (
        <HTab eventKey={ModuleName} header={header}>
            <ModuleContext.Provider value={ctx}>
                {!!error && <AjaxErrorAlert error={error} />}
                {(isLoading || !result) && !error && <LoadingAlert />}
                {!!result && (
                    <div data-testid={`Module_${ModuleName}`}>
                        {children({
                            vdStudier: result,
                            externalStudiesData: extResult || EmptyExternalStudiesData,
                            reloadExternalStudiesData: reload,
                        })}
                    </div>
                )}
            </ModuleContext.Provider>
        </HTab>
    );
};

StudieTab.ModuleName = ModuleName;

export default StudieTab;
