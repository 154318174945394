import type { RowModel } from '@cancercentrum/rcc-react';
import { Condition, ListButtonGroup } from '@cancercentrum/rcc-react';
import type { ReactElement } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import Question from '../../components/IPOQuestion';
import { SubstanceQuestion } from './components/SubstanceQuestion';
import type { HierarchifiedSubstansItem, UtvaldSubstansVDItem } from './types';

export const AvblindadQuestions = (props: {
    row: RowModel;
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
    labelClassName?: string;
    wrapperClassName?: string;
}): ReactElement | null => {
    const {
        row,
        availableSubstanser,
        vdUtvaldaSubstanser,
        labelClassName = 'col-sm-2',
        wrapperClassName = 'col-sm-10',
    } = props;

    return (
        <Condition if={row.getRegvarValue('Lkm_studielakemedel_spec')}>
            <Question
                regvar={row.getRegvar('Lkm_avblindatresultat')}
                labelClassName={labelClassName}
                wrapperClassName={wrapperClassName}
                componentClass={ListButtonGroup}
            />
            <Condition if={(row.getRegvarValue('Lkm_avblindatresultat') as ListItem | null)?.identifier === 'lakemedel'}>
                <SubstanceQuestion
                    labelClassName="col-sm-3"
                    wrapperClassName="col-sm-9"
                    availableSubstanser={availableSubstanser}
                    vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                    substanceRegvar={row.getRegvar('Lkm_substans_vd')}
                    startdatum={(row.getRegvarValue('Lkm_startdatum') as string | null)}
                    label="Avblindat läkemedel"
                    required={true}
                />
            </Condition>
        </Condition>
    );
};
