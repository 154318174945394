import type { ReactElement } from 'react';
import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import type { DataLabelsOptions, PointClickEventObject, SeriesPieOptions } from 'highcharts';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import { Point } from '../../components/Chart/Point';
import Series from '../../components/Chart/Series';
import { getUTCStartOfDayTime } from '../../utils/date';
import { useToggle } from '../../hooks/useToggle';
import { getGridRowId, unescape } from './utils';
import type { SurveysOldPromise } from './types';

interface PiePoint {
    key: string;
    score: number;
    color: string;
    tooltip: ReactElement;
}

interface PieDefinition {
    center: [number, number];
    points: PiePoint[];
}

const DATALABELS: DataLabelsOptions = {
    enabled: true,
    allowOverlap: true,
    align: 'center',
    verticalAlign: 'top',
    padding: 0,
    style: {
        color: '#222',
        textOutline: undefined,
    },
    x: -1,
    y: -8,
    formatter() {
        return this.point.options.label;
    },
};

const getPoint = (
    key: string,
    togglePie: (pieDef: PieDefinition) => void,
    color: string | undefined = '#ccc',
    linecolor: string | undefined = '#ddd',
    finishedAt: string,
    tooltip: ReactElement,
    label: string,
    getPieInfo: () => PiePoint[],
): ReactElement => {
    const marker = {
        symbol: 'circle',
        lineColor: linecolor,
        lineWidth: 2,
        radius: 8,
        states: {
            hover: {
                fillColor: color,
                lineColor: linecolor,
                lineWidth: 4,
            },
        },
    };

    const x = getUTCStartOfDayTime(parseISO(finishedAt));
    return (
        <Point
            key={key}
            x={x}
            y={0}
            color={color}
            marker={marker}
            renderTooltip={() => tooltip}
            label={label}
            events={{
                click: (e: PointClickEventObject) => {
                    e.stopPropagation();
                    e.preventDefault();


                    if (e.shiftKey || e.ctrlKey) {
                        const points = getPieInfo();

                        if (points.length) {
                            const halfRadius = e.point.options.marker!.radius! / 2;
                            togglePie({
                                center: [e.point.plotX!, (e.point.plotY! / 2) - halfRadius + e.point.series.yAxis.pos],
                                points,
                            });
                        }
                    }
                },
            }}
        />
    );
};

const usePromPoints = (togglePie: (pieDef: PieDefinition) => void, surveysOldPromise: SurveysOldPromise) => {
    const promSurveys = surveysOldPromise.result;

    return useMemo((): ReactElement[] => {
        if (!promSurveys) {
            return [];
        }

        return promSurveys.map(promSurvey => getPoint(
            getGridRowId(promSurvey),
            togglePie,
            promSurvey.avgScoreColor,
            promSurvey.avgScoreLineColor,
            promSurvey.vd.data.KlarTidpunkt!,
            promSurvey.tooltip,
            promSurvey.label,
            () => {
                return promSurvey.projectOptions.chartOptions.questions.map(question => {
                    const q = promSurvey.projectOptions.questions.find(x => x.id === question.q);
                    const aq = promSurvey.answeredQuestions.find(x => x.question.id === question.q);
                    const extraAq = promSurvey.answeredQuestions.find(x => x.question.id === question.extra);

                    return {
                        color: aq?.scoreColor || '#ccc',
                        key: question.q,
                        score: aq?.score || 0,
                        tooltip: promSurvey.projectOptions.chartOptions.getQuestionTooltip(unescape(q?.text), aq, extraAq),
                    };
                });
            },
        ));
    }, [promSurveys, togglePie]);
};

export const OldSurveySeries = (props: {
    surveysOldPromise: SurveysOldPromise
}): ReactElement => {
    const {
        surveysOldPromise,
    } = props;

    const [pie, togglePie] = useToggle<PieDefinition>();

    const promPoints = usePromPoints(togglePie, surveysOldPromise);

    return (
        <>
            <ScatterSeries dataLabels={DATALABELS} zIndex={1}>
                {promPoints}
            </ScatterSeries>

            {pie && (
                <Series<SeriesPieOptions>
                    type="pie"
                    innerSize="50%"
                    showInLegend={false}
                    zIndex={50}
                    center={pie.center}
                    dataLabels={{ enabled: false }}
                    size={60}
                    slicedOffset={0}
                >
                    {pie.points.map(point => (
                        <Point
                            key={point.key}
                            y={1}
                            value={point.score}
                            color={point.color}
                            renderTooltip={() => point.tooltip}
                        />
                    ))}
                </Series>
            )}
        </>
    );
};
