import './style.scss';

import type { ReactElement } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { AjaxErrorAlert, LoadingAlert } from '@cancercentrum/rcc-react';
import { StandardTabHeader } from '../../components/StandardTabHeader';
import HTab from '../../../components/HTabs/HTab';
import { ModuleContext } from '../../ModuleContext';
import { DisplayName, GridId, ModuleName, TableName } from './constants';
import type { CTCAEItem } from './types';
import { useExtBiverkningLoader } from './useExtBiverkningLoader';
import { useGetBiverkningSubTable } from './useGetBiverkningSubTable';
import { useCtcaeLoader } from './useCtcaeLoader';

export const BiverkningTab = (props: {
    highlight?: boolean;
    children: (data: {
        result: {
            ctcae: CTCAEItem[];
        };
    }) => ReactElement;
}): ReactElement => {
    const { children, highlight } = props;
    const ctx = useMemo(() => {
        return {
            moduleName: ModuleName,
            displayName: DisplayName,
            tableName: TableName,
            gridId: GridId,
        };
    }, []);
    const subTable = useGetBiverkningSubTable();
    const subTableRef = useRef(subTable);
    const bivLoader = useExtBiverkningLoader();

    subTableRef.current = subTable;

    const { result, isLoading, error } = useCtcaeLoader();

    useEffect(() => {
        bivLoader.reload();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handler = (e: MessageEvent) => {
            if (e.origin !== window.location.origin) {
                return;
            }

            if (e.data?.sourceRegister === 'lakemedelsbiverkan') {
                bivLoader.reload();
            }
        };

        window.addEventListener('message', handler);

        return () => {
            window.removeEventListener('message', handler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const header = (
        <StandardTabHeader
            displayName={DisplayName}
            rows={subTable.items}
            className={classnames({
                'tab-highlight': highlight,
            })}
        />
    );

    return (
        <HTab eventKey={ModuleName} header={header}>
            <ModuleContext.Provider value={ctx}>
                {!!error && <AjaxErrorAlert error={error} />}
                {(isLoading || !result) && !error && <LoadingAlert />}
                {!!result && (
                    <div data-testid={`Module_${ModuleName}`}>
                        {children({ result: { ctcae: result } })}
                    </div>
                )}
            </ModuleContext.Provider>
        </HTab>
    );
};
