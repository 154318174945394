import type { ReactNode } from 'react';
import { Fragment } from 'react';
import isNil from 'lodash/isNil';

export const Line = (props: {
    separator?: string;
    parts: ReactNode[],
    end?: string;
}): JSX.Element | null => {
    const {
        separator = ', ',
        parts,
        end = '.',
    } = props;

    const partsWithContent = parts.filter(x => !isNil(x) && x !== false && x !== '');

    if (!partsWithContent.length) {
        return null;
    }

    return (
        <>
            {partsWithContent.map((x, i, arr) => {
                if (i < arr.length - 1) {
                    return <Fragment key={i}>{typeof x === 'boolean' ? x.toString() : x}{separator}</Fragment>;
                }

                return x;
            })}{end}
        </>
    );
};
