import noop from 'lodash/noop';
import { Button } from '@cancercentrum/rcc-bootstrap';
import type { HookDataActions, RowModel, RowModelTableMetadata, SubTableModel } from '@cancercentrum/rcc-react';
import { useDataActions } from '@cancercentrum/rcc-react';
import type { ReactElement, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export type AddFnCallback<T extends RowModelTableMetadata> = (row: RowModel<T>, dataActions: HookDataActions<T>) => void;
export type OnRowAddedCallback<T extends RowModelTableMetadata> = (row: RowModel<T>) => void;
export type OnBeforeAddCallback<T extends RowModelTableMetadata> = (row: RowModel<T>) => RowModel<T>;

const getDefaultAddFn = <T extends RowModelTableMetadata>(): AddFnCallback<T> => {
    return (row, dataActions) => dataActions.updateRow(r => r.addSubTableRow(row));
};

const getDefaultBeforeAdd = <T extends RowModelTableMetadata>(): OnBeforeAddCallback<T> => {
    return (row) => row;
};

export const AddSubTableRowButton = <T extends RowModelTableMetadata>(props: {
    className?: string;
    subTable: SubTableModel<T>;
    onRowAdded?: OnRowAddedCallback<T>;
    onBeforeAdd?: OnBeforeAddCallback<T>;
    addFn?: AddFnCallback<T>;
    disabled?: boolean;
    children?: ReactNode;
}): ReactElement => {
    const { children, className, subTable, onRowAdded, onBeforeAdd, addFn, disabled } = props;
    const dataActions = useDataActions();
    const onClick = (): void => {
        let newRow = subTable.createRow();

        newRow = (onBeforeAdd || getDefaultBeforeAdd<T>())(newRow);

        (addFn || getDefaultAddFn<T>())(newRow, dataActions);

        (onRowAdded || noop)(newRow);
    };

    return (
        <Button className={className} bsStyle="success" onClick={onClick} disabled={disabled}>
            <FontAwesomeIcon icon={faPlusCircle} className="me-1" />
            {children || 'Lägg till'}
        </Button>
    );
};
