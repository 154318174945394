import type { RowModel } from '@cancercentrum/rcc-react';
import { Explanation } from '@cancercentrum/rcc-react';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import useDataSet from '../../../../hooks/useDataSet';
import { buildString, formatIf } from '../../../../components/TextRepresentation';
import type { Formatter, RegvarValueTypes } from '../../../../components/TextRepresentation/utils';
import { useVDStudier } from '../../../studie';
import type { LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { DataSet } from '../../../../types';
import { useAppSettings } from '../../../../../components/AppSettings';

interface SubstanceExtraInfoProps {
    row: LäkemedelRowModel;
    className?: string;
}

interface ExtraInfo {
    label: string;
    text?: ReactNode;
}

const formatters = {
    listText: (val: ListItem) => val.text,
    listValue: (val: ListItem) => val.value,
    raw: (val: RegvarValueTypes) => val.toString(),
};


const SubstanceExtraInfo = (props: SubstanceExtraInfoProps) => {
    const { row, className } = props;
    const ds = useDataSet();
    const appSettings = useAppSettings();
    const studier = useVDStudier();
    const sourceSystemName = row.getRegvarValue('Lkm_sourceSystemName') as string;

    const xtra = useMemo<ExtraInfo[]>(() => {
        const isRegvarInDataset = (r: RowModel, sn: string) => {
            return !!(ds[r.$rcc.tableName as keyof DataSet] as any)?.[sn];
        };

        const getRegvarField = function <T extends RegvarValueTypes>(r: RowModel, sn: string, formatter: Formatter<T>, hideLabel?: boolean): ExtraInfo | null {
            if (!isRegvarInDataset(r, sn)) {
                return null;
            }

            const text = formatIf(r.getRegvarValue(sn), formatter);
            if (!text) {
                return null;
            }

            if (hideLabel) {
                return {
                    label: text,
                };
            }
            return {
                label: (ds[r.$rcc.tableName as keyof DataSet] as any)[sn].label || r.getRegvar(sn).$rcc.regvarMetadata.label,
                text: text,
            };
        };

        const dosjusteringar: string[] = [];
        if (ds.Dosjustering) {
            row.getSubTableRows('Dosjustering').forEach(dosRow => {
                dosjusteringar.push(
                    buildString(', ', [
                        dosRow!.getRegvarValue('Dj_datum'),
                        dosRow!.getRegvarValue('Dj_justering')?.text,
                        dosRow!.getRegvarValue('Dj_orsak')?.text,
                    ]),
                );
            });
        }

        const dosjustering: ExtraInfo | null = dosjusteringar.length ? {
            label: 'Dosjusteringar',
            text: (
                <div>
                    {dosjusteringar.filter(x => x).sort().map(dj => <div key={dj}>{dj}</div>)}
                </div>
            ),
        } : null;

        let hogdos: ExtraInfo | null = null;

        if (isRegvarInDataset(row, 'Lkm_hogdosbeh')
            && isRegvarInDataset(row, 'Lkm_hogdosbeh_spec')
            && isRegvarInDataset(row, 'Lkm_hogdosbeh_allo_spec')
        ) {
            const hogdosText =
                buildString(', ', [
                    formatIf((row.getRegvarValue('Lkm_hogdosbeh') as ListItem | null), x => x.text),
                    formatIf((row.getRegvarValue('Lkm_hogdosbeh_spec') as ListItem | null), x => x.text),
                    (row.getRegvarValue('Lkm_hogdosbeh_allo_spec') as string | null),
                ]);

            if (hogdosText) {
                hogdos = {
                    label: 'Högdosbehandling med stamcellsstöd',
                    text: hogdosText,
                };
            }
        }

        return [
            getRegvarField(row, 'Lkm_antalCyklerGivna', formatters.raw),
            getRegvarField(row, 'Lkm_mutationsanalysUtford', formatters.listText),
            getRegvarField(row, 'Lkm_mutationsanalysDatum', formatters.raw),
            getRegvarField(row, 'Lkm_mutationsanalysTid', formatters.listText),
            getRegvarField(row, 'Lkm_mutationsanalysResultat', formatters.listText),
            getRegvarField(row, 'Lkm_mutationsanalysTypOkant', formatters.listText),
            getRegvarField(row, 'Lkm_mutationsanalysTyp', formatters.raw),
            getRegvarField(row, 'Lkm_beredning', formatters.listText),

            getRegvarField(row, 'Lkm_gesInomRamenAvStudie', () => 'Ges inom ramen av en studie', true),
            getRegvarField(row, 'Lkm_studie_vd', (val: number) => {
                const studie = studier.find(x => x.id === val);
                return studie?.data.namn || '[Information om studie saknas]';
            }),
            getRegvarField(row, 'Lkm_avblindatresultat', formatters.listText),

            getRegvarField(row, 'Lkm_hengsKriterie', formatters.listText),
            getRegvarField(row, 'Lkm_hengsBaseratPa', formatters.raw),

            getRegvarField(row, 'Lkm_startdos_lista', formatters.listText),
            getRegvarField(row, 'Lkm_behandlingslinje', formatters.listText),
            getRegvarField(row, 'Lkm_behandlingsbeslut', formatters.raw),
            getRegvarField(row, 'Lkm_mdk', formatters.listText),
            getRegvarField(row, 'Lkm_stadiumVidStart', formatters.listText),
            hogdos,
            getRegvarField(row, 'Lkm_kurlangd', formatters.raw),
            getRegvarField(row, 'Lkm_sistaKurensForstaDag', formatters.raw),

            dosjustering,
        ].filter((x): x is ExtraInfo => !!x);
    }, [row, ds, studier]);

    if (!xtra.length && sourceSystemName === 'inca') {
        return null;
    }

    const systemDisplayName = appSettings.sourceSystemNameMap[sourceSystemName];

    return (
        <div className={classnames('d-inline-block', className)}>
            {!!xtra.length && (
                <Explanation className="mw-100">
                    {xtra.map((x, i) => {
                        if (x.text) {
                            return <div className="mb-2" key={i}><strong>{x.label}</strong>: {x.text}</div>;
                        }

                        return <div className="mb-2" key={i}><strong>{x.label}</strong></div>;
                    })}
                </Explanation>
            )}

            {sourceSystemName !== 'inca' && (
                <OverlayTrigger
                    placement="top"
                    trigger="click"
                    rootClose={true}
                    popperConfig={{ strategy: 'fixed' }}
                    overlay={(
                        <Popover id={`Sub_${row.getId()}`}>
                            {!!systemDisplayName && <div>Uppgifterna är ursprungligen importerade från <strong>{systemDisplayName}</strong>.</div>}
                            {!systemDisplayName && <div>Uppgifterna är ursprungligen importerade från ett externt system.</div>}
                        </Popover>
                    )}
                >
                    <FontAwesomeIcon icon={faFileImport} className={classnames('text-info pointer', { 'ms-1': xtra.length })} />
                </OverlayTrigger>
            )}
        </div>
    );
};

export default SubstanceExtraInfo;
