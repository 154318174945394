import type { ReactElement, ReactNode } from 'react';
import Header from './Header';

type SectionProps = {
    header: string;
    headerTitle?: string;
    children: ReactNode;
}

const Section = (props: SectionProps): ReactElement => {
    const { header, headerTitle, children } = props;

    return (
        <div className="text__section">
            <Header text={header} title={headerTitle} />
            {children}
        </div>
    );
};

export default Section;
