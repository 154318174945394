import { useCallback } from 'react';
import type { MouseEventHandler, ReactElement, ReactNode } from 'react';

export const PropagationBoundary = (props: {
    children?: ReactNode;
}): ReactElement => {
    const { children } = props;

    const stopPropagation: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return <div onClick={stopPropagation}>{children}</div>;
};
