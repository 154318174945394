import { useContext } from 'react';
import { DropdownButton, MenuItem } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import ChartContext from '../../components/Chart/ChartContext';

export const ScaleSelector = (): ReactElement => {
    const ctxChart = useContext(ChartContext);
    if (!ctxChart) {
        throw new Error('ChartContext is missing.');
    }

    const selectedScale = ctxChart.scale.selected;

    return (
        <DropdownButton
            className="btn-group"
            title={selectedScale?.text ?? 'Välj skala'}
            bsSize="sm"
            id="GraphIntervals"
        >
            {(ctxChart.scale.scales).map(scale => {
                return <MenuItem key={scale.value} onClick={() => ctxChart.scale.set(scale)}>{scale.text}</MenuItem>;
            })}
        </DropdownButton>
    );
};
