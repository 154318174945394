import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPaperPlane, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

const StatusToIcon: Record<string, IconProp> = {
    ej_skickad: faFileAlt,
    overforing_initierad: faPaperPlane,
    skickad: faCheckCircle,
    misslyckad_overforing: faTimesCircle,
};

export const BiverkningStatusIcon = (props: {
    value: string;
    className?: string;
} & Omit<FontAwesomeIconProps, 'icon'>) => {
    const { value, ...rest } = props;
    const iconProps: FontAwesomeIconProps = {
        icon: StatusToIcon[value] || faQuestionCircle,
        fixedWidth: true,
        ...rest,
    };

    return <FontAwesomeIcon {...iconProps} />;
};
