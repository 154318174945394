import { useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import { useCallback, useEffect } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useIPO } from '../../hooks/useIPO';
import { useVDStudier } from './useVDStudier';
import type { ExternalStudieVDData, ExternalStudieVDItem } from './types';

/**
 * Map object for those studies (study id) where the patient exists.
 */
export type ExternalStudiesResult = Map<number, ExternalStudieVDItem>;

export const EmptyExternalStudiesData: ExternalStudiesResult = new Map();
export const extStudiesVDAtom = atom<ExternalStudiesResult>(EmptyExternalStudiesData);

export const useGetExternalStudies = () => {
    return useAtomValue(extStudiesVDAtom);
};

/**
 * Gets external data for those studies that have an external study register.
 */
export const useExternalStudieData = () => {
    const vdStudier = useVDStudier();
    const ipo = useIPO();
    const getValueDomainValues = useGetValueDomainValues();
    const patId = ipo.patient.id;
    const { isLoading, exec, result, error } = usePromise<ExternalStudiesResult>();
    const setExtStudiesData = useSetAtom(extStudiesVDAtom);

    const reload = useCallback(() => {
        const studiesWithVD = vdStudier.filter(x => x.data.vdnamn);

        exec(async () => {
            const res = await Promise.all(studiesWithVD.map(x => {
                return getValueDomainValues<ExternalStudieVDData>(x.data.vdnamn!, {
                    parameters: {
                        patId: [patId],
                    },
                });
            }));

            return res.reduce<ExternalStudiesResult>((acc, items, index) => {
                // A patient can exist 0..1 times in an external study register.
                if (items[0]) {
                    acc.set(studiesWithVD[index].id, items[0]);
                }

                return acc;
            }, new Map());
        });
    }, [vdStudier, exec, getValueDomainValues, patId]);

    useEffect(() => {
        if (vdStudier.length) {
            reload();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vdStudier]);

    // Synca resultatet till atomen.
    useEffect(() => {
        setExtStudiesData(result || EmptyExternalStudiesData);
    }, [result, setExtStudiesData]);

    return {
        isLoading,
        result,
        error,
        reload,
    };
};
