import { OpenRegisterRecordLink } from '../../../components/OpenRegisterRecordLink';
import type { GridColumn } from '../../types';


export const getAjaxLoaderId = (basdataId: string) => `module-basdata-${basdataId}`;
export const getGridId = (basdataId: string) => `module-basdata-${basdataId}`;

export function getOpenRegisterRecordColumn<T extends {
    data: Record<string, any>
}>(): GridColumn<T> {
    return {
        id: 'OpenRR',
        header: '',
        searchable: false,
        sortable: false,
        tdProps: {
            className: 'nostretch',
        },
        renderCell({ row }) { // eslint-disable-line react/prop-types
            return <OpenRegisterRecordLink rrId={(row.data as any).REGISTERRECORD_ID as number} />;
        },
    };
}
