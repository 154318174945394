import type { ReactElement, ReactNode } from 'react';
import { memo, useState } from 'react';
import classnames from 'classnames';
import type { HookDataActions, RowModel } from '@cancercentrum/rcc-react';
import { DetachedRow } from '@cancercentrum/rcc-react';
import { Alert, Button, Modal } from '@cancercentrum/rcc-bootstrap';
import { ModalWrapper } from '../../../../../components/ModalWrapper';
import useDataSet from '../../../../hooks/useDataSet';
import { useBiverkanRegistrator, useRegisteredBiverkan } from '../../../biverkan/utils';
import { PDLGridRemoveIcon } from '../../../pdl/PDLGridRemoveIcon';
import { EcogIcon } from '../../../../components/EcogIcon';
import { ToxIdentifiers } from '../../constants';
import type { HierarchifiedSubstansItem, RegimItem, RowSubstanceItem, UtvaldSubstansVDItem } from '../../types';
import type { Läkemedel, LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { LakemedelPromiseResult } from '../../useLakemedelVDPromise';
import { validationPredicate } from '../../../../utils/row';
import { fancyConfirm } from '../../../../../utils/fancyConfirm';
import EditLakemedel from './EditLakemedel';
import SubstanceExtraInfo from './SubstanceExtraInfo';

export interface RenderEditContentData {
    row: LäkemedelRowModel;
    dataActions: HookDataActions<Läkemedel>;
    regimerById: Record<number, RegimItem>;
    substanserById: Record<number, HierarchifiedSubstansItem>;
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
    isActive: boolean;

    // Om substans angivits i fritext, dvs studieläkemedel eller placebo.
    isFritext: boolean;
}

export interface ExtraRowInfo {
    label: string;
    text?: ReactNode;
}

export type RenderEditContentCallback = (data: RenderEditContentData) => ReactElement;
export type GetSubstanceNameCallback = (row: LäkemedelRowModel, vdItem?: HierarchifiedSubstansItem) => string;
export type GetEditButtonTextCallback = (data: {
    row: LäkemedelRowModel;
    regim: RegimItem;
    substance: HierarchifiedSubstansItem;
}) => string;

const defaultRenderEditContent: RenderEditContentCallback = data => <EditLakemedel {...data} />;

const defaultGetEditButtonText = (data: {
    row: LäkemedelRowModel;
}) => {
    const { row } = data;
    const actions = ['Redigera'];

    if (!row.getRegvarValue('Lkm_stoppdatum')) {
        actions.push('sätt ut');
    }

    return actions.join(' / ');
};

const SubstanceRow = (props: {
    data: RowSubstanceItem;
    substanserById: LakemedelPromiseResult['substanserById'];
    regimerById: LakemedelPromiseResult['regimerById'];
    renderEditContent?: RenderEditContentCallback;
    getEditButtonText?: GetEditButtonTextCallback;
    dataActions: HookDataActions;
    getSubstanceName: GetSubstanceNameCallback;
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
}): ReactElement => {
    const {
        data,
        substanserById,
        regimerById,
        renderEditContent = defaultRenderEditContent,
        getEditButtonText = defaultGetEditButtonText,
        dataActions,
        getSubstanceName,
        availableSubstanser,
        vdUtvaldaSubstanser,
    } = props;

    const ds = useDataSet();
    const hasBiverkanModule = !!ds.Biverkningsrapportering;
    const biverkanRegistrator = useBiverkanRegistrator();
    const registeredBiverkningar = useRegisteredBiverkan([data.substance.getRegvarValue('Lkm_substans_vd') as number]);

    const [showModal, setShowModal] = useState(false);

    const showSattUtModal = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const onSave = (row: RowModel) => {
        dataActions.updateRow(() => row);

        handleClose();
    };

    const onSaveBiv = (row: LäkemedelRowModel) => {
        dataActions.updateRow(() => row);

        const subId = row.getRegvarValue('Lkm_substans_vd');

        biverkanRegistrator(
            row.getRegvarValue('Lkm_stoppdatum')!,
            row.getRegvarValue('Lkm_insattningsuuid')!,
            [
                subId
                    ? { type: 'vd', substanceId: subId }
                    : { type: 'name', substanceName: row.getRegvarValue('Lkm_studielakemedel_spec') || 'Namn saknas' },
            ],
        );
        handleClose();
    };

    const onRemove = () => {
        const row = data.substance;
        const substance = substanserById[row.getRegvarValue('Lkm_substans_vd') as number];
        const name = getSubstanceName(row, substance);

        fancyConfirm({
            header: <div>Ta bort läkemedlet <strong>{name}</strong>?</div>,
            content: <span>Bekräfta borttag av <em>{name}</em>. Åtgärden går inte att ångra.</span>,
        }).then(ok => {
            if (ok) {
                if (data.substance.getRegvarValue('Lkm_regim_vd')) {
                    dataActions.updateRow(x => {
                        return x.setRegvarValue('Lkm_exkluderad', true);
                    });
                } else {
                    dataActions.removeRow();
                }
            }
        });
    };

    const row = data.substance;
    const isFritext = !!row.getRegvarValue('Lkm_studielakemedel_spec');
    const belongsToRegim = !!row.getRegvarValue('Lkm_regim_vd');
    const regim = regimerById[row.getRegvarValue('Lkm_regim_vd') as number];
    const substance = substanserById[row.getRegvarValue('Lkm_substans_vd') as number];
    const name = getSubstanceName(row, substance);
    const ecog = row.getRegvarValue('Lkm_ecog');

    const stopporsak = row.getRegvarValue('Lkm_stopporsak')?.text;
    const stopporsakSpec = row.getRegvarValue('Lkm_stopporsakSpec');

    return (
        <tr className={classnames({ inactive: !data.isActive })}>
            <td className={classnames({ indent: !!regim, bold: !belongsToRegim })}>{name}<SubstanceExtraInfo row={row} className="ms-2" /></td>
            <td>
                <Button type="button" bsSize="xs" onClick={showSattUtModal}>{getEditButtonText({ row, regim, substance })}</Button>

                {showModal && (
                    <DetachedRow id="RegimSub" initialRow={row}>
                        {({ row: dr, dataActions: da, validate }) => {
                            const showSaveBiv = hasBiverkanModule && !!(dr.getRegvarValue('Lkm_substans_vd') || dr.getRegvarValue('Lkm_studielakemedel_spec')) && ToxIdentifiers.includes(dr.getRegvarValue('Lkm_stopporsak')?.identifier);

                            return (
                                <ModalWrapper show={true} bsSize="xl" onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Redigera {name}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="lkm-edit">
                                        {!data.isActive && <Alert bsStyle="info">Observera att du redigerar en tidigare behandling!</Alert>}

                                        {renderEditContent({
                                            row: dr,
                                            dataActions: da,
                                            regimerById,
                                            substanserById,
                                            isActive: data.isActive,
                                            isFritext,
                                            availableSubstanser,
                                            vdUtvaldaSubstanser,
                                        })}

                                        {hasBiverkanModule && !!registeredBiverkningar.length && (
                                            <div className="alert alert-info">
                                                Registrerade läkemedelsbiverkningar:
                                                <ul className="mb-0">
                                                    {registeredBiverkningar.map(bv => (
                                                        <li key={bv.id}>
                                                            {bv.date}: {bv.ctcae}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={handleClose} bsStyle="link">Avbryt</Button>
                                        <Button
                                            onClick={() => {
                                                return validate({ getErrorsOptions: { validationPredicate } }) && !dr.getErrors().length && onSave(dr);
                                            }}
                                            bsStyle="primary"
                                        >
                                            Spara
                                        </Button>
                                        {showSaveBiv && (
                                            <Button
                                                onClick={() => {
                                                    return validate({ getErrorsOptions: { validationPredicate } }) && !dr.getErrors().length && onSaveBiv(dr);
                                                }}
                                                bsStyle="primary"
                                            >
                                                Spara och registera biverkning
                                            </Button>
                                        )}
                                    </Modal.Footer>
                                </ModalWrapper>
                            );
                        }}
                    </DetachedRow>
                )}
            </td>
            <td>
                <ul className="list-unstyled">
                    <li>Insatt: {row.getRegvarValue('Lkm_startdatum')}</li>
                    {!!row.getRegvarValue('Lkm_stoppdatum') && (
                        <li>Utsatt: {row.getRegvarValue('Lkm_stoppdatum')} ({stopporsakSpec ? `${stopporsak}, ${stopporsakSpec}` : stopporsak})</li>
                    )}
                </ul>
            </td>
            <td>{!!ecog && <span><EcogIcon value={ecog.value} /> {ecog.value}. {ecog.text}</span>}</td>
            <td>{row.getRegvarValue('Lkm_behandlingsintention')?.text}</td>
            <td>{row.getRegvarValue('Lkm_behandlingsintention_spec')?.text}</td>
            <td className="nostretch text-center">
                <PDLGridRemoveIcon
                    onClick={onRemove}
                    title={belongsToRegim ? 'Exkludera' : 'Ta bort'}
                    row={row}
                    messageTitle={`${name} ${row.getRegvarValue('Lkm_startdatum')}`}
                />
            </td>
        </tr>
    );
};

export default memo(SubstanceRow);
