import type { ReactElement } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import { isValidDate } from '../../utils/date';
import { EcogPoint } from '../../EcogAxis';
import * as KontaktConstants from './constants';
import { useGetKontaktSubTable } from './useGetKontaktSubTable';

export const KontaktEcogPoints = () => {
    const subTable = useGetKontaktSubTable();

    const points = subTable.getRows().toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('K_besoksdatum');
        const ecog = row.getRegvarValue('K_ecog') as ListItem | null;
        if (!isValidDate(datum) || !ecog) {
            return red;
        }

        red.push((
            <EcogPoint
                key={row.getId()}
                date={datum!}
                rowId={row.getId()}
                ecog={ecog}
                moduleName={KontaktConstants.ModuleName}
                gridId={KontaktConstants.GridId}
                displayName={KontaktConstants.DisplayName}
            />
        ));

        return red;
    }, []);

    return (
        <>{points}</>
    );
};
