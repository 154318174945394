import { useMemo } from 'react';
import { atom, useAtomValue } from 'jotai';
import { basdataAtom } from './atoms';

const EMPTY_ARRAY: unknown[] = [];

export const useGetBasdataResult = <TItem>(field: string): TItem[] => {
    const basdataAtomSelector = useMemo(() => {
        return atom<TItem[]>(get => {
            const { result } = get(basdataAtom);
            if (!result) {
                return EMPTY_ARRAY as TItem[];
            }

            if (!result[field]) {
                throw new Error(`The basdata field "${field}" does not exist!`);
            }

            return result[field];
        });
    }, [field]);

    return useAtomValue(basdataAtomSelector);
};
