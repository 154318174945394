import type { UsePromiseResult } from '@cancercentrum/rcc-react';
import { useGetValueDomainValues, usePromise } from '@cancercentrum/rcc-react';
import { hierarchifyVDResult } from '@cancercentrum/inca';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import { useEffect } from 'react';
import { atom, useSetAtom } from 'jotai';
import { useIPO } from '../../hooks/useIPO';
import { useAppSettings } from '../../../components/AppSettings.tsx';
import type { HierarchifiedSubstansItem, Icd10VDData, Icd10VDItem, RegimItem, SubstanskategoriVDData, SubstanskategoriVDItem, SubstansVDData, UtvaldSubstansVDData, UtvaldSubstansVDItem } from './types';
import { useGetLakemedelSubTable } from './useGetLakemedelSubTable';

type RegimVDItemData = {
    Sub_Substans_VD_sub_subnamnrek: string;
    reg_arkiverad: boolean | null;
    reg_diagnosgrupp_Beskrivning: string | null;
    reg_diagnosgrupp_Värde: string | null;
    reg_namn: string;
    reg_pk: number;
    sub_fk: number;
};

export interface LakemedelPromiseResult {
    vdRegimer: RegimItem[];
    vdRegimerForDiagnosis: RegimItem[];
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
    substanserById: Record<number, HierarchifiedSubstansItem>;
    regimerById: Record<number, RegimItem>;
    vdIcd10: Icd10VDItem[];
    vdSubstanskategorier: SubstanskategoriVDItem[];
}

export const lakemedelVdsAtom = atom<Omit<UsePromiseResult<LakemedelPromiseResult>, 'exec'>>({
    isLoading: false,
    error: null,
    result: null,
});

export const useLakemedelVDPromise = (): UsePromiseResult<LakemedelPromiseResult> => {
    const lkmSubTable = useGetLakemedelSubTable();
    const ipo = useIPO();
    const settings = ipo.diagnosis.layout.settings;
    const appSettings = useAppSettings();
    const getValueDomainValues = useGetValueDomainValues();
    const { diagnosisGroup } = settings;
    const setLkmAtom = useSetAtom(lakemedelVdsAtom);

    const lkmPromise = usePromise<LakemedelPromiseResult>(async () => {
        const [
            vdRegimer,
            vdSubstanser,
            vdIcd10,
            vdUtvaldaSubstanser,
            vdSubstanskategorier,
        ] = await Promise.all([
            getValueDomainValues<RegimVDItemData>('Lkm_regim_vd'),
            getValueDomainValues<SubstansVDData>('Lkm_substans_vd'),
            (async () => {
                const idn = lkmSubTable.getRows().filter(x => x!.getRegvar('Lkm_icd10_vd').value).map(x => x!.getRegvar('Lkm_icd10_vd').value).toJS() as number[];
                const kategorier = ipo.diagnosis.icd10CategoryCodes || [];
                const koder = ipo.diagnosis.icd10Codes || [];

                if (!kategorier.length && !koder.length) {
                    return [];
                }

                return getValueDomainValues<Icd10VDData>('Lkm_icd10_vd', {
                    parameters: {
                        idn: [-1, ...idn],
                        kategorier: ['-1', ...kategorier],
                        koder: ['-1', ...koder],
                    },
                });
            })(),
            (async () => {
                const vdres = await getValueDomainValues<UtvaldSubstansVDData>('VD_utvalda_substanser');
                const result = vdres.map<UtvaldSubstansVDItem>(x => ({
                    ...x,
                    id: x.data.substans_sub_pk,
                }));

                return diagnosisGroup ? result.filter(x => !x.data.diagnosgrupp_varde || x.data.diagnosgrupp_beskrivning === diagnosisGroup) : result;
            })(),
            getValueDomainValues<SubstanskategoriVDData>('VD_substanskategorier'),
        ]);

        const selectedRegimIds: number[] = uniq(lkmSubTable.getRows().map(x => x!.getRegvarValue('Lkm_regim_vd') as number | null).filter(x => x).toJS()) as number[];
        const nonArchivedRegims = vdRegimer.filter(x => !x.data.reg_arkiverad || selectedRegimIds.indexOf(x.id) !== -1);
        const allGroupedRegimer = values(groupBy(nonArchivedRegims, r => r.id)).map(regimGroup => {
            const firstRegim = {
                ...regimGroup[0],
                data: omit(regimGroup[0].data, ['sub_fk']),
            };

            return {
                ...firstRegim,
                substanser: uniq(regimGroup.map(r => r.data.sub_fk)),
            } as RegimItem;
        });

        const hierarchifiedSubstanser: HierarchifiedSubstansItem[] = hierarchifyVDResult(vdSubstanser, [
            {
                tableName: 'ATC',
                idCol: 'atc_pk',
                columns: [/^atc_/],
            },
        ]);
        const regimerForDiagnosis = diagnosisGroup ? allGroupedRegimer.filter(x => x.data.reg_diagnosgrupp_Beskrivning === diagnosisGroup) : [];
        const substansids: number[] = [...lkmSubTable.getRows().map<number>(x => x.getRegvarValue('Lkm_substans_vd') as number).toJS() as number[], ...uniq(flatMap(allGroupedRegimer, x => x.substanser))];
        const substanser = hierarchifiedSubstanser.filter(x => {
            return substansids.indexOf(x.id) !== -1
                || appSettings.substances_nsl_ids.includes(x.data.sub_nslid)
                || appSettings.substances_atc_regexps.some(pattern => x.subTables.ATC.some(atc => pattern.test(atc.data.atc_kod || '')));
        });

        return {
            vdSubstanser,
            vdIcd10,
            vdUtvaldaSubstanser,
            vdSubstanskategorier,
            vdRegimer: allGroupedRegimer,
            vdRegimerForDiagnosis: orderBy(regimerForDiagnosis, [x => x.data.reg_namn], ['asc']),
            availableSubstanser: substanser,
            substanserById: keyBy(hierarchifiedSubstanser, s => s.id),
            regimerById: keyBy(allGroupedRegimer, r => r.id),
        };
    });

    const { isLoading, result, error } = lkmPromise;

    useEffect(() => {
        setLkmAtom({
            isLoading,
            result,
            error,
        });
    }, [isLoading, result, error, setLkmAtom]);

    return lkmPromise;
};
