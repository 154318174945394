import type { ListItem } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { isValidDate } from '../../utils/date';
import { useGetKontaktSubTable } from './useGetKontaktSubTable';
import { KarnofskyPoint } from './KarnofskyPoint';

const CATEGORIES = ['Karnofsky'];

export const KarnofskyAxis = (): ReactElement => {
    const subTable = useGetKontaktSubTable();
    const points = subTable.getRows().toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('K_besoksdatum');
        const karnofsky = row.getRegvarValue('K_karnofsky') as ListItem | null;
        if (!isValidDate(datum) || !karnofsky) {
            return red;
        }

        red.push(<KarnofskyPoint key={row.getId()} row={row} y={0} />);

        return red;
    }, []);

    return (
        <CategoriesAxis categories={CATEGORIES} plotBands={true}>
            <ScatterSeries>
                {points}
            </ScatterSeries>
        </CategoriesAxis>
    );
};
