import type { KeyboardEventHandler } from 'react';
import { memo, useContext, useState } from 'react';
import classnames from 'classnames';
import { HintText, useDataActions, useValidationGroupable, Validation, ValidationGroup } from '@cancercentrum/rcc-react';
import Select from 'react-select/creatable';
import type { MultiValue } from 'react-select';
import type { KontaktPALSubTable, KontaktRowModel } from '../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { GridColumn } from '../types';
import { useIPO } from '../hooks/useIPO';
import { isDirty } from '../utils/rowmodel';
import { LatestPalsContext } from '../modules/kontakt/LatestPalsContext';
import { ReadOnlyContext } from './ReadOnlyContext';

interface PalOption {
    value: string;
    label: string;
}

const PalSelect = (props: {
    isDisabled: boolean;
    value: string[];
    onPalChange: (value: readonly PalOption[]) => void;
    isValidationGroupDirty: boolean;
}) => {
    const { isValidationGroupDirty, isDisabled, value, onPalChange } = props;
    const [inputValue, setInputValue] = useState('');
    const recentPals = useContext(LatestPalsContext);
    const recentPalOptions = recentPals.map<PalOption>(x => ({
        label: x,
        value: x,
    }));

    const allOptions = value.reduce<PalOption[]>((acc, val) => {
        if (!acc.some(x => x.value === val)) {
            acc.push({
                value: val,
                label: val,
            });
        }

        return acc;
    }, [...recentPalOptions]);
    const values = value.map(x => allOptions.find(y => y.value === x)!);

    useValidationGroupable(isValidationGroupDirty, [], {});

    const handleInputChange = (val: string) => {
        setInputValue(val);
    };

    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (inputValue && (event.key === 'Enter' || event.key === 'Tab')) {
            onPalChange([
                ...values,
                { value: inputValue, label: inputValue },
            ]);
            setInputValue('');
            event.preventDefault();
        }
    };

    const onChange = (newValue: MultiValue<PalOption>) => {
        onPalChange(newValue);
    };

    return (
        <Select<PalOption, true>
            isDisabled={isDisabled}
            value={values}
            onChange={onChange}
            isMulti={true}
            options={recentPalOptions}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onKeyDown={onKeyDown}
            classNamePrefix="ipo-react-select"
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
            placeholder={isDisabled ? '' : 'Skriv PAL eller välj från lista'}
            formatCreateLabel={x => `Lägg till "${x}"`}
            noOptionsMessage={() => 'Inga alternativ'}
        />
    );
};

export const MultiPALQuestion = memo(function MultiPALQuestion(props: {
    palSubTable: KontaktPALSubTable;
    labelClassName?: string;
    wrapperClassName?: string;
    required?: boolean;
}): JSX.Element | null {
    const {
        palSubTable,
        labelClassName = 'col-sm-3',
        wrapperClassName = 'col-sm-9',
        required,
    } = props;
    const isReadOnly = useContext(ReadOnlyContext);
    const ipo = useIPO();
    const dataActions = useDataActions();
    const hasSomethingDirty = palSubTable.items.some(x => isDirty(x));

    const onSetCurrentUser = () => {
        const name = `${ipo.currentUser.user.firstName} ${ipo.currentUser.user.lastName}`;

        dataActions.updateRow(x => x.addSubTableRow(palSubTable.createRow().setRegvarValue('KP_patientansvarigLakare', name)));
    };

    const palMetadata = palSubTable.$rcc.tableMetadata.regvars.KP_patientansvarigLakare;
    const palRows = palSubTable.getRows().toArray();
    const selectedPalOptions = palRows.map(x => x.getRegvarValue('KP_patientansvarigLakare') || '').filter(x => x);

    const onChange = (newValue: readonly PalOption[]) => {
        const newValues = newValue.map(x => x.value);

        dataActions.updateRow(storedRow => {
            let res = storedRow;

            // Ta bort de som inte längre ska vara valbara.
            res = res.removeSubTableRows('KontaktPAL', x => {
                return !newValues.some(val => val === x.getRegvarValue('KP_patientansvarigLakare'));
            });

            return newValues.reduce((red, val) => {
                if (red.getSubTableRows('KontaktPAL').some(x => x.getRegvarValue('KP_patientansvarigLakare') === val)) {
                    return red;
                }

                const newRow = red.getSubTable('KontaktPAL').createRow().setRegvarValue('KP_patientansvarigLakare', val);

                return red.addSubTableRow(newRow);
            }, res);
        });
    };

    return (
        <ValidationGroup name="kontakt-pal">
            <div className={classnames('form-group row')}>
                <label htmlFor="" className={classnames('form-label', labelClassName)}><HintText text={palMetadata.label} tooltip={palMetadata.description} /></label>
                <div className={wrapperClassName}>
                    <div className="d-flex gap-3">
                        <div style={{ minWidth: '250px' }}>
                            <PalSelect
                                isDisabled={isReadOnly}
                                value={selectedPalOptions}
                                onPalChange={onChange}
                                isValidationGroupDirty={hasSomethingDirty}
                            />
                        </div>
                        {!isReadOnly && <button type="button" className="btn btn-default" tabIndex={-1} onClick={() => onSetCurrentUser()}>Lägg till inloggad användare</button>}
                    </div>
                    <Validation isValid={!required || palRows.length > 0}>Minst en PAL måste anges.</Validation>
                </div>
            </div>
        </ValidationGroup>
    );
});

export const getPals = (subTable: KontaktPALSubTable): string => {
    return subTable.getRows().map(x => x.getRegvarValue('KP_patientansvarigLakare') || '').filter(x => x).join(', ');
};

export const multiPalGridColumn = (): GridColumn<KontaktRowModel, string> => {
    return {
        header: 'PAL',
        field: 'K_patientansvarigLakare',
        fallbackValue: '-',
        getStringValue(row) {
            return getPals(row.getSubTable('KontaktPAL'));
        },
    };
};
