import isNil from 'lodash/isNil';
import type { ReactNode } from 'react';

export const wrapInDiv = (val: string | undefined, i: number | undefined) => <div key={i}>{val}</div>;
export const divIf = function <T>(val: T | null, formatter: (x: T) => ReactNode) {
    if (isNil(val)) {
        return null;
    }

    if (typeof val === 'string' && val === '') {
        return null;
    }

    if (typeof val === 'boolean' && !val) {
        return null;
    }

    return <div>{formatter(val)}</div>;
};

