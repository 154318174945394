import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Panel } from '@cancercentrum/rcc-bootstrap';
import last from 'lodash/last';
import type { ReactElement } from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import type { BivExtItemErrand } from './types';
import { BiverkningErrandIcon } from './BiverkningErrandIcon';

const ExtErrandPanel = (props: {
    errand: BivExtItemErrand;
}): ReactElement | null => {
    const { errand } = props;

    const lastSafetyReport = last(errand.subTables.SafetyReport)!;

    return (
        <Panel bsStyle="info">
            <Panel.Heading>
                <BiverkningErrandIcon /> Utökad biverkningsrapportering till Läkemedelsverket
            </Panel.Heading>
            <Panel.Body>
                <h4 className="mb-4">Påbörjat ärende</h4>
                <p><label>Ärendet påbörjat:</label> {errand.data.ERRAND_STARTDATE}</p>
                <p>
                    <label>Ansvarig rapportör:</label> {lastSafetyReport.data.primarysource_reportergivename} {lastSafetyReport.data.primarysource_reporterfamilyname}
                </p>

                <div className="mt-3">
                    <p><em>Öppna ärendet och fyll i nödvändig information. När ärendet sedan slutförs kommer det att skickas till Läkemedelsverket.</em></p>
                    {process.env.RCC_ENV !== 'external_demo' && (
                        <div>
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="me-1" />
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={`/Errand/Details/${errand.data.ERRAND_ID}`} target="_blank" rel="opener">Öppna påbörjat ärende</a>
                        </div>
                    )}
                    {process.env.RCC_ENV === 'external_demo' && <div><em>Länken är inte tillgänglig i demomiljön.</em></div>}
                </div>
            </Panel.Body>
        </Panel>
    );
};

export const ExtBiverkningErrandPanel = (props: {
    errands: BivExtItemErrand[];
}): ReactElement | null => {
    const { errands } = props;
    if (!errands.length) {
        return null;
    }

    return (
        <div>
            {errands.map(x => <ExtErrandPanel key={x.id} errand={x} />)}
        </div>
    );
};
