import { memo } from 'react';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { IncaRowContext, useDataActions } from '@cancercentrum/rcc-react';
import { isAfter, parseISO } from 'date-fns';
import type { List } from 'immutable';
import { useGetDefaultSubstanceNameCb } from '../../utils';
import { ReadOnlyContext } from '../../../../components/ReadOnlyContext';
import type { RowRegimItem, RowSubstanceItem } from '../../types';
import type { LäkemedelRowModel, LäkemedelSubTable } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { LakemedelPromiseResult } from '../../useLakemedelVDPromise';
import type { GetEditButtonTextCallback, GetSubstanceNameCallback, RenderEditContentCallback } from './SubstanceRow';
import SubstanceRow from './SubstanceRow';
import type { RenderSattUtRegimCallback } from './RegimRow';
import RegimRow from './RegimRow';

const isFutureDate = (dateStr: string | null) => !!dateStr && isAfter(parseISO(dateStr), new Date());

type GroupedLakemedel = RowSubstanceItem | RowRegimItem;

// TODO: Flytta till util, skriv test.
const groupLakemedel = (
    lakemedelRows: List<LäkemedelRowModel>,
    regimerById: LakemedelPromiseResult['regimerById'],
    substanserById: LakemedelPromiseResult['substanserById'],
    getSubstanseNameFn: GetSubstanceNameCallback) => {
    const groupedByInsattningsUUID = lakemedelRows.groupBy(x => x!.getRegvarValue('Lkm_insattningsuuid'));
    const mapSubstanceRow = (sub: LäkemedelRowModel): RowSubstanceItem => {
        const vdItem = substanserById[sub.getRegvarValue('Lkm_substans_vd') as number];
        const isActive = !sub.getRegvarValue('Lkm_stoppdatum') || isFutureDate(sub.getRegvarValue('Lkm_stoppdatum'));

        return {
            type: 'substance',
            substance: sub,
            vdItem,
            isActive,
            name: getSubstanseNameFn(sub, vdItem),
        };
    };

    const result = groupedByInsattningsUUID.reduce<GroupedLakemedel[]>((reduction, items) => {
        // Regim.
        if (items!.first()!.getRegvar('Lkm_regim_vd').value) {
            const regimId = items!.first()!.getRegvarValue('Lkm_regim_vd') as number;
            const vdItem = regimerById[regimId];
            const name = vdItem.data.reg_namn;
            const isActive = items!.some(x => !x!.getRegvarValue('Lkm_stoppdatum') || isFutureDate(x!.getRegvarValue('Lkm_stoppdatum')));

            reduction!.push({
                type: 'regim',
                items: orderBy(items!.reduce<RowSubstanceItem[]>((r, x) => [...(r!), mapSubstanceRow(x!)], []), ['name'], ['asc']),
                regimId: regimId,
                insattningsuuid: items!.first()!.getRegvarValue('Lkm_insattningsuuid')!,
                vdItem,
                isActive,
                name,
            });

            return reduction!;
        }

        // Single substance.
        return reduction!.concat(items!.reduce<GroupedLakemedel[]>((r, x) => [...(r!), mapSubstanceRow(x!)], []))!;
    }, []);

    return orderBy(result, [x => (x.name || '').toString().toUpperCase()], ['asc']);
};

export interface BehandlingarProps {
    subTable: LäkemedelSubTable;

    lakemedelPromiseResult: LakemedelPromiseResult;
    renderSattUtRegim?: RenderSattUtRegimCallback;
    renderEditContent?: RenderEditContentCallback;
    getEditButtonText?: GetEditButtonTextCallback;
    getEditLakemedelButtonText?: GetEditButtonTextCallback;
}

const Behandlingar = (props: BehandlingarProps) => {
    const getSubstanceNameFn = useGetDefaultSubstanceNameCb();
    const {
        subTable,
        renderEditContent,
        renderSattUtRegim,
        getEditLakemedelButtonText,
        lakemedelPromiseResult,
    } = props;
    const {
        substanserById,
        regimerById,
        availableSubstanser,
        vdUtvaldaSubstanser,
    } = lakemedelPromiseResult;
    const dataActions = useDataActions();
    const lakRows = subTable.getRows().filter(x => !x!.getRegvarValue('Lkm_exkluderad') && !x!.getRegvarValue('Lkm_migreringsdublett'));
    const groupedRows = groupLakemedel(lakRows, regimerById, substanserById, getSubstanceNameFn);
    const [active2, inactive] = partition(groupedRows, x => x.isActive);
    const [activeRegims, activeSubs]: [RowRegimItem[], RowSubstanceItem[]] = partition(active2, x => x.type === 'regim') as [RowRegimItem[], RowSubstanceItem[]];
    const [inactiveRegims, inactiveSubs]: [RowRegimItem[], RowSubstanceItem[]] = partition(inactive, x => x.type === 'regim') as [RowRegimItem[], RowSubstanceItem[]];

    const activeRegimNodes = activeRegims.map(x => (
        <RegimRow
            key={x.insattningsuuid}
            dataActions={dataActions}
            data={x}
            substanserById={substanserById}
            regimerById={regimerById}
            renderEditContent={renderEditContent}
            renderSattUtRegim={renderSattUtRegim}
            getEditLakemedelButtonText={getEditLakemedelButtonText}
            isActive={true}
            getSubstanceName={getSubstanceNameFn}
            availableSubstanser={availableSubstanser}
            vdUtvaldaSubstanser={vdUtvaldaSubstanser}
        />
    ));
    const activeNodes = activeSubs.map(x => (
        <IncaRowContext
            key={x.substance.getRegvarValue('Lkm_insattningsuuid')}
            rowId={x.substance.getId()}
            tableName={x.substance.$rcc.tableName}
        >
            {({ dataActions: childDataActions }) => (
                <SubstanceRow
                    data={x}
                    dataActions={childDataActions}
                    substanserById={substanserById}
                    availableSubstanser={availableSubstanser}
                    vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                    regimerById={regimerById}
                    renderEditContent={renderEditContent}
                    getEditButtonText={getEditLakemedelButtonText}
                    getSubstanceName={getSubstanceNameFn}
                />
            )}

        </IncaRowContext>
    ));

    const inactiveRegimNodes = inactiveRegims.map(x => (
        <RegimRow
            key={x.insattningsuuid}
            dataActions={dataActions}
            data={x}
            substanserById={substanserById}
            regimerById={regimerById}
            renderEditContent={renderEditContent}
            renderSattUtRegim={renderSattUtRegim}
            isActive={false}
            getEditLakemedelButtonText={getEditLakemedelButtonText}
            getSubstanceName={getSubstanceNameFn}
            availableSubstanser={availableSubstanser}
            vdUtvaldaSubstanser={vdUtvaldaSubstanser}
        />
    ));

    const inactiveNodes = inactiveSubs.map(x => (
        <IncaRowContext
            key={x.substance.getRegvarValue('Lkm_insattningsuuid')}
            rowId={x.substance.getId()}
            tableName={x.substance.$rcc.tableName}
        >
            {({ dataActions: childDataActions }) => (
                <SubstanceRow
                    data={x}
                    dataActions={childDataActions}
                    substanserById={substanserById}
                    regimerById={regimerById}
                    availableSubstanser={availableSubstanser}
                    vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                    renderEditContent={renderEditContent}
                    getEditButtonText={getEditLakemedelButtonText}
                    getSubstanceName={getSubstanceNameFn}
                />
            )}

        </IncaRowContext>
    ));

    return (
        <ReadOnlyContext.Provider value={false}>
            <section className="block-section">
                <h3>Pågående behandling</h3>

                <table className="table table-condensed table-vertical-align-middle">
                    <thead>
                    <tr>
                        <th>Läkemedel / regim</th>
                        <th />
                        <th>Datum</th>
                        <th>ECOG-(WHO)</th>
                        <th>Beh.intention</th>
                        <th>Beh.intention (spec)</th>
                        <th>Radera</th>
                    </tr>
                    </thead>

                    {activeRegimNodes}
                    <tbody>
                    {activeNodes}
                    </tbody>

                    <tbody>
                    <tr>
                        <td colSpan={7}><h3>Tidigare behandling</h3></td>
                    </tr>
                    </tbody>

                    {inactiveRegimNodes}
                    <tbody>
                    {inactiveNodes}
                    </tbody>
                </table>
            </section>
        </ReadOnlyContext.Provider>
    );
};


export default memo(Behandlingar);
