import { useCallback, useRef } from 'react';
import { hierarchifyVDResult } from '@cancercentrum/inca';
import type { UsePromiseResult } from '@cancercentrum/rcc-react';
import { useGetValueDomainValues } from '@cancercentrum/rcc-react';
import { atom, useAtom } from 'jotai';
import { useIPO } from '../../hooks/useIPO';
import { useGetBiverkningSubTable } from './useGetBiverkningSubTable';
import type { BivExtItemErrand, BivExtItemRR, BivExtVDErrandData, BivExtVDRRData } from './types';

interface DataType {
    errands: BivExtItemErrand[];
    registerRecords: BivExtItemRR[];
}

type BivExtAjaxData = Omit<UsePromiseResult<DataType>, 'exec'>

/**
 * Atom containing side effects from the separate/external register "Läkemedelsbiverkning".
 */
export const biverkningarExtAtom = atom<BivExtAjaxData>({
    isLoading: false,
    result: null,
    error: null,
});

const subTableDefs: any[] = [
    {
        tableName: 'SafetyReport',
        idCol: 'safetyreport_ID',
        columns: [
            'safetyreportversion',
            'send_date',
            'send_error',
            'send_status_Värde',
            'send_status_Beskrivning',
            'primarysource_reportergivename',
            'primarysource_reporterfamilyname',
        ],
        subTables: [
            {
                tableName: 'Drug',
                idCol: 'drug_ID',
                columns: [],
            },
            {
                tableName: 'MedicalHistoryEpisode',
                idCol: 'medicalhistoryepisode_ID',
                columns: [],
            },
            {
                tableName: 'Reaction',
                idCol: 'reaction_ID',
                columns: [],
            },
        ],
    },
];

/**
 * Hook som hjälpler till att hämta externa biverkningsregistreringar. Synkar resultatet till key/value-storen.
 */
export const useExtBiverkningLoader = (): BivExtAjaxData & { reload: () => void } => {
    const ipo = useIPO();
    const getValueDomainValues = useGetValueDomainValues();
    const subTable = useGetBiverkningSubTable();
    const subTableRef = useRef(subTable);
    const [extData, setExtData] = useAtom(biverkningarExtAtom);

    subTableRef.current = subTable;

    const reload = useCallback((): void => {
        setExtData({
            result: null,
            error: null,
            isLoading: true,
        });

        const getUuidsParam = () => {
            return subTableRef.current.getRows().toArray().reduce((red, r) => {
                const id = r.getRegvarValue('Biv_uuid');
                if (id) {
                    red.push(id);
                }

                return red;
            }, ['-1']);
        };

        Promise.all([
            getValueDomainValues<BivExtVDErrandData>('VD_lakemedelsbiverkan_vd_errand', {
                parameters: {
                    patId: [ipo.patient.id],
                    uuids: getUuidsParam(),
                },
            }),
            getValueDomainValues<BivExtVDRRData>('VD_lakemedelsbiverkan_vd', {
                parameters: {
                    patId: [ipo.patient.id],
                    uuids: getUuidsParam(),
                },
            }),
        ]).then(res => {
            const [vdErrand, vdRR] = res;

            setExtData({
                result: {
                    errands: hierarchifyVDResult(vdErrand, subTableDefs),
                    registerRecords: hierarchifyVDResult(vdRR, subTableDefs),
                },
                error: null,
                isLoading: false,
            });
        }).catch(err => {
            setExtData({
                result: null,
                error: err,
                isLoading: false,
            });
        });

        return;
    }, [ipo.patient.id, subTableRef, setExtData, getValueDomainValues]);

    return {
        ...extData,
        reload,
    };
};
