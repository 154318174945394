export const Footer = () => {
    return (
        <footer>
            <table>
                <tbody>
                <tr>
                    <td className="footer--label">Individuell patientöversikt</td>
                    <td>v{process.env.RCC_VERSION} ({process.env.RCC_BUILD_DATE})</td>
                </tr>
                <tr>
                    <td className="footer--label">Ansvarig tillverkare:</td>
                    <td>RCC Väst (<a href="http://www.rccvast.se">www.rccvast.se</a>)</td>
                </tr>
                <tr>
                    <td className="footer--label">Adress:</td>
                    <td>
                        Västra Götalandsregionen
                    </td>
                </tr>
                <tr>
                    <td/>
                    <td>Regionalt cancercentrum väst</td>
                </tr>
                <tr>
                    <td/>
                    <td>Sahlgrenska universitetssjukhuset</td>
                </tr>
                <tr>
                    <td/>
                    <td>413 45 Göteborg</td>
                </tr>
                </tbody>
            </table>

        </footer>
    );
};
