import type { CurrentUser, Patient } from '@cancercentrum/inca';

/**
 * Assert that fictive/non-fictive users are handled in the correct region.
 * Throws an error if fictional user in non fictional region and vice versa.
 */
export const assertPatientContext = (currentUser: CurrentUser, patient: Patient): void => {
    if (patient.isFictional && !currentUser.region.isDemo) {
        throw new Error('Patienten är fiktiv och kan endast användas i fiktiva regioner.');
    }

    if (!patient.isFictional && currentUser.region.isDemo) {
        throw new Error('Patienten är inte fiktiv och kan därför inte användas eftersom du är inloggad i en fiktiv region.');
    }
};
