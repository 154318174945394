export const LabUnits: {
    [key: string]: string;
} = {
    Labb_ca153_0: 'kU/L',
    Labb_calcium_2: 'mmol/L',
    Labb_albumin_0: 'gl/L',
    Labb_korrigeratCalcium: 'mmol/L',
    Labb_ALP_2: 'μkat/L',
    Labb_CRP_0: 'mg/L',
    Labb_LD_1: 'μkat/L',
    Labb_Hb_0: 'g/L',
    Labb_LPK_2: '10<sup>9</sup>/L',
    Labb_neutrofila_1: '10<sup>9</sup>/L',
    Labb_TPK_0: '10<sup>9</sup>/L',
    Labb_mikroglobulin: 'mg/L',
    Labb_joniserande_calcium: 'mmol/L',
    Labb_koncentrationAvLattaKedjorUrin_mgmmol: 'mg/mmol kreatinin',
    Labb_koncentrationAvLattaKedjorUrin_mgl: 'mg/L',
    Labb_koncentrationAvLattaKedjorUrin_mg24h: 'mg/24h',
    Labb_koncentrationLambdakedjor: 'mg/L',
    Labb_koncentrationKappakedjor: 'mg/L',
    Labb_koncentrationMkomponentSerum: 'g/L',
    Labb_PSA_2: 'µg/L',
    Labb_Krea_0: 'µmol/L',
    Labb_testosteron_2: 'nmol/L',
    Labb_S_CA125: 'kE/L',
    Labb_CA199: 'kE/L',
    Labb_hCG_1: 'IE/L',
    Labb_betahcg: 'IE/L',
    Labb_CEA_1: 'μg/L',
    Labb_HE4: 'pmol/L',
    Labb_Inhibin_1: 'ng/L',
    Labb_AFP_1: 'μkat/L',
    Labb_plasmainf: '%',
    Labb_s100b: 'μg/L',

    K_langd_cm: 'cm',
    K_vikt: 'kg',
};
