import { useCallback } from 'react';
import { Button, Modal } from '@cancercentrum/rcc-bootstrap';
import { PropagationBoundary } from '../../../components/PropagationBoundary';
import { ModalWrapper } from '../../../components/ModalWrapper';
import type { StudieVDItem } from './types';
import type { StudieregisterInfo } from './useStudieregister';

interface ExternalStudyRegisterModalProps {
    hide: () => void;
    studieregister: StudieregisterInfo;
    studie: StudieVDItem;
}

const ExternalStudyRegisterModal = (props: ExternalStudyRegisterModalProps) => {
    const { hide, studieregister, studie } = props;
    const continueCb = useCallback(async () => {
        await studieregister.initForm.open(studie);
        hide();
    }, [studieregister.initForm, studie, hide]);

    return (
        <PropagationBoundary>
            <ModalWrapper show={true} onHide={hide} keyboard={false} bsSize="lg">
                <Modal.Header closeButton={false}>
                    <Modal.Title>{studie.data.namn}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        För denna studie finns utökad registrering i ett studieformulär.
                        Randomisering kan också vara aktuellt för studien.
                    </p>

                    <p className="mb-0">Observera att även information om att patienten inte vill delta görs i den utökade studieregistreringen.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hide} bsStyle="link">Avbryt</Button>
                    <Button onClick={continueCb} bsStyle="primary">Fortsätt till utökad registrering</Button>
                </Modal.Footer>
            </ModalWrapper>
        </PropagationBoundary>
    );
};

export default ExternalStudyRegisterModal;
