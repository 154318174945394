import classnames from 'classnames';
import { Badge } from '@cancercentrum/rcc-bootstrap';
import { useIPO } from '../../hooks/useIPO';
import { DisplayName } from './constants';
import type { SurveysPromise } from './types';

export const TabHeader = (props: {
    surveysPromise: SurveysPromise;
    highlight?: boolean;
}): JSX.Element => {
    const { highlight, surveysPromise } = props;
    const ipo = useIPO();
    const count = (surveysPromise.result || []).filter(x => !x.config.isVisible || x.config.isVisible({
        surveys: x.answers,
        registersWithRegistrationAccess: ipo.registersWithRegistrationAccess,
        ipo,
    })).flatMap(x => x.answers).filter(x => !!x.endDate).length;

    return (
        <span
            className={classnames({
                'tab-highlight': highlight,
            })}
        >
            {DisplayName} {surveysPromise.isLoading ? '(Laddar...)' : count > 0 ? <Badge bsStyle="primary">{count}</Badge> : ''}
        </span>
    );
};
