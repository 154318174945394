import { useCallback, useState } from 'react';
import { DropdownButton, MenuItem } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { generateHexColor } from '../../utils/colorUtils';
import type { Kontakt, Labbprov } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { LabUnits } from './config';
import { LabUnit } from './LabUnit';

const noneLabel = '-- Välj labprov --';

export interface LabprovPickerOption {
    label: string;
    shortname: keyof Labbprov['regvars'];
    basdata?: string | ((vdItem: any) => unknown);

    type: 'LABPROV';
}

export interface KontaktPickerOption {
    label: string;
    shortname: keyof Kontakt['regvars'];
    type: 'KONTAKT';
}

export type PickerOption = LabprovPickerOption | KontaktPickerOption;

export interface PickerProps {
    options: PickerOption[];

    onSelectLeft?: (val: PickerOption | null) => void;
    onSelectRight: (val: PickerOption | null) => void;
}

export const Picker = (props: PickerProps) => {
    const {
        onSelectLeft,
        onSelectRight,
        options,
    } = props;
    const [left, setLeft] = useState<PickerOption | null>(null);
    const [right, setRight] = useState<PickerOption | null>(options[0]);

    const onLeftClick = useCallback((option: PickerOption | null) => {
        setLeft(option);
        onSelectLeft!(option);
    }, [onSelectLeft]);

    const onRightClick = useCallback((option: PickerOption | null) => {
        setRight(option);
        onSelectRight(option);
    }, [onSelectRight]);


    const getLabel = (option?: PickerOption | null) => {
        if (!option) {
            return noneLabel;
        }

        const unit = LabUnits[option.shortname];
        return (
            <>
                <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                        color: generateHexColor(option.shortname),
                        fontSize: 10,
                    }}
                /> {option.label} {unit && <>(<LabUnit unit={unit} />)</>}
            </>
        );
    };

    return (
        <>
            {!!onSelectLeft && (
                <DropdownButton title={getLabel(left)} id="dd_labprov_left" bsSize="sm" className="btn-group">
                    <MenuItem onClick={() => onLeftClick(null)}>
                        {noneLabel}
                    </MenuItem>

                    {options.map(option => (
                        <MenuItem key={option.shortname} onClick={() => onLeftClick(option)}>
                            {getLabel(option)}
                        </MenuItem>
                    ))}
                </DropdownButton>
            )}
            <DropdownButton title={getLabel(right)} id="dd_labprov_right" bsSize="sm" className="btn-group">
                <MenuItem onClick={() => onRightClick(null)}>
                    {noneLabel}
                </MenuItem>

                {options.map(option => (
                    <MenuItem key={option.shortname} onClick={() => onRightClick(option)}>
                        {getLabel(option)}
                    </MenuItem>
                ))}
            </DropdownButton>
        </>
    );
};
