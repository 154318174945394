import { Button, ButtonGroup } from '@cancercentrum/rcc-bootstrap';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import type { PickerProps } from '../labbprov/Picker';
import { Picker } from '../labbprov/Picker';
import type { TextSummaryButtonProps } from '../../components/TextRepresentation/TextRepresentationButton';
import TextRepresentationButton from '../../components/TextRepresentation/TextRepresentationButton';
import ChartContext from '../../components/Chart/ChartContext';
import { ScaleSelector } from './ScaleSelector';
import { LakemedelModeSelector } from './LakemedelModeSelector';
import { RangeSelector } from './RangeSelector';
import { PatientIncaToolbar } from './PatientIncaToolbar';

const ChartZoomButton = () => {
    const ctxChart = useContext(ChartContext);
    if (!ctxChart) {
        throw new Error('ChartContext is missing.');
    }

    if (!ctxChart.isZoomed) {
        return null;
    }

    return (
        <div className="zoom-out-button-container">
            <Button onClick={ctxChart.zoomOut} bsSize="sm" className="zoom-out-button inca-primary">
                <FontAwesomeIcon icon={faSearchMinus} /> Nollställ zoom
            </Button>
        </div>
    );
};

export const ChartToolbar = (props: {
    showScaleSelector?: boolean;
    labprovProps?: PickerProps;
    textRepresentationProps?: TextSummaryButtonProps;
    children?: ReactNode;
}) => {
    const { showScaleSelector, children, labprovProps, textRepresentationProps } = props;
    return (
        <div className="d-flex align-items-center justify-content-center flex-wrap gap-2 mb-2">
            <ChartZoomButton />

            <div>
                {children}
            </div>

            <div>
                <ButtonGroup>
                    {!!labprovProps && <Picker {...labprovProps} />}
                    {!!textRepresentationProps && <TextRepresentationButton {...textRepresentationProps} />}

                    {showScaleSelector && <ScaleSelector />}
                    <LakemedelModeSelector />
                    <RangeSelector />
                    <PatientIncaToolbar />
                </ButtonGroup>
            </div>
        </div>
    );
};
