import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useMemo, useRef, useState } from 'react';
import { Alert, Button, DropdownButton, MenuItem, Modal } from '@cancercentrum/rcc-bootstrap';
import { useDataActions, useRowModel } from '@cancercentrum/rcc-react';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { format } from 'date-fns';
import { faChartLine, faPlusCircle, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import ChartContext from '../../components/Chart/ChartContext';
import { send1177OverviewMessage } from '../../utils/1177';
import { ReadOnlyContext } from '../../components/ReadOnlyContext';
import usePDL from '../../pdl/usePDL';
import { useAppSettings } from '../../../components/AppSettings';
import useConfig from '../../hooks/useConfig';
import { useIPO } from '../../hooks/useIPO';
import { useGlobalInca } from '../../../components/GlobalIncaProxy';
import { validationPredicate } from '../../utils/row';
import type { HighchartsRefType } from './types';
import { createExportedChart, parseExportedChartJSON } from './utils';

export interface ExportedChart {
    version: string,
    graph: Highcharts.Options;
    extremes: Highcharts.ExtremesObject;
}

const CurrentGraphVersion = 'R8';

export const PatientIncaToolbar = () => {
    const ipo = useIPO();
    const dataActions = useDataActions();
    const { patientInca, patientIncaLimitToPosIds } = ipo.diagnosis.layout.settings;
    const row = useRowModel();
    const [mode, setMode] = useState<'view' | 'create' | null>(null);
    const [isLoading1177, setIsLoading1177] = useState(false);
    const prevImage = useRef<{ date: string | null; image: string | null; } | undefined>(undefined);
    const ctxChart = useContext(ChartContext)!;
    const ctxConfig = useConfig();
    const isReadOnly = useContext(ReadOnlyContext);
    const incaProxy = useGlobalInca();
    const appSettings = useAppSettings();
    const pdl = usePDL();
    const imageDate = row.getRegvarValue('PatientINCA_bilddatum');
    const stringifiedConfig = row.getRegvarValue('PatientINCA_image');
    const chartRef = useRef<HighchartsRefType | null>(null);

    const config = useMemo(() => {
        if (!imageDate || !stringifiedConfig) {
            return null;
        }

        return parseExportedChartJSON(stringifiedConfig, chartRef);
    }, [imageDate, stringifiedConfig]);

    if (!patientInca || (patientIncaLimitToPosIds && !patientIncaLimitToPosIds.includes(ipo.currentUser.position.id))) {
        return null;
    }

    const onCreatePatientView = () => {
        if (!isReadOnly && ctxChart.chart) {
            // Spara undan befintliga värden så vi kan återställa om användaren trycker på Avbryt.
            prevImage.current = {
                date: row.getRegvarValue('PatientINCA_bilddatum'),
                image: row.getRegvarValue('PatientINCA_image'),
            };
            const exportedChart = createExportedChart(ctxChart.chart, CurrentGraphVersion);
            const img = JSON.stringify(exportedChart);

            dataActions.updateRow(x => x
                .setRegvarValue('PatientINCA_bilddatum', format(new Date(), 'yyyy-MM-dd'))
                .setRegvarValue('PatientINCA_image', img));

            setMode('create');
        }
    };
    const removeImage = () => {
        dataActions.updateRow(x => x.setRegvarValue('PatientINCA_bilddatum', null).setRegvarValue('PatientINCA_image', null));
    };

    const notify1177 = async () => {
        setIsLoading1177(true);

        const sourceSystemId = ctxConfig.layout.settings['1177']?.sourceSystemId;
        try {
            await send1177OverviewMessage(
                sourceSystemId,
                ctxConfig.id,
                ctxConfig.diagnos,
                ipo.patient.id,
                appSettings.patientPortal_overview_message,
                { EtjansterUrl: appSettings.etjanster_url },
            );

            await incaProxy.save();

            // Saving can be quite slow but should complete in less than 10s.
            setTimeout(() => {
                setIsLoading1177(false);
            }, 10000);
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e);
            alert(e.message);
            setIsLoading1177(false);
        }
    };

    const viewExisting = imageDate && stringifiedConfig ? (
        <MenuItem onClick={() => setMode('view')}><FontAwesomeIcon icon={faChartLine} /> Visa</MenuItem>
    ) : null;
    const removeExisting = imageDate && stringifiedConfig ? (
        <MenuItem onClick={removeImage}><FontAwesomeIcon icon={faTrash} /> Ta bort </MenuItem>
    ) : null;

    const onCloseModal = () => {
        if (mode === 'create') {
            const cur = prevImage.current;
            if (cur) {
                dataActions.updateRow(x => {
                    return x.setRegvarValue('PatientINCA_bilddatum', cur.date).setRegvarValue('PatientINCA_image', cur.image);
                });
            }
        }

        prevImage.current = undefined;
        setMode(null);
    };

    const curErrors = row.getErrors({
        includePristine: true,
        validationPredicate: validationPredicate,
    });
    const errorsCount = curErrors.filter((x) =>
        x.$errors.some((y) => y.isFatal),
    ).length;

    return (
        <DropdownButton bsSize="sm" title="Patientvy" id="ExportGridDropdown" className="btn-group" pullRight>
            {!!imageDate && (
                <>
                    <MenuItem header>Senast skapad - {imageDate}</MenuItem>
                    {viewExisting}
                    {removeExisting}
                    <MenuItem divider />
                </>
            )}

            <MenuItem onClick={onCreatePatientView} disabled={isReadOnly}><FontAwesomeIcon icon={faPlusCircle} /> Skapa ny patientvy</MenuItem>

            <Modal
                show={!!mode}
                bsSize="xl"
                onHide={onCloseModal}
                className="patientIncaModal"
                animation={false}
            >
                <Modal.Header closeButton={true}>Patientvy ({imageDate})</Modal.Header>
                <Modal.Body className="p-3 overflow-hidden">
                    {!!mode && !!config && (
                        <div>
                            {config.version !== CurrentGraphVersion && (
                                <div>
                                    <p>Vyn är skapad i en äldre version och går inte att förhandsgranska. Patienten kan fortfarande se den genom att logga in på e-tjanster.incanet.se.</p>
                                    {!isReadOnly && (
                                        <p>
                                            <button onClick={onCreatePatientView} className="btn btn-default">Skapa ny vy</button>
                                        </p>
                                    )}
                                </div>
                            )}
                            {config.version === CurrentGraphVersion && (
                                <div>
                                    <p>
                                        Denna patientvy kommer att kunna ses av patienten på INCAs e-tjänster efter att patientöversikten sparats.
                                    </p>
                                    {pdl.patientPortal.isActive && !errorsCount && <p>Genom att klicka på knappen längst ner skickas ett meddelande till 1177 som innehåller en länk till vyn.</p>}
                                    {!pdl.patientPortal.isActive && (
                                        <Alert bsStyle="info">
                                            Patienten saknar aktiv inkorg på 1177.
                                        </Alert>
                                    )}
                                    {errorsCount > 0 && (
                                        <Alert bsStyle="info">
                                            Det finns valideringsfel som måste åtgärdas innan det går att spara och skicka meddelande till 1177.
                                        </Alert>
                                    )}
                                    <HighchartsReact highcharts={Highcharts} options={config.graph} isPureConfig={true} ref={chartRef} />
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCloseModal} bsStyle="default-2">{mode === 'create' ? 'Avbryt' : 'Stäng'}</Button>

                    {process.env.RCC_ENV !== 'external_demo' && (
                        <Button onClick={notify1177} bsStyle="primary" disabled={isLoading1177 || !pdl.patientPortal.isActive || errorsCount > 0}>
                            {isLoading1177 && <div><FontAwesomeIcon icon={faSpinner} spin={true} className="me-2" />Spara och skicka meddelande till 1177</div>}
                            {!isLoading1177 && 'Spara och skicka meddelande till 1177'}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </DropdownButton>
    );
};
