import { useGetValueDomainValues } from '@cancercentrum/rcc-react';
import { useIPO } from '../../hooks/useIPO';

/**
 * Returnerar defaultfunktionen för att ladda en diagnos basdata. Filtrerar på patient-id.
 */
export const useDefaultGetRootBasdata = (vdname: string): () => Promise<unknown> => {
    const ipo = useIPO();
    const getValueDomainValues = useGetValueDomainValues();

    return () => getValueDomainValues(vdname, {
        parameters: {
            patientId: [ipo.patient.id],
        },
    });
};
