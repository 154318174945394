import { useEffect } from 'react';
import { useDataActions } from '@cancercentrum/rcc-react';
import { useGetEcogData } from '../kontakt';
import type { LastEcogResult } from '../kontakt';

export const useSetLatestEcog = (startdatum: string | null): LastEcogResult | undefined => {
    const last = useGetEcogData(startdatum);
    const dataActions = useDataActions();

    useEffect(() => {
        dataActions.updateRow(x => x.setRegvarValue('Lkm_ecog', last?.ecog.id || null), { skipHistory: true });
    }, [last, startdatum, dataActions]);

    return last;
};
