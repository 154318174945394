import type { DataSet } from '../types';

const minidataset: DataSet = {
    AuditLog: {
        al_anvandarnamn: { isInternal: true },
        al_klientdatum: { isInternal: true },
        al_kommentar: { isInternal: true },
        al_plattform: { isInternal: true },
        al_position_vd: { isInternal: true },
        al_roll_vd: { isInternal: true },
        al_useragent: { isInternal: true },
    },
    Oversikt: {
        PatientINCA_bilddatum: {},
        PatientINCA_image: {},
        diagnoskonfiguration: { isInternal: true },
        kommentar: {},
    },
    AnnanBehandling: {
        ab_skapadPos_vd: { isInternal: true },
        ab_skapadDatum: { isInternal: true },
    },
    Biverkningsrapportering: {
        Biv_skapadPos_vd: { isInternal: true },
        Biv_skapadDatum: { isInternal: true },
        Biv_uuid: { isInternal: true },
        Biv_orsak: {
            identifiers: ['lakemedel', 'stralbehandling', 'annan_orsak'],
        },
        Biv_orsak_annan: {},
        Biv_lkm_insattningsuuid: { isInternal: true },
        Biv_stral_uuid: { isInternal: true },
    },
    Kontakt: {
        K_besoksdatum: {},
        K_skapadPos_vd: { isInternal: true },
        K_skapadDatum: { isInternal: true },
        K_sourceSystemName: { isInternal: true },
        K_sourceSystemId: { isInternal: true },
    },
    Biobank: {
        Bb_datum: {},
        Bb_skapadPos_vd: { isInternal: true },
        Bb_skapadDatum: { isInternal: true },
    },
    Studie: {
        Stud_skapadPos_vd: { isInternal: true },
        Stud_skapadDatum: { isInternal: true },
        Stud_patientenAvbojt: {},
        Stud_startdatum: {},
        Stud_studie_vd: {},
        Stud_studienamn: {},
    },
    Stralbehandling: {
        Stral_skapadPos_vd: { isInternal: true },
        Stral_skapadDatum: { isInternal: true },
        Stral_uuid: { isInternal: true },
    },
    Kirurgi: {
        Kir_datum: {},
        Kir_skapadPos_vd: { isInternal: true },
        Kir_skapadDatum: { isInternal: true },
    },
    Labbprov: {
        Labb_datum: {},
        Labb_skapadPos_vd: { isInternal: true },
        Labb_skapadDatum: { isInternal: true },
    },
    Patologi: {
        Pat_provtagningsdatum: {},
        Pat_skapadPos_vd: { isInternal: true },
        Pat_skapadDatum: { isInternal: true },
    },
    Bilddiagnostik: {
        Bild_datum: {},
        Bild_skapadPos_vd: { isInternal: true },
        Bild_skapadDatum: { isInternal: true },
    },
    Bidragande: {
        bidr_datum: { isInternal: true },
        bidr_godkannande: { isInternal: true },
        bidr_position_fk: { isInternal: true },
        bidr_kommentar: { isInternal: true },
        bidr_anvandarnamn: { isInternal: true },
    },
    SSE: {
        SSE_datum: {},
        SSE_skapadPos_vd: { isInternal: true },
        SSE_skapadDatum: { isInternal: true },
    },
    Läkemedel: {
        // @ts-ignore ej med i utformning
        Lkm_insattningsuuid_forsta: {},
        // @ts-ignore ej med i utformning
        Lkm_stoppdatum_senaste: {},
        // @ts-ignore ej med i utformning
        Lkm_stopporsak_senaste: {
            identifiers: [],
        },
        // @ts-ignore ej med i utformning
        Lkm_stopporsakSpec_senaste: {},
        // @ts-ignore ej med i utformning
        Lkm_antalPauser: {},

        Lkm_behandlingsintention: {
            identifiers: ['1', '2', 'remissionssyftande'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '2', '3', '5'],
        },
        Lkm_icd10: {},
        Lkm_icd10_vd: {},
        Lkm_skapadDatum: { isInternal: true },
        Lkm_skapadPos_vd: { isInternal: true },
        Lkm_skapadRoll_vd: { isInternal: true },
        Lkm_startdatum: {},
        Lkm_stoppdatum: {},
        Lkm_stopporsak: {
            identifiers: [
                '1', 'enligt_plan', // '1' Kan tas bort efter R12 när identifier ändrats
                '2', 'toxicitet', // '2' Kan tas bort efter R12 när identifier ändrats
                '3', 'progress', // '3' Kan tas bort efter R12 när identifier ändrats
                '5', 'patientens_val', // '5' Kan tas bort efter R12 när identifier ändrats
                'dod_progress',
                'dod_toxicitet',
                'dod_annan',
                '9', 'annan_orsak', // '9' Kan tas bort efter R12 när identifier ändrats
            ],
        },
        Lkm_exkluderad: { isInternal: true },
        Lkm_migreringsdublett: { isInternal: true },
        Lkm_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        Lkm_regim_vd: {},
        Lkm_substans_vd: {},
        Lkm_insattningsuuid: { isInternal: true },
        Lkm_sourceSystemId: { isInternal: true },
        Lkm_sourceSystemName: { isInternal: true },
        Lkm_utvalt: {},
        Lkm_gesInomRamenAvStudie: {},
        Lkm_studielakemedel_spec: {},
        Lkm_stopporsakSpec: {},
        Lkm_kurlangd: {},
        Lkm_sistaKurensForstaDag: {},
    },
    Lokalstatus: {
        L_datum: {},
        L_skapadDatum: { isInternal: true },
        L_skapadPos_vd: { isInternal: true },
    },
    Mutationsanalys: {
        Mut_skapadPos_vd: { isInternal: true },
        Mut_skapadDatum: { isInternal: true },
    },
    Meddelande: {
        Med_avsandare_vd: {},
        Med_mottagare_vd: {},
        Med_rubrik: {},
        Med_text: {},
        Med_tidsstampel: {},
    },
    Patientportal: {
        Pp_datum: {},
        Pp_svar_vd: {},
        Pp_anvandarnamn: {},
        Pp_position_vd: {},
        Pp_notifieringsmetod: {
            identifiers: ['1177', 'pdf'],
        },
    },
    PSAPåverkandeBehandling: {
        PSA_skapadPos_vd: { isInternal: true },
        PSA_skapadDatum: { isInternal: true },
    },
    Recidivrapportering: {
        R_skapadPos_vd: { isInternal: true },
        R_skapadDatum: { isInternal: true },
    },
    Responsbedömning: {
        Resp_skapadPos_vd: { isInternal: true },
        Resp_skapadDatum: { isInternal: true },
    },
    Meddelandeåtgärd: {
        Ma_tidsstampel: {},
    },
};

export default minidataset;
