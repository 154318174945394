import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactElement } from 'react';
import { memo, useState } from 'react';
import isNil from 'lodash/isNil';
import classnames from 'classnames';
import { isValid, parseISO } from 'date-fns';
import type { HookDataActions, RowModel } from '@cancercentrum/rcc-react';
import { dataUtils, DetachedRow, IncaRowContext, useIncaForm } from '@cancercentrum/rcc-react';
import { Alert, Button, Modal } from '@cancercentrum/rcc-bootstrap';
import every from 'lodash/every';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { shallowEqual, useSelector } from 'react-redux';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { PDLGridRemoveIcon } from '../../../pdl/PDLGridRemoveIcon';
import { ModalWrapper } from '../../../../../components/ModalWrapper';
import { EcogIcon } from '../../../../components/EcogIcon';
import useDataSet from '../../../../hooks/useDataSet';
import { isSameDayOrBefore } from '../../../../utils/date';
import { useBiverkanRegistrator, useRegisteredBiverkan } from '../../../biverkan/utils';
import { ToxIdentifiers } from '../../constants';
import type { HierarchifiedSubstansItem, RowRegimItem, UtvaldSubstansVDItem } from '../../types';
import type { OverviewState } from '../../../../types';
import type { Läkemedel, LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { LakemedelPromiseResult } from '../../useLakemedelVDPromise';
import { fancyConfirm } from '../../../../../utils/fancyConfirm';
import type { GetEditButtonTextCallback, GetSubstanceNameCallback, RenderEditContentCallback } from './SubstanceRow';
import SubstanceRow from './SubstanceRow';
import RegimSattUt from './RegimSattUt';

export interface RenderSattUtRegimData {
    row: LäkemedelRowModel;

    // Det lägsta startdatumet på en av regimernas ingående substanser.
    minDate: string;

    // Antal substanser som inte kommer påverkas av regimens utsättning pga att dess startdatum ligger efter angivet stoppdatum.
    numSkipped: number;
}

export type RenderSattUtRegimCallback = (data: RenderSattUtRegimData) => ReactElement;

const defaultRenderSattUtRegim: RenderSattUtRegimCallback = (data) => <RegimSattUt {...data} />;

export const NumSkippedHelpText = (props: {
    numSkipped: number;
}): ReactElement | null => {
    const { numSkipped } = props;

    if (!numSkipped) {
        return null;
    }

    return <div className="help-block"><FontAwesomeIcon icon={faExclamationCircle} /> Regimen innehåller {numSkipped} läkemedel med insättning efter angivet datum som inte kommer att påverkas.</div>;
};

const SattUtRegimContent = (props: {
    row: LäkemedelRowModel;
    renderSattUtRegim: RenderSattUtRegimCallback;
    insattningsuuid: string;
}) => {
    const { row, renderSattUtRegim, insattningsuuid } = props;
    const stopp = row.getRegvarValue('Lkm_stoppdatum');
    const data = useSelector((state: OverviewState) => {
        const subsInRegim = state.data.present.getSubTableRows('Läkemedel').filter(x => x.getRegvarValue('Lkm_insattningsuuid') === insattningsuuid);
        const ongoingSubs = subsInRegim.filter(x => !x.getRegvarValue('Lkm_stoppdatum'));
        const subs = ongoingSubs.map<string>(x => x.getRegvarValue('Lkm_startdatum')!).sort();
        const minDate = subs.first()!;

        // Räkna endast ut substanser som skippas om datumet är efter första insättning. Annars är inte infon relevant, exempelvis slår
        // minDate-valideringen till om ett datum före minDate anges.
        const numSkipped = !!stopp && isValid(parseISO(stopp)) && stopp >= minDate ? ongoingSubs.filter(x => x.getRegvarValue('Lkm_startdatum')! > stopp).count() : 0;

        return { minDate, numSkipped };
    }, shallowEqual);

    return (
        <div>
            {renderSattUtRegim({ row, minDate: data.minDate, numSkipped: data.numSkipped })}
        </div>
    );
};

const RegimRow = (props: {
    dataActions: HookDataActions;
    isActive: boolean;
    substanserById: LakemedelPromiseResult['substanserById'];
    regimerById: LakemedelPromiseResult['regimerById'];
    renderSattUtRegim?: RenderSattUtRegimCallback;
    renderEditContent?: RenderEditContentCallback;
    data: RowRegimItem;
    getSubstanceName: GetSubstanceNameCallback;
    getEditLakemedelButtonText?: GetEditButtonTextCallback;
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
}): ReactElement => {
    const {
        data,
        dataActions,
        substanserById,
        regimerById,
        renderSattUtRegim = defaultRenderSattUtRegim,
        renderEditContent,
        getEditLakemedelButtonText,
        getSubstanceName,
        availableSubstanser,
        vdUtvaldaSubstanser,
    } = props;
    const inca = useIncaForm();
    const ds = useDataSet();
    const hasBiverkanModule = !!ds.Biverkningsrapportering;
    const biverkanRegistrator = useBiverkanRegistrator();
    const registeredBiverkningar = useRegisteredBiverkan(data.items.map(x => x.substance.getRegvarValue('Lkm_substans_vd') as number));
    const startDates = uniq(orderBy(data.items.map(x => x.substance.getRegvarValue('Lkm_startdatum') as string), [x => x], ['asc']));
    const earliestStart = startDates[0];
    const row = data.items[0].substance;
    const ecog = row.getRegvarValue('Lkm_ecog');
    const regimName = data.vdItem.data.reg_namn;
    const isUtsatt = every(data.items, item => item.substance.getRegvarValue('Lkm_stoppdatum'));
    const [initialRow] = useState(() => {
        const newRow = dataUtils.createDataRow('Läkemedel', inca.form.metadata);
        return dataUtils.createViewModel<Läkemedel>(newRow, inca.form.metadata, 'Läkemedel');
    });
    const [showModal, setShowModal] = useState(false);
    const ctxDataSet = useDataSet();
    const insattningsuuid = row.getRegvarValue('Lkm_insattningsuuid')!; // Alla lkm inom samma regiminsättning har samma UUID.

    const onRemove = () => {
        fancyConfirm({
            header: <div>Ta bort regimen <strong>{regimName}</strong>?</div>,
            content: <div>Bekräfta borttag av regimen <em>{regimName}</em> och dess läkemedel. Åtgärden går inte att ångra.</div>,
        }).then(ok => {
            ok && dataActions.updateRow(storeRow => data.items.reduce((x, r) => x.removeSubTableRow(r.substance), storeRow));
        });
    };
    const showSattUt = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };
    const onSattUt = (sattUtRow: RowModel, reset: () => void) => {
        const shortnames = Object.keys(ctxDataSet['Läkemedel']!);

        data.items.forEach(itm => {
            const lkmRow = itm.substance;

            if (isSameDayOrBefore(parseISO(lkmRow.getRegvarValue('Lkm_startdatum') || ''), parseISO(sattUtRow.getRegvarValue('Lkm_stoppdatum') || '')) && !lkmRow.getRegvarValue('Lkm_stoppdatum')) {
                dataActions.updateRow(dataRow => {
                    return shortnames.reduce((reduction, shortname) => {
                        const rv = sattUtRow.getRegvar(shortname);
                        const val = rv.value;

                        return isNil(val) ? reduction : reduction.setRegvarValue(shortname, val, { markAsDirty: rv.$isDirty });
                    }, dataRow);
                }, {
                    appendPath: [{ tableName: lkmRow.$rcc.tableName, rowId: lkmRow.getId() }],
                });
            }
        });

        setShowModal(false);

        // Resets the DetachedForm back to it's initial row.
        reset();
    };

    const onSattUtBiv = (sattUtRow: RowModel, reset: () => void) => {
        onSattUt(sattUtRow, reset);
        biverkanRegistrator(
            sattUtRow.getRegvarValue('Lkm_stoppdatum'),
            data.items[0].substance.getRegvarValue('Lkm_insattningsuuid') as string,
            data.items.map(x => ({
                type: 'vd',
                substanceId: x.substance.getRegvarValue('Lkm_substans_vd')!,
            })),
            data.items[0].substance.getRegvarValue('Lkm_regim_vd') as number | undefined,
        );
    };

    return (
        <tbody>

        <tr className={classnames({ inactive: !data.isActive })}>
            <td>
                <strong>{data.name}</strong></td>
            <td>
                {(!isUtsatt) && <Button type="button" bsSize="xs" onClick={showSattUt}>Sätt ut</Button>}

                {showModal && (
                    <DetachedRow id="RegimFrm" initialRow={initialRow}>
                        {({ reset, row: dr, validate }) => {
                            const showSaveBiv = hasBiverkanModule && ToxIdentifiers.includes(dr.getRegvarValue('Lkm_stopporsak')?.identifier);

                            return (
                                <ModalWrapper show={true} bsSize="xl" onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Sätt ut {regimName}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="regim-edit">
                                        {!isUtsatt && (
                                            <Alert bsStyle="info">
                                                <strong>OBS!</strong> Endast läkemedel som inte redan satts ut och vars startdatum är före angivet stoppdatum påverkas.
                                            </Alert>
                                        )}

                                        <div className="mb-4 form-horizontal">
                                            <div className="form-group">
                                                <label className="form-label col-md-3">Startdatum</label>
                                                <div className="col-md-9">
                                                    <p className="form-control-static">{earliestStart}</p>
                                                    {startDates.length > 1 && <p className="form-control-static"><em>Det finns läkemedel inom regimen med olika startdatum. Datumet ovan är det tidigaste.</em></p>}
                                                </div>
                                            </div>
                                        </div>

                                        <SattUtRegimContent row={dr} renderSattUtRegim={renderSattUtRegim} insattningsuuid={insattningsuuid} />

                                        {hasBiverkanModule && !!registeredBiverkningar.length && (
                                            <div className="alert alert-info">
                                                Registrerade läkemedelsbiverkningar:
                                                <ul className="mb-0">
                                                    {registeredBiverkningar.map(bv => (
                                                        <li key={bv.id}>
                                                            <strong>{substanserById[bv.substanceId].data.sub_subnamnrek}</strong> - {bv.date}: {bv.ctcae}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={handleClose} bsStyle="link">Avbryt</Button>
                                        <Button onClick={() => validate() && onSattUt(dr, reset)} bsStyle="primary">Sätt ut</Button>
                                        {showSaveBiv && <Button onClick={() => validate() && onSattUtBiv(dr, reset)} bsStyle="primary">Sätt ut och registrera biverkning</Button>}
                                    </Modal.Footer>
                                </ModalWrapper>
                            );
                        }}
                    </DetachedRow>
                )}
            </td>
            <td />
            <td>{!!ecog && <span><EcogIcon value={ecog.value} /> {ecog.value}. {ecog.text}</span>}</td>
            <td>{row.getRegvarValue('Lkm_behandlingsintention')?.text || ''}</td>
            <td>{row.getRegvarValue('Lkm_behandlingsintention_spec')?.text || ''}</td>
            <td className="nostretch text-center">
                <PDLGridRemoveIcon
                    onClick={onRemove}
                    row={data.items[0].substance}
                    messageTitle={data.name}
                />
            </td>
        </tr>

        {data.items.map(s => {
                return (
                    <IncaRowContext
                        key={`${data.regimId}-${s.substance.getId()}`}
                        rowId={s.substance.getId()}
                        tableName={s.substance.$rcc.tableName}
                    >
                        {({ dataActions: da }) => (
                            <SubstanceRow
                                data={s}
                                dataActions={da}
                                substanserById={substanserById}
                                regimerById={regimerById}
                                renderEditContent={renderEditContent}
                                getEditButtonText={getEditLakemedelButtonText}
                                getSubstanceName={getSubstanceName}
                                availableSubstanser={availableSubstanser}
                                vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                            />
                        )}
                    </IncaRowContext>
                );
            },
        )}
        </tbody>
    );
};

export default memo(RegimRow);
