import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MouseEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import type { RowModel } from '@cancercentrum/rcc-react';
import { useIncaForm } from '@cancercentrum/rcc-react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import type { GridRemoveIconProps } from '../../components/GridRemoveIcon';
import { GridRemoveIcon } from '../../components/GridRemoveIcon';
import usePDL from '../../pdl/usePDL';
import MeddelandeModal from '../meddelande/MeddelandeModal';

export interface PDLGridRemoveIconProps extends GridRemoveIconProps {
    messageTitle?: string;
    row: RowModel;
}

const helpStyle = { cursor: 'help' };
const buttonStyle = { cursor: 'pointer' };

export const PDLGridRemoveIcon = (props: PDLGridRemoveIconProps): ReactElement => {
    const { messageTitle, row } = props;
    const pdlContext = usePDL();
    const dataRowOwnerId = row.$extra.dataRowOwnerPositionId;
    const contributingPosition = pdlContext.positions.find(x => x.id === dataRowOwnerId);
    const [modalVisible, setModalVisible] = useState(false);
    const showModal: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalVisible(true);
    };
    const hideModal = () => setModalVisible(false);
    const inca = useIncaForm();
    const isReadOnly = !!row.id && !inca.form.metadata[row.$rcc.tableName].allowDataRowDeletionIfPDLAccess && dataRowOwnerId !== pdlContext.currentUser.position.id;

    if (isReadOnly) {
        if (!contributingPosition) {
            return <FontAwesomeIcon icon={faLock} fixedWidth={true} title="Denna registrering är gjord av en annan klinik och kan bara tas bort av den kliniken." style={helpStyle} />;
        }

        const title = `Denna registrering kan bara tas bort av ${contributingPosition.data.PosNameWithCode}.

Klicka här för att skicka ett meddelande till kliniken.`;

        return (
            <>
                <Button bsStyle="link" onClick={showModal} title={title} className="py-0">
                    <FontAwesomeIcon icon={faLock} fixedWidth={true} style={buttonStyle} />
                </Button>

                <MeddelandeModal visible={modalVisible} onClose={hideModal} position={contributingPosition} defaultTitle={messageTitle} />
            </>
        );
    }

    return <GridRemoveIcon {...props} />;
};
