import type { ReactElement, ReactNode } from 'react';
import { useContext } from 'react';
import type { ButtonProps } from '@cancercentrum/rcc-bootstrap';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ReadOnlyContext } from './ReadOnlyContext';

export const AddRowButton = (props: {
    content?: ReactNode;
} & ButtonProps): ReactElement | null => {
    const {
        content = 'Lägg till',
        ...rest
    } = props;
    const isReadOnly = useContext(ReadOnlyContext);

    if (isReadOnly) {
        return null;
    }

    return <Button bsStyle="success" {...rest}><FontAwesomeIcon icon={faPlusCircle} /> {content}</Button>;
};
