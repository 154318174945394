import { renderToStaticMarkup } from 'react-dom/server';
import { getAllFollowUpQuestions, getValueAsText } from '../utils';
import type { ChartInfo, QuestionMetadata, SeverityGrade, SummaryFieldRegvar, SurveyAnswer, SurveyMetadata, Utskicksprojekt, UtskicksprojektConfig } from '../types';
import { LegacyTooltip } from '../../../components/Chart/Tooltip';
import { MarkdownQuestion } from '../ProjectViewer/MarkdownQuestion';
import type { GraphPointObject } from '../../../components/Chart/types';

interface SeverityGroup {
    grade: SeverityGrade,
    questions: string[];
}

const groupBySeverity = (
    answer: SurveyAnswer,
    config: UtskicksprojektConfig,
    metadata: SurveyMetadata,
    chartInfo: ChartInfo,
): SeverityGroup[] => {
    const { severityGrades, hiddenQuestions, summaryFields } = config;
    const allFollowupQuestions = getAllFollowUpQuestions(config);

    return severityGrades.filter(x => !x.hideInPie).map(grade => {
        return {
            grade: grade,
            questions: Object.keys(metadata.questions).filter(shortname => {
                const isHidden = !!hiddenQuestions?.includes(shortname);
                const isFollowupQuestion = allFollowupQuestions.includes(shortname);
                const includedInChartInfo = !chartInfo.questionSelector || chartInfo.questionSelector(shortname);
                const includedInSummaryFields = summaryFields.filter((x): x is SummaryFieldRegvar => 'shortname' in x).some(x => x.shortname === shortname);
                const includedInSeverityGrade = grade.evaluateAnsweredQuestion(shortname, answer.data[shortname], metadata.questions[shortname]);
                return !isHidden && !isFollowupQuestion && includedInChartInfo && !includedInSummaryFields && includedInSeverityGrade;
            }),
        };
    });
};

const defaultGetTooltip = (
    shortnames: string[],
    answer: SurveyAnswer,
    metadata: SurveyMetadata,
    severityGrade: SeverityGrade,
    chartInfo: ChartInfo,
    config: UtskicksprojektConfig,
) => {
    const renderAnswer = (question: QuestionMetadata): string => {
        return (config.answerFormatter ? config.answerFormatter({
            answer,
            question,
            surveyMetadata: metadata,
            defaultFormatter: getValueAsText,
        }) : getValueAsText(answer, question, metadata)) ?? '-';
    };

    return (
        <LegacyTooltip headerText={severityGrade.name} color={severityGrade.color}>
            <table className="table table-condensed mb-0">
                <thead>
                <tr>
                    <th>Fråga</th>
                    <th>Svar</th>
                </tr>
                </thead>

                <tbody>
                {shortnames.filter(sn => !chartInfo.questionSelector || chartInfo.questionSelector(sn)).map(sn => {
                    const question = metadata.questions[sn];

                    if (!question) {
                        return null;
                    }
                    const followupQuestions = config.followupQuestions || {};

                    return [
                        <tr key={sn}>
                            <td className="tooltip-text-ellipsis"><MarkdownQuestion text={question.label} /></td>
                            <td className="tooltip-text-ellipsis">{renderAnswer(question)}</td>
                        </tr>,
                        ...(followupQuestions[sn] || []).map(followupQuestionShortname => {
                            const followupQuestion = metadata.questions[followupQuestionShortname];
                            return (
                                <tr key={followupQuestionShortname} data-shortname={followupQuestionShortname} className="followup-question">
                                    <td className="tooltip-text-ellipsis"><MarkdownQuestion className="ps-2 ps-2" text={followupQuestion.label} /></td>
                                    <td className="tooltip-text-ellipsis">{renderAnswer(followupQuestion)}</td>
                                </tr>
                            );
                        }),
                    ];
                })}
                </tbody>
            </table>
        </LegacyTooltip>
    );
};

export const getPoints = (
    utskicksprojekt: Utskicksprojekt,
    answer: SurveyAnswer,
    chartInfo: ChartInfo,
): GraphPointObject[] => {
    const groupedBySeverityGrade = groupBySeverity(answer, utskicksprojekt.config, utskicksprojekt.survey, chartInfo);
    return groupedBySeverityGrade.map(point => {
        const tt = (point.grade.getTooltip || defaultGetTooltip)(point.questions, answer, utskicksprojekt.survey, point.grade, chartInfo, utskicksprojekt.config);
        return {
            y: point.questions.length,
            value: point.questions.length,
            color: point.grade.color,
            tooltip: renderToStaticMarkup(tt),
        };
    });
};
