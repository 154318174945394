import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import { formatISO } from 'date-fns';
import type { RowModel } from '@cancercentrum/rcc-react';
import uniqBy from 'lodash/uniqBy';
import { useCallback } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import { useIPO } from '../../hooks/useIPO';
import type { LäkemedelRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import type { GetSubstanceNameCallback } from './components/Behandlingar/SubstanceRow';
import type { GetCategoryCallback, GetRegimCategoryCallback, Registration, SerieData, SubstanskategoriVDItem } from './types';

export const useGetDefaultSubstanceNameCb = () => {
    const { dataset } = useIPO();
    const callback: GetSubstanceNameCallback = useCallback((row, vdItem) => {
        const spec = row.getRegvarValue('Lkm_studielakemedel_spec');
        const subnamn = vdItem?.data.sub_subnamnrek;
        let res = subnamn || spec || 'Okänt läkemedel';

        if (dataset.Läkemedel!.Lkm_ablatioTestis && row.getRegvarValue('Lkm_ablatioTestis')) {
            res = 'Ablatio testis';
        }

        if (dataset.Läkemedel!.Lkm_avblindatresultat && row.getRegvarValue('Lkm_avblindatresultat')?.identifier === 'placebo') {
            res = 'Placebo';
        }

        if (dataset.Läkemedel!.Lkm_avblindatresultat && row.getRegvarValue('Lkm_avblindatresultat')?.identifier === 'aktivt_studielakemedel') {
            res = 'Aktivt studieläkemedel';
        }

        return res;
    }, [dataset]);

    return callback;
};

export const defaultGetCategory: GetCategoryCallback = (row, vdSubstanskategorier) => {
    const fallback = vdSubstanskategorier.find(x => x.data.k_fallback);
    if (!fallback) {
        throw new Error('Ingen fallback-kategorimappning för substanser kunde hittas. Det måste finnas en fallback.');
    }
    const subId = row.getRegvarValue('Lkm_substans_vd') as number | null;
    if (!subId) {
        return fallback;
    }

    return vdSubstanskategorier.find(x => x.data.s_substans_vd === subId) || fallback;
};

export const trim = (val: string): string => (val || '').trim();

/**
 * Hämtar kategori baserat på en grupp av läkemedel. Kategorin med högst prio returneras.
 */
export const getGroupCategory: GetRegimCategoryCallback = (rows, vdSubstanskategorier, options) => {
    const {
        getCategory = defaultGetCategory,
    } = options || {};

    if (!rows.length) {
        throw new Error('An empty array is not supported.');
    }

    const fallback = vdSubstanskategorier.find(x => x.data.k_fallback);
    if (!fallback) {
        throw new Error('Ingen fallback-kategorimappning för substanser kunde hittas. Det måste finnas en fallback.');
    }

    const cats = rows.reduce<SubstanskategoriVDItem[]>((reduction, row) => {
        const cat = getCategory(row, vdSubstanskategorier);

        reduction.push(cat);

        return reduction;
    }, []);
    const orderedCats = orderBy(cats, [x => x.data.k_prio], ['desc']);

    return orderedCats[0];
};

/**
 * 1) Understödjande ska alltid visas längst ned
 * 2) Senast stoppdatum högst upp
 * 3) Högre prio = högre upp
 */
export const getOrderedCategories = (data: {
    seriedata: SerieData[]
}): string[] => {
    const {
        seriedata,
    } = data;

    return uniq(orderBy(seriedata, [x => {
        const c = x.category;
        return c.data.k_understodjande ? 1 : -1;
    }, x => x.end, x => {
        const c = x.category;
        return c.data.k_prio;
    }], ['asc', 'desc', 'desc']).map(x => x.name));
};

/**
 * Defaultformattering för ett läkemedels start-stopp. Metoden används i textextraktion.
 */
export const defaultFormatLakemedelDate = (startdate: Date, stoppdate?: Date, options?: {
    skipStop?: boolean;
}): JSX.Element => {
    const start = formatISO(startdate, { representation: 'date' });
    const stop = stoppdate ? formatISO(stoppdate, { representation: 'date' }) : undefined;

    if (stoppdate) {
        return <>{start} – {stop}</>;
    } else if (!options?.skipStop) {
        return <>{start} – <b>pågår</b></>;
    }

    return <>{start}</>;
};

const hasDifferentValues = (rows: RowModel[], selector: ((row: RowModel) => unknown)) => {
    return uniqBy(rows, selector).length > 1;
};

/**
 * Kontrollerar om en läkemedelsgrupp (regim) ska splittas isär och visas per substans. Kriteriet är att någon ingående substans är exkluderad
 * eller att start- eller stoppdatum skiljer sig.
 */
export const shouldSplitSubstanceGroup = (group: RowModel[]): boolean => {
    const nonExcluded = group.filter(x => !x.getRegvarValue('Lkm_exkluderad'));

    return nonExcluded.length !== group.length
        || hasDifferentValues(nonExcluded, x => x.getRegvarValue('Lkm_startdatum'))
        || hasDifferentValues(nonExcluded, x => x.getRegvarValue('Lkm_stoppdatum'));
};

export const getRegistrations = (options: {
    // Samtliga rader från läkemedelstabellen, inklusive exkluderade, migreringsdubbletter osv.
    rows: LäkemedelRowModel[];
    vdSubstanskategorier: SubstanskategoriVDItem[];
}): Registration[] => {
    const { rows, vdSubstanskategorier } = options;
    const insattningar = groupBy(rows.filter(x => !x.getRegvarValue('Lkm_migreringsdublett')), x => x.getRegvarValue('Lkm_insattningsuuid') as string);

    return Object.keys(insattningar).reduce<Registration[]>((reduction, insattningsuuid) => {
        const group = insattningar[insattningsuuid];
        const nonExcludedRows = group.filter(x => !x.getRegvarValue('Lkm_exkluderad') as boolean | null);
        if (!nonExcludedRows.length) {
            return reduction;
        }

        const shouldSplit = shouldSplitSubstanceGroup(group);
        const regimId = group[0].getRegvarValue('Lkm_regim_vd') as number | null;

        if (regimId) {
            const category = getGroupCategory(nonExcludedRows, vdSubstanskategorier);

            reduction.push({
                type: 'regim',
                regimId,
                category,
                isCompleteAndSamePeriod: !shouldSplit,
                substances: nonExcludedRows.map(row => {
                    const cat = getGroupCategory([row], vdSubstanskategorier);

                    return {
                        type: 'substans',
                        category: cat,
                        row,
                    };
                }),
            });
        } else {
            nonExcludedRows.forEach(row => {
                const category = getGroupCategory([row], vdSubstanskategorier);

                reduction.push({
                    type: 'substans',
                    category,
                    row,
                });
            });
        }

        return reduction;
    }, []);
};

export const getStartdos = (rows: RowModel[]): string | undefined => {
    const startdoser = rows.reduce<string[]>((red, row) => {
        const dos = (row.getRegvarValue('Lkm_startdos_lista') as ListItem | null)?.text;

        if (dos && !red.includes(dos)) {
            red.push(dos);
        }

        return red;
    }, []);

    return startdoser.length === 1 ? startdoser[0] : undefined;
};
