import { atom } from 'jotai';
import type { UsePromiseHashResult } from '@cancercentrum/rcc-react';

export const basdataAtom = atom<Omit<UsePromiseHashResult<any>, 'exec'>>({
    isLoading: false,
    result: null,
    error: null,
});

export const basdataTabAtom = atom('');

export const selectedBasdataPill = atom('');
