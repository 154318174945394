import type { RowModel } from '@cancercentrum/rcc-react';

export const isDirty = (row: RowModel): boolean => {
    const checks = [
        () => !row.id,
        () => row._destroy,
        () => row.regvars.some(x => x!.$isDirty),
        () => row.subTables.some(st => st!.getRows().some((r) => isDirty(r!))),
    ];

    return checks.some(x => x());
};
