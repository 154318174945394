import type { RegvarValueDomainMetadata, ValueDomainItem } from '@cancercentrum/inca';
import classnames from 'classnames';
import type { RccBsButtonStyle } from '@cancercentrum/rcc-bootstrap';
import { Button } from '@cancercentrum/rcc-bootstrap';
import { useI18n } from '@cancercentrum/rcc-react';

export const VdButtonGroup = (props: {
    disabled?: boolean;
    readOnly?: boolean;
    optionsCaption?: string;
    options: ValueDomainItem[];
    formatter?: (item: ValueDomainItem) => JSX.Element;
    titleFormatter?: (item: ValueDomainItem) => string;
    regvarMetadata: RegvarValueDomainMetadata;
    value: number | null;
    onChange: (value: number | null) => void;
    onBlur?: () => void;
    tabIndex?: number;
    bsStyle?: RccBsButtonStyle;
    selectedBsStyle?: RccBsButtonStyle;
    vertical?: boolean;
}) => {
    const {
        disabled,
        bsStyle,
        formatter = x => x.text,
        titleFormatter,
        selectedBsStyle= 'info',
        optionsCaption,
        readOnly,
        value,
        tabIndex,
        vertical,
    } = props;
    const { t } = useI18n();

    const onChange = (item: ValueDomainItem) => {
        props.onChange(value === item?.id ? null : item.id);
    };

        const availableOptions = props.options;

        if (readOnly) {
            const item = value ? props.options.find(x => x.id === value) : null;
            const captionText = optionsCaption || t('component.listbuttongroup.caption');
            const text = item ? formatter(item) : captionText;

            return <div className="form-control-plaintext">{text}</div>;
        }

        const buttons = availableOptions.reduce<JSX.Element[]>((reduction, x) => {
            const isSelected = value === x.id;

            const title = titleFormatter ? titleFormatter(x) : '';

            reduction.push((
                <Button
                    key={x.id}
                    bsStyle={isSelected ? selectedBsStyle : bsStyle}
                    disabled={disabled || readOnly}
                    className={classnames({ active: isSelected })}
                    tabIndex={tabIndex}
                    onClick={() => onChange(x)}
                    title={title}
                >
                    {formatter(x)}
                </Button>
            ));

            return reduction;
        }, []);

        const btnGroupClass = classnames({
            'btn-group-vertical': vertical,
            'btn-group': !vertical,
        }, 'list-button-group');

        return <div className={btnGroupClass} role="group">{buttons}</div>;
};
