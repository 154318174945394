import type { ReactElement } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import { isValidDate } from '../../utils/date';
import { EcogPoint } from '../../EcogAxis';
import * as StralConstants from './constants';
import { useGetStralbehandlingSubTable } from './useGetStralbehandlingSubTable';

export const StralEcogPoints = () => {
    const subTable = useGetStralbehandlingSubTable();

    const points = subTable.getRows().toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('Stral_startdatum');
        const ecog = row.getRegvarValue('Stral_ecog') as ListItem | null;
        if (!isValidDate(datum) || !ecog) {
            return red;
        }

        red.push((
            <EcogPoint
                key={row.getId()}
                date={datum!}
                rowId={row.getId()}
                ecog={ecog}
                moduleName={StralConstants.ModuleName}
                gridId={StralConstants.GridId}
                displayName={StralConstants.DisplayName}
            />
        ));

        return red;
    }, []);

    return (
        <>{points}</>
    );
};
