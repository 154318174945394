import type { RowModel } from '@cancercentrum/rcc-react';
import { GridActions, useDataActions } from '@cancercentrum/rcc-react';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import last from 'lodash/last';
import { useAtomValue, useSetAtom } from 'jotai';
import type { ListItem } from '@cancercentrum/inca';
import useDataSet from '../../hooks/useDataSet';
import { wrapInDiv } from '../../utils/tooltipUtils';
import { selectedTabAtom } from '../../atoms';
import { GridId, ModuleName } from './constants';
import type { BiverkanTooltipRenderer, BivExtItemErrand, BivExtItemRR, CTCAETerm } from './types';
import { useGetBiverkningSubTable } from './useGetBiverkningSubTable';
import { ctcaeAtom } from './useCtcaeLoader';

interface SubstansVd {
    type: 'vd',
    substanceId: number;
}

interface SubstansName {
    type: 'name',
    substanceName: string;
}

export const getBiverkanText = (row: RowModel, terms: CTCAETerm[]): string => {
    const termId: number | null = row.getRegvarValue('Bv_ctcae_term_vd');
    const gradeId: number | null = row.getRegvarValue('Bv_ctcae_grade_vd');
    const biverkan = terms.find(x => x.id === termId);
    const grade = biverkan?.subTables.Grade.find(x => x.id === gradeId);
    let text = row.getRegvarValue('Bv_biverkan_alt') || biverkan?.data.term_text || '[uppgift saknas]';
    const gradeText = grade?.data.grade_text || '';

    const biverkanSpec = row.getRegvarValue('Bv_biverkanSpec');
    if (biverkanSpec) {
        text = `${text} (${biverkanSpec})`;
    }

    return gradeText ? `${text} - ${gradeText}` : text;
};

export const renderBiverkanTooltip: BiverkanTooltipRenderer = ({ row, ctcae, lakemedelPromise }) => {
    const terms = ctcae.reduce<CTCAETerm[]>((r, area) => [...r, ...area.subTables.Term], []);
    const biv = row.getSubTableRows('Biverkan')
        .filter(x => x!.getRegvarValue('Bv_ctcae_term_vd') || x!.getRegvarValue('Bv_biverkan_alt'))
        .map(x => getBiverkanText(x!, terms))
        .sortBy(x => x)
        .map(wrapInDiv);

    const substanceIds = row
        .getSubTableRows('BiverkanSubstans')
        .filter(x => x.getRegvarValue('BivSub_substans_vd'))
        .map(x => x.getRegvarValue('BivSub_substans_vd') as number)
        .toArray();
    const substanceTexts = row
        .getSubTableRows('BiverkanSubstans')
        .filter(x => x.getRegvarValue('BivSub_substans_text'))
        .map(x => x.getRegvarValue('BivSub_substans_text') as string)
        .toArray();
    const suspects = [
        ...substanceIds.map(x => lakemedelPromise.result?.substanserById[x]?.data?.sub_subnamnrek),
        ...substanceTexts,
    ].filter(x => x).map(wrapInDiv);

    return (
        <div>
            {suspects.length > 0 && (
                <div className="mb-2">
                    {suspects}
                </div>
            )}

            {biv}
        </div>
    );
};

export const useBiverkanRegistrator = () => {
    const dispatch = useDispatch();
    const dataActions = useDataActions();
    const setActiveTab = useSetAtom(selectedTabAtom);
    const subTable = useGetBiverkningSubTable();

    return useCallback((date: string, insattningsuuid: string, substances: (SubstansVd | SubstansName)[], regimId?: number): void => {
        let row = subTable.createRow()
            .setRegvarValue('Biv_datum', date)
            .setRegvarValue('Biv_regim_vd', regimId ?? null)
            .setRegvarValue('Biv_uuid', crypto.randomUUID())
            .setRegvarValue('Biv_orsak', (x: ListItem) => x.identifier === 'lakemedel')
            .setRegvarValue('Biv_lkm_insattningsuuid', insattningsuuid);

        row = row.addSubTableRow(row.getSubTable('Biverkan').createRow());

        row = substances.reduce((r, sub) => {
            let res = r.getSubTable('BiverkanSubstans').createRow();

            if (sub.type === 'vd') {
                res = res.setRegvarValue('BivSub_substans_vd', sub.substanceId);
            } else if (sub.type === 'name') {
                res = res.setRegvarValue('BivSub_substans_text', sub.substanceName);
            }

            return r.addSubTableRow(res);
        }, row);

        dataActions.updateRow(r => r.addSubTableRow(row), {
            path: [],
        });
        setActiveTab(ModuleName);
        dispatch(GridActions.setSelectedItemId(GridId, row.getId()));

        // Önskemål enligt IPO-804
        window.setTimeout(() => {
            window.top!.scrollTo(0, 0);
        }, 0);
    }, [dispatch, setActiveTab, dataActions, subTable]);
};

export interface RegisteredBiverkan {
    id: any;
    date: string;
    ctcae: string;
    substanceId: number;
}

export const useRegisteredBiverkan = (substanceIds: number[]): RegisteredBiverkan[] => {
    const dataset = useDataSet();
    const ctcae = useAtomValue(ctcaeAtom);

    const allTerms = useMemo(() => {
        return ctcae.reduce<CTCAETerm[]>((red, area) => [...red, ...area.subTables.Term], []);
    }, [ctcae]);

    const useGetBiverkningSubTable1 = useGetBiverkningSubTable();

    if (!dataset.Biverkningsrapportering) {
        return [];
    }

    const res = useGetBiverkningSubTable1.getRows().toArray().flatMap(bivRow => {
        const bivText = bivRow.getSubTableRows('Biverkan').map(b2 => getBiverkanText(b2!, allTerms)).join(', ');
        return bivRow.getSubTableRows('BiverkanSubstans')
            .filter(sub => substanceIds.includes(sub.getRegvarValue('BivSub_substans_vd') as number))
            .toArray()
            .map(sub => {
                return {
                    id: sub.getId(),
                    date: bivRow.getRegvarValue('Biv_datum')!,
                    ctcae: bivText,
                    substanceId: sub.getRegvarValue('BivSub_substans_vd') as number,
                };
            });
    });

    return orderBy(res, [x => x.date]);
};

/**
 * Hämtar en kompakt textsträng som representerar status för utökad biverkningsrapport. Används i grid och tooltip.
 */
export const getExtStatusText = (errands: BivExtItemErrand[], rr?: BivExtItemRR): string | undefined => {
    const lastVersion = rr ? last(rr.subTables.SafetyReport) : undefined;

    if (!rr && errands.length) {
        return 'Utökad rapport påbörjad';
    }

    if (rr && errands.length) {
        return 'Ny version av utökad rapport påbörjad';
    }

    if (lastVersion && !errands.length) {
        if (lastVersion.data.send_status_Värde === 'misslyckad_overforing') {
            return 'Misslyckad överföring av utökad rapport';
        }

        return 'Utökad rapport skickad';
    }

    return undefined;
};
