import type { ReactElement } from 'react';
import type { Point } from 'highcharts';
import type { ChartInfo, SurveyAnswer, Utskicksprojekt } from '../types';
import { LegacyTooltip } from '../../../components/Chart/Tooltip';

export type TooltipContentRenderer = (answer: SurveyAnswer, utskicksprojekt: Utskicksprojekt) => ReactElement;
export type LabelRenderer = (answer: SurveyAnswer, utskicksprojekt: Utskicksprojekt) => string;
export type HeaderTextRenderer = (answer: SurveyAnswer, utskicksprojekt: Utskicksprojekt) => string;

export const getChartPointInfo = (renderTooltipContent?: TooltipContentRenderer, labelRenderer?: LabelRenderer, headerTextRenderer?: HeaderTextRenderer) => {
    // eslint-disable-next-line react/display-name
    return (answer: SurveyAnswer, utskicksprojekt: Utskicksprojekt, chartInfo: ChartInfo) => {
        const headerText = headerTextRenderer?.(answer, utskicksprojekt) ?? utskicksprojekt.ipoName ?? utskicksprojekt.name;
        const tooltipContent = renderTooltipContent?.(answer, utskicksprojekt);
        return {
            labelText: labelRenderer?.(answer, utskicksprojekt), // Lite oklart, men behövs för att det ska synas i e-tjanster
            label: labelRenderer?.(answer, utskicksprojekt),
            color: '#D9D6D3',
            tooltip: (
                <LegacyTooltip headerText={headerText}>
                    <div>Inskickat {answer.endDate}</div>
                    {tooltipContent}
                    {!chartInfo.disablePie && <div>För mer info: shift-klicka på punkten i grafen.</div>}
                </LegacyTooltip>
            ),
            patientPortalTooltip: (
                <LegacyTooltip headerText={headerText}>
                    <div>Inskickat {answer.endDate}</div>
                    {tooltipContent}
                    {!chartInfo.disablePie && <div>För mer info: klicka på punkten i grafen.</div>}
                </LegacyTooltip>
            ),
        };
    };
};

export const getPointPosition = (point: Point) => {
    const xAxis = point.series.xAxis;
    const yAxis = point.series.yAxis;
    const chart = point.series.xAxis.chart;
    return {
        x: xAxis.toPixels(point.x, false) - chart.plotLeft,
        y: yAxis.toPixels(point.y ?? 0, false) - chart.plotTop,
    };
};
