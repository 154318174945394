import { DropdownList, type HookDataActions } from '@cancercentrum/rcc-react';
import Question from '../../components/IPOQuestion';
import type { Biverkningsrapportering, BiverkningsrapporteringRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useGetStralbehandlingSubTable } from '../stralbehandling';
import useDataSet from '../../hooks/useDataSet.ts';

export const StralbehandlingSelector = (props: {
    row: BiverkningsrapporteringRowModel;
    dataActions: HookDataActions<Biverkningsrapportering>;
    disabled: boolean;
}): JSX.Element => {
    const {
        row,
    } = props;
    const stralSubTable = useGetStralbehandlingSubTable();
    const dataset = useDataSet();
    const dateField = dataset.Stralbehandling?.Stral_startdatum ? 'Stral_startdatum' : 'Stral_slutdatum';
    const dateLabel = dataset.Stralbehandling?.Stral_startdatum ? 'Startdatum' : 'Slutdatum';
    const options = stralSubTable.getRows().map(x => {
        return {
            id: x.getRegvarValue('Stral_uuid') || crypto.randomUUID(),
            text: `${dateLabel}: ${x.getRegvarValue(dateField) || '-'}`,
        };
    }).toArray();
    const stralRow = stralSubTable
        .getRows()
        .toArray()
        .find(x => x.getRegvarValue('Stral_uuid') === row.getRegvarValue('Biv_stral_uuid'));

    // Extravalideringen gäller bara diagnoser som anger startdatum.
    const startValidation = dataset.Stralbehandling?.Stral_startdatum
        ? {
            invalid: (row.getRegvarValue('Biv_datum') || '9999-99-99') < (stralRow?.getRegvarValue('Stral_startdatum') || ''),
            invalidMessage: 'Vald strålbehandling påbörjades efter misstänkt biverkning.',
        }
        : {};

    return (
        <div>
            <Question
                regvar={row.getRegvar('Biv_stral_uuid')}
                labelClassName="col-sm-3"
                wrapperClassName="col-sm-9"
                componentClass={DropdownList}
                options={options}
                {...startValidation}
            />
        </div>
    );
};
