import invariant from 'invariant';
import type { CurrentUser } from '@cancercentrum/inca';
import { apiRequest } from '@cancercentrum/inca';
import escapeRegExp from 'lodash/escapeRegExp';

interface OverviewParams {
    EtjansterUrl: string;
}

export const replaceMustacheParams = (content: string, params: Record<any, any>): string => {
    return Object.keys(params).reduce((reduction, key) => {
        const r = new RegExp(`{{${escapeRegExp(key)}}}`, 'g');

        return reduction.replace(r, params[key]);
    }, content || '');
};

const send117Message = async (
    sourceSystemId: string | undefined,
    patientId: number | string,
    title: string,
    message: string,
    threadId: string,
) => {
    if (!sourceSystemId) {
        throw new Error('sourceSystemId saknas. Det kan bero på att diagnosen inte har nödvändig konfiguration uppsatt för att skicka meddelanden till 1177.');
    }

    const currentUser = await apiRequest<CurrentUser>('/Users/Current');
    const { position } = currentUser;
    const pos = position.fullNameWithCode.split(/\(\d*\)\s?-?/g).slice(1).map(x => x.trim()).filter(x => x).join(' - ');

    return apiRequest(`/Patients/${patientId}/PatientPortal/2/AddMessage`, {
        method: 'POST',
        json: {
            notify: true,
            approve: false,
            threadId: threadId,
            threadTitle: title,
            message: {
                title: title,
                text: message,
                organisationUnit: {
                    unitId: {
                        root: '1.2.752.129.2.1.4.1',
                        extension: position.hsaId,
                    },
                    unitName: pos,
                },
            },
            accountableOrganisation: {
                root: '1.2.752.129.2.1.4.1',
                extension: sourceSystemId,
            },
        },
    });
};

export const send1177OverviewMessage = async (
    sourceSystemId: string | undefined,
    configId: number,
    diagnos: string,
    patientId: number | string,
    message: string,
    params: OverviewParams,
) => {
    invariant(typeof configId === 'number', 'configId is missing.');

    return send117Message(
        sourceSystemId,
        patientId,
        `${diagnos}: uppdaterad Individuell patientöversikt (IPÖ)`,
        replaceMustacheParams(message, params),
        `ipo-${configId}`,
    );
};
