import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import type { TextRepresentation } from '../../components/TextRepresentation';
import { buildString, formatIf } from '../../components/TextRepresentation';
import useDataSet from '../../hooks/useDataSet';
import { isValidDate } from '../../utils/date';
import type { HierarchifiedSubstansItem } from '../lakemedel';
import { lakemedelVdsAtom } from '../lakemedel';
import { useGetBiverkningSubTable } from './useGetBiverkningSubTable';
import { biverkningarExtAtom } from './useExtBiverkningLoader';
import { getExtStatusText } from './utils';
import { ctcaeAtom } from './useCtcaeLoader';

type FormatHeaderCallback = (data: {
    date: string;
    reason: string;
    substanceTexts: string[],
    extBiverkanStatus?: string;
}) => string;

const EMPTY_SUB_BY_ID = {} as Record<number, HierarchifiedSubstansItem>;

const defaultFormatHeader: FormatHeaderCallback = (data) => {
    return buildString('. ', [
        buildString(', ', [
            data.date,
            data.reason,
            ...data.substanceTexts,
        ]),
        data.extBiverkanStatus,
    ]);
};

export const useBiverkningTextRepresentation = (options: {
    formatHeader?: FormatHeaderCallback;
}): TextRepresentation[] => {
    const {
        formatHeader = defaultFormatHeader,
    } = options;
    const dataset = useDataSet();
    const lakemedelPromise = useAtomValue(lakemedelVdsAtom);
    const substanserById = lakemedelPromise.result?.substanserById || EMPTY_SUB_BY_ID;
    const subTable = useGetBiverkningSubTable();
    const vdBiverkan = useAtomValue(ctcaeAtom);
    const termsById = useMemo(() => {
        return keyBy(vdBiverkan.flatMap(x => x.subTables.Term), x => x.id);
    }, [vdBiverkan]);
    const { result: extBiv } = useAtomValue(biverkningarExtAtom);

    return subTable.getRows().toArray().reduce<TextRepresentation[]>((red, bivRow) => {
        const dat = bivRow.getRegvarValue('Biv_datum') as string | null;
        if (!isValidDate(dat)) {
            return red;
        }

        const reasonSpec = bivRow.getRegvarValue('Biv_orsak_annan');
        const reason = reasonSpec || bivRow.getRegvarValue('Biv_orsak')?.text || 'orsak saknas';

        const uuid = bivRow.getRegvarValue('Biv_uuid');
        const extErrands = (extBiv?.errands || []).filter(x => x.data.r_ipo_uuid === uuid) || [];
        const extRr = (extBiv?.registerRecords || []).find(x => x.data.r_ipo_uuid === uuid);
        const extBiverkanStatus = getExtStatusText(extErrands, extRr);

        const substanceTexts = bivRow.getSubTableRows('BiverkanSubstans').toArray().map(x => {
            const subId = x.getRegvarValue('BivSub_substans_vd') as number;

            return substanserById[subId]?.data.sub_subnamnrek || `Substans-id ${subId}`;
        });

        const biverkanTexts = bivRow.getSubTableRows('Biverkan').toArray().reduce<string[]>((bred, biverkan) => {
            const termId = biverkan.getRegvarValue('Bv_ctcae_term_vd') as number | null;
            const gradeId = biverkan.getRegvarValue('Bv_ctcae_grade_vd') as number | null;

            const str = buildString(', ', [
                formatIf(termId, id => {
                    const term = termsById[id];
                    const other = biverkan.getRegvarValue('Bv_biverkanSpec') as string | null;
                    const termText = term.data.term_text || 'Term-id ${id}';

                    return other ? `${termText} (${other})` : termText;
                }),
                formatIf(gradeId, id => {
                    const term = termsById[termId!];

                    if (!term) {
                        return 'Grade-id ${id}';
                    }

                    return term.subTables.Grade.find(x => x.id === id)?.data.grade_text || 'Grade-id ${id}';
                }),
                dataset.Biverkan!.Bv_biverkan_alt && biverkan.getRegvarValue('Bv_biverkan_alt'),
            ]);

            if (str) {
                bred.push(str);
            }

            return bred;
        }, []);

        if (substanceTexts.length && biverkanTexts.length) {
            red.push({
                sortValue: dat!,
                section: 'biverkning',
                header: formatHeader({
                    date: dat!,
                    reason: reason,
                    substanceTexts,
                    extBiverkanStatus,
                }),
                rows: biverkanTexts,
            });
        }

        return red;
    }, []);
};
