import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';
import type { ReactElement } from 'react';
import { useRef } from 'react';
import { Button, Modal } from '@cancercentrum/rcc-bootstrap';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import useToggleBodyClass from '../../../useToggleBodyClass';
import { getCurrentIncaDate } from '../../utils/date';
import { useIPO } from '../../hooks/useIPO';
import Header from './Header';

type TextSummaryModalProps = {
    onClose: () => void;
    contentClass: (props: any) => ReactElement | null;
    contentClassProps?: any;
}

const TextRepresentationModal = (props: TextSummaryModalProps): ReactElement => {
    const { onClose, contentClass: Content, contentClassProps } = props;
    const ipo = useIPO();
    const { patient } = ipo;
    const containerRef = useRef<HTMLDivElement>(null!);
    const copyToClipboard = () => {
        const range = document.createRange();
        range.selectNodeContents(containerRef.current);

        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);

        document.execCommand('copy');
    };

    useToggleBodyClass('print-mode-modal');

    return (
        <Modal onHide={onClose} show={true} className="printable-modal" bsSize="xl">
            <Modal.Body>
                <div className="text print-visible" ref={containerRef}>
                    <Header text={`${patient.ssn} - ${patient.lastName}, ${patient.firstName}`} className="mt-0" />
                    <div className="mt-2 mb-4"><b><em>Text genererad {getCurrentIncaDate()}</em></b></div>
                    <Content {...contentClassProps} />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose} bsStyle="link">Stäng</Button>
                <Button bsStyle="primary" onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} className="me-2" />Kopiera text</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TextRepresentationModal;
