import Color from 'color';
import type { PatternObject } from 'highcharts';

export const getColor = (color: string, isOngoing: boolean) => {
    return !isOngoing ? Color(color).desaturate(0.1).alpha(0.5).string() : color;
};

export const getPattern = (color: string): PatternObject => {
    const lighterColor = Color(color).saturate(0.5).fade(0.5).string();
    return {
        pattern: {
            aspectRatio: 1,
            backgroundColor: 'white',
            image: '',
            opacity: 1,
            patternTransform: '',
            color: color,
            path: {
                fill: lighterColor,
                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                strokeWidth: 5,
            },
            width: 10,
            height: 10,
        },
    };
};
