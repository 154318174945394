import { useDataActions } from '@cancercentrum/rcc-react';
import { addDays, parseISO } from 'date-fns';
import type { ReactElement } from 'react';
import Question from '../../../../components/IPOQuestion';
import { formatIncaDate } from '../../../../utils/date';
import type { LäkemedelRowModel } from '../../../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';

const Kurlangd = (props: {
    row: LäkemedelRowModel;
    hideSistaKurensForstaDag?: boolean;
}): ReactElement => {
    const { row, hideSistaKurensForstaDag } = props;
    const dataActions = useDataActions();

    const setStoppdatum = (cykellangd: number | null, sistaCykelStart: string | null) => {
        if (cykellangd && sistaCykelStart && !hideSistaKurensForstaDag) {
            const res = formatIncaDate(addDays(parseISO(sistaCykelStart), cykellangd - 1));

            dataActions.updateRow(r => r.setRegvarValue('Lkm_stoppdatum', res));
        }
    };

    return (
        <>
            <Question
                regvar={row.getRegvar('Lkm_kurlangd')}
                labelClassName="col-md-3"
                wrapperClassName="col-md-9"
                max={181}
                onValueChange={x => {
                    setStoppdatum(x, row.getRegvarValue('Lkm_sistaKurensForstaDag'));
                }}
            />
            {!hideSistaKurensForstaDag && (
                <Question
                    regvar={row.getRegvar('Lkm_sistaKurensForstaDag')}
                    labelClassName="col-md-3"
                    wrapperClassName="col-md-9"
                    beforeToday={false}
                    onValueChange={x => {
                        setStoppdatum(row.getRegvarValue('Lkm_kurlangd') as number | null, x);
                    }}
                />
            )}
        </>
    );
};

export default Kurlangd;
