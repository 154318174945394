import { useContext, useMemo, useState } from 'react';
import type { RowModel } from '@cancercentrum/rcc-react';
import { DropdownList, HighlightText } from '@cancercentrum/rcc-react';
import { Button } from '@cancercentrum/rcc-bootstrap';
import uniqueId from 'lodash/uniqueId';
import Question from '../../components/IPOQuestion';
import Condition from '../../components/IPOCondition';
import { ReadOnlyContext } from '../../components/ReadOnlyContext';
import { useIPO } from '../../hooks/useIPO';
import type { StudieVDItem } from './types';

export const formatStudieElement = (studie: StudieVDItem, searchText: string) => {
    return (
        <div className="d-flex flex-column text-wrap studierad">
            <div className="d-flex gap-3">
                <div className="fw-bold"><HighlightText search={searchText} text={studie.data.namn} /></div>
                <div className="text-muted"><small><HighlightText search={searchText} text={studie.diagnoser.join(', ')} /></small></div>
            </div>
            <div className="my-1"><HighlightText search={searchText} text={studie.data.beskrivning} /></div>
        </div>
    );
};

export const formatStudieText = (studie: any) => `${studie.data.namn} - ${studie.diagnoser.join(', ')} ${studie.data.beskrivning}`;

export function SelectStudie(props: {
    row: RowModel;
    studier: StudieVDItem[];
    onChange: (id: number, studie: StudieVDItem) => void;
}) {
    const { row, studier, onChange } = props;
    const ipo = useIPO();
    const isReadOnly = useContext(ReadOnlyContext);
    const checkboxId = useMemo(() => uniqueId('CB_'), []);
    const [showAll, setShowAll] = useState(false);

    const [isCustStudie, setIsCustStudie] = useState(() => {
        return !row.getRegvarValue('Stud_studie_vd') && !!row.getRegvarValue('Stud_studienamn');
    });

    const dias = ipo.diagnosis.layout.settings.studiesDiagnosisFilter || [];
    const selectableStudier = (showAll || !dias.length)
        ? studier
        : studier.filter(x => x.diagnoser.some(d => dias.includes(d)) || x.id === row.getRegvarValue('Stud_studie_vd'));

    const toggleCustomStudie = () => {
        setIsCustStudie(x => !x);
    };

    return (
        <>
            <Condition if={!isCustStudie}>
                <div>
                    {!!dias.length && !isReadOnly && (
                        <div className="row mb-1">
                            <div className="offset-md-3 col-md-9">
                                <input id={checkboxId} type="checkbox" className="form-check-input me-2" checked={showAll} onChange={e => setShowAll(e.target.checked)} />
                                <label htmlFor={checkboxId} className="form-check-label">Visa alla studier</label>
                            </div>
                        </div>
                    )}
                    <Question
                        regvar={row.getRegvar('Stud_studie_vd')}
                        options={selectableStudier}
                        className="studie-question"
                        labelClassName="col-md-3"
                        wrapperClassName="col-md-9"
                        componentClass={DropdownList}
                        required={true}
                        formatter={formatStudieElement}
                        formatterText={formatStudieText}
                        onValueChange={onChange}
                    />

                    {!isReadOnly && !row.getRegvarValue('Stud_studie_vd') && (
                        <Button bsStyle="link" className="offset-md-3 mb-3" onClick={toggleCustomStudie}>
                            Klicka här för att lägga till en studie som saknas i listan ovan.
                        </Button>
                    )}
                </div>
            </Condition>

            <Condition if={isCustStudie}>
                <div>
                    <Question
                        regvar={row.getRegvar('Stud_studienamn')}
                        labelClassName="col-md-3"
                        wrapperClassName="col-md-9"
                        required={true}
                    />

                    {!isReadOnly && (
                        <Button bsStyle="link" className="offset-md-3 mb-3" onClick={toggleCustomStudie}>
                            Klicka här för att välja en befintlig studie.
                        </Button>
                    )}
                </div>
            </Condition>
        </>
    );
}
