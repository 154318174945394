import { useContext } from 'react';
import invariant from 'invariant';
import type { ChartContext as ChartContextType } from '../types';
import ChartContext from './index';

export default (): ChartContextType => {
    const chartContext = useContext(ChartContext);
    invariant(chartContext, 'ChartContext cannot be null.');

    return chartContext;
};
