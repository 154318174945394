import type { ReactElement } from 'react';
import type { ListItem } from '@cancercentrum/inca';
import { isValidDate } from '../../utils/date';
import { EcogPoint } from '../../EcogAxis';
import * as KirurgiConstants from './constants';
import { useGetKirurgiSubTable } from './useGetKirurgiSubTable';

export const KirurgiEcogPoints = () => {
    const subTable = useGetKirurgiSubTable();

    const points = subTable.getRows().toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('Kir_datum');
        const ecog = row.getRegvarValue('kir_ecog') as ListItem | null;
        if (!isValidDate(datum) || !ecog) {
            return red;
        }

        red.push((
            <EcogPoint
                key={row.getId()}
                date={datum!}
                rowId={row.getId()}
                ecog={ecog}
                moduleName={KirurgiConstants.ModuleName}
                gridId={KirurgiConstants.GridId}
                displayName={KirurgiConstants.DisplayName}
            />
        ));

        return red;
    }, []);

    return (
        <>{points}</>
    );
};
