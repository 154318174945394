import { useMemo } from 'react';
import type { ReactElement, ReactNode } from 'react';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';

export const BasdataAxis = (props: {
    categoryName?: string;
    children: ReactNode;
}): ReactElement => {
    const { categoryName, children } = props;
    const categories = useMemo(() => {
        return [categoryName || 'Basdata'];
    }, [categoryName]);

    return (
        <CategoriesAxis categories={categories}>
            {children}
        </CategoriesAxis>
    );
};
