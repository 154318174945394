import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactElement, ReactNode } from 'react';
import { useContext } from 'react';
import { format, parseISO } from 'date-fns';
import { Button, OverlayTrigger, Popover } from '@cancercentrum/rcc-bootstrap';
import type { RowModelTableMetadata, SubTableModel } from '@cancercentrum/rcc-react';
import classnames from 'classnames';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import type { HistoryResult } from '../types';
import RRHistoryContext from './RRHistoryContext';

export interface MultiSelectListHistoryProps<SubTableMetadata extends RowModelTableMetadata> {
    subTable: SubTableModel<SubTableMetadata>;
    shortname: keyof SubTableMetadata['regvars'];
    label: ReactNode;
    parentId: unknown;
    className?: string;
}

export const MultiSelectListHistory = <SubTableMetadata extends RowModelTableMetadata>(props: MultiSelectListHistoryProps<SubTableMetadata>): ReactElement | null => {
    const { parentId, subTable, shortname, label } = props;
    const tableMetadata = subTable.$rcc.tableMetadata;
    const rvMetadata = tableMetadata.regvars?.[shortname as string];
    let res = null;
    const ctxRRHistory = useContext<HistoryResult | null>(RRHistoryContext);

    if (rvMetadata.dataType !== 'list') {
        throw new Error('MultiSelectListHistory expects shortname to be a list.');
    }

    const tableName = subTable.$rcc.tableName;
    const tableHistory = ctxRRHistory && ctxRRHistory[tableName];

    if (tableHistory) {
        const subtableItemIds = uniq(tableHistory.filter(x => get(x, 'changes.connection.value') === parentId).map(x => x.id));
        const subTableHistory = reverse(tableHistory.filter(x => includes(subtableItemIds, x.id)));
        const currentRowValues = subtableItemIds.reduce<Record<number, string | null>>((obj, id) => ({ ...obj, [id]: null }), {});
        const historyEntries: {
            id: number;
            versionId: number;
            date: string;
            savedBy: string;
            value: string | null,
            obliterated: boolean;
        }[] = [];

        for (let i = 0; i < subTableHistory.length; i++) {
            const e = subTableHistory[i];

            const regvarChange = e?.changes?.regvars?.[shortname as string];
            if (regvarChange) {
                const match = rvMetadata.listValues.find(x => x.id === regvarChange.value);
                currentRowValues[e.id] = match ? match.text : null;
            }

            const entry = {
                id: e.id,
                versionId: e.versionId,
                date: format(parseISO(e.date), 'yyyy-MM-dd HH:mm'),
                savedBy: e.savedBy,
                value: currentRowValues[e.id],
                obliterated: !!e.changes.obliterated,
            };

            historyEntries.push(entry);
        }

        if (subTableHistory.length) {
            res = (
                <OverlayTrigger
                    rootClose={true}
                    trigger="click"
                    placement="bottom"
                    popperConfig={{ strategy: 'fixed' }}
                    overlay={(
                        <Popover id={`popover_history_${String(shortname)}`} className="mw-100">
                            <h3>Ändringshistorik<br /><small>{label}</small></h3>
                            <table className="table table-responsive table-condensed">
                                <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Användare</th>
                                    <th>Värde</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {reverse(historyEntries).map(h => {
                                    return (
                                        <tr key={`${h.id}_${h.versionId}`}>
                                            <td>{h.date}</td>
                                            <td>{h.savedBy}</td>
                                            <td>{h.value}</td>
                                            <td>{h.obliterated ? 'Borttaget' : 'Valt'}</td>
                                        </tr>
                                    );
                                })}

                                </tbody>
                            </table>
                        </Popover>
                    )}
                >
                    <Button className={classnames('ms-2', props.className)} bsStyle="link" title="Visa historik"><FontAwesomeIcon icon={faHistory} /></Button>
                </OverlayTrigger>
            );
        }
    }

    return res;
};
