import type { ReactElement } from 'react';
import { useContext } from 'react';
import type { HookDataActions } from '@cancercentrum/rcc-react';
import { IncaRowContext, useDataActions, Validation, ValidationGroup } from '@cancercentrum/rcc-react';
import classnames from 'classnames';
import { AddRowButton } from '../../components/AddRowButton';
import { ReadOnlyContext } from '../../components/ReadOnlyContext';
import type { Biverkan, BiverkanRowModel, BiverkanSubTable } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';

type RenderSubTableRowCallback = (data: {
    row: BiverkanRowModel;
    dataActions: HookDataActions<Biverkan>;
    index: number;
}) => ReactElement;

type BiverkanRegistrationProps = {
    subTable: BiverkanSubTable;
    renderRow: RenderSubTableRowCallback;
}

export const BiverkningRegistration = (props: BiverkanRegistrationProps): ReactElement => {
    const { subTable, renderRow } = props;
    const dataActions = useDataActions();
    const isReadOnly = useContext(ReadOnlyContext);
    const onClick = () => {
        const newRow = subTable.createRow();

        dataActions.updateRow(x => x.addSubTableRow(newRow));
    };

    return (
        <ValidationGroup name="subbiv">
            <div className="mt-4">
                {subTable.getRows().map((x, i) => {
                    return (
                        <div key={x.getId()} className={classnames({ 'mt-3': i > 0 })}>
                            <IncaRowContext rowId={x.getId()} tableName={x.$rcc.tableName}>
                                {data => renderRow({ row: x, dataActions: data.dataActions, index: i })}
                            </IncaRowContext>
                        </div>
                    );
                })}

                {!isReadOnly && (
                    <div>
                        <AddRowButton className="mt-3" onClick={onClick} content={subTable.getRows().isEmpty() ? 'Lägg till misstänkt biverkning' : 'Lägg till ytterligare misstänkt biverkning'} />
                    </div>
                )}
            </div>
            <Validation isValid={!subTable.getRows().isEmpty()} errorProps={{ name: 'Biverkningar', message: 'Minst en biverkning måste registreras.' }}>Minst en biverkning måste registreras.</Validation>
        </ValidationGroup>
    );
};
