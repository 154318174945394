import isEqualWith from 'lodash/isEqualWith';

const customizer = (objValue: unknown, othValue: unknown) => {
    if (typeof objValue === 'function' && typeof othValue === 'function') {
        return true;
    }

    return undefined;
};

export const isEqualExclFunctions = (objValue: any, othValue: any) => isEqualWith(objValue, othValue, customizer);

export const toast = (chart: any, text: string) => {
    if (chart.toast) {
        return;
    }

    chart.toast = chart.renderer.label(text, 10, 10)
        .attr({
            fill: '#f35',
            padding: 10,
            r: 5,
            zIndex: 8,
        })
        .css({
            color: '#FFFFFF',
        })
        .add();

    setTimeout(() => {
        chart.toast.fadeOut();
    }, 4000);

    setTimeout(() => {
        chart.toast = chart.toast.destroy();
    }, 4500);
};

export const time = (label: string, fn: () => unknown): unknown => {
    // console.time(label);
    const res = fn();
    // console.timeEnd(label);
    return res;
};
