import { valueAndTextFormatter } from '../../../utils/formatters';
import type { DataSet } from '../../types';

const dataset: DataSet = {
    Oversikt: {
        komplettLkmAdj_hoger: {},
        komplettLkmAdj_vanster: {},
        komplettLkmNeoadj_hoger: {},
        komplettLkmNeoadj_vanster: {},
        komplettLkmPalliativ: {
            props: {
                labelHelp: 'Ska kryssas i hos patienter med palliativ behandling där komplett registrering av läkemedelsbehandling från 1:a linjen finns.',
            },
        },
        selectedVd: {},
    },
    Basdata: {
        Basdata_brost_vd: {},
    },
    Kontakt: {
        K_vardgivare: {},
        K_besokstyp: {
            identifiers: ['1', '2', '3'],
        },
        K_yrkesroll: {
            identifiers: ['1', '2', '3'],
        },
        K_ecog: {
            identifiers: ['0', '1', '2', '3', '4'],
        },
        K_sammantagenBedomning: {
            props: {
                label: 'Sammanvägd bedömning',
                formatter: valueAndTextFormatter,
            },
            identifiers: ['ö', 'rf', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
    },
    KontaktPAL: {
        KP_patientansvarigLakare: {},
    },
    Lokalstatus: {
        L_tumorlokalisation: {},
        L_kliniskBedomning: {
            identifiers: ['p1', 'm', 'rf', 'cr', 'pr', 'sd', 'pd', 'of'],
            props: {
                formatter: valueAndTextFormatter,
            },
        },
    },
    Studie: {},
    Stralbehandling: {
        Stral_startdatum: {},
        Stral_indikation: {
            identifiers: ['1', '2'],
        },
        Stral_dosPerFraktionD2: {},
        Stral_slutdosD2: {},
        Stral_sida: {
            identifiers: ['1', '2'],
        },
        Stral_slutdosExklBoostD2: {},
        Stral_boostNy: {
            identifiers: ['nej', 'sekventiell', 'integrerad'],
        },
        Stral_totdosTargetInklBoostD2: {},
        Stral_oligometastatiskSjukdom: {},
        Stral_behandlingstyp: {
            identifiers: ['1', '2', '3', '4', 'brachyterapi', '5'],
        },
    },
    StralTarget: {
        ST_target: {
            identifiers: ['pbi', 'brost_brostkorgsvagg', 'axill', 'supra_infraklavikulart', 'parasternalt', 'hjarna', '4', 'lunga_lungsack', '5', 'buk_exkl_lever', 'lymfkortlar', 'ovrig'],
        },
        ST_target_spec: {},
    },
    Kirurgi: {
        Kir_indikation: {
            identifiers: ['primar_brost', 'lokoregionalt_recidiv', '2'],
        },
        Kir_sida: {
            identifiers: ['1', '2'],
        },
        Kir_axillutrymning: {
            identifiers: ['nej', 'ja'],
        },
        Kir_sentinelNode: {
            identifiers: ['nej', 'ja'],
        },
        Kir_brost_brostingrepp: {
            identifiers: ['1', '2', '3'],
        },
        Kir_brostingrepp_efterPreoperativBehandling: {},
        Kir_axillutrymning_efterPreoperativBehandling: {},
        Kir_efterPreoperativBehandling: {},
        Kir_forePreoperativBehandling: {},
        Kir_oligometastatiskSjukdom: {},
        Kir_fritext: {},
    },
    KirurgiLokal: {
        Kirlok_target: {
            identifiers: ['hjarna', '4', '6', '5', '7', 'lymfkortlar', '1', 'lokoreg_lymf', 'ovrig'],
        },
    },
    Labbprov: {
        Labb_ca153_0: {},
    },
    Patologi: {
        Pat_sida: {
            identifiers: ['1', '2'],
        },
        Pat_primartumor_typ: {
            identifiers: ['3', '1'],
        },
        Pat_storlekStorstaFocus: {},
        Pat_patologiskt_tstadium: {
            identifiers: ['t0', 'tis', 't1a', 't1b', 't1c', 't2', 't3', 't4'],
        },
        Pat_kliniskt_tstadium: {
            identifiers: ['t1a', 't1b', 't1c', 't2', 't3', 't4'],
        },
        Pat_invasivitet: {
            identifiers: ['invasiv', 'in_situ', 'ingen'],
        },
        Pat_annanCancerInSituSpec: {},
        Pat_genprofil: {
            identifiers: ['lag', 'intermediar', 'hog', 'ej_utfort'],
        },
        Pat_antalPosLymfkortlar: {},
        Pat_tumorstorlek: {},
        Pat_multifokal: {
            identifiers: ['nej', 'ja'],
        },
        Pat_kirurgityp: {
            identifiers: ['1', '2'],
        },
        Pat_patologiskt_nstatus: {
            identifiers: ['n0', 'nmi', 'nplus'],
        },
        Pat_inSitu_typ: {
            identifiers: ['dcis', 'dcis_lcis', 'annan_in_situ'],
        },
        Pat_verifieradeLymfkortelmetastaser: {
            identifiers: ['nej', 'ja'],
        },
        Pat_pCR: {
            identifiers: ['nej', 'ja'],
        },
        Pat_RCB: {
            identifiers: ['0', 'I', 'II', 'III'],
        },
        Pat_patologiskt_nstatus_mikro_antal: {},
        Pat_patologiskt_nstatus_makro_antal: {},
        Pat_patologiskt_nstatus_antal_uttagna: {},
        Pat_extent: {},
        Pat_kliniskt_nstatus: {
            identifiers: ['n0', 'nmi', 'nplus'],
        },
        Pat_kliniskt_nstatus_enligt_undersokning: {},
        Pat_kliniskt_nstatus_enligt_SN: {},
        Pat_kliniskt_nstatus_sentinel: {},
        Pat_axillutrymd: {
            identifiers: ['nej', 'ja'],
        },
        Pat_kommentar: {},
        Pat_operationsdatum: {},
        Pat_tumorrespons: {
            identifiers: ['pcr', 'pr', 'sd', 'pd'],
        },

        Pat_lokalisation_brost: {
            identifiers: ['1', '2', '3'],
        },
        Pat_provtagningstyp: {
            identifiers: ['1', '2', '3'],
        },
        Pat_lymfkortlar: {
            identifiers: ['n0', 'n1', 'n2', 'n3', 'nx'],
        },
        Pat_target: {
            identifiers: ['hjarna', '4', 'lunga_lungsack', '5', 'buk_exkl_lever', 'brost_brostkorgsvagg', 'lymfkortlar', 'ovrig'],
        },
    },
    Tumor: {
        Tum_lokalisation_primartumor_storlek: {},
        Tum_lokalisation_lokoregionaltRecidiv_brost: {
            identifiers: ['1', '2'],
        },
        Tum_lokalisation_metastas_brost: {
            identifiers: ['1', '2', '3', '4', '5', '6', '7'],
        },
        Tum_lokalisation_metastas_ovrigt: {},
        Tum_tumortyp_brost: {
            identifiers: ['1', '2', '3', '4', '5'],
        },
        Tum_storlekStorstaTumor: {},
        Tum_tumortypSpec: {},
        Tum_histologiskGrad_saknas: {},
        Tum_histologiskGrad: {
            identifiers: ['1', '2', '3'],
        },
        Tum_er_saknas: {},
        Tum_er: {},
        Tum_er_posneg: {
            identifiers: ['1', '2'],
        },
        Tum_pr_saknas: {},
        Tum_pr: {},
        Tum_pr_posneg: {
            identifiers: ['1', '2'],
        },
        Tum_her2_ihc: {
            identifiers: ['1', '2', '3', '4'],
        },
        Tum_her2_ish: {
            identifiers: ['1', '2'],
        },
        Tum_her2_posneg: {
            identifiers: ['1', '2'],
        },
        Tum_her2_saknas: {},
        Tum_ki67_saknas: {},
        Tum_ki67: {},
        Tum_ki67Prim: {
            identifiers: ['1', '2', '3'],
        },
        Tum_ki67_niva_saknas: {},
        Tum_tils: {},
        Tum_tils_saknas: {},
        Tum_pdl1_immunceller: {},
        Tum_pdl1_immunceller_saknas: {},
        Tum_pdl1_cps: {},
        Tum_pdl1_cps_saknas: {},
        Tum_ursprung: {
            identifiers: ['1', '2'],
        },
        Tum_DCIS: {
            identifiers: ['nej', 'ja'],
        },
    },
    Bilddiagnostik: {
        Bild_metod: {
            identifiers: ['7', '6', '3', '1', '5', '4', '2', '8'],
            props: {
                label: 'Modalitet',
            },
        },
        Bild_tumlok_hjarna: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_skelett: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_lunga_pleura: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_lever: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_buk: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_brost: {
            identifiers: ['p1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_lymfkortlar: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_ovrigt: {
            identifiers: ['m1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_sammanvagdRadiologiskBedomning: {
            identifiers: ['t1', 'm1', 'cr', 'pr', 'sd', 'pd', 'of'],
        },
        Bild_tumlok_ovrigt_spec: {},
        Bild_sida_vanster: {},
        Bild_sida_hoger: {},
    },
    Recidivrapportering: {
        R_inoperabelorsak: {
            identifiers: ['1', '2', '9'],
        },
        R_inoperabelorsak_spec: {},
        R_sida: {
            identifiers: ['1', '2'],
        },
        R_datum: {
            props: {
                label: 'Datum för lokoregionalt recidiv',
            },
        },
        R_denovo: {},
        R_samtidigtLokoregRecidiv: {},
        R_recidivtyp: {
            identifiers: ['1', '2'],
        },
    },
    RecidivrapporteringLokal: {
        RL_lokal: {
            identifiers: ['brost_brostkorgsvagg', 'axill', 'para_supra_infra', 'hjarna', '4', 'lunga_lungsack', '5', 'buk_exkl_lever', 'lymfkortlar', 'brost_brostkorgsvagg', 'ovrig'],
        },
        RL_spec: {},
    },
    Läkemedel: {
        Lkm_behandlingsintention: {
            identifiers: ['1', '2'],
        },
        Lkm_behandlingsintention_spec: {
            identifiers: ['1', '3'],
        },
        Lkm_studie_vd: {},
        Lkm_avblindatresultat: {
            identifiers: ['placebo', 'lakemedel', 'aktivt_studielakemedel'],
        },
    },
    Biverkningsrapportering: {
        Biv_datum: {},
        Biv_regim_vd: {},
    },
    BiverkanSubstans: {
        BivSub_substans_vd: {},
        BivSub_substans_text: {},
    },
    Biverkan: {
        Bv_biverkan_alt: {},
        Bv_ctcae_term_vd: {},
        Bv_ctcae_grade_vd: {},
        Bv_biverkanSpec: {},
    },
    Patientportal: {},
};

export default dataset;
