import type { ReactNode } from 'react';
import { memo, useEffect } from 'react';
import { AjaxErrorAlert, LoadingAlert, usePromiseHash } from '@cancercentrum/rcc-react';
import { Alert } from '@cancercentrum/rcc-bootstrap';
import { useSetAtom } from 'jotai';
import usePDL from '../../pdl/usePDL';
import { basdataAtom } from './atoms';

export const BasdataLoader = memo(function BasdataLoader(props: {
    getRootVd: () => Promise<unknown>;
    getExtraVds?: () => Record<string, Promise<unknown>>;
    children: ((data: any) => ReactNode) | ReactNode;
}): JSX.Element {
    const {
        getRootVd,
        getExtraVds,
        children,
    } = props;
    const pdlContext = usePDL();
    const setBasdataResult = useSetAtom(basdataAtom);
    const { isLoading, error, result } = usePromiseHash(() => ({
        rotdata: getRootVd(),
        ...getExtraVds?.(),
    }));

    useEffect(() => {
        setBasdataResult({
            isLoading,
            error,
            result,
        });
    }, [isLoading, error, result, setBasdataResult]);

    if (!pdlContext.samtycke) {
        return (
            <Alert bsStyle="info">
                Innehåll i basdata visas inte eftersom patientens samtycke saknas.
            </Alert>
        );
    }

    if (error) {
        return <AjaxErrorAlert error={error} />;
    }

    if (isLoading || !result) {
        return <LoadingAlert />;
    }

    return (
        <div>
            {typeof children === 'function' ? children(result) : children}
        </div>
    );
});
