import { incaAccessor } from '@cancercentrum/inca';
import { Alert, Button } from '@cancercentrum/rcc-bootstrap';

export const ButtonActionGroup = (props: {
    canCreateNew: boolean;
    onCreateNew: () => void;
    disabled?: boolean;
}): JSX.Element => {
    const { canCreateNew, onCreateNew, disabled } = props;
    const supportsTabs = !!incaAccessor()?.overview?.supportsTabs; // Extra-guards ifall inte det globala objektet finns.

    return (
        <div>
            {!supportsTabs && (
                <Alert bsStyle="warning">
                    För att kunna skapa ny eller uppdatera befintlig måste posten öppnas i INCA:s sök/registrera.
                </Alert>
            )}

            {(supportsTabs && canCreateNew) && (
                <Button onClick={onCreateNew} disabled={disabled}>Skapa ny</Button>
            )}
        </div>
    );
};
