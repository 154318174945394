import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type { ReactElement } from 'react';
import { memo } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const StatusCheckbox = (props: {
    checked?: boolean;
    className?: string;
}): ReactElement => {
    const { checked, className } = props;

    return <FontAwesomeIcon icon={checked ? faCheck : faTimes} fixedWidth={true} className={classnames(className, { 'color-success': checked, 'color-danger': !checked })} />;
};

export default memo(StatusCheckbox);
