import { useContext } from 'react';
import { DropdownButton, MenuItem } from '@cancercentrum/rcc-bootstrap';
import type { ReactElement } from 'react';
import ChartContext from '../../components/Chart/ChartContext';

export const RangeSelector = (): ReactElement => {
    const ctxChart = useContext(ChartContext);
    if (!ctxChart) {
        throw new Error('ChartContext is missing.');
    }

    const selectedRange = ctxChart.range.selected;

    return (
        <DropdownButton
            className="btn-group"
            title={selectedRange?.text ?? 'Välj intervall'}
            bsSize="sm"
            id="GraphIntervals"
        >
            {(ctxChart.range.ranges).map(range => {
                return <MenuItem key={range.key} onClick={() => ctxChart.range.set(range)}>{range.text}</MenuItem>;
            })}
        </DropdownButton>
    );
};
