import type { ReactElement } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { renderToStaticMarkup } from 'react-dom/server';
import useChartContext from './ChartContext/useChartContext';
import useSeriesContext from './SeriesContext/useSeriesContext';
import { isEqualExclFunctions } from './utils';
import type { GraphPointObject } from './types';
import { TooltipModeContext } from './TooltipModeContext';

export const Point = (props: {
    renderTooltip: () => ReactElement;
    renderTooltipPatientINCA?: () => ReactElement;
} & Omit<GraphPointObject, 'tooltip'>): null => {
    const { id, renderTooltip, renderTooltipPatientINCA, ...rest } = props;
    const chartCtx = useChartContext();
    const ctxSeriesId = useSeriesContext();
    const pointId = useMemo(() => id || uniqueId('Point_'), [id]);
    const pointOpts = {
        id: pointId,
        tooltip: renderToStaticMarkup((
            <TooltipModeContext.Provider value="ipo">{renderTooltip()}</TooltipModeContext.Provider>
        )),
        tooltipPatientINCA: renderToStaticMarkup((
            <TooltipModeContext.Provider value="e-tjanster">{renderTooltipPatientINCA ? renderTooltipPatientINCA() : renderTooltip()}</TooltipModeContext.Provider>
        )),
        ...rest,
    };
    const pointOptsRef = useRef(pointOpts);

    useEffect(() => {
        chartCtx.addPoint(ctxSeriesId, pointOpts, false /* redraw */);

        return () => {
            chartCtx.removePoint(ctxSeriesId, pointId, false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointId]);

    useEffect(() => {
        if (!isEqualExclFunctions(pointOpts, pointOptsRef.current)) {
            chartCtx.updatePoint(ctxSeriesId, pointId, pointOpts, false /* redraw */);
            pointOptsRef.current = pointOpts;
        }
    });

    return null;
};

export default Point;
