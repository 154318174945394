import type { MouseEvent } from 'react';
import { useMemo } from 'react';
import { Alert, Button, Modal } from '@cancercentrum/rcc-bootstrap';
import type { RowModel } from '@cancercentrum/rcc-react';
import { dataUtils, DetachedRow, Question, useDataActions, useIncaForm } from '@cancercentrum/rcc-react';
import { format } from 'date-fns';
import { ModalWrapper } from '../../../components/ModalWrapper';
import useCurrentUser from '../../hooks/useCurrentUser';
import { PropagationBoundary } from '../../../components/PropagationBoundary';
import type { OrganisationsenhetVDItem } from '../../types';

interface MeddelandeModalProps {
    visible: boolean;
    onClose: () => void;
    position: OrganisationsenhetVDItem;
    defaultTitle?: string;
}

const MeddelandeModal = (props: MeddelandeModalProps) => {
    const { visible, onClose, position, defaultTitle } = props;
    const cu = useCurrentUser();
    const inca = useIncaForm();
    const dataActions = useDataActions();
    const initialRow = useMemo(() => {
        const newRow = dataUtils.createDataRow('Meddelande', inca.form.metadata);
        return dataUtils
            .createViewModel(newRow, inca.form.metadata, 'Meddelande')
            .setRegvarValue('Med_avsandare_vd', cu.position.id)
            .setRegvarValue('Med_mottagare_vd', position.id)
            .setRegvarValue('Med_rubrik', defaultTitle || '');
    }, [defaultTitle, position.id, cu.position.id, inca]);

    const close = (e: MouseEvent<HTMLButtonElement>, reset: () => void) => {
        e.preventDefault();
        e.stopPropagation();
        reset();
        onClose();
    };

    const submit = (e: MouseEvent<HTMLButtonElement>, detachedRow: RowModel, reset: () => void) => {
        dataActions.updateRow(r => {
            return r.addSubTableRow(
                detachedRow.clone().setRegvarValue('Med_tidsstampel', format(new Date(), 'yyyy-MM-dd HH:mm:ss')),
            );
        }, { path: [] });

        close(e, reset);
    };

    return (
        <PropagationBoundary>
            <DetachedRow id={`msg_${initialRow.getId()}`} initialRow={initialRow}>
                {({ reset, row: dr, validate }) => {
                    return (
                        <ModalWrapper show={visible} onHide={onClose} keyboard={false}>
                            <Modal.Header closeButton={false}>
                                <Modal.Title>Skicka meddelande till {position.data.PosNameWithCode}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert bsStyle="info">Kom ihåg att spara posten efter att du skickat meddelandet.</Alert>

                                <Question regvar={dr.getRegvar('Med_rubrik')} required={true} />
                                <Question regvar={dr.getRegvar('Med_text')} required={true} textarea={true} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={e => close(e, reset)} bsStyle="link">Avbryt</Button>
                                <Button onClick={e => validate() && submit(e, dr, reset)} bsStyle="primary">Skicka meddelande</Button>

                            </Modal.Footer>
                        </ModalWrapper>
                    );
                }}
            </DetachedRow>
        </PropagationBoundary>
    );
};

export default MeddelandeModal;
