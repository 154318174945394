import type { MouseEventHandler, ReactElement } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Condition, DropdownList, HighlightText, Question, useDataActions } from '@cancercentrum/rcc-react';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import { ReadOnlyContext } from '../../components/ReadOnlyContext';
import useLayoutSettings from '../../hooks/useLayoutSettings';
import type { BiverkanRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { VdButtonGroup } from '../../../components/VdButtonGroup';
import type { CTCAEGrade, CTCAEItem, CTCAETerm } from './types';

type BiverkanPickerProps = {
    row: BiverkanRowModel;
    ctcae: CTCAEItem[];
}

interface ExtendedAreaTerm extends CTCAETerm {
    data: CTCAETerm['data'] & CTCAEItem['data'];
}

const termTextFormatter = (term: ExtendedAreaTerm, searchText?: string): ReactElement => (
    <div className="biverkanrad">
        <div className="biverkanrad__term"><HighlightText text={term.data.term_text} search={searchText} /></div>
        <div className="biverkanrad__area">(<HighlightText text={term.data.area_text} search={searchText} />) <span className="label label-info pull-right ms-2">CTCAE {term.data.area_version}</span></div>
        <div className="biverkanrad__beskrivning"><small><HighlightText text={term.data.term_definition} search={searchText} /></small></div>
    </div>
);
const termSearchTextFormatter = (term: ExtendedAreaTerm): string => term.data.area_text + ' ' + term.data.term_text + ' ' + term.data.term_definition;

const gradeTextFormatter = (grade: CTCAEGrade): ReactElement => (
    <div className="biverkanrad">
        <div><strong>{grade.data.grade_text}</strong></div>
        <div className="biverkanrad__beskrivning"><small>{grade.data.grade_definition}</small></div>
    </div>
);

export const BiverkningPicker = (props: BiverkanPickerProps): ReactElement => {
    const { row, ctcae } = props;
    const settings = useLayoutSettings();
    const [showFritext, setShowFritext] = useState(() => {
        return !!row.getRegvarValue('Bv_biverkan_alt');
    });
    const allTerms = useMemo(() => {
        const mapped = ctcae.reduce<ExtendedAreaTerm[]>((red, area) => {
            const areaTerms = area.subTables.Term;
            const extendedAreaTerms = areaTerms.map<ExtendedAreaTerm>(x => {
                return {
                    ...x,
                    data: { ...x.data, ...area.data },
                };
            });
            return [...red, ...extendedAreaTerms];
        }, []);

        return sortBy(mapped, [x => x.data.area_text, x => x.data.term_text]);
    }, [ctcae]);
    const layoutSettings = useLayoutSettings();
    const ctcaeTerm = row.getRegvarValue('Bv_ctcae_term_vd');
    const selectedTerm = useMemo(() => {
        return allTerms.find(x => x.id === ctcaeTerm);
    }, [ctcaeTerm, allTerms]);

    const grades = useMemo(() => {
        return selectedTerm?.subTables.Grade.map(g => ({ ...g, value: g.id }));
    }, [selectedTerm]);

    const selectableTerms = useMemo(() => {
        if (layoutSettings.medDRACodes) {
            return allTerms.filter(x => x === selectedTerm || includes(layoutSettings.medDRACodes, x.data.term_medDRA_code));
        }
        return allTerms;
    }, [selectedTerm, allTerms, layoutSettings.medDRACodes]);

    const dataActions = useDataActions();
    const clearGrade = useCallback(() => {
        dataActions.updateRow(r => r.clearRegvarValue('Bv_ctcae_grade_vd'));
    }, [dataActions]);

    const isReadOnly = useContext(ReadOnlyContext);

    const showOther = !!selectedTerm && selectedTerm.data.term_text.includes('Other, specify');

    const onToggleFritext: MouseEventHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        dataActions.updateRow(x => x
            .clearRegvarValue('Bv_ctcae_term_vd')
            .clearRegvarValue('Bv_biverkan_alt'));
        setShowFritext(val => !val);
    };

    const fritextMode = showFritext && !!settings.allowBiverkanFritext;

    return (
        <>
            {!fritextMode && (
                <div>
                    <Question
                        regvar={row.getRegvar('Bv_ctcae_term_vd')}
                        formatter={termTextFormatter}
                        formatterText={termSearchTextFormatter}
                        options={selectableTerms}
                        componentClass={DropdownList}
                        className="biverkan-question"
                        labelClassName="col-sm-3"
                        wrapperClassName="col-sm-9"
                        onValueChange={clearGrade}
                        disabled={isReadOnly}
                        required={true}
                        label="Misstänkt biverkning"
                    />
                    {!!settings.allowBiverkanFritext && !row.getRegvarValue('Bv_ctcae_term_vd') && (
                        <div className="form-group row">
                            <div className="offset-sm-3 col-sm-9">
                                Saknas biverkningen i listan ovan? <a href="src/form-oversikt/modules/biverkan" onClick={onToggleFritext}>Klicka för att ange i fritext.</a>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {fritextMode && (
                <div>
                    <Question
                        regvar={row.getRegvar('Bv_biverkan_alt')}
                        labelClassName="col-sm-3"
                        wrapperClassName="col-sm-9"
                        required={true}
                    />
                    <div className="form-group row">
                        <div className="offset-sm-3 col-sm-9">
                            <a href="src/form-oversikt/modules/biverkan" onClick={onToggleFritext}>Välj biverkning bland CTCAE.</a>
                        </div>
                    </div>
                </div>
            )}
            <Condition if={showOther}>
                <Question
                    regvar={row.getRegvar('Bv_biverkanSpec')}
                    labelClassName="col-sm-3"
                    wrapperClassName="col-sm-9"
                    label="Ange misstänkt biverkning"
                />
            </Condition>
            {!!grades?.length && (
                <Question
                    regvar={row.getRegvar('Bv_ctcae_grade_vd')}
                    formatter={gradeTextFormatter}
                    options={grades || []}
                    componentClass={VdButtonGroup}
                    vertical
                    labelClassName="col-sm-3"
                    wrapperClassName="col-sm-9"
                    disabled={isReadOnly}
                />
            )}
        </>
    );
};
