import type { ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';
import { GenderIcon } from '@cancercentrum/rcc-react';
import { useIPO } from './hooks/useIPO';

const PersonuppgifterStickyBottom = (props: {
    bottom: number;
}): ReactElement => {
    const { patient } = useIPO();
    const { bottom } = props;
    const inputRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const iframe = window.top!.document.querySelector<HTMLIFrameElement>(`iframe[name="${window.name}"]`);
        let offsetTop = 100;

        if (iframe) {
            offsetTop = iframe.offsetTop;
        }

        const onScroll = function (this: Window) {
            const pos = this.document.body.clientHeight - window.top!.pageYOffset - window.top!.innerHeight + bottom;

            setVisible(window.top!.pageYOffset > offsetTop);

            window.requestAnimationFrame(() => {
                if (inputRef.current) {
                    inputRef.current.style.bottom = `${pos}px`;
                }
            });
        };
        const onResize = function (this: Window) {
            const pos = this.document.body.clientHeight - window.top!.pageYOffset - window.top!.innerHeight + bottom;

            window.requestAnimationFrame(() => {
                if (inputRef.current) {
                    inputRef.current.style.bottom = `${pos}px`;
                }
            });
        };

        window.top!.addEventListener('scroll', onScroll);
        window.top!.addEventListener('resize', onResize);

        return () => {
            window.top!.removeEventListener('scroll', onScroll);
            window.top!.removeEventListener('resize', onResize);
        };
    }, [bottom]);

    return (
        <div ref={inputRef} className="personuppgifter-sticky">
            {visible && (
                <div className="personuppgifter-sticky__content">
                    <div className="alert alert-info mb-0" style={{ padding: '5px 10px', border: '1px solid' }}>
                        <GenderIcon gender={patient.gender} /> {patient.lastName}, {patient.firstName} ({patient.ssn})
                    </div>
                </div>
            )}
        </div>
    );
};

export default PersonuppgifterStickyBottom;
