import type { ChangeEventHandler, ReactElement } from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import type { RegvarModel } from '@cancercentrum/rcc-react';
import { useDataActions, useI18n } from '@cancercentrum/rcc-react';
import type { RegvarBooleanMetadata, RegvarValueDomainMetadata } from '@cancercentrum/inca';
import type { HierarchifiedSubstansItem, UtvaldSubstansVDItem } from '../types';
import Question from '../../../components/IPOQuestion';
import useDataSet from '../../../hooks/useDataSet';
import LakemedelPicker from './LaggTillRegimSubstans/LakemedelPicker';

export const SubstanceQuestion = (props: {
    showOnlyChosenQuestion?: boolean;
    availableSubstanser: HierarchifiedSubstansItem[];
    vdUtvaldaSubstanser: UtvaldSubstansVDItem[];
    disabled?: boolean;
    substanceRegvar: RegvarModel<RegvarValueDomainMetadata>;
    gesInomRamenAvStudie?: RegvarModel<RegvarBooleanMetadata>;
    startdatum: string | null;
    labelClassName?: string;
    wrapperClassName?: string;
    label?: string;
    required?: boolean;
}): ReactElement => {
    const {
        showOnlyChosenQuestion,
        vdUtvaldaSubstanser,
        availableSubstanser,
        disabled,
        substanceRegvar,
        startdatum,
        gesInomRamenAvStudie,
        labelClassName = 'col-sm-2',
        wrapperClassName = 'col-sm-10',
        label,
        required,
    } = props;
    const { t } = useI18n();
    const errors = substanceRegvar.$errors.toArray();
    const [showChosen, setShowChosen] = useState(false);
    const dataActions = useDataActions();
    const dataset = useDataSet();

    const onChosenChange: ChangeEventHandler<HTMLInputElement> = e => {
        setShowChosen(e.target.checked);
    };

    const onGesInomRamenAvStudieChange = (val: unknown) => {
        if (!val && dataset.Läkemedel!.Lkm_studie_vd) {
            dataActions.updateRow(x => x.clearRegvarValue('Lkm_studie_vd'), { skipHistory: true });
        }
    };

    return (
        <div className={classnames('form-group row', { 'is-error': !substanceRegvar.$errors.isEmpty() && substanceRegvar.$isDirty })}>
            <label htmlFor="LakSubSelector" className={classnames('form-label', labelClassName)}>{label || 'Ange läkemedel'}</label>
            <div className={classnames(wrapperClassName, 'd-flex align-items-center')}>
                <div>
                    <LakemedelPicker
                        id="LakSubSelector2"
                        disabled={!!disabled}
                        regvar={substanceRegvar}
                        vdSubstanser={availableSubstanser}
                        vdUtvaldaSubstanser={vdUtvaldaSubstanser}
                        showChosenSubtances={showChosen}
                        startdatum={startdatum}
                        required={required}
                    />
                </div>
                {showOnlyChosenQuestion && (
                    <div className="mb-0-all-children">
                        <input type="checkbox" onChange={onChosenChange} checked={showChosen} className="form-check-input" id="SubstancePickerChosen" />
                        <label className="form-check-label ms-2" htmlFor="SubstancePickerChosen">
                            Visa endast nya utvalda cancerläkemedel
                        </label>
                    </div>
                )}
                {!!gesInomRamenAvStudie && (
                    <div className="mb-0-all-children">
                        <Question
                            regvar={gesInomRamenAvStudie}
                            wrapperClassName="nostretch ms-2"
                            inline={true}
                            onValueChange={onGesInomRamenAvStudieChange}
                        />
                    </div>
                )}

            </div>
            {errors.length > 0 && substanceRegvar.$isDirty && (
                <div className="offset-sm-3 col-sm-9">
                    {errors.map(x => {
                        return <p key={x.rule} className="error-feedback">{t(x.message, x.options)}</p>;
                    })}
                </div>
            )}
        </div>
    );
};
