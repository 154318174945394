import type { ReactNode } from 'react';
import { Card } from '@cancercentrum/rcc-bootstrap';
import { createRoot } from 'react-dom/client';

const DialogContent = (props: {
    header?: ReactNode;
    content: ReactNode;
}) => {
    const { header, content } = props;

    return (
        <form method="dialog">
            <Card>
                {!!header && <Card.Header>{header}</Card.Header>}
                <Card.Body>{content}</Card.Body>
                <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
                    <button className="btn btn-link">Avbryt</button>
                    <button className="btn btn-primary" value="ok" style={{ minWidth: '5rem' }}>OK</button>
                </Card.Footer>
            </Card>
        </form>
    );
};

export const fancyConfirm = (options: {
    header?: ReactNode;
    content: ReactNode;
}): Promise<boolean> => {
    return new Promise((resolve) => {
        const { header, content } = options;
        const dialog = document.createElement('dialog');
        if (!('showModal' in dialog)) {
            throw new Error('Not supported.');
        }

        document.body.append(dialog);

        const root = createRoot(dialog);

        dialog.addEventListener('close', () => {
            resolve(dialog.returnValue === 'ok');

            // Cleanup.
            root.unmount();
            dialog.remove();
        });

        root.render(<DialogContent header={header} content={content} />);
        dialog.showModal();

        dialog.scrollIntoView({
            block: 'center',
        });
    });
};
