import type { RegvarModel, RowModel, ValueDomainModel } from '@cancercentrum/rcc-react';
import { AjaxErrorAlert, useIsMounted, usePromise } from '@cancercentrum/rcc-react';
import { Button, Panel } from '@cancercentrum/rcc-bootstrap';
import type { DesignedFormMetadata, FormData, RegvarListMetadata } from '@cancercentrum/inca';
import { errandUtils, formUtils } from '@cancercentrum/inca';
import type { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import usePDL from '../../pdl/usePDL';
import { useGetLakemedelSubTable } from '../lakemedel';
import useConfig from '../../hooks/useConfig';
import { useIPO } from '../../hooks/useIPO';

const createAndAddRow = (parent: FormData, tableName: string, metadata: DesignedFormMetadata) => {
    const childRow = formUtils.createDataRow(tableName, metadata);
    parent.subTables[tableName].push(childRow);
    return childRow;
};

const isImmutableModel = (input: any): input is RegvarModel<any> | ValueDomainModel => {
    return !!input.$rcc;
};

export const CreateNewExtBiverkningPanel = (props: {
    row: RowModel,
    onCreated: (result: any) => void;
}): ReactElement | null => {
    const { row, onCreated } = props;
    const p = usePromise();
    const ipo = useIPO();
    const isMounted = useIsMounted();
    const lkmSubTable = useGetLakemedelSubTable();
    const cfg = useConfig();
    const pdl = usePDL();
    const createReport = () => {
        let errandId: number | null = null;

        p.exec(() => errandUtils.createErrandAndExecuteActionByShortname(
            'Läkemedelsbiverkan',
            'report_biverkning_r9',
            ipo.patient.id,
            'spara',
            {
                onBeforeExecute: (errand, df, metadata) => {
                    errandId = errand.id;

                    errand.data.regvars.r_ipo_uuid.value = row.getRegvarValue('Biv_uuid');

                    const safetyreport = createAndAddRow(errand.data, 'safetyreport', metadata);
                    safetyreport.regvars.send_status.value = (metadata.safetyreport.regvars.send_status as RegvarListMetadata).listValues.find(x => x.identifier === 'ej_skickad')?.id || null;
                    safetyreport.regvars.safetyreportversion.value = 1;
                    if (ipo.patient.dateOfDeath) {
                        safetyreport.regvars.patientdeathdate.value = ipo.patient.dateOfDeath.trim();
                        safetyreport.regvars.patientdeathreport.value = (ipo.patient.causeOfDeathCode || '').trim();
                    }

                    safetyreport.regvars.primarysource_reportergivename.value = pdl.currentUser.user.firstName;
                    safetyreport.regvars.primarysource_reporterfamilyname.value = pdl.currentUser.user.lastName;
                    safetyreport.regvars.primarysource_reporterorganization.value = pdl.currentUser.position.description;
                    safetyreport.regvars.primarysource_reporterdepartment.value = pdl.currentUser.position.name;
                    safetyreport.regvars.primarysource_reporterstreet.value = pdl.currentUser.position.address?.streetAddress || null;
                    safetyreport.regvars.primarysource_reporterpostcode.value = pdl.currentUser.position.address?.zip || null;
                    safetyreport.regvars.primarysource_reportercity.value = pdl.currentUser.position.address?.city || null;

                    row.getSubTableRows('BiverkanSubstans').forEach(bivSub => {
                        const drug = createAndAddRow(safetyreport, 'drug', metadata);
                        drug.regvars.substans_vd.value = bivSub.getRegvarValue('BivSub_substans_vd');
                        drug.regvars.medicinalproduct.value = bivSub.getRegvarValue('BivSub_substans_text'); // Om studieläkemedel

                        const relatedLkminsattning = lkmSubTable.getRows()
                            .filter(x => {
                                if (x.getRegvarValue('Lkm_exkluderad') || x.getRegvarValue('Lkm_migreringsdublett') || x.getRegvarValue('Lkm_startdatum')! > row.getRegvarValue('Biv_datum')) {
                                    return false;
                                }

                                const subId = x.getRegvarValue('Lkm_substans_vd');
                                const subText = x.getRegvarValue('Lkm_studielakemedel_spec');

                                return subId ? subId === drug.regvars.substans_vd.value : subText === drug.regvars.medicinalproduct.value;
                            })
                            .sortBy(x => x.getRegvarValue('Lkm_startdatum')).last();

                        if (relatedLkminsattning) {
                            drug.regvars.drugstartdate.value = relatedLkminsattning.getRegvarValue('Lkm_startdatum');
                            drug.regvars.drugenddate.value = relatedLkminsattning.getRegvarValue('Lkm_stoppdatum');
                        }
                    });

                    row.getSubTableRows('Biverkan').forEach(biv => {
                        const reaction = createAndAddRow(safetyreport, 'reaction', metadata);
                        reaction.regvars.reactionstartdate.value = row.getRegvarValue('Biv_datum');
                        const term = biv!.getRegvarValue('Bv_ctcae_term_vd');
                        if (term) {
                            reaction.regvars.reaction_ctcae_term_vd.value = term;
                            reaction.regvars.reaction_ctcae_term_spec.value = biv!.getRegvarValue('Bv_biverkanSpec');
                        } else {
                            reaction.regvars.primarysourcereaction.value = biv!.getRegvarValue('Bv_biverkan_alt');
                        }
                    });

                    const medicalHistoryEpisode = createAndAddRow(safetyreport, 'medicalhistoryepisode', metadata);
                    medicalHistoryEpisode.regvars.patientepisodename.value = cfg.diagnos;
                },
            }).then(res => {
            if (isMounted.current) {
                onCreated(res.result);

                if (errandId) {
                    window.open(`/Errand/Details/${errandId}`, '_blank');
                }

                errandId = null;
            }

            return res;
        }));
    };
    const hasErrors = row.getErrors({ includePristine: true }).some(x => isImmutableModel(x) ? !x.$errors.isEmpty() : x.$errors.length > 0);

    return (
        <Panel bsStyle="info">
            <Panel.Heading>Utökad biverkningsrapportering till Läkemedelsverket</Panel.Heading>
            <Panel.Body>
                <p>Klicka på knappen nedan för att påbörja en utökad rapport som sedan kan skickas till Läkemedelsverket.</p>
                <p>När rapporten skapas förifylls den med viss data men du som användare måste komplettera med nödvändig information innan den kan skickas.</p>

                {!hasErrors && (
                    <div className="mt-4">
                        {process.env.RCC_ENV !== 'external_demo' && (
                            <Button onClick={createReport} disabled={p.isLoading || hasErrors}>
                                <FontAwesomeIcon icon={p.isLoading ? faSpinner : faPlusCircle} spin={p.isLoading} fixedWidth={true} className="me-1" />
                                Skapa utökad rapport till läkemedelsverket
                            </Button>
                        )}
                        {process.env.RCC_ENV === 'external_demo' && <div><em>Det är inte möjligt att skapa utökad rapport i demomiljön.</em></div>}
                    </div>
                )}
                {hasErrors && (
                    <div className="mt-4">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                        <em>Du måste fylla i alla obligatoriska fält innan en utökad rapport kan skapas.</em>
                    </div>
                )}

                {!!p.error && <AjaxErrorAlert error={p.error} className="mt-3 mb-0" />}
            </Panel.Body>
        </Panel>
    );
};
