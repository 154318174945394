import { Modal } from '@cancercentrum/rcc-bootstrap';
import type { ModalProps } from '@cancercentrum/rcc-bootstrap';

const IE11_OFFSET = 350;
const MARGIN_OFFSET = 15;

const onEnter = (): void => {
    const topWindow = window.top || window;
    const iframe: HTMLIFrameElement | null = topWindow.document.querySelector(`iframe[name="${window.name}"]`);

    if (iframe) {
        const offset = iframe.offsetTop ?? IE11_OFFSET;
        const pos = offset - MARGIN_OFFSET;

        topWindow.scrollTo({ top: pos, behavior: 'smooth' });
    }
};

export const ModalWrapper = (props: ModalProps): JSX.Element => {
    return (
        <Modal onEnter={onEnter} {...props} />
    );
};
