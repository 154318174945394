import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MutableRefObject, ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';
import { Panel } from '@cancercentrum/rcc-bootstrap';
import type { HookDataActions, RowModel, RowModelTableMetadata } from '@cancercentrum/rcc-react';
import { IncaRowContext, useIncaForm } from '@cancercentrum/rcc-react';
import { format, parseISO } from 'date-fns';
import classnames from 'classnames';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import last from 'lodash/last';
import { isRowReadOnly } from '../utils/row';
import useRowHistory from '../hooks/useRowHistory';
import RRHistoryContext from './RRHistoryContext';
import { ReadOnlyContext } from './ReadOnlyContext';

export type RenderCallback<SubTableMetadata extends RowModelTableMetadata> = (data: {
    row: RowModel<SubTableMetadata>;
    dataActions: HookDataActions<SubTableMetadata>;
}) => ReactNode;

export interface RowPanelRenderer<SubTableMetadata extends RowModelTableMetadata> {
    renderHeader: RenderCallback<SubTableMetadata>;
    renderContent: RenderCallback<SubTableMetadata>;
}

interface RowPanelProps<SubTableMetadata extends RowModelTableMetadata> {
    row: RowModel<SubTableMetadata>;
    renderer: MutableRefObject<RowPanelRenderer<SubTableMetadata>>;
    visible: boolean;
    isRootRow?: boolean;
    allowEditingForAllPositions?: boolean;
}

const isDirty = (row: RowModel): boolean => {
    const allSubTableRows = row.$rcc.tableMetadata.subTables.reduce<RowModel[]>((red, st) => {
        return [...red, ...row.getSubTableRows(st).toArray()];
    }, []);

    const regvars = Object.keys(row.$rcc.tableMetadata.regvars);
    const hasDirtyRegvars = regvars.some(x => row.getIn(['regvars', x, '$isDirty']));

    return !row.id || hasDirtyRegvars || allSubTableRows.some(isDirty);
};

export const RowPanel = <SubTableMetadata extends RowModelTableMetadata>(props: RowPanelProps<SubTableMetadata>): ReactElement | null => {
    const { row, renderer, visible, isRootRow, allowEditingForAllPositions } = props;
    const incaFrm = useIncaForm();
    const isDisabled = isRowReadOnly(row, incaFrm.currentUser);
    const rrHistory = useRowHistory(row.$extra.registerRecordId, visible);
    const firstHistoryRow = rrHistory && last(rrHistory[row.$rcc.tableName]);
    const rowContextParams = isRootRow ? { path: [] } : {
        rowId: row.getId(),
        tableName: row.$rcc.tableName,
    };

    const shouldRender = useMemo(() => {
        if (visible) {
            return true;
        }

        return !isDisabled && isDirty(row);
    }, [visible, row, isDisabled]);

    if (!shouldRender) {
        return null;
    }

    return (
        <ReadOnlyContext.Provider value={!allowEditingForAllPositions && isDisabled}>
            <RRHistoryContext.Provider value={rrHistory}>
                <IncaRowContext {...rowContextParams}>
                    {({ dataActions }) => {
                        const renderData = {
                            row,
                            dataActions: dataActions as unknown as HookDataActions<SubTableMetadata>,
                        };

                        return (
                            <Panel
                                className={classnames({
                                    'd-none': !visible,
                                })}
                            >
                                <Panel.Heading>
                                    <Panel.Title>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">{renderer.current.renderHeader(renderData)}</div>
                                            {firstHistoryRow && (
                                                <div>
                                                    <small className="pull-right">
                                                        <FontAwesomeIcon icon={faInfoCircle} /> Denna rad skapades {format(parseISO(firstHistoryRow.date), 'yyyy-MM-dd')} av användaren {firstHistoryRow.savedBy}
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body>
                                    <div className="form-horizontal">
                                        {renderer.current.renderContent(renderData)}
                                    </div>
                                </Panel.Body>
                            </Panel>
                        );
                    }}
                </IncaRowContext>
            </RRHistoryContext.Provider>
        </ReadOnlyContext.Provider>
    );
};
