import type { ReactElement, ReactNode } from 'react';
import ScatterSeries from '../../components/Chart/ScatterSeries';
import CategoriesAxis from '../../components/Chart/CategoriesAxis';
import { isValidDate } from '../../utils/date';
import type { KontaktRowModel } from '../../../offline/muu2.0/form-oversikt/brostcancer/form.brostcancer.muu';
import { useGetKontaktSubTable } from './useGetKontaktSubTable';
import KontaktPoint from './KontaktPoint';

const CATEGORIES = ['Kontakttillfälle'];

export const KontaktAxis = (props: {
    renderTooltip?: (row: KontaktRowModel) => ReactElement;
    children?: ReactNode;
}): ReactElement => {
    const { renderTooltip, children } = props;
    const subTable = useGetKontaktSubTable();
    const points = subTable.getRows().toArray().reduce<ReactElement[]>((red, row) => {
        const datum = row.getRegvarValue('K_besoksdatum');
        if (!isValidDate(datum)) {
            return red;
        }

        red.push(<KontaktPoint key={row.getId()} row={row} y={0} getTooltip={renderTooltip} />);

        return red;
    }, []);

    return (
        <CategoriesAxis categories={CATEGORIES} plotBands={true}>
            <ScatterSeries>
                {points}
                {children}
            </ScatterSeries>
        </CategoriesAxis>
    );
};
